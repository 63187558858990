import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    // showEasing: "swing",
    // hideEasing: "linear",
    // showMethod: "fadeIn",
    // hideMethod: "fadeOut"
  };

  
  const useHook = () => {
    const catalogGet = async (body) => {
        try {
            const response = await AuthAPI.getCatalogs(body);
            console.log("usehook response", response)
            return response
        } catch (error) {
            // toast.error("An error occurred during vendor creation");
            toastr.error("An error occurred during vendor creation")
        }
    }

    return {
        catalogGet
    }
  }
  
  export default useHook;
  











// import { AuthAPI } from "libs/http-service/api/auth.api";
// import toastr from 'toastr';
// toastr.options = {
//     closeButton: true,
//     debug: false,
//     newestOnTop: false,
//     progressBar: true,
//     positionClass: "toast-top-right",
//     preventDuplicates: false,
//     onclick: null,
//     showDuration: "300",
//     hideDuration: "1000",
//     timeOut: "3000",
//     extendedTimeOut: "1000",
//     // showEasing: "swing",
//     // hideEasing: "linear",
//     // showMethod: "fadeIn",
//     // hideMethod: "fadeOut"
//   };

  
//   const useHook = () => {
//     const bannersPost = async (body) => {
//         try {
//             console.log("usehook body", body)
//             const response = await AuthAPI.bannerPost(body);
//             console.log("usehook response", response)
//         } catch (error) {
//             // toast.error("An error occurred during vendor creation");
//             toastr.error("An error occurred during vendor creation")
//         }
//     }
// // image post
//     const imagePost = async (file) => {
//         try {
//             if (!file) {
//                 throw new Error("No file provided");
//             }
//                 // Check if file size is greater than 2MB
//         if (file.size > 2 * 1024 * 1024) { // 2MB limit
//             toastr.error("File size exceeds 2MB limit");
//             return null;
//         }

//         // Check if file type is an image
//         const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']; // Add more types if needed
//         if (!acceptedTypes.includes(file.type)) {
//             toastr.error("File type not supported. Please upload an image file (jpg, jpeg, png, gif).");
//             return null;
//         }
//             const reader = new FileReader();
//             const fileAsDataURL = new Promise((resolve, reject) => {
//                 reader.onload = () => resolve(reader.result);
//                 reader.onerror = reject;
//             });
//             reader.readAsDataURL(file);
//             await fileAsDataURL;  // Wait for the file to be read as data URL
//             const response = await AuthAPI.Postimages(
//                 { image: file },
//                 { headers: { "Content-Type": "multipart/form-data" } }
//             );
//             console.log("Image upload response", response);
//             if (response?.path) {
//                 toastr.success("Image uploaded successfully");
//                 console.log("response path",response.path)
//                 return response.path;
//             } else {
//                 alert("Image upload failed");
//                 return null;
//             }
//         } catch (error) {
//             console.error("Error uploading image:", error);
//             alert("An error occurred during image upload");
//             return null;
//         }
//     };
//     // const categoriesGet = async (params) => {
//     //     try {
//     //         const response = await AuthAPI.categoriesGet(params);
//     //         console.log("categories get response", response);
//     //         return response.data || [];
//     //     } catch (error) {
//     //         alert('There was an error getting the color list: ' + error.message);
//     //         // console.error('Error details:', error);
//     //         return [];
//     //     }
       
//     // };
//     // const categoriesPut = async (id, isActive) => {
//     //     try {
//     //         const response = await AuthAPI.categoriesPut(id, { isActive });
//     //         toastr.success("Switch updated successfully");
//     //         return response.data || [];
//     //     } catch (error) {
//     //         alert('There was an error posting the switch: ' + error.message);
//     //         console.error('Error details:', error);
//     //         return [];
//     //     }
//     // };
//     return {
//         imagePost,bannersPost
//     }
//   }
  
//   export default useHook;
  