import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };

const useHook = () => {


const getOrderList=async(body)=>{
 try{
    const response=await AuthAPI.getOrders(body);
    return response;
 } 
 catch(error){
    console.error("Error getting userlist",error)
 }
}


    return {
        getOrderList
        }

    }

    export default useHook
