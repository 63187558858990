// import Dashboard from '@containers/dashboard'
import Sidebar from "components/sidebar";
import DashboardRoutes from "../../../containers/dashboard";
import React, { useState } from "react";
import Header from "components/header";
import { Main, Navbar } from "./style";

const DasboardLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <Main>
      <div className="container-fluid p-0">
       <div
          style={{
            width: "100%",
            height: "100vh",
            padding: "0px",
            margin: "0px",
          }}
        >
          <div className="width1 p-0 ">
            <div className="fixed">
              <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            </div>
          </div>
          <div className="cont p-0">
            <Navbar>
              <div>
                <Header toggleSidebar={toggleSidebar}/>
              </div>
            </Navbar>
            <div>
              <DashboardRoutes />
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default DasboardLayout;
