import React, { useState, useEffect } from 'react';
import { Main } from './style';
import Img from '../../../assets/img/manage-banners/Upload Big Arrow.png';
import useHook from './usehooks';

const BannerModal = ({ getBanner }) => {
    const { imagePost, bannersPost } = useHook();
    const [selectedImage, setSelectedImage] = useState('');
    const [bannerName, setBannerName] = useState('');
    const handleImageChange = async (e) => {
        const file = e.target.files[0];

        try {
            const imagePath = await imagePost(file);
            console.log("FINAL: ", imagePath);

            if (imagePath) {
                setSelectedImage(imagePath);
                console.log("Uploaded Image URL:", imagePath);
            }
        } catch (error) {
            console.error("Error in handleFileInputChange: ", error);
            alert("An error occurred during image upload");
        }
    };
    const handleBannerChange = () => {
        document.getElementById('imageInput').click();
    };
    const handleBannerSubmit = async () => {
        const formData = {
            "name": bannerName,
            "image": selectedImage,
            "status": true
        }
        try {
            const response = await bannersPost(formData);
            console.log("Banner Post response", response)
            // close the  modal
            const modal = document.getElementById('exampleModalToggle');
            if (modal) {
                const closeButton = modal.querySelector('[data-bs-dismiss="modal"]');
                if (closeButton) {
                    closeButton.click(); // Simulate a click event on the close button
                    setBannerName('');
                    setSelectedImage(null);
                    getBanner()
                }
            }

        } catch (error) {
            console.error("Error here", error)
        }
    }
    const [isFormValid, setIsFormValid] = useState(false);

useEffect(() => {
    if (selectedImage && bannerName.trim() !== '') {
        setIsFormValid(true);
        
        
    } else {
        setIsFormValid(false);
    }
}, [selectedImage, bannerName]);
    return (
        <Main>
            <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-width text-dark">
                        <div className="modal-header header">
                            <h1 className="modal-title fs-5 padding" id="exampleModalToggleLabel">Add Banner</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body padding-body">
                            <label>Banner Image</label>
                            <div className='background-img mt-2' onClick={handleBannerChange}>
                                {selectedImage ? (
                                    <div className='' style={{ width: "100%", height: "100%", objectFit: "contain" }}>
                                        <img src={`${process.env.REACT_APP_BASE_URL}/${selectedImage}`} alt="Selected" className='img-width' />
                                    </div>
                                ) : (
                                    <div className='text-center'>
                                        <img src={Img} alt="" className='custom-img-width' />
                                        <p className='m-0'>Drag your images here</p>
                                        <p>(Only *.jpeg, *.webp and *.png images will be accepted)</p>
                                    </div>
                                )}
                                <input type="file" accept="image/*" style={{ display: 'none' }} id="imageInput" onChange={handleImageChange} />
                            </div>
                            <div className='mt-2'>
                                <label>Banner Name</label>
                                <div>
                                    <input type="text" name="" id="" placeholder='Category Name' className='input' value={bannerName}
                                        onChange={(e) => setBannerName(e.target.value)} />
                                </div>
                            </div>
                            <div className='mt-4'>
                                <button className='add-button' disabled={!isFormValid} onClick={handleBannerSubmit}>Add</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* <div class="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2" tabindex="-1">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">Modal 2</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            Hide this modal and show the first with the button below.
                        </div>
                        <div class="modal-footer">
                            <button class="btn btn-primary" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Back to first</button>
                        </div>
                    </div>
                </div>
            </div> */}
            <button className="banner-button" data-bs-target="#exampleModalToggle" data-bs-toggle="modal">Add New Banner</button>
        </Main>
    )
}

export default BannerModal
