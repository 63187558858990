import styled from "styled-components";

export const Main = styled.div`

.information-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.row1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    
}
.col-description{
    display: flex;
    flex-direction: column;
   
}
.description-area{
    width: 300px;
    max-height: 60px;
    min-height: 50px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
    resize: none;
}
.row2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.address-input{
    width: 300px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
}
.row3{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.select-input{
    width: 300px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
}
.row4{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 100%;
}
.zip-code{
    width: 300px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
}
.vendor-btn{
    width: 170px;
height: 48px;
/* top: 984px;
left: 1165px; */
gap: 0px;
border-radius: 5px;
opacity: 0px;
color:white;
outline: none;
border: none;
background-color: #156A3D;

}
.button-container{
    display: flex;
    justify-content: end;
}
.error-text{
    color: red;
    font-size: 12px;
    margin-bottom: 0px;
}
.main-container{
    height: 415px;
    overflow-y: auto;
}
.dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    width: 120px;
  }
`