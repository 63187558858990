import styled from "styled-components";

export const Main = styled.div`
width: 100%;
background-color: var(--all-background);

.padding-inline{
    padding-inline: 45px;
}
.padding-inline2{
    padding-inline: 28px;
}
.notifications{
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    padding-bottom: 20px;
}
.padding{
    padding: 7px 36px 7px 15px;
}
.flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.notification-heading{
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
    color: #FBAF43;
}

.notification-subheading{
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
}




`