import React, { useEffect, useRef, useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    IconButton,
    Avatar,
} from '@mui/material';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { VendorProfile } from 'assets/img/index'
import { IoMdClose } from "react-icons/io";
import { Main } from './style'
import useHook from './usehook';
import toastr from 'toastr';
import Error from "../../../assets/img/profile/Error.png";
const EditProfileModal = ({ open, closeModal, profiledata }) => {
    const { imagePost, postEditProfile } = useHook();
    const [email, setemail] = useState('');
    useEffect(() => {
        if (profiledata) {
            if (profiledata.name) {
                
                const fullName = profiledata.name.split(' ');
                setfirstName(fullName[0]);
                setlastName(fullName.length > 1 ? fullName.slice(1).join(' ') : '');
              }
              setGender(profiledata.gender)
        setContactNumber(profiledata.phone)
        setemail(profiledata.email)
        setDOB(formatDate(profiledata.dob))
        // setAvatar(profiledata.profile_pic)
        setSelectedImage(profiledata.profile_pic)
        }
    }, [profiledata])

    // const handleAvatarClick = () => {
    //     document.getElementById('avatarInput').click();
    // };
    // const handleAvatarChange = async (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setAvatarFile(file);
    //         // Pass the file directly to imagePost, not the FormData
    //         const imagePath = await imagePost(file);
    //         if (imagePath) {
    //             setAvatar(imagePath); // Update the avatar to the uploaded image path
    //         }
    //         console.log('Selected image file:', file); // Log the selected image file
    //     }
    // };



    const handleUpdate = async () => {
            // Concatenate firstName and lastName
    const name = firstName + " " + lastName;
    // Validate form fields
    const validationErrors = {};
    if (!firstName) validationErrors.firstName = "First name is required";
    if (!lastName) validationErrors.lastName = "Last name is required";
    if (!dob) {
      validationErrors.dob = "Date of birth is required";
  } else {
      const dobErrors = validateDateOfBirth(dob);
      if (Object.keys(dobErrors).length > 0) {
          validationErrors.dob = dobErrors.dob;
      }
  }
    if (!gender) validationErrors.gender = "Gender is required";
    if (!email) validationErrors.email = "Email is required";
    else if (!isValidEmail(email))
      validationErrors.email = "Email is not valid";
    if (!phone) validationErrors.phone = "Phone is required";
    else if (!isValidPhoneNumber(phone))
      validationErrors.phone = "Phone number must be between 11 and 15 digits";
    // if (!address) validationErrors.address = "Address is required";
    if (!selectedImage) validationErrors.selectedImage = "Picture required";
    // if (!bannerImage) validationErrors.bannerImage = "Banner Image is required";

    setErrors(validationErrors);

    // If there are errors, do not submit the form
    if (Object.keys(validationErrors).length > 0) return;
        const formdata = {
            "name": name,
            "countryCode": "string",
            "phone": phone,
            "isoCode": "string",
            "age": 26,
            "gender": gender,
            'dob':dob,
            "profile_pic": selectedImage,

        }
        console.log("form data is", formdata)
        try {
            // Call the postEditProfile API here
            const response = await postEditProfile(formdata);
            console.log(response)
            closeModal();
            if (response.status === true) {
                // Handle successful update, e.g., show a success message or close the modal
               
                    toastr.success(response?.message)
              
                // console.log('Closing modal...');
                // closeModal();
            } else {
                // Handle API response errors, e.g., show an error message
                toastr.error(response.errors[0]);
            }
        } catch (error) {
            // Handle any errors that occurred during the API call
            console.error('An error occurred while updating the profile:', error);
        }
    }

    // updated
    const maxPhonelength = 15;
    const minPhoneLength = 11;
    const [selectedImage, setSelectedImage] = useState("");
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('');
  const [phone, setContactNumber] = useState("");
    const [errors, setErrors] = useState({});
    const [gender, setGender] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [dob, setDOB] = useState('');

    const imageInputRef = useRef(null);
    const handleImageChange = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click();
        }
    };
    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];

        try {
            const imagePath = await imagePost(file); // Ensure this is awaited
            console.log("FINAL: ", imagePath);

            if (imagePath) {
                setSelectedImage(imagePath);
                setErrors((prevErrors) => ({ ...prevErrors, selectedImage: '' }));
                console.log("Uploaded Image URL:", imagePath);
            }
        } catch (error) {
            console.error("Error in handleFileInputChange: ", error);
            toastr.error("An error occurred during image upload");
        }
    };
    const handlePhoneChange = (newContactNumber) => {
        if (newContactNumber.length <= maxPhonelength) {
          setContactNumber(newContactNumber);
          setIsValid(newContactNumber.length >= minPhoneLength && newContactNumber.length <= maxPhonelength);
          setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
        }
      };
      const isValidPhoneNumber = (phone) => {
        // Check if phone number is between 11 and 15 digits
        return /^\d{11,15}$/.test(phone);
      };
    
      const validateDateOfBirth = (inputDate) => {
        const validationErrors = {};
        
        if (!inputDate) {
            validationErrors.dob = "Date of birth is required";
        } else {
            const dobDate = new Date(inputDate);
            const currentDate = new Date();
    
            // Calculate 14 years ago from today
            const fourteenYearsAgo = new Date();
            fourteenYearsAgo.setFullYear(currentDate.getFullYear() - 14);
    
            if (dobDate > currentDate) {
                validationErrors.dob = "Date of birth cannot be in the future";
            } else if (dobDate > fourteenYearsAgo) {
                validationErrors.dob = "You must be at least 14 years old";
            }
        }
    
        return validationErrors;
    };
    
  const isValidEmail = (email) => {
    // Simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleRemoveImage = () => {
    setSelectedImage("");
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
};
    return (
        <Modal open={open} onClose={closeModal}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 700,
                    height:520,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    borderRadius: 2,
                }}
            >
                <Main>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }} className='heading-container'>
                        <Typography variant="h6" component="h2" style={{paddingLeft:"300px"}}>
                            Edit Profile
                        </Typography>
                        <IconButton onClick={closeModal}>
                            <IoMdClose style={{color:"white"}} />
                        </IconButton>
                    </Box>
                    {/* <Box sx={{ display: 'flex',justifyContent:"center",alignItems:"center",width:"100px",height:"100px",marginLeft:"100px" ,mb: 3,border:"1px solid #D9D9D9" }}>
                        <Avatar
                            sx={{ width: 80, height: 80, cursor: 'pointer',position:"relative" }}
                            src={avatarFile ? URL.createObjectURL(avatarFile) : `${process.env.REACT_APP_MEDIA_URL}${profiledata.profile_pic || avatar}`}
                            alt="Profile"
                            onClick={handleAvatarClick}
                        />
                        <span style={{position:"absolute",top:"170px",backgroundColor:"white",boxShadow:"12px",width:"30px",height:"30px",display:"flex",justifyContent:"center",alignItems:"center",borderRadius:"12px",boxShadow:"0px 4px 12px rgba(0, 0, 0, 0.4)"}} onClick={handleAvatarClick}>
                        <CiCamera style={{fontSize:"40px"}} />
                        </span>
                        <input
                            type="file"
                            id="avatarInput"
                            style={{ display: 'none' }}
                            onChange={handleAvatarChange}
                        />
                    </Box>
                    <span>Name</span>
                    <input className='input mb-2' placeholder='Name' value={name}
                        onChange={(e) => setName(e.target.value)} />
                        <span>Email</span>
                        <input className='input mb-2' placeholder='Email' value={email} disabled
                        onChange={(e) => setemail(e.target.value)} />
                        <span>Phone Number</span>
                    <input className='input mb-2' placeholder='Phone' value={phone}
                        onChange={(e) => setPhone(e.target.value)} /> */}


                    <div className="card border-0 overflow" >
                        <div className="padding mt-4">
                            <div className="gap-4 form-container" >
                                <div className="">
                                    <div className="column">
                                        <div className="card img-border">
                                            <div className="card-body" onClick={handleImageChange}>
                                                {selectedImage ? (
                                                    <div>
                                                        <div onClick={handleRemoveImage}>
                                                            <img
                                                                src={Error}
                                                                alt=""
                                                                className="profile-picture-margin-left"
                                                            />
                                                        </div>
                                                        <img
                                                            src={
                                                                process.env.REACT_APP_BASE_URL +
                                                                "/" +
                                                                selectedImage
                                                            }
                                                            alt="Uploaded"
                                                            className="width"
                                                        />
                                                    </div>
                                                ) : (
                                                    <>
                                                        <input
                                                            ref={imageInputRef}
                                                            type="file"
                                                            accept="image/*"
                                                            className="input-field driving-license-input-field"
                                                            hidden
                                                            onChange={handleFileInputChange}
                                                            id="imageInput"
                                                        />
                                                        <span className="profile-image-container">
                                                            <VendorProfile />
                                                        </span>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        {errors.selectedImage && (
                                            <p className="error">{errors.selectedImage}</p>
                                        )}
                                    </div>
                                </div>
                                <div className="w-100 h-100">
                                    <div className="w-100">
                                        <div className="first-name">
                                            <label className="name-label">
                                                First Name
                                            </label>
                                            <input className="input-width1" list="" placeholder="First Name" autoComplete='off' value={firstName} onChange={(e) => {
                                                const newName = e.target.value;
                                                    setfirstName(newName);
                                               setErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
                                            }} />
                                            {errors.firstName && <p className="error">{errors.firstName}</p>}
                                        </div>
                                        <div className="last-name">
                                            <label className="name-label">
                                                Last Name
                                            </label>
                                            <input className="input-width1" placeholder="Last Name" autoComplete='off' value={lastName} onChange={(e) => {
                                                const newName = e.target.value;
                                                    setlastName(newName);
                                               setErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
                                            }} />
                                            {errors.lastName && <p className="error">{errors.lastName}</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="area-direction">
                                {/* email */}
                                <div className="email-container">
                                    <label className="name-label">Email</label>
                                    <div className="d-flex flex-column w-100">
                                        <input
                                            type="email" placeholder='Email' disabled
                                            name=""
                                            id=""
                                            className="email-width"
                                            value={email}
                                            onChange={(e) => { setemail(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, email: '' })); }}
                                        />
                                        {errors.email && <p className="error">{errors.email}</p>}
                                    </div>
                                </div>
                                {/* gender */}
                                <div className="gender-container" value={gender} onChange={(e) => { setGender(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, gender: '' })); }}>
                                    <label>Gender</label>
                                    <select className="select-gender" value={gender}>
                                        <option value=''>Select Gender</option>
                                        <option value='male'>Male</option>
                                        <option value='female'>Female</option>
                                        <option value='other'>other</option>
                                    </select>
                                    {errors.gender && <p className="error">{errors.gender}</p>}

                                </div>
                            </div>

                            {/* #======================= Email End============================== */}

                            {/* #======================= Contact Number Start============================== */}
                            <div className="area-direction mt-2">
                                <div className="phone-container">
                                    <label className="name-label">Phone</label>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex flex-row align-items-center">
                                            <ReactPhoneInput
                                                country={'us'} // Set default country
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                inputClass="input-width"
                                                tabIndex="-1"
                                            // Add any other props you might need
                                            />
                                            <span
                                                style={{
                                                    fontSize: "12px",
                                                    left: "280px",
                                                    position: "absolute",
                                                }}
                                            >
                                                {phone?.length}/{maxPhonelength}
                                            </span>
                                        </div>
                                        {!isValid && (
                                            <span style={{ color: 'red', fontSize: '12px' }}>
                                                Phone number must be between {minPhoneLength} and {maxPhonelength} digits.
                                            </span>
                                        )}
                                        {errors.phone && <p className="error">{errors.phone}</p>}
                                    </div>
                                </div>
                                <div className="date-of-birth-container">
                                    <label>Date of Birth</label>
                                    <input type="date" className="date-of-birth" value={dob} onChange={(e) => 
                                        {
                                             setDOB(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, dob: '' })); }} />
                                    {errors.dob && <p className="error">{errors.dob}</p>
                                    }
                                </div>

                            </div>

                   <button
                        className='search w-100'
                        onClick={handleUpdate}
                    >
                        Update
                    </button>
                        </div>
                    </div>

                 
                </Main>

            </Box>
        </Modal>
    );
};

export default EditProfileModal;
