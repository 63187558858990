import React, { useEffect, useState } from 'react'
import { Main } from './style'
import email from '../../../assets/img/user-management/Email.png'
import group from '../../../assets/img/user-management/Group.png'
import map from '../../../assets/img/user-management/Maps and Flags.png'
import PaginationRounded from 'components/pagination'
import { useNavigate, useParams } from 'react-router-dom'
import useHook from './usehooks'
import Spinner from 'components/loader'
import toastr from "toastr";
import { AdminLotusPRO } from 'assets/img'
import { TotalProducts, OrderCancel, OrderReturns } from 'assets/img/index'
const UserProfile = () => {
  const { id } = useParams();
  const { singleUserGet, customerOrderDetailGet, getOrders } = useHook();

  const [singleUser, setsingleUser] = useState('');
  const fechsingleUser = async () => {
    try {
      const response = await singleUserGet(id);
      setsingleUser(response.data);
      console.log("user response", response)
    } catch (error) { }
  }

  const [orderdetail, setorderDetail] = useState('');
  const fechsingleUserOrderDetail = async () => {
    try {
      const response = await customerOrderDetailGet(id);
      setorderDetail(response.data);
      console.log("user detail", response)
    } catch (error) { }
  }


  // useEffect(() => {
  //   console.log("single user and detail running ")
  //   fechsingleUser();
  //   fechsingleUserOrderDetail()
  // }, [])

  const options = [
    {
      value: "processing",
      label: "Processing",
      backgroundColor: "#00D1FF1A",
      color: "#00D1FF",
    },
    {
      value: "cancel",
      label: "Cancel",
      backgroundColor: "#FF00001A",
      color: "#FF0000",
    },
    {
      value: "delivered",
      label: "Delivered",
      backgroundColor: "#209A421A",
      color: "#209A42",
    },
    {
      value: "pending",
      label: "Pending",
      backgroundColor: "#FF8F501A",
      color: "#FF8F50",
    },
  ];

  const handleSelect = (event) => {
    const selectedValue = event.target.value;
    const selectedOption = options.find(
      (option) => option.value === selectedValue
    );

    // Update select element's style
    event.target.style.backgroundColor = selectedOption.backgroundColor;
    event.target.style.color = selectedOption.color;
  };

  const navigate = useNavigate()
  const UserInvoice = (id, items) => {
    navigate(`/user-management/user-invoice/${id}`, { state: { items } })
  }


  // All order get

  const [loading, setLoading] = useState(true);
  const [orderlist, setOrderList] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('');
  const fetchOrders = async (resetPage = false) => {
    const formData = {
      'invoiceNumber': invoiceNumber,
      'status': status,
      'startDate': startDate,
      'endDate': endDate,
      'customerId': id,
      "page": resetPage ? 1 : currentPage,
      'perPage': 10,
    }
    try {
      setLoading(true);
      const response = await getOrders(formData);
      console.log("order data", response)
      setOrderList(response.data.results);
      setTotalPage(Math.ceil(response.data.counts / 10));
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("Effect running fetch single user");

    // Default API calls
    fechsingleUser();
    fechsingleUserOrderDetail();
    // fetchOrders();
}, []);
useEffect(() => {
  console.log("Pagination Effect running for page:", currentPage);

  // Fetch orders for the current page
  fetchOrders();
}, [currentPage]); 
  // useEffect(() => {
  //   console.log("current running")
  //   fetchOrders()
  // }, [currentPage])

  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };

  const handleStartDateChange = (date) => {
    const selectedStartDate = new Date(date);

    if (endDate && selectedStartDate > new Date(endDate)) {
      setEndDate(null);
    }

    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const selectedEndDate = new Date(date);

    if (startDate && selectedEndDate < new Date(startDate)) {
      toastr.error("End date cannot be earlier than start date");
      return;
    }

    setEndDate(date);
  };

  // date format
  const formatDate = (inputDate) => {
    // Create a date object from the input string
    const date = new Date(inputDate);

    // Array to convert month index to month name
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    // Get day, month, and year from the date object
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Return formatted date
    return `${day}-${month}-${year}`;
  }
  // time format
  const formatTimeFromISO = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Get hours, minutes, and seconds
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12; // the hour '0' should be '12'

    // Format minutes and seconds to always show two digits
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    // Return the formatted time
    return `${hours}:${formattedMinutes} ${period}`;
  }
  const handleReset = () => {
    setEndDate('');
    setStartDate('');
    setInvoiceNumber('')
    setcurrentPage(1)
    setStatus('')

    setTimeout(() => {
      fetchOrders(true);
    }, 0);
  }
  useEffect(() => {
    // Check if all the relevant fields are reset/empty
    if (
      startDate === '' &&
      endDate === '' &&
      invoiceNumber === '' &&
      status === ''
    ) {
      // Fetch orders with resetPage = true
      fetchOrders(true);
    }
  }, [startDate, endDate, invoiceNumber, status]); // Depend on the relevant states
  

  const handleSearch = () => {
    setcurrentPage(1);
    fetchOrders(true);

  };


  // useEffect(() => {
  
  //   // Always fetch single user and order details once
  //   fechsingleUser();
  //   fechsingleUserOrderDetail();
  //   fetchOrders(true);
  //   // Check if all the relevant fields are empty, then call fetchOrders with true
  //   if (
  //     startDate === "" &&
  //     endDate === "" &&
  //     status === "" &&
  //     invoiceNumber === ""
  //   ) {
  //     console.log("If statement running fetch orders");
  //     fetchOrders(true);
  //   } 
  // }, [currentPage, startDate, endDate, invoiceNumber, status]);
  


  return (
    <Main>
      {loading ? (
        <>
          <Spinner />
        </>
      ) : (
        <div className='background-color'>
          <div className='container-fluid'>
            <div className="heading-flex">
              <h1 className="heading m-0">User Profile</h1>
            </div>
            <div className='card border-0'>
              <div className='card-body'>
                <div className='items'>
                  <div className='d-flex justify-content-start align-items-center'>
                    {singleUser.profile_pic ? (
                      <img
                        src={
                          singleUser.profile_pic.startsWith("https://lh3.googleusercontent.com/")
                            ? singleUser.profile_pic
                            : `${process.env.REACT_APP_MEDIA_URL}/${singleUser.profile_pic}`
                        }
                        alt="sofa img"
                        className="img-width"
                      />
                    ) : (
                      <AdminLotusPRO className="img-width" />
                    )}

                    <div className='ms-3 '>
                      <p className='m-0 style-name'>{singleUser.name ? singleUser.name : "-"}</p>
                      <p className='m-0 style-font'><img src={email} alt="" /> {singleUser.email ? singleUser.email : "-"}</p>
                      <p className='m-0 style-font'><img src={group} alt="" /> {singleUser.phone ? singleUser.phone : "-"}</p>
                      <p className='m-0 style-font'><img src={map} alt="" />
                      
                        {singleUser.userAddress ? singleUser.userAddress.address : "-"}&nbsp;
                        {singleUser.userAddress?.city}&nbsp;
                        {singleUser.userAddress?.country}&nbsp;
                        {singleUser.userAddress?.state}&nbsp;
                        {singleUser.userAddress?.zipCode}&nbsp;
                        {/* {singleUser.userAddress ? singleUser.userAddress.address : "-"}&nbsp; */}

                      </p>
                    </div>
                  </div>
                  {/* <div className='display'>
                  <ul style={{ listStyle: "none", textAlign: "center", padding: "0" }}>
                    <li className='font-style'>Enable/ Disable</li>
                    <li className='mt-1'><IOSSwitch checked={switches}  onChange={handleSwitchToggle} /></li>
                  </ul>

                  <ul style={{ listStyle: "none", textAlign: "center" }}>
                    <li className='font-style'>Actions</li>
                    <li className='mt-1'><img src={Delete} alt="" className='img-width1' onClick={() => handleOpenDeleteModal} /></li>
                  </ul>

                </div> */}
                </div>
              </div>
            </div>
            <div className='mt-4'>
              <p className='order1 m-0'>Order Details</p>
            </div>
            {/* #----------------------------------------------- */}
            <ul className="nav nav-pills">
              <li className="nav-item" role="presentation">
                <button className="nav-link  p-0 me-2" type="button" role="tab" aria-selected="true">
                  <div className='order-flex'>
                    <div className="card third-card ">
                      <div className="card-body">
                        <div className='third-flex'>
                          <span className='total-img'>
                            <TotalProducts />
                          </span>
                          <div className='margin'>
                            <p className='m-0 order-para'>Total Order</p>
                            <p className='m-0 order-number'>{orderdetail.totalOrders ? orderdetail.totalOrders : "-"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </li>
              <li className="nav-item active" role="presentation">
                <button className="nav-link p-0 me-2" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                  <div className='order-flex'>
                    <div className="card third-card">
                      <div className="card-body">
                        <div className='third-flex'>
                          <span className='cancel-img'>
                            <OrderCancel />
                          </span>
                          <div className='margin'>
                            <p className='m-0 order-para'>Order Cancel</p>
                            <p className='m-0 order-number'>{orderdetail.cancelledCount ? orderdetail.cancelledCount : "-"}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button className="nav-link p-0" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
                  <div className='order-flex'>
                    <div className="card third-card">
                      <div className="card-body">
                        <div className='third-flex'>
                          <span className='return-img'>
                            <OrderReturns />
                          </span>
                          <div className='margin'>
                            <p className='m-0 order-para'>Order Returns</p>
                            <p className='m-0 order-number'>{orderdetail.returnedCount ? orderdetail.returnedCount : "-"}</p>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>

                </button>
              </li>
            </ul>
            <div className="card border-0 mt-3">
              <div className="card-body">
                <div className='input-body'>
                  <div className='input-container'>
                    <div className='w-100 mt-4'>
                      <input
                        type="search"
                        className="input2 me-2"
                        placeholder="Search by Invoice No"
                        onChange={(e) => setInvoiceNumber(e.target.value)}
                        value={invoiceNumber}
                      />
                    </div>
                    <div className='w-100 mt-4'>
                      <select
                        className="select1 me-2"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                      >
                        <option value=''>Status</option>
                        <option value='pending'>Pending</option>
                        <option value='processing'>Processing</option>
                        <option value='delivered'>Delivered</option>
                        <option value='cancelled'>Cancelled</option>
                        <option value='returned'>Returned</option>
                        <option value='declined'>Declined</option>
                        <option value='shipped'>Shipped</option>
                      </select>
                    </div>
                    <div className='w-100 d-flex flex-column'>
                      <label className="date">Start Date</label>
                      <input
                        type="date"
                        className="date-input"
                        name=""
                        id=""
                        onChange={(e) => handleStartDateChange(e.target.value)}
                        value={startDate || ""}
                      />
                    </div>
                    <div className='w-100 d-flex flex-column'>
                      <label className="date">End Date</label>
                      <input
                        type="date"
                        className="date-input"
                        name=""
                        id=""
                        onChange={(e) => handleEndDateChange(e.target.value)}
                        value={endDate || ""}
                      />
                    </div>
                  </div>

                  {/* 2nd row */}
                  <div className='button-container'>
                    <div className="custom-margin">
                      <button
                        type="submit"
                        className="filter"
                        onClick={handleSearch}
                      >
                        Filter
                      </button>
                    </div>
                    <div className="custom-margin">
                      <button
                        type="button"
                        className="reset"
                        onClick={handleReset}
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* #==================================CURRENT ORDER======================================= */}
            <div className="tab-content active" id="pills-tabContent">
              <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                <div>
                  <p className='order'>Order History</p>
                </div>
                <div className='card border-0'>
                  <div className="card-body">
                    <div className='table-responsive'>
                      <table className='w-100 table-width'>
                        <thead>
                          <tr className='table-head'>
                            <th>Invoice</th>
                            <th>Order Time</th>
                            {/* <th>Method</th> */}
                            <th>Amount</th>
                            {/* <th>Rating</th> */}
                            <th>Status</th>
                            <th>ACTIONS</th>
                          </tr>
                        </thead>
                        <tbody className='waleed-margin'>
                          {orderlist.length > 0 ? (
                            orderlist.map((items, index) => (
                              <tr className="mt-3 flex2 style-font2" style={{ paddingTop: '10%' }} key={index}>
                                <td style={{ paddingBlock: '15px' }}>{items.id}</td>
                                <td style={{ paddingBlock: '15px' }}>{formatDate(items.createdAt) + " " + formatTimeFromISO(items.createdAt)}</td>
                                {/* <td style={{ paddingBlock: '15px' }} >{items.Method}</td> */}
                                <td style={{ paddingBlock: '15px' }}>{items.totalAmount}</td>
                                {/* <td style={{ paddingBlock: '15px' }}><span className='rating-number'> <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.2416 5.82955C16.2073 5.73174 16.1162 5.66041 16.0065 5.64555L10.7428 4.93521L8.38876 0.506236C8.33988 0.41383 8.23862 0.355469 8.12804 0.355469C8.01747 0.355469 7.91592 0.41383 7.86733 0.506236L5.51305 4.93548L0.249302 5.64582C0.139605 5.66068 0.0488203 5.73174 0.0141942 5.82982C-0.0198499 5.92736 0.00837469 6.03489 0.087811 6.10649L3.89697 9.55414L2.99757 14.4224C2.97894 14.5238 3.02375 14.6262 3.11308 14.6867C3.20299 14.7478 3.322 14.7556 3.41948 14.7072L8.12804 12.409L12.836 14.7072C12.8785 14.728 12.9254 14.7383 12.9716 14.7383C13.0319 14.7383 13.0918 14.721 13.1427 14.6867C13.2323 14.6262 13.2771 14.5238 13.2582 14.4224L12.3591 9.55441L16.1683 6.10649C16.2474 6.03435 16.2759 5.92709 16.2416 5.82955Z" fill="#FFBB02" />
          </svg>
            {items.numberOfRattings}</span></td> */}
                                <td style={{ paddingBlock: '15px', width: "10%" }} ><p className={`border2 status-${items.status === "processing"
                                  ? "Processing"
                                  : items.status === "delivered"
                                    ? "Delivered"
                                    : items.status === "cancelled"
                                      ? "cancel"
                                      : items.status === "shipped" ? "shipped"
                                        : "pending"
                                  } m-0`}>{items.status}</p></td>
                                <td style={{ paddingBlock: '15px' }}>
                                  <span className='view-icon' onClick={() => UserInvoice(items.id, items)}>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g clip-path="url(#clip0_1971_40786)">
                                        <path d="M15.1028 7.57304C14.9698 7.41807 11.7897 3.7832 7.72439 3.7832C3.65897 3.7832 0.479027 7.41807 0.345651 7.57319C0.0839761 7.87705 0.0839761 8.31794 0.345805 8.6221C0.479027 8.77706 3.65897 12.4118 7.72439 12.4118C11.7897 12.4118 14.9698 8.77676 15.103 8.62179C15.3647 8.31794 15.3647 7.87704 15.1028 7.57304ZM8.96615 5.96671C9.23952 5.81934 9.62073 5.98843 9.81748 6.34425C10.0142 6.70021 9.95209 7.10829 9.67872 7.25566C9.40551 7.40318 9.02415 7.23394 8.82724 6.87812C8.63064 6.52215 8.69263 6.11408 8.96615 5.96671ZM7.72439 11.0949C4.88458 11.0949 2.49474 8.94099 1.66756 8.09749C2.22614 7.52776 3.4979 6.36111 5.13918 5.66346C4.81905 6.14446 4.63137 6.7192 4.63137 7.33785C4.63137 9.02486 6.01604 10.3924 7.72424 10.3924C9.43243 10.3924 10.8173 9.02486 10.8173 7.33785C10.8173 6.7192 10.6297 6.14446 10.3096 5.66346C11.9509 6.36126 13.2225 7.52776 13.7811 8.09749C12.9539 8.94145 10.564 11.0949 7.72439 11.0949Z" fill="white" />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_1971_40786">
                                          <rect width="15.1498" height="14.9618" fill="white" transform="translate(0.149414 0.617188)" />
                                        </clipPath>
                                      </defs>
                                    </svg>

                                  </span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="5" className='no-record'>No Record Found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className='mt-4 d-flex justify-content-end align-items-center'>
                      <PaginationRounded
                        count={totalPage}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    </Main>
  )
}

export default UserProfile
