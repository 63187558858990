// import { Main } from "./style";
// import { Formik, Form, Field } from "formik";
// import * as Yup from "yup";
// import { Link, useNavigate } from "react-router-dom";
// import { yupToFormErrors } from "formik";
// import { useState } from "react";
// import useHooks from "../useHooks";
// import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import the icons
// import { login } from "../../../libs/http-service/api/auth.api";
// import { ToastContainer } from "react-toastify";
// import "toastr/build/toastr.min.css";
// import toastr from "toastr";
// // import "react-toastify/dist/ReactToastify.css";
// const Login = () => {
//   const [showPassword, setShowPassword] = useState(false); 
//   const { login } = useHooks();
//   const SignupSchema = Yup.object().shape({
//     firstName: Yup.string()
//       .required("Required")
//       .min(6, "Too Short!")
//       .max(11, "Too Long!"),
//     email: Yup.string().email("Invalid email").required("Required"),
//   });

//   const Dashboard = useNavigate();

//   const Forget_Navigate = useNavigate();
//   const Forget = (state) => {
//     Forget_Navigate("/auth/forget", { state });
//   };
//   const data = { email: "", password: "" };
//   const [inputdata, setinputdata] = useState(data);
//   const handleData = (e) => {
//     // const { name, value } = e.target;
//     setinputdata({ ...inputdata, [e.target.name]: e.target.value });
//   };
//   const handleSubmit = (values) => {
//     if (!values.email || !values.firstName) {
//       // Fields are not filled, show validation errors
//       console.log("Invalid fields. Show validation errors.");
//     } else {
//       // Fields are filled, route to forget
//       console.log("Fields are filled. Route to forget.");
//       Dashboard("/");
//     }
//   };

//   const navigate = useNavigate();

//   const handleSubmited = async (e) => {
//     e.preventDefault();
//     try {
//     const response= await login(inputdata);
//       let signIn = localStorage.setItem("login", true);
//       if (signIn) {
//         navigate("/");
//       }else{
//         toastr.error(response.errors[0])
//       }

//     } catch (error) {
//       localStorage.setItem("login", false);
// // Display the error message using toastr

// const errorMessage = error?.response?.data?.errors[0] || "An error occurred.";
//       // alert(error.message);
//     }
//   };
//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <>
//       <ToastContainer />
//       <Main>
//         <div>
//           <p className="yellow_border"></p>
//         </div>
//         <div className="">
//           <div className="margin">
//             <h4 className="heading">Sign In to your Account</h4>
//             <p className="welcome_Content">
//               Welcome back! please enter your detail
//             </p>
//           </div>

//           <Formik
//             initialValues={{
//               email: "",
//               password: "",
//             }}
//             validationSchema={SignupSchema}
//           >
//             {({ errors, touched }) => (
//               <Form onSubmit={handleSubmit}>
//                 <div className="text-center positon-relative">
//                   <Field
//                     type="email"
//                     name="email"
//                     value={inputdata.email}
//                     className="input my-3"
//                     placeholder="Email"
//                     onChange={handleData}
//                   />
//                   {errors.email && touched.firstName ? (
//                     <div
//                       style={{

//                         color: "red",
//                         marginTop: "-14px",
//                         textAlign: "start",
//                         marginLeft: "20%",
//                       }}
//                     >
//                       {errors.email}
//                     </div>
//                   ) : null}

//                   <Field
//                    type={showPassword ? "text" : "password"}
//                     // type="password"
//                     name="password"
//                     value={inputdata.password}
//                     className="input"
//                     placeholder="Password"
//                     onChange={handleData}
//                     autoComplete="off"
//                   />
//                      <span
//                       onClick={togglePasswordVisibility}
//                       className="eye-icon"
//                     >
//                       {showPassword ? <FaEyeSlash /> : <FaEye />}
//                     </span>
//                   {errors.firstName && touched.firstName ? (
//                     <div
//                       style={{
//                         color: "red",
//                         textAlign: "start",
//                         marginLeft: "20%",
//                       }}
//                     >
//                       {errors.firstName}
//                     </div>
//                   ) : null}
//                 </div>

//                 <div className="remember">
//                   <span className="remember_me">
//                     <input type="checkbox" /> Remember me
//                   </span>
//                   <span
//                     className="Forgot_Password"
//                     style={{ cursor: "pointer" }}
//                     onClick={() => Forget()}
//                   >
//                     Forgot Password?
//                   </span>
//                 </div>
//                 <div className="text-center">
//                   <button
//                     type="submit"
//                     className="btn SignIn_Button"
//                     onClick={handleSubmited}
//                   >
//                     Sign In
//                   </button>
//                 </div>
//               </Form>
//             )}
//           </Formik>
//         </div>
//       </Main>
//     </>
//   );
// };

// export default Login;

import { Main } from "./style";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import useHooks from "../useHooks";
import { FaEye, FaEyeSlash } from "react-icons/fa"; 
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { ClipLoader, FadeLoader } from "react-spinners";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useHooks();

  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
  });

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmited = async (values, { setSubmitting }) => {
    try {
      const response = await login(values);
      localStorage.setItem("login", true);
      navigate("/");
    } catch (error) {
      localStorage.setItem("login", false);
      const errorMessage = error?.response?.data?.errors[0] || "An error occurred.";
      toastr.error(errorMessage);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <>
      <Main>
        <div>
          <p className="yellow_border"></p>
        </div>
        <div className="">
          <div className="margin">
            <h4 className="heading">Sign In to your Account</h4>
            <p className="welcome_Content">
              Welcome back! please enter your details
            </p>
          </div>

          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={handleSubmited}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="text-center">
                  <Field
                    type="email"
                    name="email"
                    className="input my-3"
                    placeholder="Email"
                  />
                  {errors.email && touched.email ? (
                    <div
                      style={{
                        color: "red",
                        marginTop: "-14px",
                        textAlign: "start",
                        marginLeft: "20%",
                      }}
                    >
                      {errors.email}
                    </div>
                  ) : null}
                  <div style={{ position: "relative" }}>
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className="input"
                      placeholder="Password"
                      autoComplete="off"
                    />
                    <span onClick={togglePasswordVisibility} className="eye-icon">
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </span>
                    {errors.password && touched.password ? (
                      <div
                        style={{
                          color: "red",
                          textAlign: "start",
                          marginLeft: "20%",
                        }}
                      >
                        {errors.password}
                      </div>
                    ) : null}
                  </div>

                </div>


                <div className="remember">
                  <span className="remember_me">
                    <input type="checkbox" /> Remember me
                  </span>
                  <span
                    className="Forgot_Password"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/auth/forget")}
                  >
                    Forgot Password?
                  </span>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn SignIn_Button"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? <ClipLoader color="#ffffff" size={30} /> : "Sign In"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Main>
    </>
  );
};

export default Login;

