import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging";
import { getFirestore } from "firebase/firestore";
// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC3QUIZwQilnT4PKzO175ARAjXw0BgswdE",
  authDomain: "lotus-pro-4a6c1.firebaseapp.com",
  projectId: "lotus-pro-4a6c1",
  storageBucket: "lotus-pro-4a6c1.appspot.com",
  messagingSenderId: "958447086043",
  appId: "1:958447086043:web:385fa42f998dc2d7a4dffe",
  measurementId: "G-YH333H7LLV",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const db = getFirestore(app);
let displayedNotifications = new Set();
let debounceTimer = null;
const debounceDelay = 1000; // 1 second debounce delay
onMessage(messaging, async (payload) => {
  console.log("Message received. ", payload);
  // Ensure payload.data is defined and parse the JSON string
  if (!payload.data || !payload.data.data) {
    console.log("Payload data is undefined. Notification will not be shown.");
    return;
  }
  let parsedData;
  try {
    parsedData = JSON.parse(payload.data.data);
  } catch (error) {
    console.log(
      "Failed to parse nested payload data. Notification will not be shown.",
      error
    );
    return;
  }
  const chatId = parsedData.chatID; // Extract chat ID from parsed nested data
  const userId = parsedData.myInfo.id; // Assuming the user ID is in myInfo.id
  // Ensure chatId and userId are defined
  if (!chatId || !userId) {
    console.log(
      "Chat ID or User ID is undefined in parsed payload data. Notification will not be shown."
    );
    return;
  }
  // Get the current URL and extract the chat ID from it
  const currentUrl = window.location.href;
  const urlChatIdMatch = currentUrl.match(/\/chat\/(\d+)$/);
  const currentChatId = urlChatIdMatch ? urlChatIdMatch[1] : null;
  // Check if the chat ID from the payload matches the chat ID in the URL
  if (chatId === currentChatId) {
    console.log(
      "Chat ID matches the current URL. Notification will not be shown."
    );
    return; // Exit if the chat ID matches
  }
  const notificationId =
    payload.messageId || payload.notification.title || parsedData.chatID;
  const notificationTitle = payload.notification.title;
  const notificationOptions = {
    body: payload.notification.body,
  };
  if (!displayedNotifications.has(notificationId)) {
    displayedNotifications.add(notificationId);
    if (Notification.permission === "granted") {
      if (debounceTimer) clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        new Notification(notificationTitle, notificationOptions);
      }, debounceDelay);
    }
  }
});
export { db, messaging };