import React, { useEffect, useState } from "react";
import { Card, Main } from "./style";
import IosButton from "components/ios button";
import ReportModal from "components/modals/report-modal";
import { useParams } from "react-router-dom";
import Spiner from "components/loader";
import LotusPro from "assets/img/common/lotuspro.png";
import useHooks from "./usehooks";
const ProductsDetails = () => {
    const { id } = useParams();
    const { getproductsdetails, fetchproductsreviews } = useHooks();

    const formatDate = (isoDateString) => {
        const date = new Date(isoDateString);

        // Options for formatting the date
        const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true, // for 12-hour format with am/pm
        };

        // Format the date according to options
        const formattedDate = date.toLocaleDateString("en-US", options);

        return formattedDate;
    };

    const [reportComment, setReportComment] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showAllReviews, setShowAllReviews] = useState(false);

    const handleSeeAllClick = () => {
        setShowAllReviews(true);
    };

    // API hook
    const [productsDetails, setProductsDetails] = useState([]);
    useEffect(() => {
        const getproductsdetail = async () => {
            try {
                const response = await getproductsdetails(id);
                if (response.status === true) {
                    setProductsDetails(response.data);
                    setSelectedColor(response.data.colors[0]); // Set default selected color
                } else {
                    console.log("Response status is false");
                }
            } catch (error) {
                console.error("Error fetching product details:", error);
            } finally {
                setLoading(false);
            }
        };
        getproductsdetail();
    }, [id]);

    const handleColorClick = (color) => {
        setSelectedColor(color);
    };

    const HandleReportsSection = () => {
        setReportComment(true);
        // console.log("clicked");
    };

    // Products Reviews

    const [productReviews, setProductReviews] = useState([]);
    const getproductsreviews = async () => {
        const params = {
            productId: id,
            page: 1,
            perPage: 20,
        };
        try {
            const response = await fetchproductsreviews(params);
            if (response.status === true) {
                setProductReviews(response.data.results);
                console.log("hahahah", response);
                // setProductsDetails(response.data);
                // setSelectedColor(response.data.colors[0]); // Set default selected color
            } else {
                console.log("Response status is false");
            }
        } catch (error) {
            // console.error("Error fetching product details:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getproductsreviews();
    }, []);

    return (
        <Main>
            {loading ? (
                <Spiner />
            ) : (
                <>
                    <div className="container-fluid">
                        <div className="heading-flex">
                            <div className="padding-text">
                                <p className="heading m-0">Product Details</p>
                            </div>
                            {/* <div className="published-flex">
                <p className="m-0 published">Published</p>
                <div className="ios-button">
                  <IosButton />
                </div>
                <img src={Edit} alt="" className="icon-width" />
                <img src={Delete} alt="" className="icon-width" />
              </div> */}
                        </div>

                        <Card
                            className="card"
                            style={{ borderRadius: "20px", border: "0px" }}
                        >
                            <div className="card-body">
                                <div className="card-main-flex">
                                    <div>
                                        <p className="prdct-img-text">Product Image</p>

                                        {selectedColor ? (
                                            <img
                                                src={`${process.env.REACT_APP_BASE_URL}${selectedColor.image}`}
                                                alt={selectedColor.colorName}
                                                style={{
                                                    width: "133px",
                                                    height: "136px",
                                                    marginTop: "15px",
                                                    borderRadius: "10px",
                                                }}
                                            />
                                        ) : (
                                            <p>No color images available</p>
                                        )}
                                    </div>
                                    <div className="padding-table table-responsive">
                                        <table
                                            className="table table-borderless"
                                            style={{ height: "0px" }}
                                        >
                                            <tbody>
                                                <tr className="">
                                                    <td className="table-heading">Category</td>
                                                    <td className="table-data">
                                                        {productsDetails.category
                                                            ? productsDetails.category.name
                                                            : "Category Not Available"}
                                                    </td>
                                                    <td className="padding-rows table-heading">
                                                        Collection
                                                    </td>
                                                    <td className="table-data">
                                                        {productsDetails.collections &&
                                                            productsDetails.collections.length > 0 ? (
                                                            <div>
                                                                {productsDetails.collections.map(
                                                                    (collection) => (
                                                                        <div key={collection.id}>
                                                                            <p>{collection.name}</p>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        ) : (
                                                            "Collection Not Available"
                                                        )}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="table-heading">Rating</td>
                                                    <td
                                                        className="table-data"
                                                        style={{ display: "flex", alignItems: "center" }}
                                                    >
                                                        {productsDetails.totalRatting}{" "}
                                                        {/* <img src={Star} alt="" className="star-width" /> */}
                                                        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M16.2416 5.82955C16.2073 5.73174 16.1162 5.66041 16.0065 5.64555L10.7428 4.93521L8.38876 0.506236C8.33988 0.41383 8.23862 0.355469 8.12804 0.355469C8.01747 0.355469 7.91592 0.41383 7.86733 0.506236L5.51305 4.93548L0.249302 5.64582C0.139605 5.66068 0.0488203 5.73174 0.0141942 5.82982C-0.0198499 5.92736 0.00837469 6.03489 0.087811 6.10649L3.89697 9.55414L2.99757 14.4224C2.97894 14.5238 3.02375 14.6262 3.11308 14.6867C3.20299 14.7478 3.322 14.7556 3.41948 14.7072L8.12804 12.409L12.836 14.7072C12.8785 14.728 12.9254 14.7383 12.9716 14.7383C13.0319 14.7383 13.0918 14.721 13.1427 14.6867C13.2323 14.6262 13.2771 14.5238 13.2582 14.4224L12.3591 9.55441L16.1683 6.10649C16.2474 6.03435 16.2759 5.92709 16.2416 5.82955Z" fill="#FFBB02" />
                                                        </svg>

                                                    </td>
                                                    <td className="padding-rows table-heading">
                                                        Material
                                                    </td>
                                                    <td className="table-data" colSpan="3">
                                                        {productsDetails.materials &&
                                                            productsDetails.materials.length > 0
                                                            ? productsDetails.materials.map(
                                                                (material, index) => (
                                                                    <span key={material.id}>
                                                                        {material.material
                                                                            ? material.material.name
                                                                            : "Material Name Not Available"}
                                                                        {index <
                                                                            productsDetails.materials.length - 1 &&
                                                                            ", "}
                                                                    </span>
                                                                )
                                                            )
                                                            : "Materials Not Available"}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="table-heading">Price</td>
                                                    <td className="table-data">
                                                        {productsDetails.productPrice}
                                                    </td>
                                                    <td className="padding-rows table-heading">
                                                        Sale Price
                                                    </td>
                                                    <td className="table-data">
                                                        {productsDetails.salePrice}
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td className="table-heading">Product SKU</td>
                                                    <td className="table-data">{productsDetails.sku}</td>
                                                    <td className="padding-rows table-heading">
                                                        Product Tags
                                                    </td>
                                                    <td className="table-data">
                                                        {productsDetails.metas &&
                                                            productsDetails.metas.length > 0
                                                            ? productsDetails.metas
                                                                .map((meta) => meta.name)
                                                                .join(", ")
                                                            : "No metas available"}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="table">
                                    <tr>
                                        <td className="table-heading ">Dimentions</td>
                                        <td className="table-data padding-rows2">
                                            {productsDetails.length} x {productsDetails.width} x{" "}
                                            {productsDetails.height} {productsDetails.unit} (L x W x
                                            H)
                                        </td>
                                        <td className="table-heading padding-color">Colors</td>
                                        <td
                                            className="table-data "
                                            style={{ display: "flex", padding: "0px 15px" }}
                                        >
                                            {productsDetails.colors &&
                                                productsDetails.colors.length > 0
                                                ? productsDetails.colors.map((color) => (
                                                    <p
                                                        key={color.id}
                                                        style={{
                                                            backgroundColor: color.colorCode,
                                                            width: "22px",
                                                            height: "22px",
                                                            cursor: "pointer",
                                                        }}
                                                        className="color"
                                                        onClick={() => handleColorClick(color)}
                                                    >
                                                        {/* {color.colorName} */}
                                                    </p>
                                                ))
                                                : "No colors available"}
                                            &nbsp;
                                        </td>
                                    </tr>

                                    <tr>
                                        <td className="table-heading">Product Images</td>
                                        <td className="table-data padding-rows1">
                                            {productsDetails &&
                                                productsDetails.images &&
                                                productsDetails.images.length > 0 ? (
                                                productsDetails.images.map((image, index) => (
                                                    <img
                                                        key={index}
                                                        src={`${process.env.REACT_APP_BASE_URL}${image.url}`}
                                                        alt={`Product Image ${index + 1}`}
                                                        className="image-size"
                                                    />
                                                ))
                                            ) : (
                                                <div>No images available</div>
                                            )}
                                        </td>
                                    </tr>
                                </div>

                                <p className="m-0 table-heading">Product Description</p>
                                <p className="description">{productsDetails.description}</p>

                                <div className="d-flex justify-content-between mt-4">
                                    <p className="details">Product Reviews</p>
                                    {!showAllReviews && (
                                        <p className="seeAll" onClick={handleSeeAllClick}>
                                            See all
                                        </p>
                                    )}
                                </div>

                                {productReviews
                                    .slice(0, showAllReviews ? productReviews.length : 3)
                                    .map((items, index) => (
                                        <div
                                            key={index}
                                            className="d-flex"
                                            style={{
                                                alignItems: "flex-start",
                                                borderBottom: "1px solid #E2E8F0",
                                                paddingBlock: "10px",
                                            }}
                                        >
                                            <img
                                                src={
                                                    items.user?.profile_pic
                                                        ? `${process.env.REACT_APP_BASE_URL}${items.user?.profile_pic}`
                                                        : LotusPro
                                                }
                                                alt=""
                                                className="comment-pic"
                                                style={{ borderRadius: "40px" }}
                                            />
                                            <div>
                                                <div className="review-flex">
                                                    <p className="m-0 client-name">{items.user?.name}</p>
                                                    <p className="m-0 ps-4 comment-rating pe-2">
                                                        {items?.ratting
                                                            ? items.ratting.toString().slice(0, 3)
                                                            : ""}
                                                    </p>
                                                    {/* <img
                            src={Star}
                            alt=""
                            style={{ width: "16px", height: "14px" }}
                            className=""
                          /> */}
                                                    {/* <p
                className="m-0 ps-3 report"
                onClick={HandleReportsSection}
                style={{ cursor: "pointer" }}
              >
                Report
              </p> */}
                                                </div>
                                                <div style={{ padding: "0px 10px" }}>
                                                    <span className="date">
                                                        {items.createdAt ? formatDate(items.createdAt) : ""}
                                                    </span>
                                                    <p className="m-0 comment">{items.comment}</p>
                                                    <div className="comment-img-flex">
                                                        {items.images.map((image) => (
                                                            <img
                                                                key={image.id}
                                                                src={`${process.env.REACT_APP_BASE_URL}${image.image}`}
                                                                alt=""
                                                                style={{
                                                                    maxWidth: "10%",
                                                                    margin: "10px 0",
                                                                    borderRadius: "8px",
                                                                }}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                {/* {productReviews.map((index, items) => {
                  <>
                    <div key={index}
                      className="d-flex"
                      style={{
                        alignItems: "flex-start",
                        borderBottom: "1px solid #E2E8F0",
                        paddingBlock: "10px",
                      }}
                    >
                      <img
                        src={
                          items.user?.profile_pic? `${process.env.REACT_APP_BASE_URL}${items.user?.profile_pic}`
                            : LotusPro
                        }
                        alt=""
                        className="comment-pic"
                        style={{ borderRadius: "40px" }}
                      />
                      <div className="">
                        <div className="review-flex">
                          <p className="m-0 client-name">
                            {items.user?.name}
                          </p>
                          <p className="m-0 ps-4 comment-rating pe-2">
                            {productsDetails?.user.totalRatting
                            ? productsDetails.user.totalRatting
                                .toString()
                                .slice(0, 3)
                            : ""}
                          </p>
                          <img
                            src={Star}
                            alt=""
                            style={{ width: "16px", height: "14px" }}
                            className=""
                          />
                          <p
                            className="m-0 ps-3 report"
                            onClick={HandleReportsSection}
                            style={{ cursor: "pointer" }}
                          >
                            Report
                          </p>
                        </div>
                        <div style={{ padding: "0px 10px" }}>
                          <span className="date">
                            {productsDetails?.user.createdAt
                            ? formatDate(productsDetails.user.createdAt)
                            : ""}
                          </span>
                          <p className="m-0 comment">
                            {productsDetails?.user.description}
                          </p>
                          <div className="comment-img-flex">
                            <img src={items.sofaimg} alt="" />
                            <img src={items.sofaimg2} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>;
                })} */}
                            </div>
                        </Card>
                    </div>
                    {/* {reportComment && (
                        <ReportModal
                            onOpen={HandleReportsSection}
                            onClose={() => setReportComment(false)}
                        />
                    )} */}
                </>
            )}
        </Main>
    );
};

export default ProductsDetails;
