import styled from "styled-components";

export const Main = styled.div`

width: 100%;
background-color: var(--all-background);
height: var(--height);

.reset{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #E2E8F0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: #808D9E;
}
.img-width{
    width:50px;
    height:50px;
    border-radius: 100px;
}
.delete-icon{
    background-color: #FF0000;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.view-icon{
    background-color: #209A42;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.user{
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    padding-block: 20px;
}
.width{
    display: flex;
    gap: 14px;
    width: 100%;
    height: 95px;
    border-radius: 9px;
    background-color: white;
    padding: 20px 10px;
}
.input1{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid lightgray;
    font-family: inter;
    font-size: 16px;
    font-weight: 400;
    padding: 14px;
    outline: none;
}
.search{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    border: 0;
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}
.flex{
    
    font-size: 14px;
    font-weight: 700;
    font-family: inter;
    text-transform: capitalize;
}
.flex2{
    font-size: 14px;
    font-weight: 500;
    font-family: inter;
    color: #585562;
    border-bottom: 1px solid #E9E9E9;
}
.text-style{
    text-transform: capitalize;
}
.pading{
    padding-right: 38px;
}
.width2{
    width: 39px;
    height: 40px;
}
.edit{
    width: 31px;
    height: 32px;
   
}
.waleed-margin{
    padding-top: 10%;
}
.table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }
`