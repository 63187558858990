import React, { useState } from 'react';
import { Main } from './style';
import Watch from '../../../assets/img/followers/watch.png'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

const FollowLikes = () => {
    const [activeTab, setActiveTab] = useState('followers');

    const handleTabChange = (tabName) => {
        setActiveTab(tabName);
    };
    const Followers = [
        { id: 1, sr: "1", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "2", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "3", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "4", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "5", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "6", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },

    ];

    const Likes = [
        { id: 1, sr: "1", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "2", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "3", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "4", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "5", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },
        { id: 2, sr: "6", name: 'Yasmany B', email: 'ybreff87@gmail.co', phone: '9876567876', address: '456 Park Avenue, New York, NY 10022:', actions: Watch },

    ];

    return (
        <Main>
            <div className='container-fluid'>
            <div className="heading-flex">
            <h1 className="heading m-0">Vendors Details</h1>
          </div>

                <div className='card border-0'>
                    <div className=''>

                        <div className="tabs-container">
                            <div className={`tab ${activeTab === 'followers' ? 'active' : ''}`} onClick={() => handleTabChange('followers')}>
                                Followers
                                {activeTab === 'followers' && <div className="bottom-border" style={{ left: 0 }}></div>}
                            </div>
                            <div className={`tab ${activeTab === 'likes' ? 'active' : ''}`} onClick={() => handleTabChange('likes')}>
                                Likes
                                {activeTab === 'likes' && <div className="bottom-border" style={{ left: 'calc(5%)' }}></div>}
                            </div>
                        </div>

                    </div>
                </div>
                <div className=" mt-3"  >
                    {/* #================== Followers Table start ============================ */}
                    {activeTab === 'followers' && <p>
                        <div className='card border-0'>
                            <div className='card-body'>
                                <table className="dummy-table w-100">
                                    <thead>
                                        <tr className='heading'>
                                            <th>SR. No.</th>
                                            <th>NAME</th>
                                            <th>EMAIL</th>
                                            <th>PHONE NO.</th>
                                            <th>ADDRESS</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Followers.map((data, index) => (
                                            <tr key={index} className='sub-heading'>
                                                <td className='top'>{data.sr}</td>
                                                <td className='top'>{data.name}</td>
                                                <td className='top'>{data.email}</td>
                                                <td className='top'>{data.phone}</td>
                                                <td className='top'>{data.address}</td>
                                                <td className='top'> <img src={data.actions} alt="" className='img-width' /> </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='mt-4 d-flex justify-content-end'>
                                    <Stack spacing={3}  >
                                        <Pagination count={50} variant="outlined" shape="rounded" />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </p>}
                    {/* #================== Followers Table End ============================ */}

                    {activeTab === 'likes' && <p>
                    <div className='card border-0'>
                            <div className='card-body'>
                                <table className="dummy-table w-100">
                                    <thead>
                                        <tr className='heading'>
                                            <th>SR. No.</th>
                                            <th>NAME</th>
                                            <th>EMAIL</th>
                                            <th>PHONE NO.</th>
                                            <th>ADDRESS</th>
                                            <th>ACTIONS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Likes.map((data, index) => (
                                            <tr key={index} className='sub-heading'>
                                                <td className='top'>{data.sr}</td>
                                                <td className='top'>{data.name}</td>
                                                <td className='top'>{data.email}</td>
                                                <td className='top'>{data.phone}</td>
                                                <td className='top'>{data.address}</td>
                                                <td className='top'> <img src={data.actions} alt="" className='img-width' /> </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='mt-4 d-flex justify-content-end'>
                                    <Stack spacing={3}  >
                                        <Pagination count={50} variant="outlined" shape="rounded" />
                                    </Stack>
                                </div>
                            </div>
                        </div>
                    </p>}
                </div>
            </div>
        </Main>
    );
};

export default FollowLikes;