import styled from "styled-components";

export const Main = styled.div`

.title{
    display:flex;
    flex-direction: column;
}
input{
    border: 1px solid #E2E8F0;
    width: Fixed (490px)px;
height: Fixed (56px)px;
top: 37px;
padding: 8px 16px 8px 16px;
gap: 12px;
border-radius: 5px;
opacity: 0px;
margin-top:10px;
margin-bottom: 10px;
outline: none;
}
span{
    font-family: Inter;
font-size: 18px;
font-weight: 400;
line-height: 27px;
letter-spacing: 0.20000000298023224px;
text-align: left;

}
.custom-quill-editor {
  height: 480px;
  margin-bottom: 50px;
}

.custom-quill-editor .ql-container {
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background */
}

.custom-quill-editor .ql-editor {
  min-height: 100px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  overflow-y: 80px;
}

.btn{
    width: 100%;
height: 48px;
top: 800px;
left: 293px;
gap: 8px;
border-radius: 5px;
opacity: 0px;
color: white;
background-color: #156A3D;
}
.description{
    display: flex;
    flex-direction: column;
}

.heading{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    
    height: 50px;
    padding-right: 20px;
    padding-left: 410px;
    color: white;
    background-color: #156A3D ;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.close{
    color: white;
    cursor: pointer;
    font-size: 25px;
}
@media (max-width:1640px) {
    .heading{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    
    height: 50px;
    padding-right: 20px;
    padding-left: 420px;
    color: white;
    background-color: #156A3D ;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.custom-quill-editor {
  height: 220px;
  margin-bottom: 50px;
  border-radius: 5px;
}

.custom-quill-editor .ql-container {
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background */
}

.custom-quill-editor .ql-editor {
  min-height: 100px;
  padding: 10px;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 1.5;
  overflow-y: 80px;
}
}
@media (max-width:1100px) {
    .heading{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    
    height: 50px;
    padding-right: 20px;
    padding-left: 320px;
    color: white;
    background-color: #156A3D ;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.custom-quill-editor {
  height: 100px;
  margin-bottom: 50px;
  border-radius: 5px;
}
}
@media (max-width:850px) {
    .heading{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    height: 50px;
    padding-right: 20px;
    padding-left: 260px;
    color: white;
    background-color: #156A3D ;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
}
@media (max-width:740px) {
    .heading{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    
    height: 50px;
    padding-right: 20px;
    padding-left: 250px;
    color: white;
    background-color: #156A3D ;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.custom-quill-editor {
  height: 130px;
  margin-bottom: 50px;
  border-radius: 5px;
}
}

@media (max-width:740px) {
    .heading{
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    
    height: 50px;
    padding-right: 20px;
    padding-left: 140px;
    color: white;
    background-color: #156A3D ;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}
.custom-quill-editor {
  height: 130px;
  margin-bottom: 50px;
  border-radius: 5px;
}
}

`