import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Select, MenuItem, FormControl, InputLabel, Typography } from '@mui/material';
import useHook from './usehook';

const AdminStatusChange = ({ open, handleClose,statusId,StatusName }) => {
    const {disputePost}=useHook()
    
  const [formData, setFormData] = useState({
    status: '',
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    tempErrors.status = formData.status ? '' : 'Please select a status';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleChange = (e) => {
    const {name, value } = e.target;
    setErrors({
        ...errors,
        [name]: value ? '' : errors[name],
      });
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit =async (e) => {
    e.preventDefault();
    if (validate()) {

      console.log(formData);
      try{
        const id=statusId
        const response=await disputePost(statusId,formData);
      console.log(formData);
      console.log(response);
      handleClose();
      }catch(error){
        console.log("Error while Posting the admin Status")
      }
    }
  };

  return (
    <Modal  open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          borderRadius:"12px",
        }}
      >
        <Typography id="modal-title" variant="h6" component="h2" textAlign="center">
          Admin Status
        </Typography>
        <Typography id="modal-title" variant="p" component="p" textAlign="center">
          Invoice Number: {statusId}
        </Typography>
        <Typography id="modal-title" variant="p" component="p" textAlign="center">
          Customer Name: {StatusName}
        </Typography>

        <FormControl  variant="standard"  fullWidth>
        <InputLabel id="demo-simple-select-standard-label">Status</InputLabel>
        <Select style={{height:"48px"}}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={formData.status}  name="status"
            onChange={handleChange}
            error={!!errors.status}
          label="Status"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value='resolved'>Resolved</MenuItem>
          <MenuItem value='declined'>Declined</MenuItem>
        </Select>
          {errors.status && <Typography color="error">{errors.status}</Typography>}
        </FormControl>

        <Button variant="contained" style={{height:"48px"}} color="primary" onClick={handleSubmit}>
         Update
        </Button>
      </Box>
    </Modal>
  );
};

export default AdminStatusChange;
