import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);

  .heading {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    color: #0c3a2d;
  }
  .sub-heading {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1c27;
    border-bottom: 1px solid #e2e8f0;
  }
  .AssigntoAdmin {
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    background-color: #00d1ff1a;
    color: #00d1ff;
    outline: none;
    border: 0px;
    width: 129px;
    height: 24px;
    border-radius: 5px;
  }
  .solved {
    width: 74px;
    height: 24px;
    border-radius: 5px;
    background-color: #209a421a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #209a42;
    outline: none;
    border: 0px;
  }
  .pending {
    width: 82px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff8f501a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ff8f50;
    outline: none;
    border: 0px;
  }
  .declined {
    width: 85px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ff0000;
    outline: none;
    border: 0px;
  }
  .top {
    padding-block: 15px;
  }
  .view-icon {
    background-color: #209a42;
    width: 25px;
    height: 25px;
    padding: 4px;
    border-radius: 6px;
    cursor: pointer;
  }
`;
export const Input = styled.div`
  .input-reason {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    padding: 15px;
    outline: none;
  }
  .dispute-list {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 15px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    outline: none;
  }
  .filter-dispute {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: white;
    border: 0px;
    background-color: #156a3d;
  }
`;
