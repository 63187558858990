import styled from "styled-components";
// 
export const Main = styled.div`

.main-container{
    width: 100%;
    margin-bottom: 20px;
}
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 280px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #FFBB02;
    color: white;
    border-radius: 12px 12px 0px 0px;
}

.close-icon{
    font-size: 25px;
    cursor: pointer;
}

.img-container{
    margin-top: 12px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.img-profile{
    border-radius: 60px;
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}
.profile-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 50px;
    flex-wrap: wrap;
}
.column{
    display: flex;
    flex-direction: column;
}
.lablel-text{
    font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.20000000298023224px;
text-align: left;
color:#808D9E

}
.user-detail{
    font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.20000000298023224px;
text-align: left;
color: #1C1C27;
}

.vendor-name{
  color:#0C3A2D;
  font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 36px;
letter-spacing: 0.20000000298023224px;
text-align: center;
margin-bottom: 12px;

}
`
