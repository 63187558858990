import React from "react";
import { Main } from "./style";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import useHooks from "../useHooks";
import toastr from "toastr";

const Forget = () => {
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
  });

  const Forget_Navigate = useNavigate();
  const { ForgetPassword } = useHooks();

  const handleSubmit = async (values) => {
    if (!values.email) {
      // Email is not filled, show validation errors
      console.log("Invalid fields. Show validation errors.");
    } 
const role="superadmin"
      try {
        const response = await ForgetPassword({ email: values.email,role });
        if (response.status === true) {
          localStorage.setItem("email", values.email);
          toastr.success("OTP sent successfully to mail");
          Forget_Navigate("/auth/verifyemail");
        } else {
          // Handle errors based on the API response
          const errorMessage =
            response.errors || "An error occurred. Please try again.";
          toastr.error(errorMessage);
        }  
      } catch (error) {
        console.log("Error sending email: ", error);
      }
   
  };

  return (
    <Main>
      <div>
        <p className="yellow_border"></p>
      </div>

      <div className="margin">
        <h4 className="heading">Reset your password</h4>
        <p className="welcome_Content">
          Enter the email address associated with your account and we will send
          you a Otp to reset your password.
        </p>
      </div>

      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={SignupSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className="text-center">
              <Field
                type="email"
                name="email"
                className="input"
                placeholder="Email"
              />
              {errors.email && touched.email ? (
                <div
                  style={{
                    color: "red",
                    textAlign: "start",
                    marginLeft: "20%",
                  }}
                >
                  {errors.email}
                </div>
              ) : null}
            </div>

            <div className="text-center mt-4">
              <button type="submit" className="btn SignIn_Button">
                Continue
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </Main>
  );
};

export default Forget;
