import React, { useEffect, useState } from 'react'
import { Product } from './style'

import IOSSwitch from 'components/ios button';
import { AdminLotusPRO, View, Delete } from 'assets/img'
import { useNavigate, useParams } from 'react-router-dom'
import PaginationRounded from "components/pagination";
import useHook from './usehook'
const CollectionDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { getSingleCollection, getSingleProduct } = useHook();

    const [currentPage, setcurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    //     {
    //         id: 1,
    //         productId: "1",
    //         pic: Sofa,
    //         name: 'Sofa',
    //         rating: HalfStar,
    //         email: '4.5',
    //         phone: 'Furniture',
    //         gender: 'Living Room Furniture',
    //         dateOfBirth: '$400',
    //         postalCode: '$380',
    //         location: '0',
    //         status: "Sold out"

    //     },
    //     {
    //         id: 2,
    //         productId: "2",
    //         pic: Bench,
    //         name: 'Bench',
    //         rating: Star,
    //         email: '5',
    //         phone: 'Furniture',
    //         gender: 'Living Room Furniture',
    //         dateOfBirth: '$400',
    //         postalCode: '$380',
    //         location: '40',
    //         status: "In Stock"

    //     }
    // ];
    const [singlecollection, setsinglecollection] = useState({});
    const getsinglecollection = async () => {
        try {
            const response = await getSingleCollection(id);
            setsinglecollection(response.data);
            console.log("single response", response.data)

        } catch (error) {
            console.log("single collection error", error)
        }
    }
    useEffect(() => {
        getsinglecollection();
    }, [])
    const [productlist, setproductlist] = useState([])
    const getProductsByCollectionID = async () => {
        const formdata = {
            collectionId: id,
            page: currentPage,
            perPage: 10,
        }
        try {
            const response = await getSingleProduct(formdata)
            console.log("Get Product by Collection Id response is ", response)
            setTotalPage(Math.ceil(response.data.counts / 10));
            setproductlist(response.data.results)
        } catch (error) {
            console.error("Get Product by Collection Id ", error)
        }
    }
    useEffect(() => {
        getProductsByCollectionID()
    }, [currentPage])
    const handlePageChange = (e, value) => {
        setcurrentPage(value);
    };
    const handleProductDetail = (id) => {
        navigate(`/product-detail/${id}`)
    }
    return (
        <Product>
            <div className="card border-0 mt-3">
                <div className="card-body">
                    <div className='d-flex flex-row gap-2'>
                        {singlecollection.image ? (
                            <img
                                src={`${process.env.REACT_APP_MEDIA_URL}${singlecollection.image}`}
                                alt="logo"
                                className='single-image'
                            />
                        ) : (
                            <AdminLotusPRO className='single-image' />
                        )}
                        <div>
                            <p className='m-0 product-name'>{singlecollection.name ? singlecollection.name : "-"} </p>
                            <p className='product-para'> {singlecollection.productCount} {singlecollection.productCount > 1 ? 'Products' : 'Product'}</p>
                            <p className='product-count'>{singlecollection.description ? singlecollection.description : "-"} </p>

                        </div>
                    </div>
                </div>
            </div>

            <div className='card mt-4 border-0 '>
                <div className="card-body">
                    <div className='table-responsive'>
                    <table className='w-100'>
                        <thead>
                            <tr className='table-head'>
                                <th>ID</th>
                                <th>Image</th>
                                <th>Name</th>
                                <th>Ratings</th>
                                <th>Category</th>
                                <th>Sub Category</th>
                                <th>Price</th>
                                <th>Sale Price</th>
                                <th >Stock</th>
                                <th>Status</th>
                                <th>Published</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody className='waleed-margin'>
                            {productlist.length === 0 ? (
                                <tr>
                                    <td colSpan="10" className="no-record">No records found</td>
                                </tr>
                            ) : (productlist.map((item, index) => (
                                <tr key={index} className="mt-3 flex2 styled-font">
                                    <td className="pt-3">{item.id}</td>

                                    <td className="pt-3">
                                        {item.images[0].url ? (
                                            <img
                                                src={`${process.env.REACT_APP_MEDIA_URL}${item.images[0].url}`}
                                                alt="logo"
                                                className='img'
                                            />
                                        ) : (
                                            <AdminLotusPRO className='img' />
                                        )}
                                        {/* <img src={`${process.env.REACT_APP_MEDIA_URL}${item.images[0].url}`} alt="product" width="50" height="50" /> */}
                                    </td>

                                    <td className="pt-3 dot-property">{item.name}</td>
                                    <td className="pt-3">
                                        <span>
                                            <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M16.2416 5.82955C16.2073 5.73174 16.1162 5.66041 16.0065 5.64555L10.7428 4.93521L8.38876 0.506236C8.33988 0.41383 8.23862 0.355469 8.12804 0.355469C8.01747 0.355469 7.91592 0.41383 7.86733 0.506236L5.51305 4.93548L0.249302 5.64582C0.139605 5.66068 0.0488203 5.73174 0.0141942 5.82982C-0.0198499 5.92736 0.00837469 6.03489 0.087811 6.10649L3.89697 9.55414L2.99757 14.4224C2.97894 14.5238 3.02375 14.6262 3.11308 14.6867C3.20299 14.7478 3.322 14.7556 3.41948 14.7072L8.12804 12.409L12.836 14.7072C12.8785 14.728 12.9254 14.7383 12.9716 14.7383C13.0319 14.7383 13.0918 14.721 13.1427 14.6867C13.2323 14.6262 13.2771 14.5238 13.2582 14.4224L12.3591 9.55441L16.1683 6.10649C16.2474 6.03435 16.2759 5.92709 16.2416 5.82955Z" fill="#FFBB02" />
                                            </svg>

                                            <span className='ms-1'>{item.totalRatting.toFixed(2)}</span>
                                        </span>
                                    </td>
                                    <td className="pt-3">{item.category?.parent?.name}</td>
                                    <td className="pt-3 dot-property ">{item.category?.name ? item.name : "-"}</td>
                                    <td className="pt-3">{item.productPrice ? item.productPrice : "-"}</td>
                                    <td className="pt-3">{item.salePrice ? item.salePrice : "-"}</td>
                                    <td className="pt-3">{item.qnty ? item.qnty : "-"}</td>
                                    <td className="pt-3">
                                        <p
                                            className={`status-${item.qnty >= 1 ? "stockin" : "stockout"
                                                } m-0`}
                                        >
                                            {item.qnty >= 1 ? "Stock In " : "Stock Out"}
                                        </p>
                                    </td>
                                    <td className="pt-3">
                                        <IOSSwitch checked={item.isPublished} />
                                    </td>
                                    <td className="pt-3">
                                        <span className='view-icon' onClick={() => handleProductDetail(item.id)}>
                                            <View />
                                        </span>
                                    </td>
                                </tr>
                            )
                            ))}
                        </tbody>
                    </table>
                    </div>
                 
                    <PaginationRounded
                        count={totalPage}
                        page={currentPage}
                        onChange={handlePageChange}
                    />

                </div>
            </div>


        </Product>
    )
}

export default CollectionDetail
