import styled from "styled-components";

export const Main = styled.div`
width: 100%;
background-color: var(--all-background);

.vender{
    font-family: inter;
    font-size: 20px;
    font-weight: 600;
    color: #252525;
}
.profile{
    font-family: inter;
    font-size: 24px;
    font-weight: 500;
    color: #156A3D;
    margin: 0;
    padding: 0px 50px;
    letter-spacing: 0.2px;
}
.bottom{
    margin: 0;
    border-bottom: 3px solid #156A3D;
    width: 176px;
}
.padding{
    padding: 0px 50px;
    position: relative;
}
.img-border{
    width: 165px;
    height: 155px;
    border-radius: 10px;
    border: 1px solid #D9D9D9;
}
.width{
    width: 146px;
    height: 137px;
    margin: 8px 8px;
}
.card-body {
    flex: 1 1 auto;
    padding: 0px;
    color: var(--bs-card-color);
}
.margin-left{
    margin-left: 142px;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 9;
    top: 24px;
    left: 50px;
}
.div-width{
    width: 16%;
    margin-right: 0%;
}
.profile-picture{
    margin: 0;
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
}
.profile-flex{
    display: flex;
    /* gap: 17%; */
    justify-content: space-between;
    align-items: center;
}
.upload-border{
    /* border: 1px solid; */
    height: 155px;
    width: 672px;
    border: 1px solid #94A3B8;
    border-radius: 12px;
}
.name-label{
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}
.input-width{
    width: 672px;
    height: 56px;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
}
/* .margin-left{
    margin-left: 10px;
} */
.CreateVendor{
    margin-bottom: 12px;
    width: 170px;
    height: 50px;
    font-family: inter;
    font-family: 700;
    font-size: 16px;
    background-color: #156A3D;
    color: white;
    border: 0px;
    border-radius: 10px;

}
.background-img{
    background-image: url(./img/create-vender/create-vender.png);
    width: 672px;
    height: 147px;
    background-size: cover;
    border-radius: 12px;
    object-fit: cover;
}
.img-width{
    height: 39px;
    width: 39px;
    margin-top: 41px;
}
.banner-image{
    width: 100%;
    height: 100%;
}
.banner-close{
    width: 40px;
    height: 40px;
    position: absolute;
    margin-left: 650px;
    margin-top: -20px;
    z-index: 50;
}
`