import styled from "styled-components";

export const Main = styled.div`
width: 100%;
background-color: var(--all-background);

.nav-pills .nav-link.active, .edagDK .nav-pills .show>.nav-link {
    color: black;
    background-color: #F9F9F9 !important;
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: black;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.img{
    width: 100%;
    height: 186px;
    border-radius: 10px;
}
.card-width{
    width: 32%;
    border: 0px;
    border-radius: 15px;
}
.heading{
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    padding: 0px;
}
.sub-heading{
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #808D9E;
}
.card-flex{
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}
.third {
    width: 326px;
    height: 92px;
    border: 1px solid #EDF2F7;
}

@media (max-width:1000px) {
.card-width{
width: 47%;
}
}
@media (max-width:470px) {
.card-width{
width: 100%;
}
}

`
