import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };

const useHook = () => {
  const getUerID=async(id)=>{
    try{
        const response=AuthAPI.vendorProfileGet(id);
        return response;
    }catch(error){
        console.error("Error here",error)
    }
  }
  const getUerReviews=async(body)=>{
    try{
        const response=await AuthAPI.getUserReviews(body);
        return response;
    }catch(error){
        console.error("Error here",error)
    }
  }



  

    return {
        getUerID,
        getUerReviews,
        }

    }

    export default useHook;
