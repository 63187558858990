import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { IoCloseSharp } from "react-icons/io5";
import { BusinessDetailsContainer } from "./style";
import DefaultSofa from "assets/img/common/defaultSofa.jpeg";
import { Star, Clock, AdminLotusPRO } from "assets/img";
import VerificationModal from "components/modals/verification-modal";
import { useParams } from "react-router-dom";

const StyledDialog = styled(Dialog)`
  & .MuiDialog-paper {
    width: 1022px; // Adjust width as needed
    height: 90%; // Adjust height as needed
    max-width: 100%; // Ensure responsiveness
    max-height: 100%;
    border-radius: 18px;
  }
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    background-color: #ffbb02;
    color: white;
  }
  .heading {
    margin-left: 400px;
    color: white;
    font-family: "Plus Jakarta Sans";
    font-size: 22px;
    font-weight: 600;
    line-height: 25.2px;
    text-align: center;
  }
`;

const BusinessDetailsModal = ({ open, onClose, businessInfo, ProfileData }) => {
  const { id } = useParams();
  const backgroundImage = businessInfo?.businessBannerImage
    ? `${process.env.REACT_APP_BASE_URL}${businessInfo?.businessBannerImage}`
    : DefaultSofa;

  // verification modal
  const [openVerificationModal, setopenVerificationModal] = useState(false);

  const handleVerificationModalOpen = () => setopenVerificationModal(true);
  const handleVerificationModalClose = () => setopenVerificationModal(false);

 
  

  return (
    <StyledDialog open={open} onClose={onClose}>
      <div className="header">
        <DialogTitle className="heading">Business Details</DialogTitle>
        <DialogActions>
          <IoCloseSharp
            style={{ cursor: "pointer", fontSize: "25px" }}
            onClick={onClose}
          />
        </DialogActions>
      </div>
      <DialogContent>
        <Typography variant="body1">
          <BusinessDetailsContainer>
            <div
              style={{
                backgroundImage: `
          linear-gradient(
            180deg,
            rgba(2, 0, 36, 0.4) 180%,
            rgba(2, 0, 36, 1) 250%
          ),
          url(${backgroundImage})
        `,
                backgroundSize: "cover",
                backgroundPosition: "center",
                width: "100%",
                height: "200px",
                borderRadius: "25px 25px 0 0",
              }}
            >  <div className="btn-container">
              {ProfileData?.isVerified === true ? (
               <p className="verified">Verified</p> 
              ) : (
              
                  <button
                    className="verification-btn"
                    onClick={handleVerificationModalOpen}
                  >
                    <Clock /> Verification
                  </button>
               
              )}
               </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "35px",
                  padding: "0px 25px",
                }}
              >
                <p className="styling-font">
                  Followers :
                  <span className="ms-2">{ProfileData?.followersCount}</span>
                </p>
                {businessInfo?.businessLogo ? (
                  <>
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${
                        // profileData ? profileData.profile_pic : ProfileImage
                        businessInfo?.businessLogo
                      }`}
                      style={{
                        width: "149px",
                        height: "149px",
                        borderRadius: "76px",
                        border: "4px solid white",
                      }}
                      alt=""
                    />
                  </>
                ) : (
                  <>
                    <AdminLotusPRO
                      style={{
                        width: "149px",
                        height: "149px",
                        borderRadius: "76px",
                        border: "4px solid white",
                      }}
                    />
                  </>
                )}

                <p className="styling-font">
                  Profile like :
                  <span className="ms-2">{ProfileData?.profileLike}</span>
                </p>
              </div>
            </div>
            <div className="text-center" style={{ marginTop: "30px" }}>
              <h2 className="main-name">{businessInfo?.businessName}</h2>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                  alignItems: "center",
                }}
              >
                <div>
                  <p className="ratting ">
                    {ProfileData &&
                    ProfileData.totalRatting !== undefined &&
                    ProfileData.totalRatting !== null
                      ? parseFloat(ProfileData.totalRatting).toFixed(2)
                      : "Loading..."}
                    <Star />
                  </p>
                </div>
              </div>
            </div>

            <div className="main-width">
              <div className="partition-width">
                <p className="main-head">EIN/ SSN</p>
                <div>
                  <p className="sub-head">{businessInfo?.businessSsin}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">DBA</p>
                <div>
                  <p className="sub-head">{businessInfo?.dba}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">Type of Business</p>
                <div>
                  <p className="sub-head">{businessInfo?.businessType}</p>
                </div>
              </div>
            </div>
            <div className="main-width">
              <div className="partition-width">
                <p className="main-head">Date Started</p>
                <div>
                  <p className="sub-head">
                    {" "}
                    {businessInfo?.createdAt
                      ? new Date(businessInfo.createdAt).toLocaleDateString(
                          "en-US",
                          {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )
                      : "N/A"}
                  </p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">Business License</p>
                <div>
                  <p className="sub-head">{businessInfo?.businessLicenseNo}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">Upload Business License</p>
                <div>
                  {businessInfo?.businessLicenseImage && (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}${businessInfo?.businessLicenseImage}`}
                      alt=""
                      className="license-img"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="main-width">
              <div className="partition-width">
                <p className="main-head">State</p>
                <div>
                  <p className="sub-head">{businessInfo?.state}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">City</p>
                <div>
                  <p className="sub-head">{businessInfo?.city}</p>
                </div>
              </div>
              <div className="partition-width">
                <p className="main-head">Zip Code</p>
                <div>
                  <p className="sub-head">{businessInfo?.zip}</p>
                </div>
              </div>
            </div>
            <hr />

            <div style={{ display: "flex", gap: "20px" }}>
              <div className="w-50 ">
                <p className="main-head">Physical Address Line 1</p>
                <div>
                  <p className="address-font">{businessInfo?.addressLine1}</p>
                </div>
              </div>
              <div className="w-50 ">
                <p className="main-head">Physical Address Line 2</p>
                <div>
                  <p className="address-font">{businessInfo?.addressLine2}</p>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "20px", marginTop: "15px" }}>
              <div className="w-50 ">
                <p className="main-head">Description</p>
                <div>
                  <p className="address-font">{businessInfo?.description}</p>
                </div>
              </div>
              <div className="w-50 ">
                <p className="main-head">About Shop</p>
                <div>
                  <p className="address-font">{businessInfo?.aboutShop}</p>
                </div>
              </div>
            </div>
            {/* Add more detailed content as needed */}
          </BusinessDetailsContainer>
        </Typography>
      </DialogContent>
      <VerificationModal
        open={openVerificationModal}
        onClose={handleVerificationModalClose}
        id={id}
      />
    </StyledDialog>
  );
};

export default BusinessDetailsModal;
