import React, { useEffect, useState } from "react";
import { Main } from "./style";

import TotalDisputes from "../total-dispute";
import InitiatedDisputes from "../initiated-disputes";
import MyReview from "../my-review";
import ResolvedDispute from "../resolved";
import DeclinedDispute from "../declined";
import useHook from "./usehook";
import { AdminReview, Resolved, Declined, InitiatedDispute, Total } from '../../../assets/img/index'

import Spinner from "components/loader";
const Disputes = () => {
  const { getDisputes } = useHook();
  const [activeTab, setActiveTab] = useState("total");



  const [loader, setLoader] = useState(false)
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };
  const [statusCounts, setStatusCounts] = useState({
    returned: 0,
    admin_review: 0,
    initiated: 0,
    declined: 0,
    resolved: 0,
  });
  const [disputeCounts, setDisputeCounts] = useState('');
  const fetchData = async () => {
    setLoader(true);
    const formData = {
      page: 1,
      perPage: 0,
    };
    try {
      const response = await getDisputes(formData);
      // console.log('Dispute list is', response);
      setDisputeCounts(response.data.counts);

      // Extract the results array from the response
      const results = response.data.results;

      // Initialize a new object for counting statuses
      const newStatusCounts = {
        returned: 0,
        admin_review: 0,
        initiated: 0,
        returned: 0,
        resolved: 0,
      };

      // Loop through each dispute and count the statuses
      results.forEach((dispute) => {
        const status = dispute.status;
        if (newStatusCounts.hasOwnProperty(status)) {
          newStatusCounts[status]++;
        }
      });

      // Update the state with the new counts
      setStatusCounts(newStatusCounts);

      // Log the status counts
      console.log('Status counts:', newStatusCounts);
    } catch (error) {
      console.log('Dispute error', error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [])

  const renderTabContent = () => {
    if (activeTab === "total") {
      return <TotalDisputes />;
    } else if (activeTab === "initiated") {
      return <InitiatedDisputes />;
    } else if (activeTab === "review") {
      return <MyReview />;
    } else if (activeTab === "resolved") {
      return <ResolvedDispute />;
    } else if (activeTab === "returned") {
      return <DeclinedDispute />;
    }
    return null;
  };
  return (
    <Main>
      {loader ? (
        <Spinner />
      ) : (
        <div className="container-fluid">
          <div className="heading-flex">
            <h1 className="heading m-0">Order Disputes</h1>
          </div>
          <div className="disputes-tabs dispute-flex gap-2">
            <div
              className={`tab ${activeTab === "total" && "active"}`}
              onClick={() => handleTabChange("total")}
            >
              <div className="order-flex">
                <div class={`card ${activeTab === "total" ? "active" : "third-card"} `}>
                  <div class="card-body">
                    <div className="third-flex">
                      <span className="total-background">
                        <Total className="w-75" />

                      </span>
                      <div className="margin">
                        <p className="m-0 heading-2">Total Disputes</p>
                        <p className="m-0 number">{disputeCounts}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab ${activeTab === "initiated" && "active"}`}
              onClick={() => handleTabChange("initiated")}
            >
              <div className="order-flex">
                <div class={`card ${activeTab === "initiated" ? "active" : "third-card"} `}>
                  <div class="card-body">
                    <div className="third-flex">
                      <div className="initiated-background">
                        <InitiatedDispute className="w-75" />
                      </div>
                      <div className="margin">
                        <p className="m-0 heading-2">Initiated Disputes</p>
                        <p className="m-0 number">{statusCounts.initiated}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab ${activeTab === "review" && "active"}`}
              onClick={() => handleTabChange("review")}
            >
              <div className="order-flex">
                <div className={`card ${activeTab === "review" ? "active" : "third-card"} `}>
                  <div className="card-body">
                    <div className="third-flex">
                      <div className="review-background">
                        <AdminReview className="w-75" />
                      </div>
                      <div className="margin">
                        <p className="m-0 heading-2">My Review</p>
                        <p className="m-0 number">{statusCounts.admin_review}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab ${activeTab === "resolved" && "active"}`}
              onClick={() => handleTabChange("resolved")}
            >
              <div className="order-flex">
                <div className={`card ${activeTab === "resolved" ? "active" : "third-card"} `}>
                  <div className="card-body">
                    <div className="third-flex">
                      <div className="resolved-background">
                        <Resolved className="w-75" />
                      </div>
                      <div className="margin">
                        <p className="m-0 heading-cards">Resolved </p>
                        <p className="m-0 number">{statusCounts.resolved}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`tab ${activeTab === "returned" && "active"}`}
              onClick={() => handleTabChange("returned")}
            >
              <div className="order-flex">
                <div className={`card ${activeTab === "returned" ? "active" : "third-card"} `}>
                  <div className="card-body">
                    <div className="third-flex">
                      <div className="decliend-background">
                        {/* <img src={Declined} alt="img" className="total-img" /> */}
                        <Declined className="w-75" />
                      </div>
                      <div className="margin">
                        <p className="m-0 heading-2">Returned</p>
                        <p className="m-0 number">{statusCounts.returned}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* #============================================== */}

          {/* #============================================== */}
          <div className="tab-content">
            {renderTabContent()}
            </div>
        </div>
      )}
    </Main>
  );
};

export default Disputes;
