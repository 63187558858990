import styled from "styled-components";
// 
export const Main = styled.div`
 .spinner-container {
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    align-items: center;
    height: 20vh;
    background-color: transparent;
  }

  /* .spinner-background {
    position: relative;
  } */

  .rectangle {
    position: absolute;
    z-index: -1;
  }

  .spinner {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    animation: spin 2s linear infinite;
  }
  .upper-loader{
    width: 100px;
  }
  .lower-loader{
    width: 100px;
  }
  .spinner-upper-half,
  .spinner-lower-half {
    
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner-image {
    top: -40px;
    left: -15px;
    position: relative;
    width: 60px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`


