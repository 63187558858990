import React, { useState,useEffect } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { IoCloseSharp } from "react-icons/io5";
import { Main, SaveBtn } from './style';
import TextField from '@mui/material/TextField';
import useHook from './usehook';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "685px",
    height: "280px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
};

const OrderReturnedDays = ({ open, onClose, selectedValue }) => {
    const { postOrderCancellationCharges } = useHook();

    const [orderReturnedcharges, setOrderReturnedCharges] = useState('')
    const handleOrderReturnedCharges = async () => {
        const formData = {
            "data": [
                {
                    "key": "return_policy_days",
                    "value": orderReturnedcharges,
                }
            ]
        }
        try {
            const response = await postOrderCancellationCharges(formData)
            console.log("admin charges response", response)
            console.log("admin form data", formData)
            onClose()
            setOrderReturnedCharges('')
        }
        catch (error) {
            console.error("Error", error)
        }
    }

    useEffect(() => {
        if (open) {

            setOrderReturnedCharges(selectedValue || '');

        }
    }, [open, selectedValue]);
return (
    <div>
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
        >
            <Box sx={style}>
                <Main>
                    <div className='header'>
                        <Typography id="simple-modal-title" variant="h6" component="h2">

                        </Typography>
                        <Typography id="simple-modal-title" variant="h6" component="h2">
                            Order Returned Days
                        </Typography>
                        <IoCloseSharp className='close-icon' onClick={onClose} />
                    </div>
                    <div className='modal-body'>
                        <Typography id="simple-modal-description" sx={{ mt: 2, fontWeight: "bold" }}>
                            Days Remaining for Order Returned
                        </Typography>
                        <input type="number" className='bg-color-input ps-4' value={orderReturnedcharges} onChange={(e) => setOrderReturnedCharges(e.target.value)} />
                        {/* <span>Add Number of days</span> */}
                        <SaveBtn className='mt-5' onClick={handleOrderReturnedCharges}>Update Order Returned Charges</SaveBtn>

                    </div>

                </Main>

            </Box>
        </Modal>
    </div >
);
};

export default OrderReturnedDays;
