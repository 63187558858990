import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",

  };

  
  const useHook = () => {

    const categoriesGet = async (params) => {
        try {
            const response = await AuthAPI.categoriesGet(params);
            console.log("categories get response", response);
            return response.data || [];
        } catch (error) {
            console.error('There was an error getting the color list:' + error.message);
            // console.error('Error details:', error);
            return [];
        }
       
    };
    const categoriesPut = async (id, isActive) => {
        try {
            const response = await AuthAPI.categoriesPut(id, { isActive });
            toastr.success("Status Updated successfully");
            return response.data || [];
        } catch (error) {
            console.error('There was an error posting the switch: ' + error.message);
            console.error('Error details:', error);
            return [];
        }
    };
    const categoriesDelete = async (id) => {
        try {
            const response = await AuthAPI.categoriesDelete(id);
            toastr.success("SubCategory deleted successfully");
            return response.data || [];
        } catch (error) {
            console.error('There was an error posting the switch: ' + error.message);
            console.error('Error details:', error);
            return [];
        }
    };
    return {

        categoriesGet,
        categoriesPut,
        categoriesDelete,
    }
  }
  
  export default useHook;
  