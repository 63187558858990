import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };

const useHook = () => {


const getDashboardTopCards=async(body)=>{
 try{
    const response=await AuthAPI.dashboardtopCards(body);
    return response;
 } 
 catch(error){
    console.error("Error getting dashbaord card list",error)
 }
}

const GraphDataGet = async (queryString) => {
  try {
    const response = await AuthAPI.dashboardGraphDataGet(queryString);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
const GraphOrdersGet = async (queryString) => {
  try {
    const response = await AuthAPI.ordersGraphGet(queryString);
    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};









    return {
        getDashboardTopCards,GraphDataGet,GraphOrdersGet,
        }

    }

    export default useHook
