import React, { useState, useEffect } from "react";
import { Main, Input } from "./style";
import useHook from "../disputes/usehook";
import PaginationRounded from "components/pagination";
import { useNavigate } from "react-router-dom";

const DeclinedDispute = () => {
  const { getDisputes } = useHook();
  const navigate=useNavigate();

  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [search, setSearch] = useState("");
  const [disputelist, setDisputelist] = useState([]);
  const fetchData = async (resetPage = false) => {
    const formData = {
      status: "returned",
      search: search,
      page: resetPage ? 1 : currentPage,
      perPage: 10,
    };
    try {
      const response = await getDisputes(formData);
      console.log("Dispute declined list is", response);
      setTotalPage(Math.ceil(response.data.counts / 10));

      setDisputelist(response.data.results);
    } catch (error) {
      console.log("dispute error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  // date format
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
};
  // const formatDate = (inputDate) => {
  //   // Create a date object from the input string
  //   const date = new Date(inputDate);

  //   // Array to convert month index to month name
  //   const months = [
  //     "January",
  //     "February",
  //     "March",
  //     "April",
  //     "May",
  //     "June",
  //     "July",
  //     "August",
  //     "September",
  //     "October",
  //     "November",
  //     "December",
  //   ];

  //   // Get day, month, and year from the date object
  //   const day = date.getDate();
  //   const month = months[date.getMonth()];
  //   const year = date.getFullYear();

  //   // Return formatted date
  //   return `${day}-${month}-${year}`;
  // };
  // time format
  const formatTimeFromISO = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Get hours, minutes, and seconds
    let hours = date.getUTCHours(); // Use getUTCHours() to work with UTC time
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12; // the hour '0' should be '12'

    // Format minutes and seconds to always show two digits
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // Return the formatted time
    return `${hours}:${formattedMinutes} ${period}`;
  };
  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };
  const handleSearch = () => {
    setcurrentPage(1); // Reset the current page to 1
    fetchData(true); // Pass true to reset the page in fetchUser
  };
  const handleDisputeDetail=(id)=>{
    navigate(`/disputes/dispute-detail/${id}`);
  }
  return (
    <Main>
      <Input>
        <div className="card border-0 mt-3">
          <div className="card-body">
            <div className="d-flex flex-row flex-nowrap gap-2">
              <div className="w-100">
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  type="text"
                  className="input-reason me-2 "
                  placeholder="Search by Reason"
                />
              </div>

              {/* <select className="dispute-list me-2">
                  <option value="1">Category</option>
                  <option value="2">status 1</option>
                  <option value="3">status 2</option>
                </select> */}

              <div className="w-25">
                <button
                  type="submit"
                  className="filter-dispute"
                  onClick={handleSearch}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Input>
      <div className="card border-0 mt-3">
        <div className="card-body">
          <div className="table-responsive">
            <table className="dummy-table w-100">
              <thead>
                <tr className="heading">
                  <th style={{ width: "10%" }}>Dispute ID</th>
                  <th style={{ width: "20%" }}>Customer</th>
                  <th style={{ width: "13%" }}>Invoice Number</th>
                  <th style={{ width: "30%" }}>Reason</th>
                  <th style={{ width: "19%" }}>Date</th>
                  <th style={{ width: "10%" }}>FullFilled By</th>
                  {/* <th style={{ width: "10%" }}>Time</th> */}
                  <th style={{ width: "10%" }}>Actions</th>
                  {/* <th>ACTIONS</th> */}
                </tr>
              </thead>
              <tbody>
                {disputelist.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="no-record">
                      No records found
                    </td>
                  </tr>
                ) : (
                  disputelist.map((data, index) => (
                    <tr key={index} className="sub-heading ">
                      <td className="top">{data.id}</td>
                      <td className="top">{data.order?.user?.name}</td>
                      <td className="top">{data.order.id}</td>
                      <td className="top">{data.reason}</td>
                      <td className="top">{formatDate(data.createdAt)}</td>
                      <td className="top">
                        {data.fullFilledBy?.role === "superadmin"
                          ? data.fullFilledBy?.name
                          : data.fullFilledBy?.businessInfo?.businessName ||
                            "-"}
                      </td>
                      {/* <td className="top">{formatTimeFromISO(data.createdAt)}</td> */}
                      <td className="top">
                        <span
                        onClick={() => handleDisputeDetail(data.id)}
                        >
                          <svg
                            className="view-icon"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_1971_40786)">
                              <path
                                d="M15.1028 7.57304C14.9698 7.41807 11.7897 3.7832 7.72439 3.7832C3.65897 3.7832 0.479027 7.41807 0.345651 7.57319C0.0839761 7.87705 0.0839761 8.31794 0.345805 8.6221C0.479027 8.77706 3.65897 12.4118 7.72439 12.4118C11.7897 12.4118 14.9698 8.77676 15.103 8.62179C15.3647 8.31794 15.3647 7.87704 15.1028 7.57304ZM8.96615 5.96671C9.23952 5.81934 9.62073 5.98843 9.81748 6.34425C10.0142 6.70021 9.95209 7.10829 9.67872 7.25566C9.40551 7.40318 9.02415 7.23394 8.82724 6.87812C8.63064 6.52215 8.69263 6.11408 8.96615 5.96671ZM7.72439 11.0949C4.88458 11.0949 2.49474 8.94099 1.66756 8.09749C2.22614 7.52776 3.4979 6.36111 5.13918 5.66346C4.81905 6.14446 4.63137 6.7192 4.63137 7.33785C4.63137 9.02486 6.01604 10.3924 7.72424 10.3924C9.43243 10.3924 10.8173 9.02486 10.8173 7.33785C10.8173 6.7192 10.6297 6.14446 10.3096 5.66346C11.9509 6.36126 13.2225 7.52776 13.7811 8.09749C12.9539 8.94145 10.564 11.0949 7.72439 11.0949Z"
                                fill="white"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_1971_40786">
                                <rect
                                  width="15.1498"
                                  height="14.9618"
                                  fill="white"
                                  transform="translate(0.149414 0.617188)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
          <PaginationRounded
            count={totalPage}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </Main>
  );
};

export default DeclinedDispute;
