import styled from "styled-components";

export const Main = styled.div`

.view-icon{
    background-color: #209A42;
    /* padding: 5px; */
    border-radius: 6px;
    cursor: pointer;
}
.status{
  width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #FF00001A;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #FF0000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.styled-font{
  font-family: inter;
  font-weight: 400;
  font-size: 14px;
  color: #1C1C27;
}
.table-head{
  font-family: inter;
  font-weight: 700;
  font-size: 14px;
  color: #0C3A2D;
}
.status-Processing{
   width: 99px;
   height: 24px;
   border-radius: 5px;
   /* background-color: #00D1FF1A; */
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #00D1FF;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-delivered{
   width: 90px;
   height: 24px;
   border-radius: 5px;
   /* background-color: #209A421A; */
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #209A42;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-pending{
    width: 82px;
   height: 24px;
   border-radius: 5px;
   /* background-color: #FF8F501A; */
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF8F50;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-cancel{
    width: 75px;
   height: 24px;
   border-radius: 5px;
   /* background-color: #FF00001A; */
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF0000;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
@media (max-width:1085px) {
.third-card{
  width: 200px;
    height: 92px;
    border: 1px solid #EDF2F7;

}
}
.container-1{
  display: flex;
  flex-wrap: wrap;
}
.container-body{
  display: flex;
  width: 100%;
  align-items: center;
}
.container-body2{
  display: flex;
  flex-direction: row;
  width: 100%;
}

@media (max-width:705px) {

  .container-body2{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.btn-container{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.filter{
  width: 100%;
}
.reset{
width: 100%;
}
}

@media (max-width:550px) {
  .nav-link{
    width: 100%;
  }
  .nav-item{
    width: 100%;
  }
.third-card{
  width: 100%;
    height: 92px;
    border: 1px solid #EDF2F7;
}
.container-body{
  display: flex;
  flex-direction: column;
}
.container-body2{
  display: 100%;
  flex-wrap: wrap;
  width: 100%;
}
.reset{
  width: 100%;
}
.filter{
  width: 100%;
}

}



`