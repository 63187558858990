import { BrowserRouter, Routes, Route } from "react-router-dom"
import AuthLayout from "../components/layouts/auth-layout";
import DasboardLayout from "components/layouts/dasboard-layout";
const AppRoutes = () => {


    return (
        
        <BrowserRouter>
            <Routes>
                <Route path="/*" Component={DasboardLayout} />
                <Route path="/auth/*" Component={AuthLayout} />
            </Routes>
        </BrowserRouter>
    )
}

export default AppRoutes;