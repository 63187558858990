import styled from "styled-components";
export const Main=styled.div`

.header{
display: flex;
justify-content: space-between;
align-items: center;
width: 100%;
background-color: #FFBB02;
color: white;
padding-left: 12px;
padding-right: 12px;
padding-top: 10px;
padding-bottom: 6px;
border-top-left-radius: 12px;
border-top-right-radius: 12px;
}

.modal-body{
    margin-left: 20px;
    margin-right: 15px;
    padding:12px;
    display: flex;
    flex-direction: column;
}
.bg-color-input{
    background-color: #F9F9F9;
    outline: none;
    border: none;
    height: 52px;
    width: 603px;
}
.close-icon{
    font-size: 25px;
    cursor: pointer;
}
/* The container */
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
/* input type number removing arrows */
input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

`
export const SaveBtn = styled.button`

    height: 61px;
    width: 604.53px;
    border-radius: 12px;
    outline: none;
    background-color: #156A3D;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    border: 0px;

`