import React, { useState, useEffect } from 'react'
import { Product, Main } from './style';
// import DeleteModal from 'components/modal/delete';
import LotusPro from 'assets/img/common/lotuspro.png'
import Spiner from '../../../components/loader';
import useHook from './usehook';
import { useParams } from 'react-router-dom';
import PaginationRounded from "components/pagination";

import { AdminLotusPRO, View } from 'assets/img'
const CatalogImages = () => {
  const [isLoading, setIsLoading] = useState(false);




  const { id } = useParams();
  const { catalogGetSingle, catalogimagesGet } = useHook();

  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const [singleCatalog, setSingleCatalog] = useState({});
  const SingleCatalogImage = async () => {
    try {
      // setIsLoading(true);
      const response = await catalogGetSingle(id);
      setSingleCatalog(response.data)
      console.log("catalog image resposne is", response)
    } catch (error) {
      console.log("Catalog image error", error)
    } finally {
      setIsLoading(false)
    }

  }
  useEffect(() => {
    SingleCatalogImage();
  }, [])
  const [catalogImageList, setCatalogImageList] = useState([]);
  const CatalogImage = async () => {
    const formdata = {
      catalogId: id,
      page: 1,
      perPage: 9,
    }
    try {
      setIsLoading(true);
      const response = await catalogimagesGet(formdata);
      // setTotalPage(Math.ceil(response.data.results.length / 10));
      setCatalogImageList(response.data)
      console.log("catalog image list resposne is", response)
    } catch (error) {
      console.log("Catalog image error", error)
    }

  }
  useEffect(() => {
    CatalogImage();
  }, [])
  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };
  return (
    <>
      <Main>
        <div className="container-fluid">
          <Product>
            {isLoading ? (
              <Spiner />
            ) : (
              <>
                <div className="heading-flex">
                  <p className="heading m-0">My Catalogs</p>
                </div>
                <div className="card border-0 mt-3">
                  <div className="card-body">
                    <div className='single-description'>
                      {singleCatalog.image ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${singleCatalog.image}`}
                          alt='catalogImage'
                          className='singleCatalog-Image'
                        />
                      ) : (
                        <AdminLotusPRO className='singleCatalog' />
                      )}
                      {/* <img src={singleCatalog.image ? process.env.REACT_APP_MEDIA_URL + singleCatalog.image : LotusPro} alt='catalogImage' className='singleCatalog-Image' /> */}
                      <div className='description-container'>
                        <p className="m-0 product-name">{singleCatalog?.name} </p>
                        {singleCatalog?.catalogImagesCount >= 2 ? (
                          <p className="product-count">{singleCatalog.catalogImagesCount} Photos</p>
                        ) : (
                          <p className="product-count">{singleCatalog.catalogImagesCount} Photo</p>
                        )}
                        <p className="product-para">{singleCatalog.description}</p>
                      </div>
                    </div>
                  </div>
                </div>




                <div className="mt-4 card-main-flex"
                >
                  {catalogImageList.length > 0 ? (
                    catalogImageList.map((items, index) => (
                      <>
                        <div key={index} className="mt-4" style={{ boxSizing: "border-box",width:"100%"}}>
                          <div style={{
                            backgroundColor: "white",
                            padding: "12px 12px",
                            width: "95%"
                          }}>
                            {items.image ? (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}${items.image}`}
                                alt={items.heading}
                                className='img'
                              />
                            ) : (
                              <AdminLotusPRO className='img' />
                            )}
                            {/* <img src={process.env.REACT_APP_MEDIA_URL + items.image} className="img" alt={items.heading} /> */}
                            <div className="mt-3">
                              <div className="img-flex">
                                <div>
                                  <p className="m-0 heading-card2">{items.name}</p>
                                  <p className="m-0 description">{items.description}</p>
                                </div>
                              </div>
                              <div className='mt-2' style={{ display: "flex", flexWrap: "wrap", gap: "2px" }}>
                                {items.tags.length >= 1 && items.tags.map((items) => (
                                  <div>
                                    <button className="transitional">
                                      <span className='dot-property px-2'>{items}</span></button>
                                  </div>
                                ))
                                }
                              </div>
                            </div>
                          </div>
                        </div>

                      </>
                    ))
                  ) : (
                    <div className='no-record'>No Record Found</div>
                  )}


                </div>
                {/* <div className='w-100 d-flex justify-content-end'>
                  <PaginationRounded
                    count={totalPage}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div> */}
              </>
            )
            }
          </Product>
        </div>
      </Main>
    </>
  )
}
export default CatalogImages;


// import React, { useState, useEffect } from 'react'
// import Home1 from '../../../assets/img/catalogs/home1.png';
// import Home2 from '../../../assets/img/catalogs/home2.png';
// import Home3 from '../../../assets/img/catalogs/home3.png';
// import Home4 from '../../../assets/img/catalogs/home4.png';
// import Home5 from '../../../assets/img/catalogs/home5.png';
// import Home6 from '../../../assets/img/catalogs/home6.png';
// import Home7 from '../../../assets/img/catalogs/home7.png';
// import { Product } from './style';
// import { useParams } from 'react-router-dom';
// import useHook from './usehook';

// const CatalogImages = () => {
//   const { id } = useParams()
//   const { catalogGetSingle, catalogimagesGet } = useHook();
//   const collection = [
//     { id: 1, para1: "Accent Benches", para2: "3 Products", para3: "The perfect blend between casual comfort and style, the Knox Seating Collection by Jennifer Taylor Home brings cozy modern feelings into any space. The natural wood base and legs make a striking combination with the luxurious velvet upholstery. The back, seat, and arm pillows are all removable and reversible for the ultimate convenience of care." },

//   ]

//   const catalog = [
//     { id: 1, img: Home5, heading: "Abbey 60 Double Vanity", subHeading: "Abbey 60 bathroom vanity in charcoal gray with Carrara Marble top" },
//     { id: 2, img: Home6, heading: "Abbey 60 Double Vanity", subHeading: "Abbey 60 bathroom vanity in charcoal gray with Carrara Marble top" },
//     { id: 3, img: Home7, heading: "Abbey 60 Double Vanity", subHeading: "Abbey 60 bathroom vanity in charcoal gray with Carrara Marble top" },
//   ]
//   const [singleCatalog, setSingleCatalog] = useState({});
//   const SingleCatalogImage = async () => {
//     try {
//       const response = await catalogGetSingle(id);
//       setSingleCatalog(response.data)
//       console.log("catalog image resposne is", response)
//     } catch (error) {
//       console.log("Catalog image error", error)
//     }

//   }
//   useEffect(() => {
//     SingleCatalogImage();
//   }, [])
//   const [catalogImageList, setCatalogImageList] = useState([]);
//   const CatalogImage = async () => {
//     const formdata = {
//       catalogId: id,
//       page: 1,
//       perPage: 100
//     }
//     try {
//       const response = await catalogimagesGet(formdata);
//       setCatalogImageList(response.data)
//       console.log("catalog image list resposne is", response)
//     } catch (error) {
//       console.log("Catalog image error", error)
//     }

//   }
//   useEffect(() => {
//     CatalogImage();
//   }, [])
//   return (
//     <Product>

//       <div className="card border-0 mt-3">
//         <div className="card-body">
//           <div className='d-flex flex-row gap-2'>
//             <img src={`${process.env.REACT_APP_MEDIA_URL}${singleCatalog.image}`} className='catalog-image' />
//             <div>
//               <p className='m-0 product-name'>{singleCatalog.name} </p>
//               <p className='product-count'>{singleCatalog.description} </p>
//               <p className='product-para'>{singleCatalog.catalogImagesCount} </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div className='  '>
//         {catalogImageList.map((item) => (
//         <div key={item.id} className=" p-3 border rounded shadow">
//           <img
//             src={`${process.env.REACT_APP_MEDIA_URL}${item.image}`}
//             alt={item.name}
//             className="img object-contain"
//           />
//           <div className="mt-3">
//             <p className="m-0 heading font-bold text-lg">{item.name}</p>
//             <p className="m-0 sub-heading text-gray-600">{item.description}</p>
//             <div className="mt-2 ">
//               {item.tags.map((tag, index) => (
//                 <button
//                   key={index}
//                   className="transitional bg-blue-500 text-white px-2 py-1 rounded mx-1"
//                 >
//                   {tag}
//                 </button>
//               ))}
//             </div>
//           </div>
//         </div>
//       ))}

//       </div>
//     </Product>
//   )
// }

// export default CatalogImages;
