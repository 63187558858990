import AuthRoutes from "../../../containers/auth/index";
import { Main } from "./style"
import AdminImg from '../../../assets/img/auth/sign screens1.png'


const AuthLayout = () => {
    return (
        <Main>
            {/* <h2>Auth layout</h2> */}
            <div className="container-fluid auth-container">
                <div className="row">
                    <div className="col-6 p-0 h-100">
                        <img src={AdminImg} alt="Login Image" className="img-con" />
                    </div>
                    <div className="col-6 p-0 auth-routes">
                    <AuthRoutes  />
                    </div> 

                </div>
            </div>

        </Main>
    )
}

export default AuthLayout;