// In this file, you will be putting the styling which you are going to use globally in your application which leads to the reusability of the styling

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

    *{
        margin: 0 ;
        padding: 0;
        box-sizing: border-box;
        /* background: var(--all-background); */
        /* height: var(--height); */

        
        
    }

    :root{
        --primary: #41e0a3;
        --all-background:#F9F9F9;
        --height:100vh

        //put your global colors here which you are using multiple times in your application.
        /*  You can replace the primary color with your main theme color */
    }

    .heading{
        font-family: poppins;
    font-weight: 600;
    font-size: 20px;
   color: #252525;
   padding: 15px 0px;
    }
    .heading-flex{
        display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0px;
    /* padding-block: 10px; */
    }
  .no-record{
  width: 100% !important;
    background-color: white !important;
    padding: 12px !important;
    text-align: center !important;
    /* font-weight: 600; */
    font-size: 16px !important;
    color: red !important;
    font-family: 'Inter' !important;
}
.load-more {
    display: flex;
    justify-content: end;
    padding: 10px 35px;
    /* cursor: pointer; */
  }
  .load {
    background-color: #0C3A2D;
    color: white;
    font-family: "Inter";
    padding: 5px 10px;
    border-radius: 3px;
  }
`;
