import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom';
import PaginationRounded from "components/pagination";
import { OrderCancel, OrderReturns, TotalProducts } from 'assets/img/index'
import useHook from './usehook';
import { Main } from './style';
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};

const OrderListing = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { getVendorOrderDetail, getVendorOrderlist } = useHook();
    // const [loading,setloading]=useState(false);

    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [status, setStatus] = useState('')
    const [currentPage, setcurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);

    // Order Detail
    const [orderDetail, setOrderDetail] = useState('');
    const getVendorDetail = async () => {
        try {
            const response = await getVendorOrderDetail(id);
            setOrderDetail(response.data)
            // console.log("hahahhhaahhssldksldklskdl",response.data)
            // console.log("vendor detail", response.data)
        } catch (error) {
            console.error("error vendor detail", error)
        }
    }

    // orders lsit
    const [orderlist, setOrderList] = useState([]);
    const fetchOrderList = async (resetPage = false) => {
        const formData = {
            userId: id,
            invoiceNumber: invoiceNumber,
            customerName: customerName,
            startDate: startDate,
            endDate: endDate,
            status: status,
            page: resetPage ? 1 : currentPage,
            perPage: 10,
        }
        try {
            const response = await getVendorOrderlist(formData);
            setTotalPage(Math.ceil(response.data.counts / 10));
            setOrderList(response.data.results)
            console.log("Order List response", response)
        } catch (error) {
            console.error("Order List Error", error)
        }
    }
    useEffect(() => {
        getVendorDetail()
    }, [])
    useEffect(() => {
        fetchOrderList()

    }, [currentPage])
    const handleReset = () => {
        setCustomerName('')
        setEndDate('');
        setStartDate('');
        setInvoiceNumber('');
        setStatus('')
        setcurrentPage(1)

    }

    useEffect(() => {
        if (
            customerName === "" &&
            startDate === "" &&
            endDate === "" &&
            invoiceNumber === ""
        ) {
            fetchOrderList()
        }
    }, [currentPage,startDate,endDate,invoiceNumber]);

    const handleStartDateChange = (date) => {
        const selectedStartDate = new Date(date);

        if (endDate && selectedStartDate > new Date(endDate)) {
            setEndDate(null);
        }

        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        const selectedEndDate = new Date(date);

        if (startDate && selectedEndDate < new Date(startDate)) {
            toastr.error("End date cannot be earlier than start date");
            return;
        }

        setEndDate(date);
    };
    // 

    // const UserInvoice = (state) => {
    //     navigate("/userinvoice", { state });
    // };
    const OrderInvoice = (id) => {
        navigate(`/order-management/order-invoice/${id}`);
    };

    // date format
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
    };
    // const formatDate = (inputDate) => {
    //     // Create a date object from the input string
    //     const date = new Date(inputDate);

    //     // Array to convert month index to month name
    //     const months = [
    //         "January", "February", "March", "April", "May", "June",
    //         "July", "August", "September", "October", "November", "December"
    //     ];

    //     // Get day, month, and year from the date object
    //     const day = date.getDate();
    //     const month = months[date.getMonth()];
    //     const year = date.getFullYear();

    //     // Return formatted date
    //     return `${day}-${month}-${year}`;
    // }
    // time format
    const formatTimeFromISO = (dateTimeString) => {
        const date = new Date(dateTimeString);

        // Get hours, minutes, and seconds
        let hours = date.getUTCHours(); // Use getUTCHours() to work with UTC time
        const minutes = date.getUTCMinutes();
        const seconds = date.getUTCSeconds();

        // Determine AM or PM
        const period = hours >= 12 ? 'PM' : 'AM';

        // Convert 24-hour format to 12-hour format
        hours = hours % 12 || 12; // the hour '0' should be '12'

        // Format minutes and seconds to always show two digits
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const formattedSeconds = seconds.toString().padStart(2, '0');

        // Return the formatted time
        return `${hours}:${formattedMinutes} ${period}`;
    }
    const handlePageChange = (e, value) => {
        setcurrentPage(value);
    };
    const handleSearch = () => {

        setcurrentPage(1); // Reset the current page to 1
        fetchOrderList(true); // Pass true to reset the page in fetchUser
    };
    return (
        <>
            <Main>
                <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link active p-0 me-2"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                        >
                            <div className="order-flex">
                                <div className="card third-card">
                                    <div className="card-body">
                                        <div className="third-flex">
                                            {/* <img src={total} alt="img" className="total-img" /> */}
                                            <span>
                                                <TotalProducts className="total-img" />
                                            </span>
                                            <div className="margin">
                                                <p className="m-0 order-para">Total Order</p>
                                                <p className="m-0 order-number">{orderDetail.totalOrders ? orderDetail.totalOrders : "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link p-0 me-2"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            aria-controls="pills-profile"
                            aria-selected="false"
                        >
                            <div className="order-flex">
                                <div className="card third-card">
                                    <div className="card-body">
                                        <div className="third-flex">
                                            {/* <img src={cancel} alt="img" className="cancel-img" /> */}
                                            <span className="cancel-img">
                                                <OrderCancel />
                                            </span>
                                            <div className="margin">
                                                <p className="m-0 order-para">Order Cancel</p>
                                                <p className="m-0 order-number">{orderDetail.cancelledCount ? orderDetail.cancelledCount : "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className="nav-link p-0"
                            id="pills-contact-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-contact"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                        >
                            <div className="order-flex">
                                <div className="card third-card">
                                    <div className="card-body">
                                        <div className="third-flex">
                                            {/* <img src={turn} alt="img" className="return-img" /> */}
                                            <span>
                                                <OrderReturns className="return-img" />
                                            </span>
                                            <div className="margin">
                                                <p className="m-0 order-para">Order Returns</p>
                                                <p className="m-0 order-number">{orderDetail.returnedCount ? orderDetail.returnedCount : "-"}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </button>
                    </li>
                </ul>
                <div className="card border-0">
                    <div className="card-body">
                        <div className='container-1'>
                            <div className='container-body gap-2'>
                                <div className='w-100'>
                                    <input
                                        type="number"
                                        className="input2"
                                        placeholder="Search by Invoice Number"
                                        value={invoiceNumber}
                                        onChange={e => setInvoiceNumber(e.target.value)}
                                    />
                                </div>
                                <div className='w-100'>
                                    <input
                                        type="text"
                                        className="input me-2"
                                        placeholder="Search by Customer Name"
                                        value={customerName}
                                        onChange={(e) => setCustomerName(e.target.value)}
                                    />
                                </div>
                                <div className='w-100'>
                                    <select className="select1 me-2"
                                        onChange={(e) => setStatus(e.target.value)}
                                        value={status}>
                                        <option value="">status</option>
                                        <option value="pending">Pending</option>
                                        <option value="processing">Processing</option>
                                        <option value="delivered">Delivered</option>
                                        <option value="cancelled">Cancelled</option>
                                        <option value="returned">Returned</option>
                                        <option value="declined">Declined</option>
                                        <option value="shipped">Shipped</option>
                                    </select>
                                </div>
                            </div>

                            <div className="container-body2 gap-2">
                                <div className='d-flex flex-row align-items-center w-100 mt-2 gap-2'>
                                    <div className='w-100 flex-column'>
                                        <label className="date">Start Date</label> <br />
                                        <input type="date" className="date-input" name="" id=""
                                            onChange={(e) => handleStartDateChange(e.target.value)}
                                            value={startDate || ""} />
                                    </div>
                                    <div className='w-100 flex-column'>
                                        <label className="date">End Date</label> <br />
                                        <input type="date" className="date-input" name="" id=""
                                            onChange={(e) => handleEndDateChange(e.target.value)}
                                            value={endDate || ""}
                                        />
                                    </div>
                                </div>
                                <div className='btn-container d-flex flex-row align-items-center gap-2' style={{ marginTop: "30px" }}>
                                    <div className='w-100 gap-2'>
                                        <button type="submit" className="filter"
                                            onClick={() => handleSearch()}
                                        >
                                            Filter
                                        </button>
                                    </div>
                                    <div className='w-100'>
                                        <button type="submit" className="reset"
                                            onClick={() => handleReset()}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card border-0 mt-3">
                    <div className="card-body table-responsive">
                        <table className="w-100 table">
                            <thead>
                                <tr className="">
                                    <th>Invoice</th>
                                    <th style={{ width: "18%" }}>Order Time</th>
                                    <th>Customer Name</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody className="waleed-margin">
                                {orderlist.map((items) => (
                                    <tr
                                        className="mt-3 flex2 style-font2"
                                        style={{ paddingTop: "10%" }}
                                    >
                                        <td style={{ paddingTop: "3%" }}>
                                            {items.id}
                                        </td>
                                        <td style={{ paddingTop: "3%", width: "25%" }}>
                                            {`${formatDate(items.createdAt)}`}
                                        </td>
                                        <td style={{ paddingTop: "3%" }}>{items.user?.name}</td>
                                        <td style={{ paddingTop: "3%" }}>{items.totalAmount}</td>
                                        <td style={{ paddingTop: "3%", width: "15%" }}>
                                            <p
                                                className={`status-${items.status === "processing"
                                                    ? "Processing"
                                                    : items.status === "delivered"
                                                        ? "Delivered"
                                                        : items.status === "cancelled"
                                                            ? "cancel"
                                                            : items.status === "shipped" ? "shipped"
                                                                : "pending"
                                                    } m-0`}
                                            >
                                                {items.status}
                                            </p>
                                        </td>
                                        <td style={{ paddingTop: "3%" }}>
                                            <span className='view-icon' onClick={() => OrderInvoice(items.id)}>
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clip-path="url(#clip0_1971_40786)">
                                                        <path d="M15.1028 7.57304C14.9698 7.41807 11.7897 3.7832 7.72439 3.7832C3.65897 3.7832 0.479027 7.41807 0.345651 7.57319C0.0839761 7.87705 0.0839761 8.31794 0.345805 8.6221C0.479027 8.77706 3.65897 12.4118 7.72439 12.4118C11.7897 12.4118 14.9698 8.77676 15.103 8.62179C15.3647 8.31794 15.3647 7.87704 15.1028 7.57304ZM8.96615 5.96671C9.23952 5.81934 9.62073 5.98843 9.81748 6.34425C10.0142 6.70021 9.95209 7.10829 9.67872 7.25566C9.40551 7.40318 9.02415 7.23394 8.82724 6.87812C8.63064 6.52215 8.69263 6.11408 8.96615 5.96671ZM7.72439 11.0949C4.88458 11.0949 2.49474 8.94099 1.66756 8.09749C2.22614 7.52776 3.4979 6.36111 5.13918 5.66346C4.81905 6.14446 4.63137 6.7192 4.63137 7.33785C4.63137 9.02486 6.01604 10.3924 7.72424 10.3924C9.43243 10.3924 10.8173 9.02486 10.8173 7.33785C10.8173 6.7192 10.6297 6.14446 10.3096 5.66346C11.9509 6.36126 13.2225 7.52776 13.7811 8.09749C12.9539 8.94145 10.564 11.0949 7.72439 11.0949Z" fill="white" />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_1971_40786">
                                                            <rect width="15.1498" height="14.9618" fill="white" transform="translate(0.149414 0.617188)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>

                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="mt-4 d-flex justify-content-end">
                            <PaginationRounded
                                count={totalPage}
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </Main>
        </>
    )
}

export default OrderListing
