import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};
const useHooks = () => {
  const commentPost = async (id, body) => {
    try {
      const response = await AuthAPI.postComment(id,body);
      return response;
    } catch (error) {
      console.error(error);
      toastr.error(error.message);
      return null;
    }
  };
  const imagePost = async (file) => {
    try {
        if (!file) {
            throw new Error("No file provided");
        }

    // Check if file size is greater than 2MB
    if (file.size > 2 * 1024 * 1024) { // 2MB limit
        toastr.error("File size exceeds 2MB limit");
        return null;
    }

    // Check if file type is an image
    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif']; // Add more types if needed
    if (!acceptedTypes.includes(file.type)) {
        toastr.error("File type not supported. Please upload an image file (jpg, jpeg, png, gif).");
        return null;
    }
        const reader = new FileReader();
        const fileAsDataURL = new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });
        reader.readAsDataURL(file);
        await fileAsDataURL;  // Wait for the file to be read as data URL
        const response = await AuthAPI.Postimages(
            { image: file },
            { headers: { "Content-Type": "multipart/form-data" } }
        );
        console.log("Image upload response", response);
        if (response?.path) {
            toastr.success("Image uploaded successfully");
            console.log("response path",response.path)
            return response.path;
        } else {
            console.error("Image upload failed");
            return null;
        }
    } catch (error) {
        console.error("Error uploading image:", error);
        console.error("An error occurred during image upload");
        return null;
    }
};

  return {
     commentPost,imagePost

   };
};

export default useHooks;
