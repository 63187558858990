import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};

const useHook = () => {
  const profileGet=async(body)=>{
    try{
        const response=await AuthAPI.getAdminProfile(body);
        return response;
    }catch(error){
        console.log("Error Getting Profile Data",error)
    }
  }


    return {
        profileGet,

    }
}
export default useHook