import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);

  .active {
    background-color: white;
    width: 190px;
    height: 85px;
    border: 0px;
    cursor: pointer;
    border-radius: 7px;
  }
  .order-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
  }
  .third-card {
    /* width: 212px; */
    /* height: 92px; */
    height: 85px;
    width: 190px;
    /* border: 1px solid #EDF2F7; */
    /* margin-top: 1%; */
    background-color: #f5f5f5;
    /* background-color: white; */
    cursor: pointer;
  }
  .third-card:hover {
    background-color: white;
    border: 0px;
  }
  .third-flex {
    justify-content: start;
    display: flex;
    align-items: center;
  }
  .total-img {
    width: 30px;
    height: 30px;
  }
  .margin {
    margin-left: 3%;
  }
  .heading-2 {
    font-family: inter;
    /* font-size:16px; */
    font-size: 14px;
    font-weight: 400;
    color: #1c1c27;
    letter-spacing: 0.2px;
    white-space: nowrap;
  }
  .card-body {
    padding-left: 10px;
  }
  .number {
    font-family: inter;
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.2px;
    color: #1c1c27;
  }
  .dispute-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 2px;
    flex-wrap: wrap;
  }
  .total-background {
    background-color: #004e971a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 30px;
  }
  .initiated-background {
    background-color: #ff8f501a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 30px;
  }
  .review-background {
    background-color: #00d1ff1a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 30px;
  }
  .resolved-background {
    background-color: #209a421a;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-radius: 30px;
  }
  .decliend-background {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px;
    background-color: #ff00001a;

    border-radius: 30px;
  }
`;
export const Input = styled.div`
  .input-reason {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    padding: 15px;
    outline: none;
  }
  .dispute-list {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 15px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    outline: none;
  }
  .filter-dispute {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: white;
    border: 0px;
    background-color: #156a3d;
  }
`;
