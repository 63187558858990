// import styled from "styled-components";
// export const Main = styled.div`
// width: 100%;
// background-color: var(--all-background);

// .tabs-container {
//     display: flex;
//     flex-direction: row;
//     border-bottom: 5px solid aqua;
//     gap: 30px;
//     padding-top: 13px;
//     padding-left: 20px;
//     font-family: inter;
//     font-size: 16px;
//     font-weight: 600;
//     color: #1C1C27;
//     width: 200px;
//     padding-inline: 0px;
  
// }

// .tab {
//   /* padding: 10px; */
//   cursor: pointer;
// }

// .bottom-border {
//     border-bottom: 4px solid black;
//     padding: 5px 42px;
// }

// .content {
//   padding: 20px;
// }
// `

import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);

  .tabs-container {
    display: flex;
    flex-direction: row;
    border-bottom: 5px solid #E2E8F0;
    gap: 30px;
    padding-top: 13px;
    padding-left: 20px;
    font-family: inter;
    font-size: 16px;
    font-weight: 600;
    color: #1C1C27;
    width: 300px;
    padding-inline: 0px;
  }

  .tab {
    cursor: pointer;
    position: relative;
    /* width: 100%; */
    text-align: center;
    padding: 0px 0px 15px 42px;
  }

  .bottom-border {
    border-bottom: 5px solid black;
    position: absolute;
    bottom: 0;
    bottom: -5px;
    width: calc(70% - -49px);
    transition: left 0.3s ease;
  }

  .content {
    padding: 20px;
  }

  .tab.active .bottom-border {
    left: 0;
  }

  .tab.active:nth-child(2) .bottom-border {
    width: calc(115% - -60px);
  }
  .heading{
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    align-items: center;
    color: #0C3A2D;
  }
  .sub-heading{
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #1C1C27;
    border-bottom: 1px solid #E2E8F0;
  }
  .top{
    padding-block: 13px;
  }
  .img-width{
    width: 23px;
    height: 24px;
  }
  .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;

    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
    min-width: 48px;
    height: 48px;
    border-radius: 12px;
    padding: 0 6px;
    margin: 0 3px;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(0, 0, 0, 0.23);
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #0C3A2D;
    color: #FFBB02;
}
`;
