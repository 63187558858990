import React, { useEffect, useState } from "react";
import {
    Modal,
    Box,
    Typography,
} from "@mui/material";
import { Main } from './style'
import { IoMdClose } from "react-icons/io";
import toastr from "toastr";
import useHook from "./usehook";
import { ImageDummy } from "assets/img";
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
};

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderTopRightRadius: '20px',
    borderTopLeftRadius: '20px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    "@media (max-width: 585px)": {
        width: 300,
        height: "auto",

    },
};

export default function CategoryModal({
    open,
    onClose,
    ModalparentID,
    parentItemName,
}) {
    const { imagePost, categoriesPost, MainCategoriesUpdate } = useHook();
    const [categoryName, setcategoryName] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);
    const [errors, setErrors] = useState({});
    // const [loader, setloader] = useState(false);


    useEffect(() => {
        if (ModalparentID) {
            setSelectedImage(parentItemName.image);
            setcategoryName(parentItemName.name)
        }

    }, [open])
    const validateForm = () => {
        let isValid = true;
        const newErrors = {};

        if (!categoryName.trim()) {
            newErrors.categoryName = "Category name is required";
            isValid = false;
        }

        if (!selectedImage) {
            newErrors.selectedimage = "Image required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        try {
            const imagePath = await imagePost(file);
            setSelectedImage(imagePath);
            setErrors((prevErrors) => ({
                ...prevErrors,
                selectedimage: "",
            }));
        } catch (error) {
            console.error("Error in handleImageChange: ", error);
            toastr.error("An error occurred during image upload");
        }
    };
    const handleImagePickerClick = () => {
        document.getElementById("imagePicker").click();
    };

    const handlAddCategry = async () => {
        if (validateForm()) {
            const formData = {
                name: categoryName,
                image: selectedImage || "String",
            };
            try {
                // setloader(true);
                await categoriesPost(formData);
                console.log(formData);
                toastr.success("Categories created successfully");
                setcategoryName("");
                setSelectedImage(null);
                onClose()
            } catch (error) {
                console.error("Error adding category: ", error);
            }
            // finally {
            //     setloader(false);
            // }
        }
    };
    // useEffect(()=>{
    //     setSelectedImage(parentItemName.image)
    //     setcategoryName(parentItemName.name)
    // },[])
    const handlUpdateMainCategry = async () => {
        if (validateForm()) {
            const params = {
                name: categoryName,
                image: selectedImage || "String",
            };
            try {
                // setloader(true);
                const response = await MainCategoriesUpdate(ModalparentID, params);
                console.log("response is", response);
                setcategoryName("");
                setSelectedImage(null);
                onClose();
            } catch (error) {
                console.error("Error updating category: ", error);
            }
            // finally {
            //     setloader(false);
            //     fetchData();
            // }
        }
    };
    const handleClose = () => {
        setSelectedImage(null);
        setcategoryName("")
        onClose();
    }
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Main>
                    <Box paddingRight="12px" display="flex" justifyContent="space-between" alignItems="center" className="background2">

                        <Typography id="modal-title" variant="h6" component="h2" className="style-category py-2 px-3" >
                            {ModalparentID ? "Update Category" : "Add Category"}
                        </Typography>
                        <IoMdClose className="close-icon" onClick={handleClose} />
                    </Box>
                    <div className="modal-body">
                        <div className="front d-flex gap-4">
                            <p className="font">Service Icon</p>
                            <p className="font">Write Category Name</p>
                        </div>
                        <div className="front2" style={{ gap: ModalparentID ? '10%' : '10%' }}
                        >
                            <div
                                onClick={handleImagePickerClick}
                                style={{ cursor: "pointer" }}
                                width="100%"
                            >
                                <input
                                    type="file"
                                    id="imagePicker"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleImageChange}
                                />

                                {selectedImage ? (
                                    <div className="w-50">
                                        <img
                                            src={`${process.env.REACT_APP_BASE_URL}/${selectedImage}`}
                                            alt="img" style={{ borderRadius: "40px" }}
                                            className={
                                                selectedImage ? "img" : "defaultimage"
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div className="w-100 d-flex flex-column">
                                        <ImageDummy />
                                        {errors.selectedimage && (
                                            <div className="mr-5 error">
                                                {errors.selectedimage}
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <p className="m-0">
                                <input
                                    type="text"
                                    className="input1"
                                    placeholder="Category Name"
                                    value={categoryName}
                                    onChange={(e) => {
                                        setcategoryName(e.target.value);
                                        setErrors((prevErrors) => ({
                                            ...prevErrors,
                                            categoryName: "",
                                        }));
                                    }}
                                />
                                {errors.categoryName && (
                                    <span className="error ps-3">{errors.categoryName}</span>
                                )}
                            </p>
                        </div>

                        <button
                            className="add-button2"
                            onClick={ModalparentID ? handlUpdateMainCategry : handlAddCategry}
                        >
                            {ModalparentID ? "Update" : "Add"}
                        </button>

                    </div>

                </Main>

            </Box>
        </Modal>
    );
}
