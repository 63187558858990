import React, { useState, useEffect } from "react";
import { Card, InputMaterial, AddButton, Main } from "./style";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Delete } from "assets/img";
import useHook from "./usehook";
import { IoMdClose } from "react-icons/io";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "1022px",
  height: "90%",
  borderRadius: "28px",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  outline: "none",
  "@media (max-width: 1640px)": {
    width: "1022px",
    height: "530px",
    // padding: 2,
  },  
  "@media (max-width: 1100px)": {
    width: "800px",
    height: "400px",
    // padding: 2,
  },  
  "@media (max-width: 850px)": {
    width: "700px",
    height: "400px",
    // padding: 2,
  },  
  "@media (max-width: 720px)": {
    width: "450px",
    height: "440px",
    // padding: 2,
  }, 
};

const MaterialComponent = ({ isOpen, onClose }) => {
  const { MaterialPost, materialGet, materialDelete } = useHook();

  const [products, setProducts] = useState([]);
  const [productName, setProductName] = useState("");
  const [materiallist, setmateriallist] = useState([]);
  const [materiallength, setmateriallength] = useState('');
  const [error, setError] = useState("");

  const validateInput = () => {
    if (productName.trim() === "") {
      setError("Material name is required.");
      return false;
    } else {
      setError("");
      return true;
    }
  };

  const handleAddProduct = async () => {
    if (!validateInput()) {
      return
    }
    const productData = {
      "name": productName,
      "status": true
    };
    await MaterialPost(productData);
    setProductName('');
    fetchMaterial()
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await materialDelete(productId);
      const updatedProducts = products.filter(product => product.id !== productId);
      setProducts(updatedProducts);
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const fetchMaterial = async () => {
    const formParameter = {
      status: true,
      page: 1,
      perPage: 2000
    };
    const fetchproduct = await materialGet(formParameter);
    setmateriallist(fetchproduct);
    setmateriallength(fetchproduct.length);
  };

  useEffect(() => {
    fetchMaterial();
  }, [products]);

  const handleClose = () => {
    setError('');
    onClose();
  }
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Main>
          <Typography className="main-container">
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="total-material-label"
            >
              Total Materials
            </Typography>
            <IoMdClose style={{ fontSize: "24px", fontWeight: 500, letterSpacing: "0.5%", lineHeight: "26px", cursor: "pointer" }} onClick={handleClose} />
          </Typography>
          <Typography id="modal-modal-description" className="body">
            <label style={{ fontSize: "16px", fontWeight: 500, letterSpacing: "0.5%", lineHeight: "26px" }}>Material Name</label>
            <div>
              <InputMaterial
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                className="input-color"
              />
              {error && (
                <Typography variant="body2" color="error">
                  {error}
                </Typography>
              )}
            </div>
            <div className="text-end" style={{ padding: "13px 0px" }}>
              <AddButton
                type="submit"
                className="add"
                onClick={handleAddProduct}
              >
                Add
              </AddButton>
            </div>

            <Card className="card">
              <div className="card-body">
                <table style={{ width: "100%" }}>
                  <tbody>
                    {materiallist.length === 0 ? (
                      <tr style={{ textAlign: "center" }}>
                        <td colSpan="5" className="no-record">
                          No Record Found
                        </td>
                      </tr>
                    ) : (
                      materiallist.map((item, index) => (
                        <tr key={index} style={{backgroundColor:"none !important"}}>
                          {/* <td style={{ width: "80px", paddingTop: "10px",outline:"none",border:"none" }} className="width-index">{index + 1}</td> */}
                          <td style={{ padding: "10px 0px 0px 60px"}} className="material-name">{item.name}</td>
                          <td className="delete-td">
                            <span id={(e) => e.target.id} className="delete-icon" onClick={() => handleDeleteProduct(item.id)}>
                              <Delete />
                            </span>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </Card>
          </Typography>
        </Main>

      </Box>
    </Modal>
  );
};

export default MaterialComponent;
