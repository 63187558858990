import React, { useEffect, useState } from "react";
import { Main } from "./style";
import { useNavigate } from "react-router-dom";
import useHook from "./usehook";
import PaginationRounded from "components/pagination";
import Spinner from "components/loader";
import { View } from 'assets/img/index'
import toastr from 'toastr';
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};
const OrderManagement = () => {
  const { getOrderList } = useHook();

  const navigate = useNavigate();
  const UserInvoice = (id) => {
    navigate(`/order-management/order-invoice/${id}`);
  };

  const [searchVendor, setSearchVendor] = useState("");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [status, setStatus] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const getOrder = async (resetPage = false) => {
    const formData = {
      invoiceNumber: invoiceNumber,
      customerName: searchCustomer,
      businessName: searchVendor,
      startDate: startDate,
      endDate: endDate,
      status: status,
      page: resetPage ? 1 : currentPage,
      perPage: 10,
    };
    try {
      setLoading(true);
      const response = await getOrderList(formData);
      console.log("order response", response.data.results);
      setTotalPage(Math.ceil(response.data.counts / 10));
      setdata(response.data.results);
    } catch (error) {
      console.error("error", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("current page with getOrder")
    getOrder();
  }, [currentPage]);
  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };

  // const formatDate = (inputDate) => {
  //   // Create a date object from the input string
  //   const date = new Date(inputDate);

  //   // Array to convert month index to month name
  //   const months = [
  //     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  //   ];

  //   // Get day, month, and year from the date object
  //   const day = date.getDate();
  //   const month = months[date.getMonth()];
  //   const year = date.getFullYear();

  //   // Return formatted date
  //   return `${day}-${month}-${year}`;
  // }
  // time format
  const formatTimeFromISO = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Get hours, minutes, and seconds
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12; // the hour '0' should be '12'

    // Format minutes and seconds to always show two digits
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    // Return the formatted time
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  }
  // const formatDateTime = (isoString) => {
  //   const dateObject = new Date(isoString);
  //   const datePart = dateObject.toISOString().split('T')[0];
  //   const timePart = dateObject.toTimeString().split(' ')[0];
  //   return `${datePart} ${timePart}`;
  // };
  const formatDateTime = (isoString) => {
    const dateObject = new Date(isoString);

    // Format date part as "Aug 24, 2024"
    const datePart = dateObject.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

    // Format time part as "HH:MM:SS"
    const timePart = dateObject.toTimeString().split(' ')[0];

    return `${datePart}, ${timePart}`;
  };
  const handleStatus = (e) => {
    setStatus(e.target.value);
  };
  const handleReset = () => {
    setSearchCustomer("");
    setSearchVendor("");
    setStatus("");
    setStartDate("");
    setEndDate("");
    setInvoiceNumber("");
  };
  useEffect(() => {
    if (
      searchCustomer === "" &&
      searchVendor === "" &&
      startDate === "" &&
      endDate === "" &&
      status === "" &&
      invoiceNumber === ""
    ) {
      getOrder(true);
    }
  }, [searchCustomer, startDate, endDate, invoiceNumber, status, searchVendor]);

  const handleStartDateChange = (date) => {
    const selectedStartDate = new Date(date);

    if (endDate && selectedStartDate > new Date(endDate)) {
      setEndDate(null);
    }

    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const selectedEndDate = new Date(date);

    if (startDate && selectedEndDate < new Date(startDate)) {
      toastr.error("End date cannot be earlier than start date");
      return;
    }

    setEndDate(date);
  };
  const handleSearch = () => {
    setcurrentPage(1);
    getOrder(true);
  };
  return (
    <Main>
      {loading ? (
        <Spinner />
      ) : (
        <div className="background-color">
          <div className="container-fluid">
            <div className="heading-flex">
              <h1 className="heading m-0">Order Management</h1>
            </div>
            <div className="card border-0">
              <div className="card-body">
                <div className="card-container gap-2">
                  <div className="w-25 input-div">
                    <input
                      value={searchCustomer}
                      onChange={(e) => setSearchCustomer(e.target.value)}
                      type="text"
                      className="input me-2"
                      placeholder="Search by Customer Name"
                    />
                  </div>
                  <div className="w-25 input-div">
                    <input
                      value={searchVendor}
                      onChange={(e) => setSearchVendor(e.target.value)}
                      type="text"
                      className="input2 me-2"
                      placeholder="Search by Business"
                    />
                  </div>
                  <div className="w-25 input-div">
                    <input
                      value={invoiceNumber}
                      onChange={(e) => setInvoiceNumber(e.target.value)}
                      type="text"
                      className="input2 me-2"
                      placeholder="Search by Invoice Number"
                    />
                  </div>
                  <div className="w-25 input-div">
                    <select
                      className="select1 me-2"
                      onChange={handleStatus}
                      value={status}
                    >
                      <option>Status</option>
                      <option value="pending">Pending</option>
                      <option value="processing">Processing</option>
                      <option value="delivered">Delivered</option>
                      <option value="cancelled">Cancelled</option>
                      <option value="returned">Returned</option>
                      <option value="declined">Declined</option>
                      <option value="shipped">Shipped</option>
                    </select>
                  </div>
                </div>
                <div className="flex mt-3">
                  <div className="w-100">
                    <label className="date">Start Date</label> <br />
                    <input
                      type="date"
                      className="date-input"
                      name=""
                      id=""
                      onChange={(e) => handleStartDateChange(e.target.value)}
                      value={startDate || ""}
                    />
                  </div>
                  <div className="w-100">
                    <label className="date">End Date</label> <br />
                    <input
                      type="date"
                      className="date-input"
                      name=""
                      id=""
                      onChange={(e) => handleEndDateChange(e.target.value)}
                      value={endDate || ""}
                    />
                  </div>
                  <div className="button-container">
                    <div className="mt-4 w-50">
                      <button type="submit" className="filter" onClick={handleSearch}>
                        Filter
                      </button>
                    </div>
                    <div className="mt-4 w-50">
                      <button type="submit" className="reset" onClick={handleReset}>
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* #================================================= */}

            <div className="card border-0 mt-3">
              <div class="card-body">
                {loading ? (
                  <Spinner />
                ) : data.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table w-100" >
                        <thead className="">
                          <tr className="table-head">
                            <th>Invoice</th>
                            <th>Order Date</th>
                            <th >Customer Name</th>
                            <th >Business Name</th>
                            <th >Amount</th>
                            <th >Status</th>
                            <th >Actions</th>
                          </tr>
                        </thead>
                        <tbody className="waleed-margin">
                          {data.map((items, index) =>
                          (
                            <tr
                              key={index}
                              className="mt-3 style-font2"
                              style={{ paddingTop: "15px" }}
                            >
                              <td style={{ paddingBlock: "15px" }}>
                                {items.id}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {formatDate(items?.createdAt)}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {items.user?.name}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {items.vendor?.businessInfo?.businessName}
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                {items.totalAmount}
                              </td>
                              <td
                                style={{
                                  paddingBlock: "15px",
                                  width: "10%",
                                }}
                              >
                                <p className={`status-${items.status === "processing"
                                  ? "Processing"
                                  : items.status === "delivered"
                                    ? "Delivered"
                                    : items.status === "cancelled"
                                      ? "cancel"
                                      : items.status === "shipped" ? "shipped"
                                        : "pending"
                                  } m-0`}>{items.status}</p>
                              </td>
                              <td style={{ paddingBlock: "15px" }}>
                                <span
                                  onClick={() => UserInvoice(items.id)}
                                  className="view-icon"
                                >
                                  <View />
                                </span>
                              </td>
                            </tr>
                          )
                          )}
                        </tbody>
                      </table>
                    </div>

                    <PaginationRounded
                      count={totalPage}
                      page={currentPage}
                      onChange={handlePageChange}
                    />
                  </>
                ) : (
                  <div>
                    <div className="no-record">
                      No Record found
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* #================================================= */}
          </div>
        </div>
      )}

    </Main>
  );
};

export default OrderManagement;
