import React, { useEffect, useState } from 'react';
import { ProfileContainer } from './style';
import EditProfileModal from 'components/modals/edit-profile';
// import LotusPro from 'assets/img/common/LotusProLogo.svg'
import { AdminLotusPRO, View, Delete } from 'assets/img'
import useHook from './usehook';
import Viewer from "react-viewer";
import toastr from 'toastr';
const AdminProfile = () => {
    const { profileGet } = useHook();

    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to open the modal
    const openModal = () => {
        setIsModalOpen(true);
    };

    // Function to close the modal
    const closeModal = () => {
        setIsModalOpen(false);
        handleProfile();
    };
    const [profiledata, setProfileData] = useState({});
    const handleProfile = async () => {
        try {
            const response = await profileGet();
            setProfileData(response.data)
            console.log("profile response is ", response)
           
        } catch (error) {
            console.log("error profile", error)
        }
    }
    useEffect(() => {
        handleProfile();
    }, [])
    // image preview
    const [visible, setVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(null); // Store the currently clicked image
    const handleImageClick = (imageSrc) => {
        setCurrentImage(imageSrc); // Set the current image to show in viewer
        setVisible(true); // Open the viewer
    }


    return (
        <ProfileContainer>
            {/* Profile Picture */}

            <div className="profile-picture">
                {profiledata.profile_pic ? (
                    <img onClick={()=>handleImageClick(`${process.env.REACT_APP_MEDIA_URL}${profiledata.profile_pic}`)}
                        src={`${process.env.REACT_APP_MEDIA_URL}${profiledata.profile_pic}`}
                        alt="logo"
                        className="w-100 h-100 object-cover profile-pic " style={{cursor:"pointer"}}
                    />
                ) : (
                    <AdminLotusPRO className="w-100 h-100 object-cover profile-pic" />
                )}
                {visible && currentImage && (
                    <Viewer
                        visible={visible}
                        onClose={() => setVisible(false)}
                        images={[
                            { src: currentImage, alt: "Image" },
                        ]} // Only show the clicked image
                        drag={false}
                    />
                )}
            </div>

            {/* Profile Information */}
            <div className='flex flex-col name-container'>
                <h6>Name</h6>
                <span className="font-bold text-xl">{profiledata.name}</span>
            </div>
            <div className='email-container'>
                <h6>Email</h6>
                <span className="text-gray-600">{profiledata.email}</span>
            </div>
            <div className='phone-container'>
                <h6>Phone Number</h6>
                <span className="text-gray-600">{profiledata.phone}</span>
            </div>
            <button className='search' onClick={openModal}>Edit</button>
            {/* Pass the open state and close function to the modal */}
            <EditProfileModal open={isModalOpen} closeModal={closeModal} profiledata={profiledata} />
        </ProfileContainer>
    );
};

export default AdminProfile;
