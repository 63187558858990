import React, { useEffect, useState, useRef } from "react";
import { Main } from "./style";
import IOSSwitch from "components/ios button";
import Arrow from "../../../assets/img/categories/arrow.png";
import Down from "../../../assets/img/categories/Arrow  Down.png";
import useHook from "./useHook";

import Spinner from "components/loader";
import DeleteModal from "components/modals/delete";
import { Edit } from "assets/img";
import { AdminLotusPRO } from 'assets/img'
import { Delete } from "assets/img";
import toastr from "toastr";
import CategoryModal from "components/modals/category-modal";
import SubCategoryModal from "components/modals/sub-category-modal";


toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
};


const Categories = () => {
  const {
    categoriesGet,
    categoriesPut,
    categoriesDelete,
  } = useHook();

  const [categorylist, setCategorylist] = useState([]);
  const [switches, setSwitches] = useState([]);
  const [subCategoryVisibility, setSubCategoryVisibility] = useState([]);
  const [subCategoriesLists, setSubCategoriesLists] = useState({});
  const [subSwitches, setSubSwitches] = useState({});
  const [loader, setloader] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteID, setDeleteID] = useState(null);

  const fetchData = async () => {
    setloader(true);
    try {
      const data = await categoriesGet();
      setCategorylist(data);
      setSwitches(data.map((item) => item.isActive));
      setSubCategoryVisibility(Array(data.length).fill(false));
    } catch (error) {
      console.error("Error fetching data: ", error);
    } finally {
      setloader(false);
    }
  };
  // useEffect(() => {
  //   console.log("1st fetch data")
  //   fetchData();
  // }, []);
  const toggleSubCategory = async (index, itemID) => {
    // Create a new visibility array to toggle the clicked subcategory
    const newVisibility = subCategoryVisibility.map((_, i) => (i === index ? !subCategoryVisibility[i] : false));
  
    // Update the visibility state
    setSubCategoryVisibility(newVisibility);
  
    // If the subcategory is now open (true), fetch its data
    if (newVisibility[index]) {
      const response = await categoriesGet({ parentId: itemID });
      setSubCategoriesLists({
        [itemID]: response, // Replace the subcategory list with the new one
      });
  
      const newSubSwitches = {};
      response.forEach((subCat) => {
        newSubSwitches[subCat.id] = subCat.isActive;
      });
  
      setSubSwitches({
        ...newSubSwitches, // Set the switches for the newly opened subcategory
      });
    } else {
      // If the subcategory is closed, clear the subcategory list
      setSubCategoriesLists({
        [itemID]: [], // Reset the list for this item
      });
    }
  };
  

  // below is for opening more than one subcategory at same time

  // const toggleSubCategory = async (index, itemID) => {
  //   const newVisibility = [...subCategoryVisibility];
  //   newVisibility[index] = !newVisibility[index];
  //   setSubCategoryVisibility(newVisibility);

  //   if (newVisibility[index]) {
  //     const response = await categoriesGet({ parentId: itemID });
  //     setSubCategoriesLists({
  //       ...subCategoriesLists,
  //       [itemID]: response,
  //     });
  //     const newSubSwitches = {};
  //     response.forEach((subCat) => {
  //       newSubSwitches[subCat.id] = subCat.isActive;
  //     });
  //     setSubSwitches((prevState) => ({
  //       ...prevState,
  //       ...newSubSwitches,
  //     }));
  //   } else {
  //     setSubCategoriesLists({
  //       ...subCategoriesLists,
  //       [itemID]: [],
  //     });
  //   }
  // };

  const handleSwitchToggle = async (index, itemID) => {
    // Create a copy of the switches array
    const newSwitches = [...switches];
    // Toggle the switch
    newSwitches[index] = !newSwitches[index];

    setSwitches(newSwitches);
    const isActive = newSwitches[index];

    try {
      await categoriesPut(itemID, isActive);
    } catch (error) {
      console.error("Error in handleSwitchToggle: ", error);
      setSwitches((prevSwitches) => [...prevSwitches]);
      return;
    }
  };

  const handleSubSwitchToggle = async (itemID) => {
    const newSubSwitches = { ...subSwitches };
    newSubSwitches[itemID] = !newSubSwitches[itemID];
    setSubSwitches(newSubSwitches);
    const isActive = newSubSwitches[itemID];

    try {
      await categoriesPut(itemID, isActive);
    } catch (error) {
      console.error("Error in handleSubSwitchToggle: ", error);
      toastr.error("An error occurred while updating switch state");
      setSubSwitches((prevState) => ({
        ...prevState,
        [itemID]: !prevState[itemID],
      }));
    }
  };
  //  delete modal
  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };
  const deleteSub = async (id) => {
    if (deleteID !== null) {
      try {
        const response = await categoriesDelete(deleteID);
        console.log("response is", response);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("error is", error);
      }
    }
  };



  // open parent or Main Category modal
  const [ModalparentID, setModalparentID] = useState("");
  const [parentItemName, setParentItemName] = useState('')
  const EditAddCategoryModal = (item) => {
    setParentItemName(item)
    setModalparentID(item.id);
    setIsModalOpen(true)
  };

  // catagoryModal states
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalparentID('')
  };
  // Sub Category Modal
  const [isSubModalOpen, setSubModalOpen] = useState(false);
  const [parentState, setParentState] = useState({});

  // Function to open the Sub modal
  const handleSubOpenModal = (item) => {
    console.log("parent item",item)
    setParentState(item)
    setSubModalOpen(true);
  };
  const handleSubCloseModal = () => {
    setSubModalOpen(false);
  }
  // Edit SubModal
  const [subState, setSubState] = useState({});
  const [itemSub, setSubItem] = useState('');
  const handleEditSubModal = (subItem,item) => {
    console.log("parent item is",item);
    setParentState(item)
    setSubModalOpen(true);
    setSubState(subItem)
    setSubItem(subItem.id)
    console.log("subitem.id is", subItem.id)
  }
  useEffect(() => {
    if (!deleteModalOpen) {

      console.log("delete fetch data")
      fetchData();
    }
  }, [deleteModalOpen]);
  useEffect(() => {
    if (!isModalOpen) {
      console.log("categories fetch data")
      fetchData();
    }
  }, [isModalOpen])
  useEffect(() => {
    if (!isSubModalOpen) {
      console.log("subcategories fetch data")
      fetchData();
    }
  }, [isSubModalOpen])

  return (
    <Main>
      {loader ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div className="background">
            <div className="container-fluid pb-4">
              <div className="heading-flex">
                <h1 className="heading m-0">Categories Type</h1>
                <button
                  className="new-vender"
                  onClick={handleOpenModal}
                >
                  Add Category
                </button>
              </div>
              {categorylist.length === 0 ? (
                <div className="no-record">No Record Found</div>
              ) : (
                categorylist.map((item, index) => (
                  <div key={item.id} >
                    <div
                      className="singleRow"
                      style={{
                        cursor: "pointer",
                        marginBottom: !subCategoryVisibility[index]
                          ? "16px"
                          : 0,
                      }}
                    >
                      <div className="flex-2">
                        <div className="d-flex align-items-center gap-3 ">
                          {item.image ? (
                            <img
                              src={`${process.env.REACT_APP_MEDIA_URL}/${item.image}`}
                              alt="sofa img"
                              className="img-width"
                            />
                          ) : (
                            <AdminLotusPRO className="img-width" />
                          )}
                          <div style={{}}>
                            <p className="style-furniture m-0">{item.name}</p>
                          </div>
                        </div>
                        <div className="d-flex align-items-center flex-wrap gap-3">
                          <p className="enable-style">
                            {switches[index] ? "Enabled" : "Disabled"}
                          </p>
                          <div className="">
                            <IOSSwitch
                              checked={switches[index]}
                              onChange={() =>
                                handleSwitchToggle(index, item.id)
                              }
                            />
                          </div>
                          <button
                            className="Category"
                            onClick={() => handleSubOpenModal(item)}
                          >
                            Add Sub Category
                          </button>
                          <span
                            className="edit-icon"
                            onClick={() => EditAddCategoryModal(item)}
                          >
                            <Edit />
                          </span>
                          <img
                            src={subCategoryVisibility[index] ? Arrow : Down}
                            alt=""
                            className="arrow"
                            style={{ cursor: " pointer" }}
                            onClick={() => toggleSubCategory(index, item.id)}
                          />
                        </div>
                      </div>
                    </div>
                    {subCategoryVisibility[index] && (
                      <div className="singleRow mb-3 mt-0">
                        {subCategoriesLists[item.id] &&
                          subCategoriesLists[item.id].length > 0 ? (
                          subCategoriesLists[item.id].map((subItem) => (
                            <div className="flex-second" key={subItem.id}>
                              <div className="sub-pic">
                                {item.image ? (
                                  <img
                                    src={`${process.env.REACT_APP_MEDIA_URL}/${subItem.image}`}
                                    alt="sofa img"
                                    className="img-width"
                                  />
                                ) : (
                                  <AdminLotusPRO className="img-width" />
                                )}
                                <div className="d-flex">
                                  <p className="style-sub-category m-0">
                                    {subItem.name}
                                  </p>
                                </div>
                              </div>

                              <div className="gap">
                                <p className="enable-style">
                                  {subSwitches[subItem.id]
                                    ? "Enabled"
                                    : "Disabled"}
                                </p>
                                <IOSSwitch
                                  checked={subSwitches[subItem.id]}
                                  onChange={() =>
                                    handleSubSwitchToggle(subItem.id)
                                  }
                                />
                                <div
                                  className="enable-style"
                                >
                                  <span onClick={() => handleEditSubModal(subItem,item)}
                                    className="edit-icon"
                                  >
                                    <Edit />
                                  </span>
                                </div>
                                <p className="enable-style">
                                  <span
                                    className="delete-icon"
                                    onClick={() =>
                                      handleOpenDeleteModal(subItem.id)
                                    }
                                  >
                                    <Delete />
                                  </span>
                                </p>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="no-record">No Record Found</div>
                        )}
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>

        </>
      )}
      <CategoryModal open={isModalOpen} onClose={handleCloseModal} ModalparentID={ModalparentID} parentItemName={parentItemName} />
      <SubCategoryModal open={isSubModalOpen} onClose={handleSubCloseModal} parentState={parentState} itemSub={itemSub} subState={subState} />
      <DeleteModal
        open={deleteModalOpen}
        handleClose={handleCloseDeleteModal}
        handleDelete={deleteSub}
      />
    </Main>
  );
}
export default Categories;
