import React, { useEffect, useState, useRef } from "react";
import { Main } from "./style";
import { FiAlignLeft } from "react-icons/fi"; 
import Ellipse from "../../assets/img/header/Ellipse.png";
// import Notification from "../../assets/img/header/notification-bing 1.png";
import { useNavigate } from "react-router-dom";
import { Notification } from '../../assets/img/index'; 
import useHook from "./usehook";
const Header = ({ toggleSidebar }) => {
  const { profileAdminGet } = useHook();
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const Notifications = (state) => {
    navigate("/notifications", { state });  
  };
  const handleSetting = () => {
    navigate("/settings");
  }
  const [adminProfile, setAdminProfile] = useState({})
  const handleProfile = async () => {
    try {
      const response = await profileAdminGet()
      setAdminProfile(response.data)
      console.log(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    handleProfile();
  }, [])

  // const handleLogout = () => {
  //   localStorage.removeItem("Token");
  //   navigate("/auth/");
  // };
  const handleLogout = () => {
    let logout = localStorage.clear(false);
    if (!logout) {
      navigate("/auth");
    } else {
      alert("not logout");
    }
  };
  const counter = localStorage.getItem('unreadCount')
  return (
    <Main ref={headerRef}>
      <div className="width">
      <FiAlignLeft
                className="burger-icon"
                size={30}
                onClick={toggleSidebar} // Use the toggleSidebar prop
                style={{ marginRight: "20px" }}
              />
        <div className="flex">
          <div className="burger-div">
            <div>
             
            </div>
            <div style={{ outline: "1px solid #E2E8F0", height: "48px", width: "40px", display: "flex", justifyContent: "center", alignItems: "center", borderRadius: "5px", position: "relative",
               cursor: "pointer"
                }} onClick={() => Notifications()}>
              {counter && (
                <span
                  style={{
                    position: "absolute",
                    top: "-5px",
                    right: "-5px",
                    background: "rgba(255, 187, 2, 1)",
                    color: "white",
                    borderRadius: "50%",
                    padding: "2px 6px",
                    fontSize: "12px",
                    fontWeight: "bold",
                    lineHeight: "12px",
                  }}
                >
                  {counter}
                </span>
              )}
              <Notification />
            </div>
            <div className="dropdown">
              <div
                className="rounded-2 bg-white h-auto padding dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={`${process.env.REACT_APP_MEDIA_URL}${adminProfile.profile_pic}`}
                  alt="Ellipse"
                  className="image"
                />
                <span className="select">{adminProfile.name}</span>
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end"
                style={{ width: "200px" }}
              >
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={handleSetting}
                  >
                    Settings
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item logoutButton"
                    type="button"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};
export default Header;
