import React, { useState, useEffect } from 'react';
import { Main } from './style';
import Error from '../../../assets/img/profile/Error.png'
import Img from '../../../assets/img/profile/Upload Big Arrow.png'
import { set } from 'react-hook-form';
import { createVendor } from '../../../libs/http-service/api/auth.api'
import useHook from './useHooks';
// import { postRequest } from 'libs/http-service/methods';
const CreateVender = () => {
    const { createVendor, imagePost } = useHook();
    // Validation state
    const [errors, setErrors] = useState({});


    const [selectedImage, setSelectedImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [name, setName] = useState('')
    const [Brandname, setBrandName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setContactNumber] = useState('');
    const [address, setAddress] = useState('');
    const [charges, setcharges] = useState(0.10);





    const handleImageChange = () => {
        document.getElementById('imageInput').click();
    };

    // const handleFileInputChange = async (event) => {
    //     const file = event.target.files[0];
    //     const imagePath = await imagePost(file);
    //     console.log("FINAL: ",imagePath)
    //     // const response = await postRequest('images', {image: file})
    //     // console.log("DIRECT", setSelectedImage(`http://13.238.163.253:81/${response.path}`))

    //     setSelectedImage(process.env.REACT_APP_BASE_URL+imagePath);
    //     console.log("Uploaded Image URL:", imagePath);
    //     // if (file) {
    //     //     const reader = new FileReader();

    //     //     reader.onloadend = () => {
    //     //         setSelectedImage(reader.result);
    //     //     };

    //     //     reader.readAsDataURL(file);
    //     // }
    // };

    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];

        try {
            const imagePath = await imagePost(file); // Ensure this is awaited
            console.log("FINAL: ", imagePath);

            if (imagePath) {
                setSelectedImage(process.env.REACT_APP_BASE_URL + "/" + imagePath);
                console.log("Uploaded Image URL:", imagePath);
            }
        } catch (error) {
            console.error("Error in handleFileInputChange: ", error);
            alert("An error occurred during image upload");
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        document.getElementById('imageInput').value = '';
    };

    const handleBannerFileInputChange = async (e) => {
        const file = e.target.files[0];
        try {
            const imagePath = await imagePost(file); // Ensure this is awaited
            console.log("FINAL: ", imagePath);

            if (imagePath) {
                setBannerImage(process.env.REACT_APP_BASE_URL + "/" + imagePath);
                console.log("Uploaded Image URL:", imagePath);
            }
        } catch (error) {
            console.error("Error in handleFileInputChange: ", error);
            alert("An error occurred during image upload");
        }
    }
    const handleBannerChange = () => {
        document.getElementById('imageInput1').click();

    };
    const handlebannerRemove = () => {
        setBannerImage(null);
        // const imageInputElement = document.getElementById('imageInput1');
        // if (imageInputElement) {
        //     imageInputElement.value = '';
        // } else {
        //     console.error("Element with ID 'imageInput1' not found.");
        // }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Validate form fields
        const validationErrors = {};
        if (!name) validationErrors.name = 'Name is required';
        if (!Brandname) validationErrors.Brandname = 'Business Name is required';
        if (!email) validationErrors.email = 'Email is required';
        if (!phone) validationErrors.phone = 'Phone is required';
        if (!address) validationErrors.address = 'Address is required';
        if (!selectedImage) validationErrors.selectedImage = 'Profile Picture is required';
        if (!bannerImage) validationErrors.bannerImage = 'Banner Image is required';

        setErrors(validationErrors);

        // If there are errors, do not submit the form
        if (Object.keys(validationErrors).length > 0) return;



        // let formData = {
        //     "name": Brandname,
        //     "email": email,
        //     "countryCode": "789",
        //     "phone": phone,
        //     "isoCode": "string",
        //     "address": {
        //         "addressName": "string",
        //         "address": address,
        //         "city": "string",
        //         "zipCode": "string",
        //         "state": "string",
        //         "countryCode": "string",
        //         "phone": "string",
        //         "country": "string",
        //         "lat": "string",
        //         "long": "string"
        //     },
        //     "image": selectedImage,
        //     "backgroundImage": bannerImage
        // }
        let formData = {
            "name": name,
            "businessName": Brandname,
            "address": address,
            "email": email,
            "countryCode": "789",
            "phone": phone,
            "isoCode": "string",
            "profile_pic": selectedImage,
            "background_image": bannerImage,
            "platformCharges": charges,
        }
        console.log(formData)

        // console.log(typeof selectedImage)
        // console.log(typeof createVendor)
        createVendor(formData)
        // Reset the form fields
        setName('');
        setBrandName('');
        setAddress('');
        setEmail('');
        setContactNumber('');
        setSelectedImage(null);
        setBannerImage(null);
    }


    return (
        <Main>
            <div className='container-fluid'>
                <div className="heading-flex">
                    <h1 className="heading m-0">Vendors Management</h1>
                </div>
                <div className='card border-0'>



                    <p className='profile'>Profile</p>
                    <p className='bottom'></p>

                    <div className='padding mt-5'>
                        <div className='d-flex flex-row gap-3 items-center'>
                            <div className=''>
                                <div className='d-flex flex-column'>
                                    <p className='profile-picture'>Profile Picture</p>
                                    <div onClick={handleRemoveImage} >
                                        <img src={Error} alt="" className='margin-left' />
                                    </div>
                                    <div className='card img-border' >
                                        <div className='card-body' onClick={handleImageChange}>
                                            {selectedImage && (
                                                <div>
                                                    <img src={selectedImage} alt="Uploaded" className=" width" />
                                                </div>
                                            )}
                                            <input
                                                type="file"
                                                accept="image/*"
                                                className="input-field driving-license-input-field"
                                                hidden
                                                onChange={handleFileInputChange}
                                                id="imageInput"
                                            />
                                        </div>
                                    </div>
                                    {errors.selectedImage && <p className='error'>{errors.selectedImage}</p>}

                                </div>



                            </div>
                            <div>
                                <label className='profile-picture'>Banner Image</label>
                                <div onClick={handlebannerRemove}>
                                    <img src={Error} alt="" className='banner-close' />
                                </div>
                                <div className='background-img mt-2 ' onClick={handleBannerChange}>
                                    {/* <input type="file" name="" id="" /> */}
                                    {bannerImage ? (
                                        <>
                                            <img src={bannerImage} alt='banner-image' className='banner-image' />

                                        </>
                                    ) : (
                                        <>
                                            <div className='text-center'>
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    className="input-field driving-license-input-field"
                                                    hidden
                                                    onChange={handleBannerFileInputChange}
                                                    id="imageInput1"
                                                />
                                                <img src={Img} alt="" className='img-width ' />
                                                <p className='m-0'>Drag your images here</p>
                                                <p>(Only *.jpeg, *.webp and *.png images will be accepted)</p>
                                            </div>
                                            {errors.bannerImage && <p className='error'>{errors.bannerImage}</p>}
                                        </>
                                    )}

                                </div>
                            </div>

                        </div>
                        {/* #======================= Name Start============================== */}
                        <div className='d-flex flex-column'>
                            <div className='profile-flex mt-4'>
                                <div>
                                <label className='name-label'>Name</label>
                                </div>
                                <div className='d-flex flex-column'>
                                    <input type="text" name="" id="" className='input-width' value={name}
                                        onChange={(e) => setName(e.target.value)} />
                                    {errors.name && <p className='error'>{errors.name}</p>}
                                </div>
                            </div>
                        </div>


                        {/* #======================= Name End============================== */}
                        {/* #======================= Name Start============================== */}
                        <div className='profile-flex mt-4 '>
                            <label className='name-label'>Business Name</label>
                            <div className='d-flex flex-column '>
                                <input type="text" name="" id="" className='input-width' value={Brandname}
                                    onChange={(e) => setBrandName(e.target.value)} />
                                {errors.Brandname && <p className='error'>{errors.Brandname}</p>}
                            </div>

                        </div>

                        {/* #======================= Name End============================== */}

                        {/* #======================= Email Start============================== */}
                        <div className='profile-flex mt-4'>
                            <label className='name-label'>Email</label>
                            <div className='d-flex flex-column'>
                                <input type="email" name="" id="" className='input-width' value={email} onChange={(e) => setEmail(e.target.value)} />
                                {errors.email && <p className='error'>{errors.email}</p>}
                            </div>
                        </div>

                        {/* #======================= Email End============================== */}

                        {/* #======================= Contact Number Start============================== */}
                        <div className='profile-flex mt-4'>
                            <label className='name-label'>Phone</label>
                            <div className='d-flex flex-column'>
                                <input type="number" name="" id="" className='input-width' value={phone} onChange={(e) => setContactNumber(e.target.value)} />
                                {errors.phone && <p className='error'>{errors.phone}</p>}
                            </div>
                        </div>

                        {/* #======================= Contact Number End============================== */}

                        {/* #======================= Address Number Start============================== */}
                        <div className='profile-flex mt-4'>
                            <label className='name-label'>Address</label>
                            <div className='d-flex flex-column'>
                                <input type="text" name="" id="" className='input-width' value={address} onChange={(e) => setAddress(e.target.value)} />
                                {errors.address && <p className='error'>{errors.address}</p>}
                            </div>
                        </div>

                        {/* #======================= Address Number End============================== */}


                        <div className='text-end mt-4'>
                            <button type='submit' className='CreateVendor' onClick={handleSubmit}>Create Vendor</button>
                        </div>
                    </div>



                </div>
            </div>
        </Main>
    )
}

export default CreateVender;

