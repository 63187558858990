import React, { useState, useEffect } from "react";
import { Main } from "./style";
import { AdminLotusPRO } from 'assets/img'
import { useParams } from "react-router-dom";
import useHook from "./usehook";
const OrderInvoice = () => {
  const { id } = useParams();
  const { getOrderListID } = useHook();
  const [data, setdata] = useState([]);
  const getOrder = async () => {
    try {
      const response = await getOrderListID(id);
      console.log("order invoice", response.data);
      setdata(response.data);
    } catch (error) {
      console.error("error", error);
    }
  };
  useEffect(() => {
    getOrder();
  }, []);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };
  return (
    <Main>
      <div className="background-color">
        <div className="container-fluid">
          <div className="heading-flex">
            <h1 className="heading m-0">Invoice</h1>
          </div>
          <div>
            <div className="card mb-5 border-0">
              <div class="card-body">
                <div className="d-flex flex-row justify-content-between align-items-center">
                  <div className="">
                    <p className="invoice1 m-0">Invoice No. </p>
                    <p className="status m-0">{data.id}</p>
                  </div>
                  <div className="d-flex flex-column align-items-center" key={data.id}>
                    <p className="status1 m-0">Status</p>
                    <button type="submit" className="process-button ms-2 text-capitalize">
                      {data.status}
                    </button>
                  </div>
                  <div>
                    <p className="style-date m-0">Date</p>
                    <p className="status m-0">{formatDate(data.createdAt)}</p>
                  </div>
                </div>
                <hr className="" />
                <div className="vendor-container">
                  <div className="w-50 h-50 d-flex flex-column">
                    <p className="invoice2">Invoice From</p>

                    {data.vendor?.businessInfo?.businessLogo ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${data.vendor?.businessInfo?.businessLogo}`}
                          alt="logo"
                          className="vendorPic"
                        />
                      ) : (
                        <AdminLotusPRO className="vendorPic" />
                      )}
                     <h6 className="style-jenifer">{data.vendor?.businessInfo?.businessName}</h6>
                        <div className="style-address">
                          <p>
                          {data.vendor?.countryCode}-{data.vendor?.phone}   
                            <p className="m-0">
                            {data.vendor?.email}
                            </p>
                            {data.vendor?.businessInfo?.addressLine1},{data.vendor?.businessInfo?.city},{data.vendor?.businessInfo?.country},{data.vendor?.businessInfo?.state},{data.vendor?.businessInfo?.zip}
                          </p>
                        </div>

                  </div>
                  
                  <div className="text-end w-50 h-50">
                    <h6 className="style-jenifer">Invoice &nbsp; &nbsp;To</h6>
                    {data.user?.profile_pic ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${data.user?.profile_pic}`}
                          alt="logo"
                          className="vendorPic"
                        />
                      ) : (
                        <AdminLotusPRO className="vendorPic" />
                      )}

                    <div className="style-address">
                      <p>
                        {" "}
                        <p className="style-jenifer">{data.user?.name}</p>
                        <p>
                          <p className="m-0">
                            {data.user?.countryCode}-{data.user?.phone}
                          </p>
                          <p className="m-0">{data.user?.email}</p>{" "}
                          {data.address?.address},{data.address?.city},
                          {data.address?.state},{data.address?.country},
                          {data.address?.zipCode}
                        </p>
                      </p>
                    </div>

                  </div>
                </div>


                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-end">


                  </div>
                </div>
                <div className="card" style={{ border: "1px solid #D9DCE0" }}>
                  <div class="bg-white" style={{ borderRadius: "9px" }}>
                    <table className="w-100">
                      <thead
                        style={{ backgroundColor: "#F8FAFC", height: "60px" }}
                      >
                        <tr className="flex ms-2 table-head">
                          <th style={{ paddingInline: "10px" }}>Sr No.</th>
                          <th>Item Name</th>
                          <th>Color</th>
                          <th className="center">Quantity</th>
                          <th className="center">Item Price</th>
                          <th className="center">Amount</th>
                        </tr>
                      </thead>
                      <tbody className="waleed-margin">
                        {data.orderproducts?.map((items, index) => (
                          <tr
                            className="mt-3 flex2 style-table"
                            style={{ paddingTop: "10%" }}
                            key={index}
                          >
                            <td
                              style={{ paddingBlock: "15px",paddingLeft:"13px" }}
                             
                            >
                              {index + 1}
                            </td>
                            <td
                              style={{
                                paddingBlock: "15px",
                                width: "47%",
                              }}
                            >
                              {items.product?.name}
                            </td>
                            <td style={{ paddingBlock: "15px" }}>
                              <div
                                style={{
                                  backgroundColor:
                                    items?.orderProductColor?.colorCode,
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  display: "inline-block",
                                }}
                              ></div>
                            </td>
                            <td
                              style={{ paddingBlock: "15px" }}
                              className="center"
                            >
                              {items?.qnty}
                            </td>
                            <td
                              style={{ paddingBlock: "15px" }}
                              className="center"
                            >
                              $
                              {parseInt(
                                items.itemPrice || items.itemActualPrice || 0,
                                10
                              )}
                            </td>
                            {/* <td style={{ paddingBlock: '15px' }} className='center'> ${parseInt(items.itemPrice || items.itemActualPrice || 0, 10)}</td> */}
                            <td
                              style={{ paddingBlock: "15px" }}
                              className="center"
                            >
                              ${items?.totalPrice}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  className="card mt-4"
                  style={{
                    backgroundColor: "#F8FAFC",
                    border: "1px solid #D9DCE0",
                  }}
                >
                  <div class="card-body" style={{ padding: " 20px 32px" }}>
                    <table className="w-100">
                      <thead className="style-payment">
                        <tr className="flex ms-2">
                          {/* <th className="" style={{ width: "25%" }}>
                            DISCOUNT{" "}
                          </th> */}
                          <th className="text-end">Total Amount</th>
                        </tr>
                      </thead>
                      <tbody className="waleed-margin">
                        <tr className=" flex2 style-pay">
                          {/* <td className="">{data.totalDiscount}%</td> */}
                          <td className="text-end amount">
                            ${data.totalAmount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default OrderInvoice;
