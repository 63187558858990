import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import useHook from './usehook';
import DefaultSofa from 'assets/img/common/defaultSofa.jpeg'
import { Star } from 'assets/img/index';
import { Reviews, SingleUser, ProfileContainer } from './style';
import Spinner from 'components/loader';
import { AdminLotusPRO } from 'assets/img'
import EditVendorProfile from 'components/modals/edit-vendor-profile';
import BusinessDetailsModal from 'components/modals/business-detail-modal';
import { VscVerifiedFilled } from "react-icons/vsc";

const VendorProfile = () => {
  const { id } = useParams();

  const { getUerID, getUerReviews } = useHook();

  const [singelVendor, setSingleVendor] = useState('');
  const [vendorReviews, setVendorReviews] = useState([]);
  const [reviews, setReview] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loading, setLoading] = useState(false);

  // business states
  const [businessInfo, setbusinessInfo] = useState({})
  const [ProfileData, setProfileData] = useState('')
  const getSingleVendor = async () => {
    setLoading(true)
    try {
      const response = await getUerID(id);
      setSingleVendor(response.data)
      setbusinessInfo(response.data.businessInfo)
      setProfileData(response.data);
      // console.log("single vendor", response)
    } catch (error) {
      console.log("single vendor error", error)
    } finally {
      setLoading(false)
    }
  }
  const handleLoadMore = () => {
    setLoadingMore(true);
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const getSingleVendorReviews = async (currentPage) => {
    const formData = {
      userId: id,
      page: currentPage,
      perPage: 10
    }
    try {
      setLoading(false)
      const response = await getUerReviews(formData);
      const newReview = response.data.results
      setReview(response.data)
      setVendorReviews((prevReview) => [
        ...prevReview,
        ...newReview,
      ]);
      //   console.log("single vendor reviews", response)

      setLoadingMore(false);
    } catch (error) {
      console.log("single vendor reviews error", error)
    } finally {
      setLoading(false);
    }
  }

  // const backgroundImage = singelVendor ? `${process.env.REACT_APP_MEDIA_URL}${singelVendor?.background_image}` : DefaultSofa
  const backgroundImage = singelVendor.businessInfo?.businessBannerImage
    ? `${process.env.REACT_APP_MEDIA_URL}${singelVendor.businessInfo?.businessBannerImage}`
    : DefaultSofa;


  // time format
  const formatTimeFromISO = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Get hours, minutes, and seconds
    let hours = date.getUTCHours(); // Use getUTCHours() to work with UTC time
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12; // the hour '0' should be '12'

    // Format minutes and seconds to always show two digits
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = seconds.toString().padStart(2, '0');

    // Return the formatted time
    return `${hours}:${formattedMinutes}:${formattedSeconds} ${period}`;
  }

  // date format
  // const formatDate = (inputDate) => {
  //   // Create a date object from the input string
  //   const date = new Date(inputDate);

  //   // Array to convert month index to month name
  //   const months = [
  //     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  //   ];

  //   // Get day, month, and year from the date object
  //   const day = date.getDate();
  //   const month = months[date.getMonth()];
  //   const year = date.getFullYear();

  //   // Return formatted date
  //   return `${day}-${month}-${year}`;
  // }
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
};
  useEffect(() => {
    getSingleVendor();
  }, [id]);

  useEffect(() => {
    // if (loadingMore || loading) return;  
    getSingleVendorReviews(currentPage);
  }, [currentPage]);


  // Edit vendor profile
  const [vendorProfileOpen, setvendorProfileOpen] = useState(false);

  const handleVendorProfileOpen = () => setvendorProfileOpen(true);
  const handleVendorProfileClose = () => setvendorProfileOpen(false);

  // Edit vendor profile
  const [businessModalOpen, setbusinessModalOpen] = useState(false);

  const handlebusinessModalOpen = () => setbusinessModalOpen(true);
  const handlebusinessModalClose = () => setbusinessModalOpen(false);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <SingleUser>

            <div className="card mt-3 rounded-5 border-0 card-profile">
              <div className="back-img text-white" style={{
                width: '100%',
                height: '200px',
                borderRadius: '10px 25px 0px 0px',
                backgroundImage: `
          linear-gradient(
            180deg, 
            rgba(2, 0, 36, 0.4) 180%, 
            rgba(2, 0, 36, 1) 250%
          ),
          url(${backgroundImage})
        `,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                marginBottom: '145px',
                padding: '12px 48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                position: "relative",
              }}>

              </div>
              <div className='btn-container'>
                <button className='view-business-detail' onClick={handlebusinessModalOpen}>View Business Detail</button>
              </div>
              <div className="vendor-image-container">
                <div className="flex-main">
                  <div className="vendor-image">
                    {singelVendor?.businessInfo?.businessLogo ? (
                      <img
                        src={`${process.env.REACT_APP_MEDIA_URL}${singelVendor?.businessInfo?.businessLogo}`}
                        alt="logo"
                        className="women"
                      />
                    ) : (
                      <AdminLotusPRO className="women" />
                    )}
                  </div>

                  <div className="container" style={{ paddingTop: "60px" }}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <div>
                        <p className="text-capitalize mb-0 text-headding">{singelVendor.businessInfo?.businessName}</p>
                      </div>
                      <div>
                        <span className='text-ratting'>{singelVendor.numberOfRattings} ({singelVendor.totalRatting}) <Star /></span>
                      </div>
                    </div>

                    <div className="row w-100">
                      {/* Business Information 1 */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <p className="mb-1 text-color w-100 dot-property-col-1">License: <span className='text-field-color license-padding'>{singelVendor.businessInfo?.businessLicenseNo}</span></p>
                        <p className="mb-1 text-color w-100 dot-property-col-1">Type: <span className='text-field-color padding-type'>{singelVendor.businessInfo?.businessType}</span></p>
                        <p className="mb-1 text-color w-100 dot-property-col-1">SSIN: <span className='text-field-color padding-type'>{singelVendor.businessInfo?.businessSsin}</span></p>
                      </div>

                      {/* Business Information 2 */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <p className="mb-1 text-color dot-property-col-2 ">City: <span className='text-field-color padding-city'>{singelVendor.businessInfo?.city}</span></p>
                        <p className="mb-1 text-color w-100">Start Date: <span className='text-field-color padding-date'>{singelVendor.businessInfo?.createdAt ? formatDate(new Date(singelVendor.businessInfo.createdAt)) : ""}</span></p>
                        <p className="mb-1 text-color dot-property-col-2">State: <span className='text-field-color padding-state'>{singelVendor.businessInfo?.state}</span></p>
                      </div>

                      {/* Business Information 3 */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <p className="mb-1 text-color dot-property-col-3">Zip Code: <span className='text-field-color padding-zip'>{singelVendor.businessInfo?.zip}</span></p>
                        <p className="mb-1 text-color dot-property-col-3">DBA: <span className='text-field-color padding-dba'>{singelVendor.businessInfo?.dba}</span></p>

                        <div>
                          {singelVendor.isVerified && (
                            <div className="d-flex align-items-center">
                              <span className="me-2 text-color">Status</span>
                              <div className="d-flex align-items-center padding-status">
                                <VscVerifiedFilled style={{ color: "#156A3D", fontSize: "20px" }} />
                                <span className="ms-2" style={{ color: "#156A3D", fontSize: "15px", fontWeight: "bold" }}>Verified</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
              </div>


            </div>
          </SingleUser >
          <div className="card mt-3 border-0">
            <div className="card-body">
              <ProfileContainer>


                {/* Profile Information */}
                <div className='name-container'>
                  <h6 className='name-label'>Name</h6>
                  <span className="font-bold text-xl text-name">{singelVendor.name}</span>
                </div>
                <div className='email-container'>
                  <h6 className='email-label'>Email</h6>
                  <span className="email-vendor">{singelVendor.email}</span>
                </div>
                <div className='phone-container'>
                  <h6 className='phone-label'>Phone Number</h6>
                  <span className="text-gray-600 phone-number">{singelVendor.phone}</span>
                </div>
                <div>
                  <button className='view-profile' onClick={handleVendorProfileOpen}>View Profile</button>
                </div>

              </ProfileContainer>


            </div>
          </div>
          {/* {console.log(vendorReviews,'review')} */}
          {
            reviews.counts >= 1 ? (
              <Reviews>
                <div className="card mt-3 border-0">
                  <p className="m-0 reviews pb-2">My Reviews</p>
                  {vendorReviews.map((review, index) => (
                    <div key={index}>
                      <div className="card-body pb-0 pt-0">
                        <div className="mt-3">
                          <div className="d-flex">
                            {review.user?.profile_pic ? (
                              <img
                                src={`${process.env.REACT_APP_MEDIA_URL}${review.user?.profile_pic}`}
                                alt="logo"
                                className="img-height"
                              />
                            ) : (
                              <AdminLotusPRO className="img-height" />
                            )}
                            <div className="ms-4">
                              <div>
                                <span className="name">
                                  {review.user?.name}{" "}
                                  <span className="rating ms-3">
                                    {review.ratting}
                                  </span>{" "}
                                  <span>
                                    <Star />
                                  </span>
                                </span>
                              </div>
                              <div>
                                <span className="date">
                                  {formatDate(review.createdAt)}{" "}
                                  <span className="">{formatTimeFromISO(review.createdAt)}</span>{" "}
                                </span>
                              </div>
                              <div>
                                <p className="comment">{review.comment}</p>
                              </div>
                              <div>
                                {review.images?.map((item, index) => {
                                  const imageUrl = `${process.env.REACT_APP_MEDIA_URL}${item.image}`;
                                  console.log(`Image URL: ${imageUrl}`);
                                  return (
                                    <span key={index}>
                                      <img src={imageUrl} alt={`Review image ${index}`} className='review-img' />
                                    </span>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  {loadingMore && <Spinner />}
                  {vendorReviews.length < reviews.counts && !loadingMore && (
                    <div className="load-more" onClick={handleLoadMore}>
                      <p className="load">Load more</p>
                    </div>
                  )}


                </div>
              </Reviews>
            ) : (
              <p className="no-record">No Reviews Found</p>
            )
          }
          <EditVendorProfile open={vendorProfileOpen} onClose={handleVendorProfileClose} singelVendor={singelVendor} />

          <BusinessDetailsModal open={businessModalOpen} onClose={handlebusinessModalClose} businessInfo={businessInfo} ProfileData={ProfileData} />
        </>
      )
      }
    </>
  )
}

export default VendorProfile;
