import React, { useState, useEffect,useRef } from "react";
import AppRoutes from "containers";
import useHook from "./containers/dashboard/notifications/usehook";
function App() {
  // const { getNotifications } = useHook();
  // const [notifications, setNotifications] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const Notifications = async () => {
  //   const id = localStorage.getItem("id");
  //   if (!id) {
  //     console.log("ID not found in localStorage. Skipping API call.");
  //     return; // Skip the API call if ID is not present
  //   }
  //   const params = {
  //     page: "1",
  //     perPage: "0",
  //   };
  //   try {
  //     const response = await getNotifications(params);
  //     const notifications = response.data.results;
  //     // Filter notifications where isRead is false
  //     const unreadNotifications = notifications.filter(
  //       (notification) => !notification.isRead
  //     );
  //     // Get the count of unread notifications
  //     const unreadCount = unreadNotifications.length;
  //     // Set notifications state
  //     setNotifications(notifications);
  //     // Log the count of unread notifications
  //     console.log("Unread notifications count:", unreadCount);
  //     // Store the count in localStorage if greater than 0
  //     if (unreadCount > 0) {
  //       localStorage.setItem("unreadCount", unreadCount);
  //     } else {
  //       localStorage.removeItem("unreadCount");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } 
  //   // finally {
  //   //   setLoading(false);
  //   // }
  // };
  // useEffect(() => {
  //   // Delay the API call by 5 seconds
  //   const timer = setTimeout(() => {
  //     Notifications();
  //   },100);
  //   // Fetch notifications every 30 seconds
  //   const intervalId = setInterval(Notifications, 30000); // Fetch every 30 seconds
  //   // Cleanup the timer and interval if the component unmounts
  //   return () => {
  //     clearTimeout(timer);
  //     clearInterval(intervalId);
  //   };
  // }, []);

  const { getNotifications } = useHook();
  const [notifications, setNotifications] = useState([]);
  const notificationsRef = useRef([]); // Using ref to store notifications
  // const [loading, setLoading] = useState(true);

  const Notifications = async () => {
    const id = localStorage.getItem("id");
    if (!id) {
      console.log("ID not found in localStorage. Skipping API call.");
      return; // Skip the API call if ID is not present
    }

    const params = {
      page: "1",
      perPage: "0",
    };

    try {
      const response = await getNotifications(params);
      const newNotifications = response.data.results;

      // Only update the state if there are new or changed notifications
      if (JSON.stringify(newNotifications) !== JSON.stringify(notificationsRef.current)) {
        notificationsRef.current = newNotifications; // Update ref
        setNotifications(newNotifications); // Update state
      }

      // Filter notifications where isRead is false
      const unreadNotifications = newNotifications.filter(
        (notification) => !notification.isRead
      );

      // Get the count of unread notifications
      const unreadCount = unreadNotifications.length;

      // Log the count of unread notifications
      console.log("Unread notifications count:", unreadCount);

      // Store the count in localStorage if greater than 0
      if (unreadCount > 0) {
        localStorage.setItem("unreadCount", unreadCount);
      } else {
        localStorage.removeItem("unreadCount");
      }
    } catch (error) {
      console.log(error);
    } 
    // finally {
    //   setLoading(false);
    // }
  };

  useEffect(() => {
    // Delay the API call by 5 seconds
    const timer = setTimeout(() => {
      Notifications();
    }, 100);

    // Fetch notifications every 30 seconds
    const intervalId = setInterval(Notifications, 30000); // Fetch every 30 seconds

    // Cleanup the timer and interval if the component unmounts
    return () => {
      clearTimeout(timer);
      clearInterval(intervalId);
    };
  }, []);
  return (
    <div>
      <AppRoutes />
    </div>
  );
}

export default App;
