import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../methods";

export const AuthAPI = {
  // Auth api
  login: async (body) => await postRequest("auth/admin/login", body),
  forgetPassword: async (body) => postRequest("auth/forget-password", body),
  verifyOtp: async (body) => postRequest("auth/verify-reset-otp", body),
  changePassword: async (body) =>
    postRequest("auth/change-forget-password", body),

  // vender creation
  createVendor: async (body) => await postRequest("users", body),
  vendorUpdate:async(id,body)=>await postRequest(`users/${id}/edit-info`,body),
  Postimages: async (body, params) =>
    await postRequest("images", body, (params = params)),

  // settings   =>   Add color
  colorPost: async (body) => await postRequest("colors", body),
  colorGet: async (body) => await getRequest("colors", body),

  // material post
  materialPost: async (body) => await postRequest("materials", body),
  materialGet: async (body) => await getRequest("materials", body),

  // categories
  categoriesPost: async (body) => await postRequest("categories", body),
  categoriesGet: async (params) => await getRequest("categories", params),
  categoriesDelete: async (id) => await deleteRequest(`categories/${id}`),
  SubcategoriesPut: async (id, params) =>
    await putRequest(`categories/${id}`, (params = params)),
  // categories switch put
  categoriesPut: async (id, body) =>
    await putRequest(`categories/${id}/change-category-status`, body),
  getcategory: async (params) => getRequest("categories", params),
  materialDelete: async (id) => await deleteRequest(`materials/${id}`),

  // UserManagement
  userGet: async (params) => await getRequest(`users`, params),
  userDelete: async (id) => await deleteRequest(`users/${id}`),
  userPutSwitch: async (id, body) => await putRequest(`users/${id}`, body),
  sigleUserget: async (id) => await getRequest(`users/${id}`),
  //vender Managment
  // GetVendors: async (params) => {
  //   const { role, page, perPage, search,isVerified,status } = params;
  //   const url = `${process.env.REACT_APP_BASE_URL_PREFIX}/users?search=${search}&role=${role}&page=${page}&perPage=${perPage}&isVerified=${isVerified}&status=${status}`;
  //   return await getRequest(url);
  // },
  GetVendors: async (params) => {
    const { role, page, perPage, search, isVerified, status } = params;
  
    const baseURL = `${process.env.REACT_APP_BASE_URL_PREFIX}/users`;
  
    const queryParams = new URLSearchParams();
  
    if (role) queryParams.append('role', role);
    if (page) queryParams.append('page', page);
    if (perPage) queryParams.append('perPage', perPage);
    if (search) queryParams.append('search', search);
    if (isVerified !== undefined) queryParams.append('isVerified', isVerified);
    if (status) queryParams.append('status', status);
  
    const url = `${baseURL}?${queryParams.toString()}`;
  
    return await getRequest(url);
  },
  

  VendorsPut: async (id, body) => await putRequest(`users/${id}`, body),
  venderDelete: async (id) => deleteRequest(`users/${id}`),

  // customer
  customerOrderDetail: async (id) =>
    await getRequest(`users/${id}/customer-order-details`),

  // Banners
  bannerPost: async (body) => await postRequest("banners", body),
  bannerGet: async (body) => await getRequest("banners", body),
  bannerPut: async (id, params) => await putRequest(`banners/${id}`, params),
  bannerDelete: async (id) => await deleteRequest(`banners/${id}`),

  // Order Cancellation Charges
  orderCancelCharge: async (body) => postRequest(`settings`, body),
  getOrderCancellationList: async (body) => getRequest(`settings/get`, body),

  // user changed Password
  userPasswordChanged: async (body) =>
    postRequest(`users/change-password`, body),

  // get All Orders
  getOrders: async (body) => getRequest(`orders`, body),
  getOrdersID: async (id) => getRequest(`orders/${id}`),

  // vendor detail
  getUserbyID: async (id) => getRequest(`users/${id}`),
  getUserReviews: async (body) => getRequest("/user-reviews", body),

  // productlisting
  productlist: async (body) => await getRequest(`products`, body),
  getproductsReviews: async (params) => getRequest("reviews", params),
  getproductsdetails: async (id, body) => getRequest(`products/${id}`, body),

  // collection List
  getcollection: async (body) => await getRequest(`collections`, body),
  GetSinglecollection: async (id) => await getRequest(`collections/${id}`),

  // catalogs
  getCatalogs: async (body) => await getRequest(`catalogs`, body),
  getCatalogSingle: async (id) => await getRequest(`catalogs/${id}`),

  // catalog images
  getCatalogImages: async (body) => await getRequest(`catalog-images`, body),

  // vendor dispute
  getDispute: async (body) => await getRequest(`disputes`, body),
  orderDetail: async (id) =>
    await getRequest(`users/${id}/customer-order-details`),
  orderlist: async (body) => await getRequest(`orders`, body),

  // notification
  getnotification: async (body) =>
    getRequest("users/get-my-notifications", body),
  readNotification: async (body) =>
    getRequest("/users/read-notifications", body),

  // disputes
  getDisputes: async (body) => getRequest("/disputes", body),
  postDisputes: async (id, body) =>
    postRequest(`/disputes/${id}/change-status`, body),

  // withdraw
  getWithdraw: async (body) =>
    getRequest(`/withdrawal-requests/get-all-withdrawals-requests/`, body),
  postWithraw: async (id, params) =>
    await postRequest(`/withdrawal-requests/${id}/approve`, params),

  // DashboardCards
  dashboardtopCards: async (body) =>
    await getRequest(`/dashboard/admin/dashboard-top-titles`, body),

  // Admin Profile profie
  getAdminProfile: async (body) => await getRequest("/users/get-profile", body),
  postProfileEdit: async (body) =>
    await putRequest("/users/update-profile", body),
  profilePostImage: async (body) => await postRequest("/images", body),

  // Admin Profile profie
  getAdminProfile: async (body) => await getRequest("/users/get-profile", body),
  postProfileEdit: async (body) =>
    await putRequest("/users/update-profile", body),
  profilePostImage: async (body) => await postRequest("/images", body),

  vendorOrderNumberGet: async (id) =>
    await getRequest(`/users/${id}/vendor-order-details`),

  SaveToken: async (deviceId, token) =>
    postRequest("users/save-fcm-tokens", { deviceId, token }),

  dashboardGraphDataGet: async (body) =>
    getRequest(`/dashboard/admin/get-stats${body}`),
  ordersGraphGet: async (body) =>
    getRequest(`/dashboard/admin/get-orders-stats${body}`),

  vendorProfileGet:async(id)=>await getRequest(`users/${id}/vendor-profile`),


  // business info create vendor
  businessInfoUpdate:async(id,params)=>await postRequest(`users/${id}/edit-business-info`,params),
  // additional info create vendor
  additionalInfoUpdate:async(id,params)=>await postRequest(`users/${id}/edit-additional-info`,params),


  // account verification
  verifyAccount:async(id,params)=>await postRequest(`users/${id}/verify`,params),

// disputes
  getdisputebyID:async(id)=>await getRequest(`disputes/${id}`),
  postComment:async(id,body)=>await postRequest(`disputes/${id}/create-comment`,body),

  // about us---/ terms and condition
  postAboutus:async(body)=>await postRequest(`/about-us`,body),
  getAboutus:async(params)=>await getRequest(`/about-us/get`,params),
  patchAboutus:async(id,params)=>await patchRequest(`/about-us/${id}`,params),
  deleteAboutus:async(id)=>await deleteRequest(`/about-us/${id}`),

};
