import React from "react";
import { Main } from "./style";
import { useLocation } from "react-router-dom";
import { AdminLotusPRO } from 'assets/img'
const UserInvoice = () => {
  const location = useLocation();
  const { items } = location.state;
  console.log("location items Order get", items);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  return (
    <Main>
      <div className="background-color">
        <div className="container-fluid">
          <div className="heading-flex">
            <h1 className="heading m-0">Invoice</h1>
          </div>
          <div>
            <div className="card mb-5 border-0">
              <div class="card-body">
                {/* date*/}
                <div className="d-flex justify-content-between">
                  <div className="">
                    <p className="invoice2 m-0">Invoice No</p>
                    <p className="status m-0">{items.id}</p>
                  </div>
                  <div className="d-flex flex-column align-items-center">
                    <p className="status1 m-0">Status</p>
                    <button type="submit" className="process-button ms-2">
                      {items.status}
                    </button>
                  </div>
                  <div>
                    <p className="style-date m-0">Date</p>
                    <p className="status m-0">{formatDate(items.createdAt)}</p>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                  <div className="d-flex flex-column">
                    <div>
                      <div>
                        <p className="invoice2">Invoice From</p>
                      </div>
                    </div>
                    {/* invoice from */}
                    <div className="text-left">
                      {items.vendor?.businessInfo?.businessLogo ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${items.vendor?.businessInfo?.businessLogo}`}
                          alt="logo"
                          className="vendorPic"
                        />
                      ) : (
                        <AdminLotusPRO className="vendorPic" />
                      )}


                      {/* {items.profile_pic ? (
                                  <img
                                    src={
                                      items.profile_pic.startsWith("https://lh3.googleusercontent.com/")
                                        ? items.profile_pic
                                        : `${process.env.REACT_APP_MEDIA_URL}/${items.profile_pic}`
                                    }
                                    alt="sofa img"
                                    className="img-width"
                                  />
                                ) : (
                                  <AdminLotusPRO className="vendorPic" />
                                )} */}


                      <h6 className="style-jenifer">{items.vendor?.businessInfo?.businessName}</h6>
                      <div className="style-address">
                        <p>
                          {items.vendor?.countryCode}-{items.vendor?.phone}
                          <p className="m-0">
                            {items.vendor?.email}
                          </p>
                          {items.vendor?.businessInfo?.addressLine1},{items.vendor?.businessInfo?.city},{items.vendor?.businessInfo?.state},{items.vendor?.businessInfo?.country},{items.vendor?.businessInfo?.zip}
                        </p>

                      </div>
                    </div>
                  </div>
                  {/* invoice to */}
                  <div className="text-end">
                    <div className="text-right mt-0">
                      <h6 className="style-jenifer">Invoice &nbsp; &nbsp;To</h6>
                      {items.user?.profile_pic ? (
                        <img
                          src={`${process.env.REACT_APP_MEDIA_URL}${items.user?.profile_pic}`}
                          alt="logo"
                          className="vendorPic"
                        />
                      ) : (
                        <AdminLotusPRO className="vendorPic" />
                      )}


                      <div className="style-address">
                        <p>
                          <p className="mb-3 style-jenifer">{items.user?.name}</p>
                          <p className="m-0">
                            {items.user?.countryCode}-{items.user?.phone}
                          </p>
                          {items.user?.email}{" "}
                          <p>
                            {" "}
                            {items.user?.addresses?.[0] && (
                              <div>
                                {items.user.addresses[0].addressName}, {items.user.addresses[0].city},
                                {items.user.addresses[0].state}, {items.user.addresses[0].country},
                                {items.user.addresses[0].zipCode}
                              </div>
                            )}

                          </p>
                        </p>
                      </div>
                    </div>
                  </div>

                </div>

                <div className="card" style={{ border: "1px solid #D9DCE0" }}>
                  <div class="bg-white" style={{ borderRadius: "9px" }}>
                    <table className="w-100">
                      <thead
                        style={{ backgroundColor: "#F8FAFC", height: "60px" }}
                      >
                        <tr className="flex ms-2 table-head">
                          <th className="left ps-3">Sr No.</th>
                          <th className="left">Item Name</th>
                          <th className="right">Color</th>
                          <th className="right">Quantity</th>
                          <th className="right">Item Price</th>
                          <th className="right">Amount</th>
                        </tr>
                      </thead>
                      <tbody className="waleed-margin">
                        {items.orderproducts?.map((item, index) => (
                          <tr
                            className="mt-3 flex2 style-table"
                            style={{ paddingTop: "10%" }}
                            key={index}
                          >
                            <td
                              style={{ paddingBlock: "10px", width: "100px" }}
                              className="left ps-3"
                            >
                              {index + 1}
                            </td>
                            <td style={{ paddingBlock: "15px", width: "620px" }}>
                              <span className="w-50">
                                {item.product?.name}
                              </span>
                            </td>
                            <td style={{ paddingBlock: "15px", width: "100px" }}>
                              <div
                                style={{
                                  backgroundColor:
                                    item?.orderProductColor?.colorCode,
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  display: "inline-block",
                                  // marginLeft: "12px"
                                }}
                              ></div>
                            </td>
                            <td
                              style={{ paddingBlock: "15px", width: "100px" }}
                              className="right"
                            >
                              {item.qnty}
                            </td>
                            <td
                              style={{ paddingBlock: "15px", width: "100px" }}
                              className="right"
                            >
                              $
                              {parseInt(
                                item.itemPrice || item.itemActualPrice || 0,
                                10
                              )}
                            </td>
                            {/* <td style={{ paddingTop: '3%', width: "10%" }} ><p className='return'>Returned</p></td> */}
                            <td
                              style={{ paddingBlock: "15px", width: "100px" }}
                              className="right"
                            >
                              ${item?.totalPrice}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>

                <div
                  className="card mt-4"
                  style={{
                    backgroundColor: "#F8FAFC",
                    border: "1px solid #D9DCE0",
                  }}
                >
                  <div class="card-body" style={{ padding: " 20px 32px" }}>
                    <table className="w-100">
                      <thead className="style-payment">
                        <tr className="flex ms-2">
                          {/* <th className='' style={{ width: "25%" }}>PAYMENT METHOD	</th> */}
                          {/* <th style={{ width: "25%" }}>SHIPPING COST	</th> */}
                          {/* <th className="" style={{ width: "25%" }}>
                            DISCOUNT{" "}
                          </th> */}
                          <th className="text-end">Total Amount</th>
                        </tr>
                      </thead>
                      <tbody className="waleed-margin">
                        <tr className=" flex2 style-pay">
                          {/* <td className=''>{items.PAYMENTMETHOD}</td>
                                                        <td >{items.SHIPPINGCOST}</td> */}
                          {/* <td className="">{items.totalDiscount}%</td> */}
                          <td className="text-end amount">
                            ${items.totalAmount}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default UserInvoice;
