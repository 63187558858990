import styled from "styled-components";

export const Main = styled.div`
width: 100%;
height: fit-content;
background-color: var(--all-background);
.container-fluid{
    height: 92vh;
    margin-bottom: 12px;
}
.delete-icon{
    background-color: #FF0000;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.banner-flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 8px;
}
.banner{
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    padding-block: 20px;
}
.banner-button{
    width: 177px;
    height: 51px;
    border-radius: 5px;
    background-color: #FFBB02;
    font-family: poppins;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    border: 0px;
}

.banner-img{
    width: 250px;
    height: 119px;
}
.banner-name{
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 14px;
    color: #808D9E;
}
.name{
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}
.delete-width{
    width: 38px;
    height: 40px;
    border-radius: 5px;

}
.enable{
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 2%;
    color: #252525;
}

@media (max-width:800px) {

    .action-container{
        margin-top: 15px;
    }
}
`