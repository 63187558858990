import React, { useState } from "react";
import {
  Main
} from "./style";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useForm } from "react-hook-form";
import MaterialComponent from "components/modals/materials";
import { MaterialManagement,ColorManagement, TermsAndCondition, AboutUs } from "assets/img";
import { useNavigate } from "react-router-dom";
import useHook from "./useHook";
import AdminProfile from "../admin-profile";
import Aboutus from '../../../components/modals/about-us'
import TermsCondition from "components/modals/terms-conditions";
import toastr from "toastr";
const Setting = () => {
  const { postPassword } = useHook();

  const [isMaterialModalOpen, setMaterialModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showpassword, setshowPassword] = useState(false);
  const [showpassword2, setshowPassword2] = useState(false);
  const navigate = useNavigate();
  const handleCardClick = () => {
    // if (itemId === 2) {
    setMaterialModalOpen(true);
    // }
  };

  const handleCloseMaterialModal = () => {
    setMaterialModalOpen(false);
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
    reset
  } = useForm();
  // const onSubmit = (data) => {
  //   const { password, confirmPassword } = data;
  //   const values = getValues();
  //   const currentPassword = values.currentPassword;
  //   const newPassword = values.password;
  //   const formData = {
  //     "oldPassword": currentPassword,
  //     "password": newPassword,
  //   }
  //   if (password === confirmPassword && password != '') {
  //     try {
  //       const response = postPassword(formData);
  //       console.log("password changed", response);
  //       reset();
  //     } catch (error) {
  //       console.error("Error occured");
  //     }
  //   }
  // };

  const onSubmit = async (data) => {
    const { password, confirmPassword } = data;
    const values = getValues();
    const currentPassword = values.currentPassword;
    const newPassword = values.password;
    const formData = {
      "oldPassword": currentPassword,
      "password": newPassword,
    };
  
    if (password === confirmPassword && password !== '') {
      try {
        const response = await postPassword(formData); // Await the API call
        console.log("Password changed", response); // Log the actual response once resolved
        reset(); // Reset the form after successful password change
        if (response?.status === true) {
          toastr.success(response?.message)
        }else{
          toastr.error(response?.error)
        }
      } catch (error) {
        console.error("Error occurred", error);
      }
    }
  };
  
  const watchPassword = watch('password', '');

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleOrderTransactions = () => {
    navigate('/settings/order-transaction-management')
  }

  const [aboutModal,setAboutModal]=useState(false);

  const handleAboutModalOpen = () => setAboutModal(true);
  const handleAboutModalClose = () => setAboutModal(false);

  const [termsCondition,setTermsCondition]=useState(false);

  const handleTermConditionOpen = () => setTermsCondition(true);
  const handleTermConditionClose = () => setTermsCondition(false);


  return (
    <Main>
      <div className="container-fluid">
        <div className="heading-flex">
          <h1 className="heading m-0">Settings</h1>
        </div>
        {/* profile Picture */}
      
        <div className="mb-3 admin-container">
        <AdminProfile />
        </div>
        {/* top cards container */}
        <div className="cards-management">
          <div className="card-flex" style={{ cursor: "pointer" }}>
            <div
              className="card card-width"
              onClick={handleOrderTransactions}
            >
              <div className="card-body">
                <div style={{ lineHeight: "40px" }}>
                  <span className="material-background">
                   <ColorManagement/>
                  </span>
                  <div>
                    <p className="total-color">Order Transactions Management</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-flex">
            <div
              className="card card-width" style={{ cursor: "pointer" }}
              onClick={() => handleCardClick()}
            >
              <div className="card-body">
                <div style={{ lineHeight: "40px" }}>
                  <span className="material-background">
                    <MaterialManagement/>

                  </span>
                  <div>
                    <p className="total-color">Materials</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Terms and Condition */}
          <div className="card-flex">
            <div
              className="card card-width" style={{ cursor: "pointer" }}
              onClick={() => handleTermConditionOpen()}
            >
              <div className="card-body">
                <div style={{ lineHeight: "40px" }}>
                  <span className="material-background">
                    <TermsAndCondition/>

                  </span>
                  <div>
                    <p className="total-color">Terms and Condition</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About us */}
          <div className="card-flex">
            <div
              className="card card-width" style={{ cursor: "pointer" }}
              onClick={() => handleAboutModalOpen()}
            >
              <div className="card-body">
                <div style={{ lineHeight: "40px" }}>
                  <span className="material-background">
                    <AboutUs/>

                  </span>
                  <div>
                    <p className="total-color">About Us</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3 border-0">
          <div className="padding">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="password-flex">
                <div className="password-container">
                  <label className="label">Current Password</label>
                  <div style={{ position: "relative",width:"100%" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      className="input-password"   {...register("currentPassword", {
                        required: "Current password is required",
                      })}
                    />
                    {showPassword ? (
                      <FaRegEyeSlash
                        size={20}
                        onClick={togglePasswordVisibility}
                        className="icon"
                      />
                    ) : (
                      <FaRegEye
                        size={20}
                        onClick={togglePasswordVisibility}
                        className="icon"
                      />
                    )}
                    <p className="error-password">
                      {errors.currentPassword && errors.currentPassword.message}
                    </p>
                  </div>

                  <div>
                    <p className="m-0 create-new">Create new password</p>

                    <label className="label mt-3">New Password</label>
                    <div style={{ position: "relative" }}>
                      <div className="passwordDiv">
                        <div className="d-flex justify-content-between ">
                          <input
                            type={showpassword ? "text" : "password"}
                            className="input-password "
                            id="password"
                            {...register("password", {
                              required: true,
                              minLength: 5,
                              maxLength: 20,
                            })}
                          />
                          <span onClick={() => setshowPassword(!showpassword)}>
                            {showpassword ? (
                              <FaRegEyeSlash
                                size={20}
                                className="icon"
                              />
                            ) : (
                              <FaRegEye size={20} className="icon" />
                            )}
                          </span>
                        </div>
                        <p className="error-password">
                          {errors.password?.type === "required" &&
                            "password is required"}
                          {errors.password?.type === "minLength" &&
                            "entered password is less than 5 characters"}
                          {errors.password?.type === "maxLength" &&
                            "entered password is more than 20 characters"}
                        </p>
                      </div>
                    </div>

                    <label className="label mt-3">Confirm Password</label>
                    <div style={{ position: "relative" }}>
                      <div className="passwordDiv">
                        <div className="d-flex justify-content-between">
                          <input
                            type={showpassword2 ? "text" : "password"}
                            className="input-password"
                            id="confirmPassword"
                            {...register("confirmPassword", {
                              required: true,
                              minLength: 5,
                              maxLength: 20,
                              validate: (value) =>
                                value === watchPassword ||
                                "Passwords do not match",
                            })}
                          />
                          <span
                            onClick={() =>
                              setshowPassword2(!showpassword2)
                            }
                          >
                            {showpassword2 ? (
                              <FaRegEyeSlash
                                size={20}
                                className="icon"
                              />
                            ) : (
                              <FaRegEye size={20} className="icon" />
                            )}
                          </span>
                        </div>
                        <p className="error-password">
                          {errors.confirmPassword &&
                            errors.confirmPassword.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button className="update-btn">Update</button>
              </div>
            </form>
          </div>
        </div>
      
      </div>

      <MaterialComponent
        isOpen={isMaterialModalOpen}
        onClose={handleCloseMaterialModal}
      />
      
      <Aboutus aboutModal={aboutModal} handleAboutModalClose={handleAboutModalClose}/>
      <TermsCondition termsCondition={termsCondition} handleTermConditionClose={handleTermConditionClose}/>
    </Main>
  );
};

export default Setting;


// import React, { useEffect, useState } from "react";
// import {
//   AddButton,
//   Card,
//   Input,
//   InputDate,
//   InputMaterial,
//   Main,
//   Update,
// } from "./style";
// import ColorImg from "../../../assets/img/settings/Colour.png";
// import Fabric from "../../../assets/img/settings/Fabric.png";
// import Delete from "../../../assets/img/settings/delete.png";
// import IOSSwitch from "components/ios button";
// import ColorSwitches from "components/Ios-orange";
// import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import Modal from "@mui/material/Modal";
// import Pagination from "components/pagination";
// import { FaRegEye } from "react-icons/fa";
// import { FaRegEyeSlash } from "react-icons/fa";
// import { useForm } from "react-hook-form";
// import { Link } from "react-router-dom";

// import useHook from './useHook';
// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: "858px",
//   height: "450px",
//   borderRadius: "28px",
//   bgcolor: "background.paper",
//   border: "none",
//   boxShadow: 24,
//   padding: "15px 30px",
//   outline: "none",
// };

// const Setting = () => {
//   const { MaterialPost, materialGet, materialDelete } = useHook()

//   const [selectedItemId, setSelectedItemId] = useState(null);
//   const [isColorModalOpen, setColorModalOpen] = useState(false);
//   const [isMaterialModalOpen, setMaterialModalOpen] = useState(false);

//   const handleCardClick = (itemId) => {
//     setSelectedItemId(itemId);
//     if (itemId === 1) {
//       setColorModalOpen(true);
//     } else if (itemId === 2) {
//       setMaterialModalOpen(true);
//     }
//   };

//   const handleCloseColorModal = () => {
//     setColorModalOpen(false);
//     setSelectedItemId(null);
//   };

//   const handleCloseMaterialModal = () => {
//     setMaterialModalOpen(false);
//     setSelectedItemId(null);
//   };



//   // Function to handle changes in the color name input
//   const handleColorNameChange = (event) => {
//     const newColorName = event.target.value;
//     setColorName(newColorName);
//   };

//   // Function to get color code based on color name
//   const getColorCodeFromName = (colorName) => {
//     // Your logic to get the color code based on the color name goes here
//     // For simplicity, I'm providing a hardcoded color code mapping
//     const colorCodeMap = {
//       red: "#FF0000",
//       green: "#00FF00",
//       blue: "#0000FF",
//       // Add more color mappings as needed
//     };
//     return colorCodeMap[colorName.toLowerCase()] || ""; // Convert color name to lowercase for case-insensitivity
//   };

//   // Get the color code based on the current color name
//   // const colorCode = getColorCodeFromName(colorName);

//   const [products, setProducts] = useState([]);
//   const [productName, setProductName] = useState("");

//   const handleAddProduct = (e) => {
//     e.preventDefault();
//     const productData = {
//       "name": productName,
//       "status": true
//     }
//     MaterialPost(productData)
//     setProductName('')
//     // if (productName.trim() !== "") {
//     //   setProducts([...products, productName]);
//     //   setProductName("");
//     // }
//   };

//   const handleDeleteProduct = async (productId) => {
//     try {
//       // Call materialDelete to delete the product from the server
//       await materialDelete(productId);
//       // If successful, update the state (assuming products is your state)
//       const updatedProducts = products.filter(product => product.id !== productId);
//       setProducts(updatedProducts);
//     } catch (error) {
//       // Handle error
//       console.error('Error deleting product:', error);
//       // You might want to show an error message to the user or retry the operation
//     }
//   };

//   //   const handleDeleteProduct = async (e) => {
//   //     const updatedProducts = [...products];
//   //     const deletedProduct = e.target.id;
//   //     // console.log()
//   //     console.log("delete id",deletedProduct)

//   //     try {
//   //         // Call materialDelete to delete the product from the server
//   //         await materialDelete({ id: deletedProduct.id });
//   //         // If successful, update the state
//   //         // Handle error
//   //         console.error('Error deleting product:');
//   //         // You might want to show an error message to the user or retry the operation
//   //     }
//   //     catch{
//   //       console.log("error")
//   //     }
//   // };
//   // ferch material
//   const [materiallist, setmateriallist] = useState([]);
//   const [materiallength, setmateriallength] = useState('')
//   const fetchMaterial = async () => {
//     const formParameter = {
//       status: true,
//       page: 1,
//       perPage: 100
//     }
//     const fetchproduct = await materialGet(formParameter);
//     setmateriallist(fetchproduct)
//     setmateriallength(fetchproduct.length)
//     console.log(fetchproduct.data)
//     console.log(materiallist)
//   }
//   useEffect(() => {
//     fetchMaterial()
//   }, [products])



//   // Color modal
//   const [color, setColor] = useState("");
//   const [colorCodee, setColorCodee] = useState("");
//   const [colorName, setColorName] = useState('');
//   const handleColorChange = (e) => {
//     setColor(e.target.value);
//     setColorCodee(e.target.value);
//   };

//   const handleColorCodeChange = (e) => {
//     setColorCodee(e.target.value);
//     setColor(e.target.value);
//   };
//   // retriving the colors and show it in table
//   // const [colorList, setColorList] = useState([]);
//   // const [colorLength, setcolorlength] = useState('')
//   // const fetchData = async () => {
//   //   const formParameter = {
//   //     status: true,
//   //     page: 1,
//   //     perPage: 12
//   //   }
//   //   const colors = await colorGet(formParameter);
//   //   setColorList(colors);
//   //   console.log("setting component", colors)
//   //   setcolorlength(colors.length)
//   // };

//   // useEffect(() => {

//   //   fetchData();

//   // }, [])

//   // color submit
//   // const handleAddColor = (e) => {
//   //   e.preventDefault();

//   //   let formData =
//   //   {
//   //     "name": colorName,
//   //     "colorCode": color,
//   //   }
//   //   console.log(typeof formData)
//   //   colorPost(formData)
//   //   fetchData();
//   //   setColorName('');
//   //   setColor('');
//   // }
//   // const deleteColorRow = (index) => {
//   //   // Create a copy of the colorList array
//   //   const updatedColorList = [...colorList];
//   //   // Remove the item at the specified index
//   //   updatedColorList.splice(index, 1);
//   //   // Update the state with the new array
//   //   setColorList(updatedColorList);
//   // };
//   // const Color = [
//   //   {
//   //     id: "1",
//   //     colorName: "blue",
//   //     colorCode: "#232242",
//   //     color: "",
//   //     img: Delete,
//   //   },
//   //   {
//   //     id: "2",
//   //     colorName: "blue",
//   //     colorCode: "#232242",
//   //     color: "",
//   //     img: Delete,
//   //   },
//   //   {
//   //     id: "3",
//   //     colorName: "blue",
//   //     colorCode: "#232242",
//   //     color: "",
//   //     img: Delete,
//   //   },
//   //   {
//   //     id: "4",
//   //     colorName: "blue",
//   //     colorCode: "#232242",
//   //     color: "",
//   //     img: Delete,
//   //   },
//   // ];




//   // password modal
//   const [showPassword, setShowPassword] = useState(false);
//   // const [shownewPassword, setNewPassword] = useState(false);
//   // const [showconfirmPassword, setconfirmPassword] = useState(false);

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };
//   // const newPassword = () => {
//   //   setNewPassword(!shownewPassword);
//   // };
//   // const confirmPassword = () => {
//   //   setconfirmPassword(!showconfirmPassword);
//   // };

//   const [showpassword, setshowPassword] = useState(false);
//   const [showpassword2, setshowPassword2] = useState(false);

//   const {
//     register,
//     formState: { errors },
//     handleSubmit,
//     watch,
//   } = useForm();
//   const onSubmit = (data) => {
//     const { password, confirmPassword } = data;
//     if (password === confirmPassword) {
//       //navigate to next page
//     }
//   };
//   const watchPassword = watch('password', '');



//   return (
//     <Main>
//       <div className="container-fluid">
//         <div className="heading-flex">
//           <h1 className="heading m-0">Settings</h1>
//         </div>
//         <div className="card-flex">
//           {[
//             // { id: 1, img: ColorImg, total: "Total Colors", numb: colorLength },
//             { id: 2, img: Fabric, total: "Total Materials", numb: materiallength },
//           ].map((items) => (
//             <div
//               className="card card-width"
//               key={items.id}
//               onClick={() => handleCardClick(items.id)}
//             >
//               <div className="card-body">
//                 <div style={{ lineHeight: "27px" }}>
//                   <img src={items.img} className="color" alt={items.total} />
//                   <div>
//                     <p className="total-color">{items.total}</p>
//                     <p className="numb-color">{items.numb}</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         <div className="card mt-3 border-0">
//           <div className="padding">
//             <form onSubmit={handleSubmit(onSubmit)}>
//               <div className="password-flex">
//                 <div>
//                   <label className="label">Current Password</label>
//                   <div style={{ position: "relative" }}>
//                     <input
//                       type={showPassword ? "text" : "password"}
//                       className="input-password"
//                     />
//                     {showPassword ? (
//                       <FaRegEyeSlash
//                         size={20}
//                         onClick={togglePasswordVisibility}
//                         className="icon"
//                       />
//                     ) : (
//                       <FaRegEye
//                         size={20}
//                         onClick={togglePasswordVisibility}
//                         className="icon"
//                       />
//                     )}
//                   </div>

//                   <div>
//                     <p className="m-0 create-new">Create new password</p>

//                     <label className="label mt-3">New Password</label>
//                     <div style={{ position: "relative" }}>
//                       <div className="passwordDiv">
//                         <div className="d-flex justify-content-between ">
//                           <input
//                             type={showpassword ? "text" : "password"}
//                             className="input-password "
//                             id="password"
//                             {...register("password", {
//                               required: true,
//                               minLength: 5,
//                               maxLength: 20,
//                             })}
//                           />
//                           <span onClick={() => setshowPassword(!showpassword)}>
//                             {showpassword ? (
//                               <FaRegEyeSlash
//                                 size={20}
//                                 className="icon"
//                               />
//                             ) : (
//                               <FaRegEye size={20} className="icon" />
//                             )}
//                           </span>
//                         </div>
//                         <p className="error-password">
//                           {errors.password?.type === "required" &&
//                             "password is required"}
//                           {errors.password?.type === "minLength" &&
//                             "entered password is less than 5 characters"}
//                           {errors.password?.type === "maxLength" &&
//                             "entered password is more than 20 characters"}
//                         </p>
//                       </div>
//                     </div>

//                     <label className="label mt-3">Confirm Password</label>
//                     <div style={{ position: "relative" }}>
//                       <div className="passwordDiv">
//                         <div className="d-flex justify-content-between ">
//                           <input
//                             type={showpassword2 ? "text" : "password"}
//                             className="input-password "
//                             id="confirmPassword"
//                             {...register("confirmPassword", {
//                               validate: (value) =>
//                                 value === watchPassword ||
//                                 "Passwords do not match",
//                             })}
//                           />
//                           <span onClick={() => setshowPassword2(!showpassword2)}>
//                             {showpassword2 ? (
//                               <FaRegEyeSlash
//                                 size={20}
//                                 className="icon"
//                               />
//                             ) : (
//                               <FaRegEye size={20} className="icon" />
//                             )}
//                           </span>
//                         </div>
//                         <p className="error-password">
//                           {errors.confirmPassword?.message}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div>
//                   <p className="m-0 notifications">Notifications</p>

//                   <div className="ios-flex">
//                     <p className="m-0 enable">Enabled</p>
//                     <ColorSwitches />
//                   </div>
//                 </div>
//               </div>
//               <Update>
//                 <button type="submit" className="update-btn">
//                   Update
//                 </button>
//               </Update>
//             </form>
//           </div>
//         </div>

//         {/* color modal */}
//         {/* <Modal
//           open={isColorModalOpen}
//           onClose={handleCloseColorModal}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={style}>
//             <Typography
//               id="modal-modal-title"
//               variant="h6"
//               component="h2"
//             >
//               Total Colors
//             </Typography>
//             <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//               <div className="d-flex justify-content-between">
//                 <div>
//                   <label>Color Name</label>
//                   <div>
//                     <Input className="input-color" value={colorName} onChange={(e) => setColorName(e.target.value)} />
//                   </div>
//                 </div>
//                 <div style={{ position: "relative" }}>
//                   <label>Color </label>
//                   <div>
//                     <InputDate
//                       type="color"
//                       value={color}
//                       onChange={handleColorChange}
//                       className="input-color"
//                     />
//                     <p
//                       style={{
//                         position: "absolute",
//                         top: "40px",
//                         left: "140px",
//                       }}
//                     >
//                       {" "}
//                       {color}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="text-end " style={{ padding: "13px 0px" }}>
//                 <AddButton type="submit" className="add" onClick={handleAddColor}>
//                   Add
//                 </AddButton>
//               </div>
//               <Card className="color-card">
//                 <div className="card-body" style={{overflowY: "auto",overflowX:"hidden"}}>
//                   <table className="color-table">
//                     <tbody className="color-tbody">
//                       {colorList.length === 0 ? (
//                         <tr style={{ textAlign: "center" }}>
//                           <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>
//                             Data not exist
//                           </td>
//                         </tr>
//                       ) : (
//                         colorList.map((color, index) => (
//                           <tr key={index} style={{width:"100%"}}>
//                             <td style={{ width: "80px", paddingTop: "10px" }}>{color.id}</td>
//                             <td style={{ padding: "10px 0px 0px 60px" }}>{color.name}</td>
//                             <td style={{ padding: "10px 0px 0px 140px" }}>{color.colorCode}</td>
//                             <td style={{ paddingTop: "10px" }}>{color.color}</td>
//                             <td style={{ padding: "10px 0px 0px 200px" }}>
//                               <img
//                                 src={Delete}
//                                 style={{
//                                   width: "31px",
//                                   height: "31px",
//                                   cursor: "pointer",
//                                 }}
//                                 alt="Delete"
//                                 onClick={() => deleteColorRow(index)}
//                               />
//                             </td>
//                           </tr>
//                         ))
//                       )}

//                     </tbody>
//                   </table>
//                 </div>
//               </Card>
//             </Typography>
//           </Box>
//         </Modal> */}

//         {/* material modal */}
//         <Modal
//           open={isMaterialModalOpen}
//           onClose={handleCloseMaterialModal}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={style}>
//             <Typography
//               id="modal-modal-title"
//               variant="h6"
//               component="h2"
//               className="text-center"
//             >
//               Total Materials
//             </Typography>
//             <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//               <label>Material Name</label>
//               <div>
//                 <InputMaterial
//                   type="text"
//                   value={productName}
//                   onChange={(e) => setProductName(e.target.value)}
//                   className="input-color"
//                 />
//               </div>
//               <div className="text-end" style={{ padding: "13px 0px" }}>
//                 <AddButton
//                   type="submit"
//                   className="add"
//                   onClick={handleAddProduct}
//                 >
//                   Add
//                 </AddButton>
//               </div>

//               <Card className="card">
//                 <div className="card-body">
//                   <table style={{ width: "100%" }}>
//                     <tbody>
//                       {materiallist.length === 0 ? (
//                         <tr style={{ textAlign: "center" }}>
//                           <td colSpan="5" style={{ textAlign: "center", padding: "10px" }}>
//                             Data not exist
//                           </td>
//                         </tr>
//                       ) : (
//                         materiallist.map((item, index) => (
//                           <tr key={index} >
//                             <td style={{ width: "80px", paddingTop: "10px" }}>{item.id}</td>
//                             {console.log(item.id)}
//                             <td style={{ padding: "10px 0px 0px 60px" }}>{item.name}</td>
//                             <td style={{ padding: "10px 0px 0px 200px" }}>
//                               <img id={(e) => e.target.id}
//                                 src={Delete}
//                                 style={{
//                                   width: "31px",
//                                   height: "31px",
//                                   cursor: "pointer",
//                                 }}
//                                 alt="Delete"
//                                 onClick={() => handleDeleteProduct(item.id)}
//                               />
//                             </td>
//                           </tr>
//                         ))
//                       )}

//                     </tbody>
//                   </table>
//                 </div>
//               </Card>
//             </Typography>
//           </Box>
//         </Modal>
//       </div>
//     </Main>
//   );
// };

// export default Setting;

