import styled from 'styled-components';

export const Main = styled.div`
/* new */
.heading-container {
    background-color: #ffbb02;
    color: white;
    padding: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
.headings{
    padding-Left:"320px";
    font-size:"20px";
}
  .first-name {
    display: flex;
    flex-direction: column;
  }
  .last-name {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
  }
  .close-icon {
    font-size: 25px;
    cursor: pointer;
  }

  .padding {
    padding: 0px 25px;
    position: relative;
  }
  .img-border {
    width: 156px;
    height: 160px;
    border: none;
  }
  .width {
    width: 160px;
    height: 160px;
    margin: 0px 0px;
    border-radius: 5px;
}
.react-tel-input .form-control{
    width: 295px;
    height: 48px;
    margin: 0px 0px;
    padding-left: 45px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    outline: none;
    
}
.react-tel-input .flag-dropdown{
    border-radius: 5px;
    border: 1px solid #E2E8F0;
}
.input-width:focus {
  outline: none;
  box-shadow: none; /* Remove any default box-shadow */
}
.gender-container{
    display: flex;
    flex-direction: column;
    width: 53%;
  }
  .select-gender {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  .card-body {
    flex: 1 1 auto;
    padding: 0px;
    color: var(--bs-card-color);
  }
  .profile-picture-margin-left {
    margin-left: 90px;
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 9;
    top: -12px;
    left: 50px;
    cursor: pointer;
  }
  .div-width {
    width: 16%;
    margin-right: 0%;
  }
  .area-direction {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .email-container {
    display: flex;
    flex-direction: column;
    width: 46%;
  }
  .email-width {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  .business-name-input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  .phone-container {
    display: flex;
    flex-direction: column;
    width: 50%;
  }
  .date-of-birth {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  .profile-flex {
    display: flex;
    /* gap: 17%; */
    justify-content: space-between;
    align-items: center;
  }
  .upload-border {
    /* border: 1px solid; */
    height: 155px;
    width: 672px;
    border: 1px solid #94a3b8;
    border-radius: 5px;
  }
  .name-label {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }
  .banner-font {
    font-size: 14px;
  }
  .input-width {
    width: 380px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  .input-width1 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }

  .error {
    color: red;
    font-size: 14px;
    margin-bottom: 0px;
  }
  .profile-img-width {
    width: 25px;
    height: 25px;
    margin-left: 40px;
    margin-top: 12px;
  }
  .profile-font {
    text-align: center;
    font-size: 10px;
    margin-top: 20px;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .form-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .column {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }

  .date-of-birth-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: 585px) {
    .overflow {
      height: 400px;
      overflow-y: auto;
    }

    .form-container {
      display: flex;
      overflow-x: scroll;
      flex-direction: column;
      align-items: center;
    }
    .input-width {
      width: 300px;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #e2e8f0;
      font-family: inter;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      padding: 13px;
      outline: none;
    }
    .input-width1 {
      width: 300px;
      height: 48px;
      border-radius: 5px;
      border: 1px solid #e2e8f0;
      font-family: inter;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
      padding: 13px;
      outline: none;
    }
    .column {
      display: flex;
      flex-direction: column;
    }
    .area-direction {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    .email-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .gender-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    .phone-container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  /* business information tab */
  .info-container-1 {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .dba-field {
    display: flex;
    /* gap: 17%; */
    flex-direction: column;
  }
  .input-business-container {
    display: flex;
  }
  .input-width-business {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 13px;
    outline: none;
  }
  .business-type {
    display: flex;
    flex-direction: column;
  }
  .input-width1-business {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    padding: 8px;
    outline: none;
  }
  .upload-btn {
    width: 130px;
    height: 48px;
    background-color: #e2e8f0;
    border: 0px;
    font-family: inter;
    border-radius: 5px;
    padding: 8px 16px 8px 8px;
    font-weight: 400;
    font-size: 16px;
    color: #1c1c27;
  }

  .input-width-business {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 8px 8px 8px 8px;
    outline: none;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
  }

  .padding1 {
    height: 415px;
    padding: 0px 25px;
    position: relative;
  }
  .business-licence-width {
    width: 350px;
  }
  .dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    width: 250px;
  }
  .Vendor-Information-child {
    display: flex;
    flex-direction: column;
    height: 70px;
  }

.search{
    margin-top: 20px;
    width: 235px;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    border: 0;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}
`;
