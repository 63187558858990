import React, { useEffect, useState } from "react";
import { Main } from "./style";
import useHook from "./usehook";
import { useNavigate, useParams } from "react-router-dom";
import { View } from "assets/img";
import PaginationRounded from "components/pagination";
const VenderDispute = () => {
  const { getdisputes } = useHook();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [disputelist, setdsiputelist] = useState([]);
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    initiated: { currentPage: 1, totalPages: 1 },
    admin_review: { currentPage: 1, totalPages: 1 },
    resolved: { currentPage: 1, totalPages: 1 },
    rejected: { currentPage: 1, totalPages: 1 },
  });
  // Track the active tab
  const [activeTab, setActiveTab] = useState("initiated");
  const handleVendorDisputes = async (status, page = 1) => {
    const formdata = {
      userId: id,
      search: search,
      status: status,
      page: page,
      perPage: 10,
    };
    try {
      const response = await getdisputes(formdata);
      setdsiputelist(response.data.results);
      setPagination((prev) => ({
        ...prev,
        [status]: {
          currentPage: page,
          totalPages: response.data.totalPages, // Adjust this based on your API response
        },
      }));
      console.log("resposne is ", response);
    } catch (error) {
      console.error("disputer error", error);
    }
  };
  const handleFilter = () => {
    // Reset page number to 1 for the active tab
    handleVendorDisputes(activeTab, 1);
  };

  // Handle tab change
  const handleTabChange = (status) => {
    setActiveTab(status);
    handleVendorDisputes(status, 1); // Fetch data for the new tab and reset page number
  };

  useEffect(() => {
    handleVendorDisputes(activeTab);
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const handlePageChange = (status, page) => {
    handleVendorDisputes(status, page);
  };

  const handleReset = () => {
    setSearch("");
  };
  useEffect(() => {
    if (search === "") {
      handleVendorDisputes();
    }
  }, [search]);
  const handleDisputeDetail = (id) => {
    navigate(`/disputes/dispute-detail/${id}`);
  };
  return (
    <Main>
      <div className="container-fluid">
        {/* <div className="order-flex">
          <div class="card third-card ">
            <div class="card-body">
              <div className="third-flex">
                <img src={total} alt="img" className="total-img" />
                <div className="margin">
                  <p className="m-0 order-para">Total Order</p>
                  <p className="m-0 order-number">65</p>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="card border-0 mt-3 w-100">
          <div className="card-body w-100">
            <div className="d-flex w-100 gap-2">
              <div className="d-flex flex-row justify-content-between w-100 gap-2">
                <div className="w-100">
                  <input
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="input-reason me-2"
                    placeholder="Search by Reason"
                  />
                </div>
                <div className="d-flex gap-2">
                  <button
                    type="submit"
                    className="filter-dispute"
                    onClick={handleVendorDisputes}
                  >
                    Filter
                  </button>
                  <button type="submit" className="reset" onClick={handleReset}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* #========================= Dispute tabs start ================================= */}
        <ul
          className="nav nav-tabs mt-3 dispute-font"
          id="myTab"
          role="tablist"
        >
          <li className="nav-item padding-dispute" role="presentation">
            <button
              onClick={() => handleVendorDisputes("initiated")}
              className="nav-link active dispute-font"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              Initiated
            </button>
          </li>
          <li className="nav-item padding-dispute" role="presentation">
            <button
              onClick={() => handleVendorDisputes("admin_review")}
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
            >
              Admin Review
            </button>
          </li>
          <li className="nav-item padding-dispute" role="presentation">
            <button
              onClick={() => handleVendorDisputes("resolved")}
              className="nav-link"
              id="contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#contact-tab-pane"
              type="button"
              role="tab"
              aria-controls="contact-tab-pane"
              aria-selected="false"
            >
              Resolved
            </button>
          </li>
          <li className="nav-item padding-dispute" role="presentation">
            <button
              onClick={() => handleVendorDisputes("returned")}
              className="nav-link"
              id="Declined-tab"
              data-bs-toggle="tab"
              data-bs-target="#Declined-tab-pane"
              type="button"
              role="tab"
              aria-controls="disabled-tab-pane"
              aria-selected="false"
            >
              Returned
            </button>
          </li>
        </ul>
        <div className="tab-content dispute-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabindex="0"
          >
            <div className="card border-0 card-border mb-5">
              <div className="card-body">
                <table className="w-100">
                  <thead>
                    <tr className="table-heading">
                      <th style={{ width: "10%" }}>Dispute ID</th>
                      <th style={{ width: "14%" }}>Customer</th>
                      <th style={{ width: "18%" }}>Invoice Number</th>
                      <th style={{ width: "28%" }}>Reason</th>
                      <th style={{ width: "16%" }}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {disputelist.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="no-record">
                          No Record found
                        </td>
                      </tr>
                    ) : (
                      disputelist.map((item, index) => (
                        <tr key={index} className="table-row">
                          <td style={{ paddingBlock: "13px" }}>{item.id}</td>
                          <td style={{ paddingBlock: "13px" }}>
                            {item.order.user.name}
                          </td>
                          <td style={{ paddingBlock: "13px" }}>
                            {item.order.id}
                          </td>
                          <td style={{ paddingBlock: "13px" }}>
                            {item.reason}
                          </td>
                          <td style={{ paddingBlock: "13px" }}>
                            {formatDate(item.createdAt)}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabindex="0"
          >
            <div className="card border-0 card-border mb-5">
              <div className="card-body table-responsive">
                <table className="w-100 table">
                  <thead>
                    <tr className="table-heading">
                      <th style={{ width: "10%" }}>Dispute ID</th>
                      <th style={{ width: "14%" }}>Customer</th>
                      <th style={{ width: "18%" }}>Invoice Number</th>
                      <th style={{ width: "28%" }}>Reason</th>
                      <th style={{ width: "16%" }}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {disputelist.length === 0 ? (
                      <tr>
                        <td colSpan="8" className="no-record">
                          No Record found
                        </td>
                      </tr>
                    ) : (
                      disputelist.map((item, index) => (
                        <>
                          <tr key={index} className="table-row">
                            <td style={{ paddingBlock: "13px" }}>{item.id}</td>
                            <td style={{ paddingBlock: "13px" }}>
                              {item.order?.user?.name}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {item.order?.id}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {item.reason}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {formatDate(item.createdAt)}
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="contact-tab-pane"
            role="tabpanel"
            aria-labelledby="contact-tab"
            tabindex="0"
          >
            <div className="card border-0 card-border mb-5">
              <div className="card-body table-responsive">
                <table className="w-100 table">
                  <thead>
                    <tr className="table-heading">
                      <th style={{ width: "10%" }}>Dispute ID</th>
                      <th style={{ width: "14%" }}>Customer</th>
                      <th style={{ width: "18%" }}>Invoice Number</th>
                      <th style={{ width: "28%" }}>Reason</th>
                      <th style={{ width: "16%" }}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {disputelist.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="no-record">
                          No Record found
                        </td>
                      </tr>
                    ) : (
                      disputelist.map((items, index) => (
                        <>
                          <tr key={index} className="table-row">
                            <td style={{ paddingBlock: "13px" }}>{items.id}</td>
                            <td style={{ paddingBlock: "13px" }}>
                              {items.order?.user?.name}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {items.order?.id}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {items.reason}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {formatDate(items.createdAt)}
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="Declined-tab-pane"
            role="tabpanel"
            aria-labelledby="Declined-tab"
            tabindex="0"
          >
            <div className="card border-0 card-border mb-5">
              <div className="card-body table-responsive">
                <table className="w-100 table">
                  <thead>
                    <tr className="table-heading">
                      <th style={{ width: "10%" }}>Dispute ID</th>
                      <th style={{ width: "14%" }}>Customer</th>
                      <th style={{ width: "18%" }}>Invoice Number</th>
                      <th style={{ width: "28%" }}>Reason</th>
                      <th style={{ width: "16%" }}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {disputelist.length === 0 ? (
                      <tr>
                        <td colSpan="5" className="no-record">
                          No Record found
                        </td>
                      </tr>
                    ) : (
                      disputelist.map((item, index) => (
                        <>
                          <tr key={index} className="table-row">
                            <td style={{ paddingBlock: "13px" }}>{item.id}</td>
                            <td style={{ paddingBlock: "13px" }}>
                              {item.order?.user?.name}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {item.order?.id}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                              {item.reason}
                            </td>
                            <td style={{ paddingBlock: "13px" }}>
                            {formatDate(item.createdAt)}
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default VenderDispute;
