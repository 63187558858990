import styled from "styled-components";
export const Product = styled.div`
width: 100%;
background-color: var(--all-background);
height: auto;

.img{
  width: 50px;
  height: 50px;
  border-radius:100px;
}
.dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    margin-top: 12px;
    height: 40px;
    width: 140px;
  }
.single-image{
width: 80px;
height: 80px;
border-radius: 5px;
}

.status-stockin{
    text-align: center;
background-color: #E9F5EC;
color: #209A42;
border-radius: 5px;
width: 80px;
margin-left: 2px;
margin-right: 20px;

}
.status-stockout{
    background-color: #FFE5E5;
    color: #FF0000;
    border-radius: 5px;
}
.product-name{
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
}
.product-count{
    font-family: Plus Jakarta Sans;
    font-weight: 400;
    font-size: 12px;
    color: rgba(28, 28, 39, 1);
    margin-bottom: 0px ;
    width: 100%;

}
.product-para{
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: rgba(128, 141, 158, 1);
    margin-bottom: 0px;
}
.table-head{
  font-family: inter;
  font-weight: 700;
  font-size: 14px;
  color: #0C3A2D;
}
.styled-font{
  font-family: inter;
  font-weight: 400;
  font-size: 14px;
  color: #1C1C27;
}
.status{
  width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #FF00001A;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #FF0000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.edit{
    width: 31px;
    height: 32px;
}
.view-icon{
    background-color: #209A42;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
`

