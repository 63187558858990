import styled from "styled-components";
export const InputMaterial = styled.input`

    height: 56px;
    width: 100%;
    border-radius: 10px;
    outline: none;
    border: 1px solid #F9F9F9;
    background-color: #F9F9F9;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding: 13px;

`
export const AddButton = styled.button`

    height: 50px;
    width: 170px;
    border-radius: 12px;
    outline: none;
    background-color: #156A3D;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    border: 0px;

`
export const Card = styled.div`

    height: 550px;
    width: 960px;
    border-radius: 10px;
    outline: none;
    background-color: #F9F9F9;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    border: 0px;
    .card-body{
   overflow: scroll;
    overflow-x: hidden;
    overflow-y: scroll;
    }
 

    .color-card{
        width: 800px;
        height: 100px;
        overflow-y: scroll;
    }
    .color-table{
        width: 100%;
        height: 40px;
        overflow: auto;
        outline: none;
    }
    .color-tbody{
        /* height: 40px; */
        height: 100px;
        overflow-y: scroll;
    }
    .delete-icon{
    background-color: #FF0000;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.delete-td{
    padding: 10px 0px 0px 500px;
}
@media (max-width:1640px) {
    width: 960px;
    height: 250px;

    .delete-td{
    padding: 10px 0px 0px 300px;
}


}
@media (max-width:1100px) {
    width: 720px;
    height: 150px;

    .delete-td{
    padding: 10px 0px 0px 300px;
}


}
@media (max-width:850px) {
    width: 640px;
    height: 150px;
    .delete-td{
    padding: 10px 0px 0px 180px;
}

}
@media (max-width:740px) {
    width: 100%;
    height: 200px;

    .card-body{
        width: 100%;
        overflow-x: scroll;
    }
}

`
export const Main = styled.div`
.main-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    //background-color: #FFBB02;
    background-color: #156A3D;
    color: white;



}
.total-material-label{
    margin-left: 450px;
    font-size: 22px;
    font-family: "Inter";
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    line-height: 25.2px;


}
.body{
    margin-top: 10px;
    padding: 15px 30px;
}

@media (max-width:1100px) {

    .total-material-label{
    margin-left: 330px;
    font-size: 22px;
    font-family: "Inter";
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    line-height: 25.2px;


}
}
@media (max-width:850px) {
    .total-material-label{
    margin-left: 270px;
    font-size: 22px;
    font-family: "Inter";
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    line-height: 25.2px;


}
    .delete-td{
    padding: 10px 0px 0px 200px;
}

}
@media (max-width:740px) {
    width: 100%;
    overflow-x: auto;
    .delete-td{
    padding: 10px 0px 0px 0px;
}
.material-name{
width: 100%;
}
.total-material-label{
    margin-left: 150px;
    font-size: 22px;
    font-family: "Inter";
    font-family: 'Plus Jakarta Sans';
    font-weight: 600;
    line-height: 25.2px;


}

}
`