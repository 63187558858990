import styled from "styled-components";
export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  .heading-flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  .nav-pills .nav-link.active,
  .edagDK .nav-pills .show > .nav-link {
    color: black;
    background-color: #F9F9F9 !important;
  }
  .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: black;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
  .img {
    width: 100%;
    height: 186px;
    border-radius: 10px;
  }
  .card-width {
    width: 32%;
    border: 0px;
    border-radius: 15px;
  }
  .sub-heading {
    font-family: Plus Jakarta sans;
    font-weight: 600;
    font-size: 14px;
    color: #808D9E;
  }
  .card-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
  .third {
    width: 326px;
    height: 92px;
    border: 1px solid #EDF2F7;
  }
  .catalog-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 20px;
  }
  .catalog {
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #1C1C27;
  }
  .category-btn {
    width: 218px;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    border: 0px;
  }
  .img-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .img-flex-2 {
    display: flex;
    padding-top: 6px;
    gap: 3px;
  }
  .pagination {
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .heading-card {
    font-family: Plus Jakarta sans;
    font-weight: 600;
    font-size: 16px;
    /* color: #1C1C27; */
    letter-spacing: 0.2px;
    padding: 0px 0px;
  }
`;
export const Product = styled.div`

.singleCatalog{
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.description{
  font-family: Inter;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
color: rgba(128, 141, 158, 1);

}
  .product-name {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
  .product-count {
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 14px;
    color: #808D9E;
  }
  .product-para {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1C1C27;
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    color: #0C3A2D;
  }
  .styled-font {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1C1C27;
  }
  .status {
    width: 65px;
    height: 28px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #FF0000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
  }
  .edit {
    width: 31px;
    height: 32px;
  }
  /* .img {
    width: 359px;
    height: 223px;
    border-radius: 12px;
  } */
  .flex-1 {
    width: 100%;
    height: 100%;
  }
  .transitional {
    padding: 2px 2px;
    width: auto;
    height: 28px;
    border-radius: 5px;
    background-color: #0C3A2D;
    font-family: Plus Jakarta Sans;
    font-weight: 500;
    font-size: 14px;
    color: white;
    border: 0px;
  }
  .flex-4 {
    display: flex;
    gap: 15px;
  }
  .heading-card2 {
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }
  .sub-heading2 {
    font-family: Plus Jakarta sans;
    font-weight: 400;
    font-size: 14px;
    color: #808D9E;
  }
  .img-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
  }
  .category-btn {
    width: 218px;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #FFFFFF;
    border: 0px;
  }
  .single-description {
    display: flex;
    flex-direction: row;
  }
  .singleCatalog-Image {
    height: 100px;
    border-radius: 8px;
  }
  .description-container {
    margin-left: 10px;
  }


  .custom-card {
  height: 320px; 
  flex-wrap: nowrap;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
  overflow: hidden; 
}

.custom-img {
  height:  160px; /* Set a fixed height for images */
  object-fit: cover; /* Ensures the image covers the area */
}

.card-body {
  /* flex: 1;  */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 40px;
    
  }
  .card-main-flex{
    display: flex;
  }
  @media (max-width:1047px) {
    .card-main-flex{
   flex-wrap: wrap;
    }
  }
`;

// import styled from "styled-components";

// export const Product = styled.div`
// width: 100%;
// background-color: var(--all-background);
// .product-name{
//     font-family: Plus Jakarta Sans;
//     font-weight: 600;
//     font-size: 14px;
//     color: #000000;
// }
// .product-count{
//     font-family: Plus Jakarta Sans;
//     font-weight: 600;
//     font-size: 14px;
//     color: #808D9E;
// }
// .product-para{
//     font-family: inter;
//     font-weight: 400;
//     font-size: 14px;
//     color: #1C1C27;
// }
// .table-head{
//   font-family: inter;
//   font-weight: 700;
//   font-size: 14px;
//   color: #0C3A2D;
// }
// .styled-font{
//   font-family: inter;
//   font-weight: 400;
//   font-size: 14px;
//   color: #1C1C27;
// }
// .status{
//   width: 65px;
//     height: 28px;
//     border-radius: 5px;
//     background-color: #FF00001A;
//     font-family: inter;
//     font-weight: 400;
//     font-weight: bolder;
//     font-size: 12px;
//     color: #FF0000;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin: 0px;
// }
// .edit{
//     width: 31px;
//     height: 32px;
// }
// .img{
//     width: 100%;
//     height: 223px;
//     border-radius: 12px;
// }
// .flex-1{
//     width: 31%;
//     padding: 0px 10px;
// }
// .transitional{
//     padding: 0px 12px;
//     width: auto;
//     height: 28px;
//     border-radius: 5px;
//     background-color: #0C3A2D;
//     font-family: Plus Jakarta Sans;
//     font-weight: 500;
//     font-size: 14px;
//     color: white;
//     border: 0px;
// }
// .flex-4{
//     display: flex;
//     /* width: 350px;
//     flex-wrap: wrap; */
// }
// .catalog-image{
//     width: 80px;
//     height: 80px;
//     border-radius: 5px;
// }

// `

