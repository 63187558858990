import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography } from '@mui/material';
import { IoCloseSharp } from "react-icons/io5";
import { Main, SaveBtn } from './style';
import TextField from '@mui/material/TextField';
import useHook from './usehook';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "685px",
    height: "260px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '12px',
};

const OrderCancellation = ({ open, onClose, modalID, selectedValue }) => {
    const { postOrderCancellationCharges } = useHook();
    // Decline Charges Data
    const [declineCharges, setDeclineCharges] = useState('')
    const DeclineChargesData = async () => {
        const formData = {
            "data": [
                {
                    "key": "declined_fee",
                    "value": declineCharges,
                }
            ]
        }
        try {
            const response = await postOrderCancellationCharges(formData)
            console.log("decline charges response", response)
            console.log("decline form data", formData)
            onClose();
            setDeclineCharges('')
        }
        catch (error) {
            console.error("Error", error)
        }
    }
    // admin Charges data
    const [adminCharges, setAdminCharges] = useState('')
    const AdminChargesData = async () => {
        const formData = {
            "data": [
                {
                    "key": "platform_fee",
                    "value": adminCharges,
                }
            ]
        }
        try {
            const response = await postOrderCancellationCharges(formData)
            console.log("admin charges response", response)
            console.log("admin form data", formData)
            onClose()
            setAdminCharges('')
        }
        catch (error) {
            console.error("Error", error)
        }
    }
    // cancellation Charges
    const [canCellationCharges, setCancellationCharges] = useState('')
    const sendCancellationData = async () => {
        const formData = {
            "data": [
                {
                    "key": "order_cancellation_fee",
                    "value": canCellationCharges,
                }
            ]
        }
        try {
            const response = await postOrderCancellationCharges(formData)
            console.log("cancellation charges response", response)
            console.log("cancellation form data", formData)
            onClose();
            setCancellationCharges('');
        }
        catch (error) {
            console.error("Error", error)
        }
    }
    useEffect(() => {
        if (open) {
            switch (modalID) {
                case 1:
                    setDeclineCharges(selectedValue || '');
                    break;
                case 2:
                    setAdminCharges(selectedValue || '');
                    break;
                case 3:
                    setCancellationCharges(selectedValue || '');
                    break;
                default:
                    break;
            }
        }
    }, [open, modalID, selectedValue]);
    return (
        <div>
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Box sx={style}>
                    <Main>
                        <div className='header'>
                            <Typography id="simple-modal-title" variant="h6" component="h2">

                            </Typography>
                            <Typography id="simple-modal-title" variant="h6" component="h2">
                                {modalID === 1 && (
                                    <span>Order Decline</span>
                                )}
                                {modalID == 2 && (
                                    <span>Admin Fees Per Order</span>
                                )}
                                {modalID === 3 && (
                                    <span> Order Cancellation Charges </span>
                                )}


                            </Typography>
                            <IoCloseSharp className='close-icon' onClick={onClose} />
                        </div>
                        <div className='modal-body'>

                            {modalID === 1 && (
                                <>
                                    <div className='relative'>
                                        <Typography id="simple-modal-description" sx={{ mt: 2, fontWeight: "bold" }}>
                                            Add Amount
                                        </Typography>
                                        <div className='d-flex flex-row align-items-center pl-3'>
                                            <input
                                                type="text" value={declineCharges}
                                                className='bg-color-input pl-8 ps-4'
                                                style={{ paddingLeft: '20px' }} onChange={(e) => setDeclineCharges(e.target.value)}
                                            />
                                            <div className='text-center d-flex align-items-center justify-content-center fs-3' style={{ color: "#0C3A2D", width: "40px", height: "50px", backgroundColor: "#F9F9F9", fontWeight: "800" }}>$</div>
                                        </div>

                                    </div>

                                    <SaveBtn className='mt-4' onClick={DeclineChargesData}>Update Order Decline Charges</SaveBtn>
                                </>

                            )}
                            {modalID === 2 && (
                                <>
                                    <div className='relative'>
                                        <Typography id="simple-modal-description" sx={{ mt: 2, fontWeight: "bold" }}>
                                            Add Percentage
                                        </Typography>
                                        <div className='d-flex flex-row align-items-center pl-3'>
                                            <input
                                                type="number" value={adminCharges} onChange={(e) => setAdminCharges(e.target.value)}
                                                className='bg-color-input pl-8 ps-4'
                                                style={{ paddingLeft: '20px' }}
                                            />
                                            <div className='text-center d-flex align-items-center justify-content-center fs-3' style={{ color: "#0C3A2D", width: "40px", height: "50px", backgroundColor: "#F9F9F9", fontWeight: "800" }}>%</div>
                                        </div>

                                    </div>

                                    <SaveBtn className='mt-4' onClick={AdminChargesData}>Update Admin Fees Per Order</SaveBtn>
                                </>
                            )}
                            {modalID === 3 && (
                                <>
                                    <div className='relative'>
                                        <Typography id="simple-modal-description" sx={{ mt: 2, fontWeight: "bold" }}>
                                            Add Amount
                                        </Typography>
                                        <div className='d-flex flex-row align-items-center pl-3'>
                                            <input
                                                type="number" value={canCellationCharges} onChange={(e) => setCancellationCharges(e.target.value)}
                                                className='bg-color-input pl-8 ps-4'
                                                style={{ paddingLeft: '20px' }}
                                            />
                                            <div className='text-center d-flex align-items-center justify-content-center fs-3' style={{ color: "#0C3A2D", width: "40px", height: "50px", backgroundColor: "#F9F9F9", fontWeight: "800" }}>$</div>
                                        </div>

                                    </div>

                                    <SaveBtn className='mt-4' onClick={sendCancellationData}>Update Order Cancellation Charges </SaveBtn>
                                </>
                            )}
                        </div>

                    </Main>

                </Box>
            </Modal>
        </div >
    );
};

export default OrderCancellation;
