import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Home from "./home";
import Categories from "./categories";
import UserManagement from "./user-management";
import UserProfile from "./user-profile";
import UserInvoice from "./user-invoice";
import OrderManagement from "./order-management";
import VendorManagement from "./vendor-management";
import VenderDetails from "./vendor-details";
import CreateVender from "./create-vender";
import FollowLikes from "./follow-likes";
import Disputes from "./disputes";
import ManageBanners from "./manage-banners";
import Notifications from "./notifications";
import Withdraw from "./withdraw";
import Settings from "./settings";
import OrderTransactionManagement from "./order-transaction-management";
import OrderInvoice from "./order-invoice";
import CollectionDetail from "./collection-detail";
import CatalogImages from "./catalog-images";
import ProductsDetails from "./products-detail";
import DisputeDetail from "./disputes-detail";

const DashboardRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" Component={Home} />
        <Route path="/categories" Component={Categories} />
        <Route path="/user-management" Component={UserManagement} />
        <Route
          path="/user-management/user-profile/:id"
          Component={UserProfile}
        />
        <Route
          path="/user-management/user-invoice/:id"
          Component={UserInvoice}
        />
        <Route path="/order-management" Component={OrderManagement} />
        <Route
          path="/order-management/order-invoice/:id"
          Component={OrderInvoice}
        />
        <Route path="/vendor-management" Component={VendorManagement} />
        <Route
          path="/vendor-management/vendor-details/:id"
          Component={VenderDetails}
        />
        <Route path="/create-Vendor" Component={CreateVender} />
        <Route path="/follow-likes" Component={FollowLikes} />
        <Route path="/disputes" Component={Disputes} />
        <Route path="/manage-banners" Component={ManageBanners} />
        <Route path="/settings" Component={Settings} />
        <Route path="/notifications" Component={Notifications} />
        <Route path="/settings" Component={Settings} />
        <Route
          path="/settings/order-transaction-management"
          Component={OrderTransactionManagement}
        />
        <Route path="/withdraw" Component={Withdraw} />
        {/* <Route path='/' Component={}/> */}
        <Route
          path="/vendor-management/collection-detail/:id"
          Component={CollectionDetail}
        />
        <Route path="/catalog-image-detail/:id" Component={CatalogImages} />
        <Route path="/product-detail/:id" Component={ProductsDetails} />
        <Route path="/disputes/dispute-detail/:id" Component={DisputeDetail}/>
      </Routes>
    </>
  );
};

export default DashboardRoutes;
