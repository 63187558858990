import styled from "styled-components";

export const Main = styled.div`

width: 100%;
background-color: var(--all-background);
height: 190vh;
.style-profile{
  font-family: poppins;
  font-weight: 600;
  font-size: 20px;
  color: #252525;
  margin: 0;
    padding-block: 20px;
}
.view-icon{
  background-color: #209A42;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
    margin-left: 20px;
}
.custom-margin{
  margin-top: 20px;
  width: 25%;
}
.img-width{
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.style-font{
  font-family: inter;
  font-size: 14px;
  font-weight: 400;
  color: #808D9E;
}
.style-font2{
  font-family: inter;
  font-size: 14px;
  font-weight: 400;
  color: black;
  border-bottom: 1px solid #E2E8F0;

}
.style-name{
  font-family: inter;
  font-size: 18px;
  font-weight: 800;
  color: black;
}
.display{
  display: flex;
    margin-left: 44%;
    gap: 0px;
}
.items{
  display: flex;
    align-items: center;
}
.img-width1{
  width: 29px;
  height: 31px;
}
.font-style{
  font-family: poppins;
  font-weight: 600;
  font-size: 12px;
  color: #252525;
}
.order{
  font-family: inter;
  font-weight: 600;
  font-size: 18px;
  color: #1C1C27;
  margin: 0;
    padding-block: 20px;
}
.order1{
  font-family: inter;
  font-weight: 600;
  font-size: 18px;
  color: #1C1C27;
  
}
.order-flex{
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    margin-top: 3%;
}
.third-card{
    width: 264px;
    height: 92px;
    border: 1px solid #EDF2F7;
    background-color: #ffffff;
    /* transition: 0.3s; */
    /* margin-top: 1%; */
}
/* .third-card:hover{
  background-color: white;
  border: 0px;
  
} */
.third-flex{
    justify-content: start;
    display: flex;
    align-items: center;
}
.order-img{
    width: 62px;
    height: 62px;
}
.margin{
    margin-left: 8%;
}
.order-para{
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #1C1C27;
}
.order-number{
    font-family: Inter;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 0.2px;
    color: #1C1C27;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color:white;
    background-color: #F9F9F9 !important;
}
.border1{
  width: 81px;
    height: 30px;
    background-color: #00D1FF1A;
    color: #00D1FF;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
}
.border2{
  width: 81px;
    height: 30px;
    background-color: #209A421A;
    color: #209A42;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
}
.border3 {
    width: 100px;
    height: 30px;
    background-color: #00D1FF1A;
    color: #00D1FF;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}
.border4 {
    width: 100px;
    height: 30px;
    background-color: #209A421A;
    color: #209A42;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}
.rating-number{
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  color: #1C1C27;
}
.cancel{
  background-color: #F084751A;
  color: #EB2A00;
  width: 73px;
    height: 24px;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}
.return{
  background-color: #FFE9D2;
  color: #B36618;
  width: 73px;
    height: 24px;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}
.total-img{
  width: 61px;
    height: 61px;
    background-color: #004E971A;
    padding: 14px;
    border-radius: 35px;
}
.cancel-img{
  width: 61px;
    height: 61px;
    background-color: #FF10101A;
    /* padding: 12px; */
    padding-top: 12px;
    padding-left: 6px;
    border-radius: 35px;
}
.return-img{
  width: 61px;
    height: 61px;
    background-color: #8B56251A;
    padding: 14px;
    border-radius: 35px;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;

    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
    min-width: 48px;
    height: 48px;
    border-radius: 12px;
    padding: 0 6px;
    margin: 0 3px;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(0, 0, 0, 0.23);
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #0C3A2D;
    color: #FFBB02;
}
.pagination-items{
  font-family: inter;
  font-weight: 600;
  font-size: 16px;
  color: #0F172A;
}
.table-head{
  font-family: inter;
  font-weight: 700;
  font-size: 14px;
  color: #0C3A2D;
}
.status-Processing{
   width: 99px;
   height: 24px;
   border-radius: 5px;
   background-color: #00D1FF1A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #00D1FF;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-delivered{
   width: 90px;
   height: 24px;
   border-radius: 5px;
   background-color: #209A421A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #209A42;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.options{
    font-family: inter;
   font-family: 400;
   font-size: 12px;
   outline: none;
   border: 0px;
}
.custom-select {
    border: none;
    border-radius: 5px;
    width: 99px;
    height: 24px;
    outline: none;
}
.input-body{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 15px;
}
.filter {
    width: 160px;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  .reset {
    width: 160px;
    height: 48px;
    border-radius: 5px;
    background-color: #e2e8f0;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    border: 0px;
  }
  .button-flex {
    display: flex;
    gap: 5px;
    padding-block: 24px;
  }
  .table-head {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #0c3a2d;
  }
  .table-body {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #1c1c27;
    border-bottom: 1px solid #e2e8f0;
  }
  .status-processing {
    width: 99px;
    height: 24px;
    border-radius: 5px;
    background-color: #00d1ff1a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #00d1ff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-delivered {
    width: 90px;
    height: 24px;
    border-radius: 5px;
    background-color: #209a421a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #209a42;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .input-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    gap: 10px;
    align-items: center;
  }
  .status-pending {
    width: 82px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff8f501a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff8f50;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-cancelled {
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }

  .status-returned {
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-declined {
    color: #cd2121;
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #cc00002e;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-shipped {
    color: firebrick;
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: cornsilk;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }

  .custom-select {
    border: none;
    border-radius: 5px;
    width: 99px;
    height: 24px;
    outline: none;
  }

  .custom-select option {
    background-color: #fff;
  }
  .options {
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    outline: none;
    border: 0px;
  }
  .pagination-flex {
    display: flex;
    justify-content: end;
  }
  .input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .input2 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .select1 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .select2 {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    outline: none;
  }
  .date {
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #1c1c27;
  }
  .date-input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    gap: 88px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    padding: 10px;
    outline: none;
  }
  .flex {
    display: flex;
    gap: 10px;
  }
  .filter {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #156a3d;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: white;
  }
  .reset {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #e2e8f0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: #808d9e;
  }
  .display-grid {
    display: grid;
    grid: auto / auto auto auto auto;
    grid-gap: 10px;
  }

  .table-responsive {
    width: 100%;
  }
  .button-container{
      display: flex;

      justify-content: end;
      width: 100%;
      gap: 10px;

    }
    .custom-margin{
      width: 20%;
    }

  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }

  @media (max-width:600px){
   .nav{
    width:100%;
   }
   .nav-item {
    width: 100%;
   }
   .nav-pills{
    width: 100%;
   }
   .nav-link {
    width: 100%;
   }
   .order-flex{
    width:100%
   }
   .third-card{
    width: 100%;
   }
   .third-flex{
    width: 100%;
   }
   .button-container{
      display: flex;
      flex-direction: row;
      justify-content: end;
      width: 100%;
      gap: 10px;

    }
  }

  @media (max-width:992px){
    .nav{
    width:100%;
   }
   .nav-item {
    width: 100%;
   }
   .nav-pills{
    width: 100%;
   }
   .nav-link {
    width: 100%;
   }
   .order-flex{
    width:100%
   }
   .third-card{
    width: 100%;
   }
   .third-flex{
    width: 100%;
   }
   .button-container{
      display: flex;
      flex-direction: row;
      justify-content: end;
      width: 100%;
      gap: 10px;

    }
  }
  @media(min-width: 993px) and (max-width:1200px) {
    .nav{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    width:40%;
   }
   .nav-item{
    width: 33.33% !important;
    gap:40px;
   }
   .nav-pills{
    width: 100%;
   }
   .nav-link {
    width: 100%;
   }
   .order-flex{
    width:100%
   }
   .third-card{
    width: 100%;
   }
   .third-flex{
    width:100%;
   }
   .button-container{
      display: flex;
      flex-direction: row;
      justify-content: end;
      width: 100%;
      gap: 10px;

    }
  }
  @media (max-width:460px){

    .input-container{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      align-items: center;
      gap: 15px;
    }
    .button-container{
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 10px;

    }
    .custom-margin{
      width: 100%;
    }
  }

`