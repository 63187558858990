import styled from "styled-components";

export const Main = styled.div`
  width: 100%;

  .yellow_border{
    border-bottom: 6px solid #FFBB02;
    padding: 10%;
    margin-inline: 10%;
    }
    .heading{
    font-family: 'Plus Jakarta Sans';
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #0F172A;
    }
    .welcome_Content{
        font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 400;
    color: #64748B;
    width: 75%;
    }
    .margin{
    margin-top: 12%;
    margin-left: 20%;
    }
    .input{
    width: 60%;
    height: 45px;
    border: 1px solid #E2E8F0;
    border-radius: 6px;
    color: black;
    padding: 9px;
    outline: #FFBB02;
    font-family: inter;
    }
    .SignIn_Button{
        background-color: #0C3A2D;
    color: white;
    width: 60%;
    border-radius: 10px;
    font-family:'Plus Jakarta Sans' ;
    height: 45px;
    }
 
`