import OrderDisputeModal from 'components/modals/order-dispute-modal';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Main } from './style';
import { FilePDF } from 'assets/img';
import Woman from 'assets/img/disputes-detail/Woman.png'
import Viewer from "react-viewer";
import useHook from './usehook';
// import LotusPro from 'assets/img/disputes-detail/LotusPro.png'
const DisputeDetail = () => {
  const { id } = useParams();
  const { getDisputes } = useHook()
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const OrderDispute = () => {
    if (location.pathname === "/vendor-management/vendor-details/:id") {
      navigate("/vendor-management/vendor-details");
    } else {
      navigate("/disputes");
    }

  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    }).format(date);
  };
  const [disputelist, setDisputelist] = useState({});
  const handleDisputes = async () => {
    try {
      const response = await getDisputes(id);
      console.log(response.data)
      setDisputelist(response.data)
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    handleDisputes();
  }, [])
  // image viwer
  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc);
    setVisible(true);
  };
  function getTimeFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    let hours = date.getHours(); // Get local hours
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Get minutes and pad
    const seconds = date.getSeconds().toString().padStart(2, '0'); // Get seconds and pad
    const ampm = hours >= 12 ? 'PM' : 'AM'; // Determine AM or PM

    // Convert to 12-hour format
    hours = hours % 12 || 12; // If hours = 0 (midnight), set to 12

    // Return the time in HH:MM:SS AM/PM format
    return `${hours}:${minutes}:${seconds} ${ampm}`;
  }


  return (
    <>
      <Main>
        <div className="container-fluid pt-3">
          <div className="card card-border">
            <div className="card-body">
              <div>
                <button
                  type="button"
                  className="back-button"
                  onClick={() => OrderDispute()}
                >
                  Back
                </button>
              </div>

              <div className="cards-parent">
                {/* #======================   Order Details   ======================#   */}
                <div className="first-card order-detail-card">
                  <div class="card-body">
                    <div>
                      <p className="main-heading ">Order Details</p>
                    </div>
                  </div>
                  <hr className="border-bottom-color" />
                  <div className="padding">
                    <p className="sub-heading">Invoice Number</p>
                    <p className="main-heading">{disputelist.order?.id}</p>

                    <div className="order-details-flex">
                      <div className="order-date">
                        <p className="sub-heading">Order Date</p>
                        <p className="main-heading">
                          {disputelist?.order?.createdAt
                            ? formatDate(disputelist.order.createdAt)
                            : "N/A"}
                        </p>
                      </div>
                      <div className="order-amount">
                        <p className="sub-heading">Order Amount</p>
                        <p className="main-heading">${disputelist.order?.totalAmount}</p>
                      </div>
                    </div>
                    <div className="order-details-flex">
                      <div className="order-date">
                        <p className="sub-heading">Customer</p>
                        <p className="main-heading">{disputelist.order?.user?.name}</p>
                      </div>
                      <div className="order-amount">
                        <p className="sub-heading">Vendor</p>
                        <p className="main-heading">{disputelist.order?.vendor?.businessInfo?.businessName}</p>
                      </div>
                    </div>
                  </div>
                  <hr className="border-bottom-color mt-3 mb-2" />
                  <p className="main-heading" style={{ padding: "0px 15px" }}>
                    Dispute Details
                  </p>
                  <hr className="border-bottom-color  mt-2 mb-3" />
                  <div className="padding pb-3">
                    <p className="sub-heading">Dispute ID</p>
                    <p className="main-heading">{disputelist.id}</p>

                    <div className="order-details-flex">
                      <div className="order-date">
                        <p className="sub-heading">Dispute Date</p>
                        <p className="main-heading">
                          {disputelist?.createdAt
                            ? formatDate(disputelist.createdAt)
                            : "N/A"}
                        </p>
                      </div>
                      <div className="order-amount">
                        <p className="sub-heading">Dispute Amount</p>
                        <p className="main-heading">${disputelist.orderProduct?.totalPrice}</p>
                      </div>
                    </div>
                    <div className="order-details-flex">
                      <div className="order-date">
                        <p className="sub-heading">Color</p>
                        <p
                          style={{
                            backgroundColor: disputelist?.orderProduct?.orderProductColor?.colorCode,
                            width: "20px",
                            height: "20px",
                            borderRadius: "20px",
                            marginTop: "5px",
                          }}
                        ></p>
                      </div>
                      <div className="order-amount">
                        <p className="sub-heading">Product Name</p>
                        <p className="main-heading">{disputelist.orderProduct?.product.name}</p>
                      </div>
                    </div>
                    <p className="sub-heading mt-3">Dispute Reason</p>
                    <p className="main-heading">
                      {disputelist.reason}
                    </p>
                    <p className="sub-heading mt-3">Evidence Images</p>
                    {/* {disputelist.images} */}
                    <div className="evidence-img-flex">
                      {disputelist?.images?.map((img, index) => (
                        <div key={index}>
                          <img
                            src={`${process.env.REACT_APP_BASE_URL}/${img.url}`}
                            alt={`Evidence ${index + 1}`}
                            style={{
                              width: "70px",
                              height: "70px",
                              borderRadius: "10px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleImageClick(
                                `${process.env.REACT_APP_BASE_URL}/${img.url}`
                              )
                            }
                          />
                          {visible && currentImage && (
                            <Viewer
                              visible={visible}
                              onClose={() => setVisible(false)}
                              images={[{ src: currentImage, alt: "Image" }]} // Only show the clicked image
                              drag={false}
                            />
                          )}
                          {/* {img.url && (
                                <p className="evidence-font">Document.jpg</p>
                              )} */}
                        </div>
                      ))}
                    </div>







                    {/* <div className="evidence-img-flex">
                      <div className="evidence-img">
                        <FilePDF />
                        <p className="evidence-font">Document.jpg</p>
                      </div>
                      <div className="evidence-img">
                        <FilePDF />
                        <p className="evidence-font">Document.jpg</p>
                      </div>
                    </div> */}
                  </div>
                </div>
                {/* #======================   Resolution   ======================#   */}

                <div className="second-card order-detail-card pb-5">
                  <div class="card-body">
                    <div>
                      <p className="main-heading ">Resolution</p>
                    </div>
                  </div>

                  <hr className="border-bottom-color" />
                  <div className="padding">
                    <div className="order-details-flex m-0">
                      <div className="order-date">
                        <p className="sub-heading">Dispute Status</p>
                        <button className="status">{disputelist.status}</button>
                      </div>
                      {/* <div className="order-amount">
                        <p className="sub-heading">Dispute Reached</p>
                        <p className="main-heading">Awarded to Customer</p>
                      </div> */}
                      {disputelist && disputelist.comments
                        ? disputelist.comments
                          .filter((comment) => comment.warning)
                          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                          .slice(0, 1)
                          .map((warning) => (
                            <div className="order-amount" key={warning.id}>
                              <p className="sub-heading">Warning</p>
                              <p className="main-heading">
                                <div className="warning-text">
                                  {warning.warning ? warning.warning : "no warning"}
                                </div>
                              </p>
                            </div>
                          ))
                        : null // or you can return a different UI component or message if no comments are found
                      }

                    </div>
                  </div>
                  <hr className="border-bottom-color mt-4 mb-4" />
                  <div className="padding">
                    <div
                      className="order-details-flex m-0"
                      style={{ alignItems: "center" }}
                    >
                      <div className="order-date">
                        <p className="main-heading">Resolution History</p>
                        <p className=" sub-heading">
                          Please see below for dispute details and resolution.
                        </p>
                      </div>
                      {disputelist.status === "admin_review" && (
                        <div className="order-amount text-end">
                          <button type="button" className="takeAction-button" onClick={handleOpen}>
                            Take Action
                          </button>
                        </div>
                      )}

                    </div>
                    <div style={{ overflowY: "auto", height: "580px" }}>
                      {disputelist.comments?.map((item, index) => (
                        item.comment?.trim() && (
                          <>
                            <div style={{ position: "relative" }} key={index}>
                              <div className="chat-flex">
                                <div className='img-con'>
                                  <img src={
                                    item.user.businessInfo
                                      ? `${process.env.REACT_APP_BASE_URL}${item?.user?.businessInfo?.businessLogo}`
                                      : `${process.env.REACT_APP_BASE_URL}${item?.user?.profile_pic}`
                                  } style={{ width: "70px", height: "70px", borderRadius: "100px" }} alt="" />
                                </div>
                                <div className='chat-name-container'>
                                  <p className="chat-name-heading">
                                    {item.user?.businessInfo
                                      ? item.user?.businessInfo.businessName
                                      : item.user?.name}
                                  </p>
                                  <div className='date-time'>
                                    <p className="sub-heading">{formatDate(item.createdAt)}</p>
                                    <p className="sub-heading">{getTimeFromTimestamp(item.createdAt)}</p>
                                  </div>
                                </div>
                              </div>
                              <p className="under-img-border"></p>
                              <div className="card mt-3">
                                <div className="padding">
                                  <p className="chat-text mb-0">{item.comment}</p>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex flex-row gap-2'>
                              {/* {item.images?.map((items, index) => (
                                <div className='img-container' key={index}>
                                  <img src={`${process.env.REACT_APP_MEDIA_URL}${items.url}`} alt='imag' className='comment-images'  onClick={() => {
                                        setVisible(true); // Show the viewer
                                        setCurrentImage(
                                          `${process.env.REACT_APP_BASE_URL}/${item.url}`
                                        ); // Set the current image
                                      }} />
                                </div>
                              ))} */}

                              {item.images.length > 0 && (
                                <div className="comment-images1">
                                  {item.images.map((image) => (
                                    <img
                                      key={image.id}
                                      src={`${process.env.REACT_APP_BASE_URL}/${image.url}`}
                                      alt="comment image"
                                      className='comment-images'
                                      style={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setVisible(true); // Show the viewer
                                        setCurrentImage(
                                          `${process.env.REACT_APP_BASE_URL}/${image.url}`
                                        ); // Set the current image
                                      }}
                                    />
                                  ))}
                                </div>
                              )}
                              {visible && currentImage && (
                                <Viewer
                                  visible={visible}
                                  onClose={() => setVisible(false)}
                                  images={[{ src: currentImage, alt: "Image" }]} // Only show the clicked image
                                  drag={false}
                                />
                              )}
                            </div>



                          </>
                        )
                      ))}
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Main>
      <OrderDisputeModal open={open} handleClose={handleClose} disputelist={disputelist} handleDisputes={handleDisputes} />
    </>
  )
}

export default DisputeDetail
