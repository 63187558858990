import React, { useState } from "react";
import { Main, NavLinkStyled } from "./style";
import Logo from "../../assets/img/sidebar/Logo.png";
import {Banners,Dashboard,Disputes,File,Settings,User,Vendor,Withdraw,category} from '../../assets/img/index'
import { FiAlignLeft } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { RxCross1 } from "react-icons/rx"
import LotusProLogo from '../../assets/img/common/LotusProLogo.svg'
// import Categories from "@containers/dashboard/categories";
// import { Mysvg } from "assets/img/index";

const Sidebar = ({ toggleSidebar, isOpen }) => {
  const location = useLocation();
  // const [isOpen, setIsOpen] = useState(false);

  const data = [
    {
      id: 1, Image:Dashboard , name: "Dashboard", path: "/"
    },
    {
      id: 2,  Image:category , name: "Categories", path: "/categories"
    },
    {
      id: 3, Image:User , name: "Users Management", path: "/user-management"
    },
    { id: 4, Image:File , name: "Orders Management", path: "/order-management" },
    {
      id: 5, Image:Vendor  , name: "Vendor Management", path: "/vendor-management",
    },
    { id: 6, Image:User , name: "Disputes", path: "/disputes" },
    { id: 7,Image:Banners , name: "Manage Banners", path: "/manage-banners" },
    { id: 8, Image:Settings , name: "Settings", path: "/settings" },
    {
      id: 9, Image:Withdraw , name: "Withdraw Request", path: "/withdraw"
    },
  ];

  // const toggleSidebar = () => {
  //   setIsOpen(!isOpen);
  // };

  return (
    <Main isOpen={isOpen}>
      <FiAlignLeft className="burger-icon" size={50} onClick={toggleSidebar} />
      <div className={`sidebar-content ${isOpen ? "open" : ""}`}>
        <div className="background-color">
          <div className="container-fluid">
            <div className="cross text-end">
              <RxCross1
                onClick={toggleSidebar}
                size={30}
                style={{ cursor: "pointer" }}
              />
            </div>
            <div className="logo-container">
              <img src={LotusProLogo} alt="Logo" className="logo" />
              <p className="logo-heading">
                Lotus <span className="pro ps-1"> Pro</span>
              </p>
            </div>
            <p className="border-white"></p>

            <ul className="mt-0" style={{ listStyle: "none", padding: "0" }}>
              {data.map((item) => {
                const Icon = item.Image
                return(
                <NavLinkStyled
                  className="nav-link dashboard d-flex align-items-center mt-0 "
                  key={item.id}
                  isActive={() => location.pathname === item.path}
                  to={item.path}
                  exact={item.path === "/"}
                >
                  <div>
                    {/* <span className="dash-logo">{item.SvgIcon}</span> */}
                    <Icon className="color"/>
                    <span className="ms-2">{item.name}</span>
                    <div style={{ color: "blue" }}>
                      {/* <Mysvg className="fill" /> */}
                    </div>
                  </div>
                </NavLinkStyled>
                )
})}
            </ul>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Sidebar;
