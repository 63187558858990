
import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };

const useHook = () => {

    const imagePost = async (file) => {
        try {
            if (!file) {
                throw new Error("No file provided");
            }
    
            const formData = new FormData();
            formData.append('image', file);
    
            console.log("Uploading image...");
    
            const response = await AuthAPI.profilePostImage(formData);  // Correctly passing FormData
    
            console.log("Full image upload response:", response);
    
            if (response?.path) {
                toastr.success("Image uploaded successfully");
                console.log("Uploaded image path:", response.path);
                return response.path;
            } else {
                console.error("Image upload failed: No path in response");
                toastr.error("Image upload failed. Please try again.");
                return null;
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            toastr.error("An error occurred during image upload. Please try again.");
            return null;
        }
    };
    
    
    
const postEditProfile=async(body)=>{
 try{
    const response=await AuthAPI.postProfileEdit(body);
    return response;
 } 
 catch(error){
    console.error("Error getting list",error)
 }
}

    return {
        imagePost,
        postEditProfile,
        }

    }

    export default useHook
