import React, { useEffect, useState } from 'react';
import { Main } from './style';
import useHook from './usehook';
import Data from './city.json'
import toastr from 'toastr';
const AdditionalInformation = ({ vendorData, editId }) => {
  const { UpdateAdditionalInfo } = useHook();
  const [formData, setFormData] = useState({
    description: '',
    aboutShop: '',
    address1: '',
    address2: '',
    state: '',
    city: '',
    zipCode: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validate = () => {
    const newErrors = {};
    if (!formData.description) newErrors.description = 'Description is required';
    if (!formData.aboutShop) newErrors.aboutShop = 'About Shop is required';
    if (!formData.address1) newErrors.address1 = 'Address Line 1 is required';
    if (!formData.state) newErrors.state = 'State is required';
    if (!formData.city) newErrors.city = 'City is required';
    if (!formData.zipCode) newErrors.zipCode = 'Zip Code is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (validate()) {
      console.log('Form Submitted', formData);
      // Perform the submission logic here
      const params = {
        description: formData.description,
        aboutShop: formData.aboutShop,
        addressLine1: formData.address1,
        addressLine2: formData.address2,
        state: formData.state,
        city: formData.city,
        zip: formData.zipCode,
      }
      try {
        const response = await UpdateAdditionalInfo(editId, params);
        if (response?.error) {
          toastr.error(response.error); 
      } else {
          toastr.success("Vendor Business info saved successfully");
      }

      } catch (error) {
        console.error("error updating business info")
      }

    } else {
      console.log('Form has errors');
    }
  };
  const [filteredCities, setFilteredCities] = useState([]);
  useEffect(() => {

    if (vendorData) {
      setFilteredCities([{name:vendorData.businessInfo?.city}]);
      console.log("filtered city",filteredCities)
      const singleCity=filteredCities[0]
      console.log(singleCity?.name)
      setFormData({
        description: vendorData.businessInfo?.description,
        aboutShop: vendorData.businessInfo?.aboutShop || '',
        address1: vendorData.businessInfo?.addressLine1 || '',
        address2: vendorData.businessInfo?.addressLine2 || '',
        state: vendorData.businessInfo?.state || '',
        city: vendorData.businessInfo?.city,
        // city: vendorData.businessInfo?.city || '',
        zipCode: vendorData.businessInfo?.zip || '',
      });
    console.log("selected by default city is ",formData.city)
    console.log("selected by default city is ",vendorData.businessInfo?.city)
    
    }
 

  }, [vendorData]);

  useEffect(() => {
    console.log("Updated city is: ", formData.city);
  }, [formData]);

  const handleStateChange = (e) => {
    const stateId = e.target.value;  // Get the selected state's name
    setFormData((prevData) => ({
      ...prevData,
      state: stateId,
      city: '',  // Reset city when a new state is selected
    }));
    
    const selectedState = Data.states.find(state => state.name === stateId);
    setFilteredCities(selectedState ? selectedState.cities : ''); 
  };
  

  return (
    <>
      <Main>
        <div className='main-container'>
          <h5>Additional Business Information</h5>
          <div className='information-container'>
            <div className='row1'>
              <div className='col-description' >
                <label htmlFor="Description">Description</label>
                <textarea name="description" value={formData.description} id="Description1" placeholder='Enter Description' rows={3} className='description-area' onChange={handleChange}></textarea>
                {errors.description && <p className="error-text">{errors.description}</p>}
              </div>
              {console.log("data is ", Data)}
              <div className='col-description'>
                <label htmlFor="aboutShop">About Shop</label>
                <textarea name="aboutShop" value={formData.aboutShop} placeholder='Enter About Shop' className='description-area' rows={3} id="" onChange={handleChange}></textarea>
                {errors.aboutShop && <p className="error-text">{errors.aboutShop}</p>}
              </div>
            </div>
            <div className='row2 mt-2'>
              <div className='col-description'>
                <label htmlFor="address1">Physical Address (Address Line 1)</label>
                <input type='text' value={formData.address1} name="address1" className='address-input' placeholder='Enter Physical Address' onChange={handleChange} />
                {errors.address1 && <p className="error-text">{errors.address1}</p>}
              </div>
              <div className='col-description'>
                <label htmlFor="address2">Physical Address (Address Line 2)</label>
                <input type='text' name='address2' value={formData.address2} className='address-input' placeholder='Apartment,unit etc' onChange={handleChange} />
              </div>
            </div>

            <div className='row3 mt-2'>
              <div className='col-description'>
                <label htmlFor="state">State</label>
                <select name="state" onChange={handleStateChange}
                  className='select-input'
                  value={formData.state}>
                  <option value="">Select State</option>
                  {Data.states.map(state => (
                    <option key={state.id} value={state.name}>{state.name}</option>
                  ))}
                </select>
                {errors.state && <p className="error-text">{errors.state}</p>}
              </div>
              <div className='col-description'>
                <label htmlFor="city">City</label>
                <select name="city" onChange={handleChange}
                  className='select-input'
                  value={formData.city} >

                  <option value="">Select City</option>
                  {filteredCities.map(city => (
                    <option key={city?.name} value={city?.name}>{city?.name}</option>
                  ))}
                </select>
                {console.log("iltered cities",filteredCities)}
                {errors.city && <p className="error-text">{errors.city}</p>}
              </div>
            </div>
            <div className='row4 mt-2'>
              <label htmlFor="Zip Code">Zip Code</label>
              <input type="number" className='zip-code' name='zipCode' placeholder='Enter Zip Code' value={formData.zipCode} onChange={handleChange} />
              {errors.zipCode && <p className="error-text">{errors.zipCode}</p>}
            </div>

          </div>
          <div className='mt-2 button-container'>
            <button className='vendor-btn' onClick={handleSubmit}>Update</button>
          </div>
        </div>
      </Main>

    </>
  )
}

export default AdditionalInformation
