import React, { useState } from "react";
import { IoCloseSharp } from "react-icons/io5";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  Button,
  TextareaAutosize,
} from "@mui/material";
import useHook from "./usehook";

const VerificationModal = ({id, open, onClose }) => {
    const {postVerification}=useHook();
  const [reason, setReason] = useState();
  const [otherReason, setOtherReason] = useState("");
  const [errorMessage, setErrorMessage] = useState("");  

  const handleReasonChange = (event) => {
    setReason(event.target.value);
    setErrorMessage(""); 
  };

  const handleOtherReasonChange = (event) => {
    setOtherReason(event.target.value);
  };

  const handleConfirm =async () => {
     // Validation: If unverified, reason must be provided
     if (reason === "false" && !otherReason) {
      setErrorMessage("Please provide a reason for unverification.");
      return;
    }

    // Prevent API call if no option is selected
    if (!reason) {
      setErrorMessage("Please select a verification type.");
      return;
    }
    // Handle confirmation logic here

    console.log("Selected reason:", reason);
    console.log("Additional reason:", otherReason);
    const params= {
        "verify": reason === "true",
        "reason": otherReason,
      }
      console.log("verify is",reason)
    try{
        const response=await postVerification(id,params);
        console.log("response post verification is",response);
        onClose();
    }catch(error){
        console.error("Error in changing Account status")
    }

    
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: 24,
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography className="d-flex align-items-center justify-content-between mx-3 my-1">
        <Typography variant="h6" component="h2">
        </Typography>
        <Typography variant="h6" component="h2">
          Verification
        </Typography>

          <IoCloseSharp style={{cursor:"pointer",fontSize:"20px"}} onClick={onClose}/>
        </Typography>
      <div className="p-4">
      <FormControl fullWidth>
          <select style={{border:"1px solid #E2E8F0",outline:"none",height:"48px",paddingLeft:"10px",borderRadius:"5px"}}
            placeholder="Select Verification Type"
            value={reason}
            onChange={handleReasonChange}
            label="Select a Reason"
          >
            <option value="">Select Verification Type</option>
            <option value={true}>Verify</option>
            <option value={false}>Unverified </option>
          </select>
        </FormControl>
 {/* Only show textarea if "Unverified" is selected */}
 {reason === "false" && (
            <>
              <Typography mt={2} mb={1}>
                Reason:
              </Typography>
              <TextareaAutosize
                minRows={4}
                placeholder="Enter reason here..."
                style={{ width: "100%", padding: "10px", borderRadius: "4px" }}
                value={otherReason}
                onChange={handleOtherReasonChange}
              />
            </>
          )}

          {/* Display error message if any */}
          {errorMessage && (
            <Typography color="error" mt={1}>
              {errorMessage}
            </Typography>
          )}

        <button style={{backgroundColor:"#156A3D",borderRadius:"5px",height:"48px",width:"100%",color:"white",marginTop:"10px"}}
          onClick={handleConfirm}
          fullWidth
          sx={{ mt: 3 }}
        >
          Confirm
        </button>
      </div>
       
      </Box>
    </Modal>
  );
};

export default VerificationModal;
