// // import React from 'react'
// // // import Pagination from '@mui/material/Pagination';
// // import Stack from '@mui/material/Stack';
// // import { Main } from './style';

// // const Pagination = () => {
// //     return (
// //         <Main>

// //             <Stack spacing={3}>
// //                 {/* <Pagination count={10} shape="rounded" /> */}

// //                 <Pagination count={50} variant="outlined" shape="rounded" />
// //             </Stack>
// //         </Main>

// //     )
// // }

// // export default Pagination


// import React from "react";
// import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
// import { Main } from "./style";

// const PaginationRounded = ({ count, page, onChange }) => {
//   console.log(count);
//   const getShowingRange = () => {
//     const startIndex = (currentPage - 1) * 10 + 1;
//     const endIndex = Math.min(currentPage * 10, userlength); // Use userlength for accurate end index
//     return `Showing ${startIndex}-${endIndex} of ${userlength}`;
//   };
//   return (
//     <Main className="">
//       <div>
//         <p className="showing m-0">
//           Showing page {page} of {count}
//         </p>
//       </div>
//       <div>
//         <Stack spacing={2}>
//           <Pagination
//             count={count}
//             page={page}
//             onChange={onChange}
//             variant="outlined"
//             shape="rounded"
//           />
//         </Stack>
//       </div>
//     </Main>
//   );
// };

// export default PaginationRounded;


import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { Main } from "./style";

const PaginationRounded = ({ count, page, onChange }) => {
  // Function to display the current page out of the total pages
  const getShowingRange = () => {
    return `Showing page ${page} of ${count}`;
  };

  return (
    <Main>
      <div>
        <p className="showing m-0">{getShowingRange()}</p>
      </div>
      <div>
        <Stack spacing={2}>
          <Pagination
            count={count}
            page={page}
            onChange={onChange}
            variant="outlined"
            shape="rounded"
          />
        </Stack>
      </div>
    </Main>
  );
};

export default PaginationRounded;
