import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Main } from './style';
import { IoMdClose } from "react-icons/io";
import useHook from './usehook';

import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};
const style={
    bgcolor: 'background.paper',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    boxShadow: 24,
    borderRadius: '20px 20px 10px 10px',
    outline: 'none',
    "@media (max-width: 585px)": {
    width: 400,
    height: "auto",
    // padding: 2,
  },
}
const WithdrawRequest = ({ open, onClose, currentItem }) => {
    const { postWithdraw, imagePost } = useHook()
    // const [selectedFile, setSelectedFile] = React.useState(null);
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
    };

    const [imagePath, setImagePath] = React.useState(''); // State to store the image path
    const fileInputRef = React.useRef(null);
    const [fileName, setFileName] = React.useState('');
    const handleUploadClick = async (event) => {
        event.preventDefault();

        if (fileInputRef.current && fileInputRef.current.files.length > 0) {
            const file = fileInputRef.current.files[0];
            setFileName(file.name);
            try {
                const path = await imagePost(file); // Upload the image
                setImagePath(path); // Store the image path in state
                console.log('File uploaded successfully. Path:', path);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        } else {
            console.error('No file selected or file input is not correctly bound.');
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Open file dialog
        }
    };

    const handleSendData = async () => {
        const id = currentItem.id
        const formData = {
            "image": imagePath,
        }
        console.log("formdata of withdraw posting is:", formData)
        try {
            const response = await postWithdraw(id, formData)
            if (response.status === true) {
                toastr.success("Money request sent successfully");
                onClose();
                setImagePath('')
                setFileName('')
            } else {
                // Assuming response.errors is an array of error messages
                let errorMessage = "Not enough balance in venodr wallet";
                if (Array.isArray(response.errors) && response.errors.length > 0) {
                    // Join all errors in the array into a single string
                    errorMessage = response.errors.join(', ');
                }
                toastr.error(errorMessage);
            }
            console.log("withdraw post", response)
        } catch (error) {
            console.error('Error in withdraw posting', error)
        }
    }
const handlecloseModal=()=>{
    onClose();
    setImagePath('')
    setFileName('')
}

    return (
        <>

            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="withdraw-container">
                    <Main>
                        <Typography className='header-container px-2 py-2'>
                            <Typography>

                            </Typography>
                            <Typography variant='p' className='header-font text-white'>
                                Withdraw Request
                            </Typography>
                            <IoMdClose className='close-icon' onClick={handlecloseModal} />
                        </Typography>
                        <div className='px-3 my-3 mx-3'>
                            <div className='amount-row mt-2'>
                                <div className='amount-container gap-2'>
                                    <span className='font-change'>Amount ID</span>
                                    <span className='ps-3 font'> {currentItem.id}</span>
                                    {/* <input type='text' className='input-background' value={currentItem.id} disabled /> */}
                                </div>
                                <div className='requested-date-container gap-2'>
                                    <span className='font-change'>Requested Date</span>
                                    <span className='ps-3 font'> {formatDate(currentItem.createdAt)}</span>
                                    {/* <input type='text' className='input-background' value= disabled /> */}
                                </div>
                            </div>
                            <div className='vendor-row mt-3 gap-3'>
                                <div className='vendor-name'>
                                    <span className='font-change'>Vendor Name</span>
                                    <span className='font' > {currentItem.user?.name}</span>
                                    {/* <input type='text' className='input-background' value={currentItem.user?.name} style={{ width: "250px" }} disabled /> */}
                                </div>
                                <div className='amount-number'>
                                    <span className='font-change'>Amount</span>
                                    <span className='font'> {currentItem.amount}</span>
                                    {/* <input type='text' className='input-background' value={currentItem.amount} style={{ width: "150px" }} disabled /> */}
                                </div>
                                <div className='iban-number'>
                                    <span className='font-change'>IBAN  Number</span>
                                    <span className='font'> {currentItem.account?.accountNumber}</span>
                                    {/* <input type='text' className='input-background' value={currentItem.account?.accountNumber} style={{ width: "200px" }} readOnly /> */}
                                </div>
                            </div>
                            <div className=' mt-2'>
                                <div className='d-flex flex-column w-100'>
                                    <span className='font-change'>Upload Transaction Image</span>
                                    <div className='d-flex flex-row align-items-center w-100 gap-2'>
                                        <input
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            onChange={handleUploadClick}
                                        />
                                        {imagePath && (
                                            <div className='d-flex flex-row align-items-center gap-2'>

                                                <img src={`${process.env.REACT_APP_MEDIA_URL}${imagePath}`} alt="Uploaded" style={{ width: "60px", height: "48px" }} />
                                            </div>
                                        )}
                                        <input type='text' className='input-background w-100' value={fileName} disabled />

                                        <button
                                            className='upload px-3'
                                            onClick={handleButtonClick}
                                        >
                                            Upload Image
                                        </button>
                                        {/* Display the uploaded image */}
                                    </div>



                                </div>
                            </div>
                            <button className='approved mt-4' onClick={handleSendData}>Approved</button>
                        </div>
                    </Main>
                </Box>
            </Modal>

        </>
    )
}

export default WithdrawRequest
