import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  .yellow_border {
    border-bottom: 6px solid #ffbb02;
    padding: 6%;
    margin-inline: 10%;
  }
  .verifyImg {
    width: 25%;
  }
  .heading {
    font-family: "Plus Jakarta Sans";
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #0f172a;
  }
  .welcome_Content {
    font-family: "Plus Jakarta Sans";
    font-size: 14px;
    font-weight: 400;
    color: #64748b;
  }
  .SignIn_Button {
    background-color: #0c3a2d;
    color: white;
    width: 60%;
    border-radius: 10px;
    font-family: "Plus Jakarta Sans";
    height: 45px;
    margin-bottom: 10px;
  }
  .recieve {
    font-family: "Plus Jakarta Sans";
    font-weight: 500;
    font-size: 14px;
    color: #0f172a;
  }
  .resend {
    font-size: 14px;
    font-weight: 700;
    font-family: Inter;
    color: #ffbb02;
  }
  .otp-input {
    width: 56px;
    border-radius: 4px;
    height: 50px;
    border: 1px solid #e2e8f0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 23px;
    font-family: "Inter";
    font-size: 20px;
  }
  .gap {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding-bottom: 22px;
  }
  .input {
    width: 60%;
    height: 45px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    color: black;
    padding: 9px;
    outline: #ffbb02;
    font-family: "Inter";
    font-size: 16px;
  }
  .error {
    color: red;
    font-family: inter;
  }
`;
