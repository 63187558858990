import styled from "styled-components";
export const Main = styled.div`
width: 100%;
height: 84vh;
background-color: var(--all-background);
.transfer-img{
width: 20px;
height: 20px;
}
.transfer-icon{

  /* background-color: #0C3A2D; */
  background-color: #ff8f50;
  padding: 6px;
  border-radius: 6px;
  cursor: pointer;
}
.container-fluid{
    height: 100%;
}
.edit-icon{
    background-color: #FF8F50;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.waleed-margin{
    padding-top: 10%;
}
.withdraw{
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    padding-block: 20px;
}
.input-area{
    width: 100%;
}
.input{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    outline: none;
}
.input2{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    outline: none;
}
.select1{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    outline: none;
}
.select2{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    outline: none;
}
.date{
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #1C1C27;
}
.date-input{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    gap: 88px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808D9E;
    padding: 10px;
    outline: none;
}
.flex{
    display: flex;
    gap: 10px;
}
.filter{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: white;
}
.reset{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #E2E8F0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: #808D9E;
}
.create-vendor{
  display: flex;
  flex-direction: row;
}
.search-vendor{
  width:70%
}
.select-status{
  width: 30%;
}

@media (max-width:992px) {
.container-vendor{
  display: flex;
  flex-direction: column;
}
.button-container{
  width: 100% !important;
}
.create-vendor{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.search-vendor{
  width:100%
}
.select-status{
  width: 100%;
}
.input2{
  width: 100%;
}
.select1{
  width: 100%;
}


}
/* .search-name{
    width: 655px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 13px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    outline: none;
}
.status{
    width: 389px;
    height: 56px;
    border-radius: 5px;
    border: 1px solid #E2E8F0 ;
    padding: 13px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #515151;
    outline: none;
} */
`

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  background-color: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  overflow: hidden;
  .no-record{
  width: 100%;
    background-color: white;
    padding: 12px;
    text-align: center;
    /* font-weight: 600; */
    font-size: 16px;
    color: red !important;
    font-family: 'Inter';
    border-bottom: none;
}

  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }

  th {
    /* background-color: #f8f9fa; */
    font-weight: 600;
    color: #252525;
  }

  td {
    font-family: Inter, sans-serif;
    font-size: 14px;
    color: #515151;
  }

  /* tr:hover {
    background-color: #f1f5f9;
  } */

  img {
    border-radius: 50%;
  }
`;



export const TableContainer = styled.div`
  /* margin-top: 10%; */
  overflow-x: auto;

  .status{
  width: 65px;
    height: 28px;
    border-radius: 5px;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #FF0000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.status-approved{
   width: 82px;
   height: 24px;
   border-radius: 5px;
   background-color: #209A421A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #209A42;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
   text-transform: capitalize;
}
.status-pending{
    width: 82px;
   height: 24px;
   border-radius: 5px;
   background-color: #FF8F501A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF8F50;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
   text-transform: capitalize;
}



`;