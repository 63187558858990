import React, { useState } from 'react';
import { Box, Modal, Typography, TextField, Button } from '@mui/material';
import { styled } from '@mui/system';
import { Main } from './style';
import { IoMdClose } from "react-icons/io";
import { AdminLotusPRO } from 'assets/img';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    outline: 'none',
    borderRadius: "12px",
    borderTopLeftRadius: '20px',  // Apply border to top-left corner
    borderTopRightRadius: '20px', // Apply border to top-right corner
    boxShadow: 24,
};


const EditVendorProfile = ({ open, onClose, singelVendor }) => {
    const [profileData, setProfileData] = useState({
        name: '',
        age: '',
        email: '',
        phone: '',
        dateOfBirth: '',
    });


    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };
    // function formatDate(dateString) {
    //     const date = new Date(dateString);

    //     // Format the date to 'DD MMM YYYY'
    //     const options = { day: '2-digit', month: 'short', year: 'numeric' };

    //     return date.toLocaleDateString('en-GB', options);
    // }
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
    };


    return (
        <div>
            <Modal open={open} onClose={onClose}>
                <Box sx={style}>
                    <Main>
                        <Typography className='header'>
                            <Typography variant="h6">Personal Details</Typography>
                            <IoMdClose className='close-icon' onClick={onClose} />
                        </Typography>
                        <div className='img-container'>
                            <img
                                src={`${process.env.REACT_APP_MEDIA_URL}${singelVendor.profile_pic}`} // Placeholder, replace with your image path or state
                                alt="Profile" className='img-profile'
                                
                            />
                            <span className='vendor-name'>{singelVendor.name}</span>
                        </div>
                        <div className='main-container'>
                            <div className='profile-container'>
                                <span className='column'>
                                    <label className='lablel-text'>Email</label>
                                    <span className='user-detail'>{singelVendor.email}</span>
                                </span>
                                <span className='column'>
                                    <label className='lablel-text'>Phone</label>
                                    <span className='user-detail'>{singelVendor.phone}</span>
                                </span>
                                <span className='column'>
                                    <label className='lablel-text'>Gender</label>
                                    <span className='user-detail'>{singelVendor.gender}</span>
                                </span>

                                <span className='column'>
                                    <label className='lablel-text'>Date of Birth</label>
                                    <span className='user-detail'>{formatDate(singelVendor.dob)}</span>
                                </span>
                            </div>
                        </div>
                    </Main>
                    {console.log("single vendor", singelVendor)}

                </Box>
            </Modal>
        </div>
    );
};

export default EditVendorProfile;
