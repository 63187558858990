import styled from "styled-components";

export const Main = styled.div`

.font-change{
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    font-family: poppins;
    color: rgba(37, 37, 37, 1);
}
.font{
    color: Gray;
    font-family: inter;
    font-weight: 400;
font-size: 16px;
}
.path-input{
    height:48px;
    width: 100%;
}
.header-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color:#FFBB02;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}
.close-icon{
    color: white;
    font-size: 20px;
    cursor: pointer;
}
.header-font{
    font-size: 20px;
    font-family: Poppins;
    font-weight: 600;
}
.amount-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.amount-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
}
.requested-date-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
}
.vendor-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-block: 30px;
}
.input-background{
    background-color: #e3e3e3;
    height: 48px;
    border: none;
    border-radius: 5px;
    position: relative;
    outline: none;
    padding: 0px 13px;
}
.approved{
    height: 48px;
    border: none;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    background-color: #0C3A2D;
    color: white;
}
.upload{
    height: 40px;
    border: none;
    border-radius: 5px;
    text-align: center;
    background-color: #0C3A2D;
    color: white;
    position: absolute;
    right: 37px;
}
.vendor-name{
    display: flex;
    flex-direction: column;
}
.amount-number{
    display: flex;
    flex-direction: column;
}
.iban-number{
    display: flex;
    flex-direction: column;
}

`