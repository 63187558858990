import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, IconButton, Input, Button } from '@mui/material';
import { IoMdClose } from "react-icons/io";
import useHook from './usehook';
import { Main } from './style';
import {ImageDummy} from 'assets/img/index'
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
};

function SubCategoryModal({ open, onClose, parentState, itemSub,subState }) {
  const { imagePost, subCategoriesUpdate, categoriesPost } = useHook();

  const [parentID, setParentID] = useState('');
  const [errors, setErrors] = useState({});
  // const [loader, setloader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentCategoryName, setCurrentCategoryName] = useState('');

  useEffect(() => {
    console.log("parent state is",parentState)
   
    // setEditID(itemSub.id)
if(itemSub){
  setEditID(itemSub)
  console.log("item sub is ",itemSub)
  console.log("sub state is ",subState)
  setSubCatagoryImage(subState.image)
  setSubCategoryName(subState.name)

console.log("parent state",parentState)
  setParentID(parentState.id)
  setSelectedImage(parentState.image)
  setCurrentCategoryName(parentState.name)
}else{
  setParentID(parentState.id)
  setSelectedImage(parentState.image)
  setCurrentCategoryName(parentState.name)
}

    
  }, [open])

  const validateFields = () => {
    const newErrors = {};
    if (!subCategoryName) newErrors.subCategoryName = "Sub Category Name is required";
    if (!subCatagoryImage) newErrors.subCatagoryImage = "Image required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const [subCatagoryImage, setSubCatagoryImage] = useState(null)

  const handleSubCatagoryImageChange = async (e) => {
    const file = e.target.files[0];
    try {
      const imagePath = await imagePost(file);
      setSubCatagoryImage(imagePath);
      setErrors((prevErrors) => ({
        ...prevErrors,
        subCatagoryImage: "",
      }));
    } catch (error) {
      console.error("Error in handleImageChange: ", error);
      toastr.error("An error occurred during image upload");
    }
  };
  const handleSubCatagoryImagePickerClick = () => {
    document.getElementById("imagePickerSub").click();
  };
  const [subCategoryName, setSubCategoryName] = useState('')
  const [editID, setEditID] = useState('')


  const handleUpdateSubCategory = async () => {
    if (!validateFields()) return;
    const params = {
      name: subCategoryName,
      image: subCatagoryImage,
      parentId: parentID,
      // "isActive": true
    };
    console.log("params", params);
    console.log("edit id is",editID)
    try {
      const response = await subCategoriesUpdate(editID, params);
      console.log("response is", response);
      setSubCatagoryImage('');
      setSubCategoryName('')
      setEditID('')
      onClose();
      toastr.success("SubCategory updated successfully")
    } catch (error) {
      console.error("Sub categories Error", error);
    }
  };
  const handleSubCategory = async () => {
    if (!validateFields()) return;
    let formData = {
      name: subCategoryName,
      image: subCatagoryImage,
      parentId: parentID,
      isActive: true,
    };
    try {
      // setloader(true);
      await categoriesPost(formData);
      toastr.success("SubCategory created successfully")
      setSubCatagoryImage(null)
      setSubCategoryName('')
      setEditID('')
      onClose();
    } catch (error) {
      console.error("Error adding subcategory: ", error);
    } 
    // finally {
    //   setloader(false);
    // }
  };
const handleClose=()=>{
 
  setSubCatagoryImage(null);
  setSubCategoryName('');
  setEditID('');
  onClose();
}


  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Main>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            borderTopRightRadius: '20px',
            borderTopLeftRadius: '20px',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
          }}
        >
          <div className='modal-content'>
            <div className='background2 px-3 py-2'>
              <Typography id="modal-title" variant="h6" component="h2" className='style-category'>
                {editID ? 'Update Sub Category' : 'Add Sub Category'}
              </Typography>
              <IoMdClose onClick={handleClose} className='close-icon' />
            </div>
            <div className='container-padding'>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">Category</Typography>
              <Box display="flex" alignItems="center" gap="30px" sx={{ mt: 1 }}>
                <Box
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 100, height: 67, backgroundColor: '#F9F9F9', borderRadius: '50%', overflow: 'hidden' }}
                >
                  <input
                    type="file"
                    id="imagePicker"
                    accept="image/*"
                    style={{ display: 'none' }}
                  />
                  {selectedImage ? (
                    <>
                      <img
                      src={`${process.env.REACT_APP_BASE_URL}/${selectedImage}`}
                      alt="Selected"
                      style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
                    />
                    </>
                  
                  ) : (
                    <ImageDummy/>
                  )}
                </Box>
                <input type="text" className='input1' placeholder='Category Name'  value={currentCategoryName} />
              </Box>
            </Box>
            <div className='mt-3 w-100'>
              <Typography variant="body1">Write Sub Category Name</Typography>
              <div className='d-flex flex-row align-items-center justify-content-between mt-2'style={{gap:"20px"}} >
                <div className='d-flex flex-column align-items-center' style={{width:"100px"}}
                  onClick={handleSubCatagoryImagePickerClick}
                >
                  <input
                    type="file"
                    id="imagePickerSub"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleSubCatagoryImageChange}
                  />
                  {subCatagoryImage ? (
                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${subCatagoryImage}`}
                      alt="Sub Category"
                      className={subCatagoryImage ? "img" : "defaultimage"
                                                         }

                      />
                  ) : (
                    <ImageDummy style={{width:"60px",height:"60px"}}/>
                  )}
                  <div>
                  {errors.subCatagoryImage && (
                <Typography color="error" sx={{ fontSize:"10px" }}>{errors.subCatagoryImage}</Typography>
              )}
                  </div>
                </div>
              
                <div className="mt-2 w-100">
                  <input
                    type="text"
                    className="input2"
                    placeholder="Sub Category Name"
                    value={subCategoryName}
                    onChange={(e) => setSubCategoryName(e.target.value)}
                  />
                   {errors.subCategoryName && (
                      <Typography color="error"sx={{ fontSize:"10px" }}>{errors.subCategoryName}</Typography>
                    )}
                </div>
              </div>
            </div>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
              <button className='add-button2' onClick={itemSub? handleUpdateSubCategory :handleSubCategory}>
                {itemSub ? 'Update' : 'Add'}
              </button>
            </Box>
            </div>
          </div>

        </Box>
      </Main>

    </Modal>
  );
}

export default SubCategoryModal;
