import styled from "styled-components";

export const Main = styled.div`
width: 100%;
background-color: var(--all-background);
/* height: var(--height); */
height: 100vh;
/* @media (max-width:1460px) {
    
} */
/* padding: 0px 18px; */
.dashboard-name{
    padding-top: 20px;
    font-family: Inter;
font-size: 18px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0.20000000298023224px;
text-align: left;

}
.calender{
    background-color: #0C3A2D;
    padding:12px;
    border-radius: 5px 0px 0px 5px;
    /* width: 40px;
    height:50px; */
}
.to{
    color:rgba(128, 141, 158, 1);
    font-size: 12px;
    font-weight: 600;
    font-family: "Plus Jakarta Sans";
    line-height: 22px;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

/* Hide the calendar icon in Firefox */
input[type="date"]::-moz-focus-inner {
    border: 0;
}
.img-width{
    width: 32px;
    height: 32px;
}
.flex-dates{
    display: flex;
    width: 100%;
    height: 150px;
    gap: 1%;
    /* flex-wrap: wrap; */
    /* @media (max-w1idth: 660px) {
        display: flex;
            justify-content: center;
    } */
}
.flex-vendor{
    display: flex;
    margin-top: 0.5%;
    /* width: 100%; */
    gap: 1%;

    /* @media (max-width:1200px){
        display: flex;
        gap:20px;
        width: 450px;
        width: 50%;
        flex-direction: row;
        flex-wrap: wrap;
    } */
    
    /* @media (max-width: 660px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
            justify-content: center;
    } */
}
.flex{
    display: flex;
    gap: 2%;
    flex-wrap: wrap;
    @media (max-width: 660px) {
        display: flex;
            justify-content: center;
    }
}
.number{
    font-family: poppins;
    font-weight: 600;
    font-size: 32px;
    letter-spacing :0.5%;
    color: #252525;
    margin-top: 8px;
    float: right;
    padding: 12px 0px;
}
.para{
    font-family: poppins;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.5%;
    color: #9CA4AB;
    /* padding-top: 6%; */
}
.card-width{
    border: 1px solid #EDF2F7;
    width: 480px;
    height: 140px;
    border-radius: 12px;
    margin-top: 0.5%;
    flex-wrap: wrap;
@media (max-width:660px) {
    
}
}
.second-cards{
    
    width: 36px;
    height: 32px;
    flex-wrap: wrap;
}
.padding{
    padding-top: 15px;
}
.vendor-style{
    font-family: poppins;
    font-weight: 500;
    font-size: 14px;
    color: #9CA4AB;
}
.vendor-number{
  font-family: poppins;
  font-weight: 600;
  font-size: 20px;
  color: #252525;
}
.second-card{
display: flex !important;
    width: 100%;
    height: 130px;
    border-radius: 12px;
    border: 1px solid #EDF2F7;

    @media (max-width: 660px) {
        width: 312px;
    margin-top: 4%;
    }
    }

.third-card{
    width: 100%;
    height: 92px;
    border: 1px solid #EDF2F7;
    /* margin-top: 1%; */
   @media (max-width:660px) {
    width: 312px;
   }
}
.third-flex{
    justify-content: start;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.margin{
    margin-left: 8%;
}
.order-para{
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #1C1C27;
}
.order-number{
    font-family: Inter;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 0.2px;
    color: #1C1C27;
}
/* .order-flex{
    margin-top: 0.5%;
    display: grid;
    width: 100%;
    grid: auto / auto auto auto auto;
    gap: 13px;

} */
    .order-flex {
    margin-top: 1%;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr); /* 4 equal columns */
    gap: 13px;
    border-radius: 5px;
}
@media (max-width:1220px) {
    .order-flex{
        grid-template-columns: repeat(2, 1fr);
    }}
.order-img{
    width: 32px;
    height: 32px;
}
.SalesStatistics{
    font-family: inter;
    font-weight: 500px;
    font-size: 18px;
    color: #000000;
}
.sales{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #0C3A2D;
    color: white;
    font-family: inter;
    font-weight: 500;
    letter-spacing: 0.2px;
    border: 0px;
}
.Orders{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #FFBB02;
    color: white;
    font-family: inter;
    font-weight: 400;
    letter-spacing: 0.2px;
    border: 0px;
}
.walet-back{
    background-color: #0C3A2D;
    padding: 5px;
    border-radius: 10px 0px 0px 10px;
}
.wallet-main{
    border: 1px solid black;
    border-radius: 10px 0px 0px 10px;
    width: 296px;
    height: 38px;

}
.wallet-input{
    border: 0;
    outline: none;
    width: 100%;
    width: 115px; 
}
.to{
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 16px;
    color: #808D9E;
}

@media(max-width:480px){
    .flex-dates{
        margin-bottom: 110px !important;
    }
    /* flex-vendor */
.second-card {
    width: 100% !important;
    /* margin-top: 10px !important; */
    margin-bottom: 0%;
}

}
@media (max-width: 600px){
    height: 200vh;
    .dashboard-name{
    font-family: Inter;
font-size: 18px;
font-weight: 600;
line-height: 27px;
letter-spacing: 0.20000000298023224px;
text-align: left;

}
    /* flex dates */
.flex-dates{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100px;
    margin-top: 100px; 
    margin-bottom: 80px; 
}
.card-width{
margin-top: 2%;
}

/* flex-vendor */
.flex-vendor{
    display: flex;
        /* gap:100px; */
        margin-top: 20%;
        width: 100%;
        flex-wrap: wrap;
        padding: 0px;
        padding-bottom:10px;
}
.second-card {
    margin-top: 0px;
    width: 100% !important;
    padding: 0px;
    /* margin:0px; */
}

/* order-flex */
.order-flex{
    display: grid;
    width: 100% ;
    grid-template-columns: 1fr;
    background-color: var(--all-background);
}
.third-card{
    width:100%;
}
}
@media (max-width: 992px){
    .flex-vendor{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    gap: 10px;
    }
    .second-card {
        display: flex;
        flex-direction: row;
        width: 49%; /* Adjusted to ensure two cards fit in one row */
        box-sizing: border-box;
    }
    
}
`