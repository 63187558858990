import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, TextField, Button } from "@mui/material";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { Main } from "./style";
import { IoMdClose } from "react-icons/io";
import useHook from "./usehook";
// Styles for the modal
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "1022px",
    height: "90%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    border: "none",
    outline: "none",
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    "@media (max-width: 1640px)": {
        width: "1022px",
        height: "530px",
        // padding: 2,
      },  
      "@media (max-width: 1100px)": {
        width: "800px",
        height: "400px",
        // padding: 2,
      },  
      "@media (max-width: 850px)": {
        width: "700px",
        height: "400px",
        // padding: 2,
      },  
      "@media (max-width: 740px)": {
        width: "450px",
        height: "440px",
        // padding: 2,
      }, 
};

const Aboutus = ({ aboutModal, handleAboutModalClose }) => {
    const { updateAboutus, AboutusGet } = useHook();
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [aboutId, setAboutId] = useState();

  
    useEffect(() => {
        const handleAboutUs = async () => {
            const params = {
                type: "about_us",
            }
            try {
                const response = await AboutusGet(params);
                console.log("About us resposne", response);
                // Find the id where the type is about_us
                const aboutUsData = response.data.find(item => item.type === "about_us");
    
                // Set the about_us id in the state
                if (aboutUsData) {
                    setAboutId(aboutUsData.id);
                    setTitle(aboutUsData.title); // Set title from API response
                    setDescription(aboutUsData.description);
                }
            } catch (error) {
                console.error(error)
            }
        }
        handleAboutUs();
    }, [aboutModal])


    const handleSubmit = async () => {
        // Handle form submission logic here
        console.log("Title:", title);
        console.log("Description:", description);
        const params = {
            "title": title,
            "description": description,
            "image": "string",
            "type": "about_us"
        }
        try {
            const response = await updateAboutus(aboutId, params);
            console.log(response)
            handleClose();
        } catch (error) {
            console.error(error)
        }

    };
    const handleClose=()=>{
        handleAboutModalClose();
        setAboutId('');
        setTitle('');
        setDescription("");
    }


    return (
        <div>
            <Modal open={aboutModal} onClose={handleClose}>
                <Box sx={style}>
                    <Main>
                        <div className="heading py-2">
                            <p>
                                About Us
                            </p>
                            <div>
                                <IoMdClose className="close" onClick={handleClose} />
                            </div>
                        </div>

                        <div className="p-4">

                            <div className="title">
                                {/* Title Field */}
                                <span>Title</span>
                                <input type="text" placeholder="Introduction" value={title} onChange={(e) => setTitle(e.target.value)} />
                            </div>


                            <div className="description">
                                {/* Description Field with React Quill */}
                                <span>
                                    Description
                                </span>
                                <ReactQuill placeholder="Description"
                                    value={description}
                                    onChange={setDescription}
                                    theme="snow" className="custom-quill-editor"
                                />

                            </div>
                            {/* Submit Button */}
                            <div className="btn-container">
                                <button className="btn" onClick={handleSubmit}>
                                    Update
                                </button>
                            </div>

                        </div>
                    </Main>
                </Box>
            </Modal>
        </div>
    );
};

export default Aboutus;
