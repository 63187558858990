import styled from "styled-components";

export const Main = styled.div`

.dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    width: 90px;
    
  }

.header{
    background-color: #FFBB02;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    padding-left: 250px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding-top: 4px;
    padding-bottom: 4px;
    
}
.close-icon{
    font-size: 20px;
    cursor: pointer;
}
.container-body{
    margin-top: 20px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
}
.label-color{
    color: #808D9E;
    font-family: Inter;
font-size: 12px;
font-weight: 500;
line-height: 14.52px;
text-align: left;
margin-bottom: 4px;

}
.customerlabel{
    font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
text-align: left;

}
.row1{
    display: flex;
    flex-direction: row;
justify-content: space-between;
    
}
.direction1{
    display: flex;
    flex-direction: column;
}
.row2{
    margin-top: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.reason-container{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    
}
.reason{
    font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
letter-spacing: 0.02em;
text-align: left;
width: 100%;
margin-bottom: 5px;
}
.reason-field{
    
    background-color: #F9F9F9;
    color:#808D9E;
    font-family: Inter;
    width:100%;
    height: 48px;
    padding-left:15px;
    padding-top: 15px;
    padding-right: 6px;
font-size: 14px;
font-weight: 400;
line-height: 16.94px;
text-align: left;
margin-bottom: 5px;

}


@media screen {
    
}
`
export const TabContent = styled.div`
  padding: 20px;
  .comment-container{
    display: flex;
    flex-direction: column;
  }
  .comment{
    font-family: Inter;
font-size: 14px;
font-weight: 500;
line-height: 16.94px;
letter-spacing: 0.02em;
text-align: left;
color: #1C1C27;
margin-bottom: 5px;
  }
  .textarea{
    font-family: Roboto;
    width: 100%;
    height: 100px;
    padding-left: 10px;
    padding-top: 10px;
    min-height: 40px;
    max-height: 80px;
    resize: none;
    border: 1px solid #E2E8F0;
    outline: none;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: left;
background-color: #F9F9F9;

  }
  .btn-container{
    display: flex;
    justify-content: end;
    margin-top: 10px;
  }
  .vendor-pic{
    width: 60px;
    height: 60px;
  }
  .image-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
  .selected-images{
    display: flex;
    flex-direction: row;
    border-radius: 5px;
  }
  .thumbnail{
    width: 60px;
    height: 60px;
    border-radius: 5px;
  }
  .select-conatiner{
    width: 100%;
  }
  .select-input{
    appearance: none; /* Remove the default select arrow */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; 
    width: 295px;
height: 48px;
top: 26px;
padding: 8px 16px 8px 16px;
gap: 151px;
border-radius: 5px;
opacity: 0px;
outline: none;
border: 1px solid #E2E8F0;

  }
  .custom-arrow {
  position: absolute;
  top: 76%;
  right: 270px;
  transform: translateY(-50%);
  pointer-events: none;
  color: #808D9E; 
}
`;

export const Tab = styled.div`
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  color: ${({ active }) => (active ? "#1C1C27" : "#D9D9D9")};
  border-bottom: ${({ active }) => (active ? "2px solid #808D9E" : "none")};
  &:hover {
    color: #1C1C27;
  }
`;
export const TabsWrapper = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid #ccc;
`;

export const Send = styled.button`
  margin-top: 0px;
  /* padding: 10px 20px; */
  width: 150px;
  height:48px;
  background-color: #0C3A2D;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;

