import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};

const useHook = () => {


    const getNotifications = async (body) => {
        try {
            const response = await AuthAPI.getnotification(body);
            return response;
        }
        catch (error) {
            console.error("Error getting userlist", error)
        }
    }
    const getNotificationsRead = async (body) => {
        try {
            const response = await AuthAPI.readNotification(body);
            return response;
        }
        catch (error) {
            console.error("Error getting userlist", error)
        }
    }
    const SaveFCMToken = async (deviceId, token) => {
        try {
          const response = await AuthAPI.SaveToken(deviceId, token);
          console.log(response);
          if (response?.status) {
            // toastr.success("Token Saved successfully");
          } else {
            toastr.error("Token Saved failed");
          }
        } catch (error) {
          toastr.error("Notification error:", error);
        }
      };

    
    return {
        getNotifications,
        getNotificationsRead,
        SaveFCMToken,
    }

}

export default useHook;
