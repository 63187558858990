import styled from "styled-components";

export const Main = styled.div`
width: 100%;

.banner-button{
    width: 177px;
    height: 51px;
    border-radius: 5px;
    background-color: #FFBB02;
    font-family: poppins;
    font-weight: 700;
    font-size: 14px;
    color: #FFFFFF;
    border: 0px;
}


.modal-width{
    width: 685px;
    height: 490px;
}
@media (min-width: 576px){
.modal-dialog {
    max-width: auto;
    /* margin-right: auto; */
    /* margin-left: 343px; */
}
}
.padding{
   padding: 0px 247px;
}
.header{
    background-color: #FFBB02;
    color: white;
}
.padding-body{
    text-align: start;
    padding: 30px 74px;
}
.background-img{
    background-image: url(./img/banner/background.png);
    width: 517px;
    height: 151px;
    border-radius: 12px;
}
.img-width{
    height: 100%;
    width:517px;
    border-radius: 12px;
}
.custom-img-width{
    width: 50px;
    height: 50px;
}
.input{
    width: 518px;
    height: 52px;
    border: 0;
    background: #F9F9F9;
    font-family: poppins;
    font-weight: 500;
    font-size: 14px;
    color: #999999;
    padding: 20px;
    outline: none;
}
.add-button{
    width: 518px;
    height: 61px;
    border-radius: 5px;
    background-color: #0C3A2D;
    font-family: poppins;
    font-weight: 500;
    font-size: 19px;
    text-align: center;
    color: #FFFFFF;
}

`
