import styled from "styled-components";

export const Main = styled.div`
width: 100%;

.chart{
    width: 100% !important;
    height: 442px !important;
    /* overflow-x: scroll ; */
}

`