import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };

const useHook = () => {
const getUsers=async(params)=>{
 try{
    const response=await AuthAPI.userGet(params);
    return response;
 } 
 catch(error){
    console.error("Error getting userlist",error)
 }
}
const deleteUser=async(id)=>{
    try {
      const response = await AuthAPI.userDelete(id);  
      
      return response;
    } catch (error) {
      console.error(error);
      toastr.error("Error")
      return null;
    }
  }
  const userPutSwitches = async (id, status) => {
    try {
        const response = await AuthAPI.userPutSwitch(id, { status });
        toastr.success("status updated successfully");
        return response.data || [];
    } catch (error) {
        console.error('Error details:', error);
        return [];
    }
};



    return {
        // get return
        getUsers,deleteUser,userPutSwitches
        }

    }

    export default useHook
