import styled from "styled-components";

export const Main = styled.div`
width: 100%;
background-color: var(--all-background);
/* height: var(--height); */
height: 130vh;

.vendor-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.vendorPic{
    width: 80px;
    height: 80px;
    border-radius: 5px;
}
.invoice1{
    font-weight: 600;
    font-size: 18px;
}
.invoice{
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    margin: 0;
    padding-block: 20px;
    letter-spacing: 0.2px;
}
.invoice2{
    margin-top: 0px;
    padding-top: 0px;
    font-family: poppins;
    font-weight: 600;
    font-size: 18px;
    color: #252525;
}
.status{
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: #000000;
    
}
.status1{
    font-family: inter;
    font-weight: 600;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #000000;
    
}
.process-button{
    width: 86px;
    height: 24px;
    border-radius: 31px;
    background-color: #06D36621;
    font-family: inter;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.2px;
    text-align: center;
    color: #156A3D;
    border: 0px;
}
.style-jenifer{
    font-family: Plus Jakarta Sans;
    font-weight: 600;
    font-size: 20px;
    color: black;
    margin-bottom: 16px;
}
.style-payment{
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
    color: black;
}
.style-address{
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: black;
}
.style-date{
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    color: black;
}
.style-pay{
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: black;
}
.center{
    text-align: center;
}
.style-table{
    border-bottom: 1px solid #F1F5F9;
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #0F172A;
}
.amount{
    color: #FF0000;
    font-weight: 700;
    font-size: 23px;
    letter-spacing: 0.2px;
}
.table-head{
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #394B42;
}

`