import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};

const useHook = () => {
    // material post
    const MaterialPost = async (body) => {
        try {
            const response = await AuthAPI.materialPost(body);
            console.log("material response", response.data)
            // toastr.success('Material added successfully')   
        }
        catch (error) {
            toastr.error('Error posting Material')
        }
    }
    const materialGet = async (body) => {
        try {
            const response = await AuthAPI.materialGet(body);
            return response.data || [];
        }
        catch (error) {
            console.error('Error posting Material')
            return [];
        }
    }
    const materialDelete = async (params) => {
        try {
            const response = await AuthAPI.materialDelete(params);
            return response;
        }
        catch (error) {
            console.error('Error deleting material')
            return [];
        }
    }
    const postPassword = async (body) => {
        try {
            const response = await AuthAPI.userPasswordChanged(body);
            console.log(response.data)
            return response
            // toastr.success('Password changed successfully')
        }
        catch (error) {
            toastr.error('Error occured. Try Again Later...')
        }
    }

    return {
        // Post
        MaterialPost,

        // Get
        // colorGet,
        materialGet,

        // Delete
        materialDelete,
        // password Post
        postPassword

    }
}
export default useHook