import styled from "styled-components";
export const Main=styled.div`
background-color: var(--all-background);
padding-top: 20px;
padding-left: 20px;
padding-right: 30px;
width: 100%;
height: 100vh;

.card-list{
display: flex;
flex-direction: column;
gap:12px
}
.card-bg{
    background-color: white;
    width: 100%;
    padding: 10px;
}
.card-body{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.add-btn{
    width: 170px;
    height: 50px;
    border-radius: 12px;
    background-color: #156A3D;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: 0px;
}
.edit-btn{
    background-color: #FF8F50;
    width: 170px;
    height: 50px;
    border-radius: 12px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: 0px;
}


@media (max-width:580px) {
    .card-body{
        flex-wrap: wrap;
        justify-content: flex-start;
        display: flex;
        flex-direction: column;
    }
}

`
