import styled from "styled-components";

export const Main = styled.div`
width: 100%;
background-color: var(--all-background);

.admin-container{
width: 100%;
overflow-x: auto;
}
.material-background{
    background-color:#F9F9F9;
    padding: 10px;
    border-radius: 4px;

}
.card-width{
    width: 332px;
    height: 155px;
    border-radius: 10px;
    background-color: #FFFFFF;
    border: 0px;
}
.color{
    width: 40px;
    height: 40px;
    background-color: #F9F9F9;
    padding: 8px;
    border-radius: 5px;
}
.total-color{
    font-family: inter;
    font-weight: 900;
    font-size: 14px;
    margin: 0px;
    color: #000000;
    padding: 10px 0px;
}
.numb-color{
    font-family: inter;
    font-weight: 500;
    font-size: 36px;
    margin: 0px;
    color: #000000;
}
.card-flex{
    display: flex;
    gap: 25px;
}
.padding{
    padding: 50px 60px;
}
.label{
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #252525;
}
.create-new{
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    color: #252525;
    padding-top: 70px;
}
.input-password{
    width: 522px;
    height: 52px;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    outline: none;
    padding: 13px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #252525;
}
.password-flex{
    display: flex;
    gap: 80px;
}
.notifications{
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: #000000;
}
.ios-flex{
    display: flex;
    gap: 110px;
    padding-top: 16px;
}
.enable{
    font-family: inter;
    font-weight: 500;
    font-size: 18px;
    color: #66707A;
}
.icon{
    position: absolute;
    top: 17px;
    right: 15px;
    color: #64748B;
}
.update-btn{
    width: 161px;
    height: 48px;
    border-radius: 12px;
    background-color: #156A3D;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: 0px;
    cursor: pointer;
}
.error-password{
    color: red;
}
.cards-management{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 12px;
}

@media (max-width:992px) {
    .cards-management{
        display: flex;
        flex-direction: column;
        width: 100%;
    }
 .card-width{
    width: 100%;
 }
 .input-password{
    width: 100% !important;
 }
 .password-flex{
width: 100%;
   flex-wrap: wrap;
}
.password-container{
    width: 100%;
}
.passwordDiv{
    width: 100%;
}
}


`
export const Update = styled.button`
display: flex;
justify-content: end;
align-items: center;
.update-btn{
    width: 170px;
    height: 50px;
    border-radius: 12px;
    background-color: #156A3D;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: #FFFFFF;
    border: 0px;
    cursor: pointer;
}
`
export const Input = styled.input`

    height: 56px;
    width: 390px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #F9F9F9;
    background-color: #F9F9F9;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding: 13px;

`
export const InputMaterial = styled.input`

    height: 56px;
    width: 100%;
    border-radius: 10px;
    outline: none;
    border: 1px solid #F9F9F9;
    background-color: #F9F9F9;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding: 13px;

`
export const InputDate = styled.input`

    height: 56px;
    width: 390px;
    border-radius: 10px;
    outline: none;
    border: 1px solid #F9F9F9;
    background-color: #F9F9F9;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    padding: 13px 290px 13px 13px;

`
export const AddButton = styled.button`

    height: 50px;
    width: 170px;
    border-radius: 12px;
    outline: none;
    background-color: #156A3D;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 700;
    color: #FFFFFF;
    border: 0px;

`
export const Card = styled.div`

    height: 200px;
    width: 796px;
    border-radius: 10px;
    outline: none;
    background-color: #F9F9F9;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    border: 0px;
    overflow: scroll;
    /* overflow-y: scroll; */

    .color-card{
        width: 796px;
        height: 100px;
        overflow-y: scroll;
    }
    .color-table{
        width: 100%;
        height: 40px;
        overflow: auto;
    }
    .color-tbody{
        /* height: 40px; */
        height: 100px;
        overflow-y: scroll;
    }


`

