import styled from "styled-components";

export const Main = styled.div`
  background-color: var(--all-background);
  height: -webkit-fill-available;

  .card-border {
    border-radius: 10px;
    border: 0px;
  }
  .img-container{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  .comment-images1{
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
  }
  .comment-images{
    width: 80px;
    border-radius: 5px;
    cursor: pointer;
  }
  .back-button {
    width: 83px;
    height: 47px;
    border-radius: 12px;
    background-color: #156a3d;
    font-family: inter;
    font-weight: 700;
    align-items: center;
    color: #ffffff;
    border: 0px;
    cursor: pointer;
  }
  .cards-parent {
    display: flex;
    width: 100%;
    margin-top: 15px;
    gap: 20px;
  }
  .first-card {
    width: 30%;
    background-color: beige;
  }
  .second-card {
    width: 70%;
    background-color: bisque;
  }
  .main-heading {
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-top: 5px;
  }
  .sub-heading {
    font-family: inter;
    font-weight: 500;
    font-size: 12px;
    color: #808d9e;
    margin-bottom: 0px;
    padding-bottom: 0px;

  }
  .date-time{
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .border-bottom-color {
    margin-top: 0px;
    border: 1px solid #E3E4E4;
  }
  .order-detail-card {
    border: 2px solid #e3e4e4;
    background-color: #ffffff;
    border-radius: 10px;
  }
  .padding {
    padding: 0px 15px;
  }
  .order-date {
    width: 50%;
  }
  .order-amount {
    width: 50%;
  }
  .order-details-flex {
    display: flex;
    gap: 40px;
    margin-top: 25px;
    width: 100%;
  }
  .evidence-img {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 127px;
    height: 32px;
    background-color: #e2e8f0;
    justify-content: center;
    padding: 10px;
    padding-top: 10px;
    border-radius: 16px;
  }
  .evidence-font {
    font-family: inter;
    font-weight: 500;
    font-size: 12px;
    color: #2e4d55;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .evidence-img-flex {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
  }
  .status {
    text-transform: capitalize;
    font-size: 14px;
    width: 107px;
    height: 25px;
    border-radius: 30px;
    padding: 10px;
    border: 1px solid #209a42;
    color: #209a42;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
  }
  .takeAction-button {
    width: 146px;
    height: 47px;
    border-radius: 12px;
    background-color: #156a3d;
    color: white;
    border: 0px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
  }
  .chat-name-container{
    margin-top: 12px;
    width: 200px;
  }
  .chat-name-heading {
   margin-bottom: 3px;
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    color: #333333;
  }
  .chat-flex {
    display: flex;
    gap: 20px;
    margin-top: 15px;
  }
  .img-con{
    z-index:100;
  }
  .under-img-border {
    border-left: 1px solid #808d9e;
    height: 50%;
    margin-left: 35px;
    color: white;
    position: absolute;
    top: 35px;
  }
  .chat-text {
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    /* margin-top: 5px; */
    padding: 10px 2px;
  }
`;
