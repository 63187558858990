import React, { useEffect, useState } from "react";
import { Main } from "./style";
import useHook from "./usehook";
import PaginationRounded from "components/pagination";
import Spinner from "components/loader";
import { getToken } from "firebase/messaging";
import { messaging } from "../../../firebase";
const Notifications = () => {
  const { getNotifications, getNotificationsRead, SaveFCMToken } = useHook();

  const [currentPage, setcurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [notifylist, setnotify] = useState([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [totalNotifications, setTotalNotifications] = useState(0); 
  const handleNotification = async (currentPage) => {
    const formData = {
      page: currentPage,
      perPage: 10,
    };
    try {
      const response = await getNotifications(formData);
      const newNotifications = response.data.results;
      setTotalNotifications(response.length);
      // Append new notifications to the existing list
      setnotify((prevNotifications) => [
        ...prevNotifications,
        ...newNotifications,
      ]);
      setLoading(false);
      setLoadingMore(false);
    } catch (error) {
      console.error("Notification Error", error);
    } finally {
      setLoading(false);
    }
  };
  const handleNotificationRead = async () => {
    const formData = {
      page: 1,
      perPage: 0,
    };
    try {
      const response = await getNotificationsRead(formData);
      console.log("response read botification is ", response);
    } catch (error) {
      console.error("Notification Error", error);
    }
  };
  useEffect(()=>{
    handleNotificationRead();
  },[])
  useEffect(() => {
    handleNotification(currentPage);
    handleNotificationRead();
  }, [currentPage]);



  const handleLoadMore = () => {
    setLoadingMore(true);
    setcurrentPage((prevPage) => prevPage + 1);
  };

  // date conversion
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    const requestPermission = async () => {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        // Generate token
        const token = await getToken(messaging, {
          vapidKey:
            "BI3e34yhGxRmTkesxjtIk2mxlwg4muhYnV5Lh4AgeGONxh7BMKaCqBkT66ceA9Yy-XKEiILXMuxifc4nFrmDop0",
        });
        console.log("Token Gen", token);
        // Define deviceId here (e.g., use a unique identifier for the device)
        const deviceId = "C511DF12-9134-4680-9B80-345FE098DF37"; // Replace with your actual device ID logic
        console.log("Device ID:", deviceId);
        SaveFCMToken(deviceId, token);
        // Check chatId value in Firestore
        //   const docRef = doc(db, "chatInUser", deviceId);
        //   console.log("Document Reference:", docRef);
        //   try {
        //     const docSnap = await getDoc(docRef);
        //     // debugger;
        //     if (docSnap.exists()) {
        //       const data = docSnap.data();
        //       console.log("Document Data:", data);
        //       const chatId = data.chatId;
        //       console.log("Chat ID:", chatId);
        //       if (chatId === "" && !isInChat) {
        //         // Send token to server and show notfication
        //         // SaveFCMToken(deviceId, token);
        //         new Notification("Chat ID is empty!");
        //       }
        //     } else {
        //       console.log("No such document!");
        //     }
        //   } catch (error) {
        //     console.error("Error getting document:", error);
        //   }
      } else if (permission === "denied") {
        // alert("Permission is Denied");
      }
    };
    requestPermission();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then(function (registration) {
          console.log("Registration successful, scope is:", registration.scope);
        })
        .catch(function (err) {
          console.log("Service worker registration failed, error:", err);
        });
    }
    // Call the checkIfInChat function to update the isInChat state
    // checkIfInChat();
    // Optional: set up a listener or interval to keep updating the isInChat state
    // const intervalId = setInterval(checkIfInChat, 1000); // Adjust the interval as needed
    // // Cleanup interval on component unmount
    // return () => clearInterval(intervalId);


  }, []);


  const totalLoadedNotifications = notifylist.length;
  const hasMoreNotifications = totalLoadedNotifications < totalNotifications;

  return (
    <Main>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="container-fluid">
            <div className="heading-flex">
              <h1 className="heading m-0">Notifications</h1>
            </div>
          </div>
          <div className="">
            {notifylist.length === 0 ? (
              <div className="no-record">
                <p>No record found</p>
              </div>
            ) : (
              notifylist.map((items, index) => (
                <div className="card border-0 mt-2" key={index}>
                  <div className="padding">
                    <div className="flex justify-between">
                      <div>
                        <p className="m-0 notification-heading">
                          {items.title}
                        </p>
                        <p className="m-0 notification-subheading">
                          {items.message}
                        </p>
                      </div>
                      <div>
                        <p className="m-0 notification-subheading">
                          {formatDate(items.timestamp)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
            {/* {notifylist.length > 0 && (
                            <PaginationRounded
                                count={totalPage}
                                page={currentPage}
                                onChange={handlePageChange}
                            />
                        )} */}
            {loadingMore && <Spinner />}
            {!loadingMore &&  notifylist.length > 0 && (
              <div className="load-more" onClick={handleLoadMore}>
                <p className="load"> Load more</p>
              </div>
            )}
          </div>
        </>
      )}
    </Main>
  );
};

export default Notifications;
