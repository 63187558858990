import React, { useState, useEffect } from 'react'
import { Main } from './style'
import { Card, Product } from './style';
import Total from '../../../assets/img/collection/Group 1597883350.png';
import Products from '../../../assets/img/collection/Data collection.png';
import useHook from './usehooks';
import { useParams, useNavigate } from 'react-router-dom';
import PaginationRounded from "components/pagination";
import { AdminLotusPRO, View, Delete } from 'assets/img'



const Catalogs = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const { catalogGet } = useHook();
  const handleClick = (id) => {
    navigate(`/catalog-image-detail/${id}`)
  };
  const [cataloglist, setCataloglist] = useState([])
  const [totalphotoCount, settotalPhotoCount] = useState();
const [counts,setcounts]=useState('')
  const getCatalogs = async () => {
    const formdata = {
      userId: id,
      page: currentPage,
      perPage: 9,
    }
    try {
      const response = await catalogGet(formdata)
      // console.log("catalog response", response)
      setCataloglist(response.data.results)
      setcounts(response.data.counts)
      const totalProductCount = response.data.results.reduce((accumulator, item) => {
        return accumulator + item.catalogImagesCount;
      }, 0);
      settotalPhotoCount(totalProductCount)
      setTotalPage(Math.ceil(response.data.counts / 10));
    } catch (error) {
      console.error("catalogs error", error)
    }
  }
  useEffect(() => {
    getCatalogs();
  }, [])

  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };
  return (
    <Main>
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button className="nav-link active" id="pills-home3-tab" data-bs-toggle="pill" data-bs-target="#pills-home3" type="button" role="tab" aria-controls="pills-home3" aria-selected="true">
            <div className='order-flex'>
              <div className="card third border-0">
                <div className="card-body">
                  <div className='third-flex'>
                    <img src={Total} alt="img" className='total-img' />
                    <div className='margin'>
                      <p className='m-0 order-para'>Total Catalogs</p>
                      <p className='m-0 order-number'>{counts}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button className="nav-link" id="pills-profile3-tab" data-bs-toggle="pill" data-bs-target="#pills-profile3" type="button" role="tab" aria-controls="pills-profile3" aria-selected="false"> <div className='order-flex'>
            <div className="card third  border-0">
              <div className="card-body">
                <div className='third-flex'>
                  <img src={Products} alt="img" className='total-img' />
                  <div className='margin'>
                    <p className='m-0 order-para'>Total Images in Catalogs</p>
                    <p className='m-0 order-number'>{totalphotoCount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </button>
        </li>

      </ul>

      <div>
        <div className="tab-content" id="pills-tabContent">
          <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home3-tab" tabIndex="0">
            <div className='card-flex'>
              {cataloglist.length > 0 ? (
                <>
                  {cataloglist.map((items,index) => (
                    <div className='card card-width' key={index}>
                      <div className="card-body" onClick={() => handleClick(items.id)} style={{ cursor: "pointer" }}>
                        <div>
                        {items.image ? (
                            <img
                                src={`${process.env.REACT_APP_MEDIA_URL}${items.image}`}
                                alt="logo"
                                className='img'
                            />
                        ) : (
                            <AdminLotusPRO className='img' />
                        )}
                          {/* <img src={`${process.env.REACT_APP_MEDIA_URL}${items.image}`} alt="" className='img' /> */}
                          <div className='mt-3'>
                            <p className='m-0 heading'>{items.name}</p>
                            <p className='m-0 sub-heading'>
                              {items.catalogImagesCount} {items.catalogImagesCount > 1 ? 'Photos' : 'Photo'}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                 
                </>
              ) : (
                <div className='no-record'>No Record Found</div>
              )}
            </div>
            <PaginationRounded
                    count={totalPage}
                    page={currentPage}
                    onChange={handlePageChange}
                  />

          </div>

        </div>
      </div>
    </Main>
  )
}

export default Catalogs
