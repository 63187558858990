import React from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IoCloseSharp } from 'react-icons/io5';
import { Main } from './style';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 250,
  bgcolor: "background.paper",
  border: "0px",
  borderRadius: "12px",
};

const DeleteModal = ({ open, handleClose, handleDelete }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      BackdropProps={{
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <Main>
        <Box sx={style}>
          <div className='d-flex flex-row justify-content-between align-items-center heading'>
            <Typography variant="h6" />
            <Typography variant="h6" style={{ fontSize: "20px", fontWeight: "bold" }}>
              Confirm Delete
            </Typography>
            <IoCloseSharp style={{ fontSize: "30px", cursor: "pointer" }} onClick={handleClose} />
          </div>
          <Box className='text-container'>
            <Typography variant="body1" style={{ fontSize: "20px" }} gutterBottom>
              Are you sure you want to delete this record?
            </Typography>
            <Box display="flex" justifyContent="space-between" mt={10}>
              <button className='filter' onClick={handleDelete}>
                Yes
              </button>
              <button className="reset" onClick={handleClose}>
                No
              </button>
            </Box>
          </Box>
        </Box>
      </Main>
    </Modal>
  );
};

export default DeleteModal;
