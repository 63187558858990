import React, { useEffect, useState } from 'react';
import { Main } from './style';
import OrderCancellation from 'components/modals/order-cancellation';
import OrderReturnedDays from 'components/modals/order-returned-days';
import useHook from './usehook';

const OrderTransactionManagement = () => {
    const { getCancelationList } = useHook();
    const [orderDeclineModal, setOrderDeclineModal] = useState(false);
    const [modalID, setModalID] = useState(0);
    const [selectedValue, setSelectedValue] = useState(null);
    const [orderReturnedDays, setOrderReturnedDays] = useState(false);
    const [cancelList, setCancelList] = useState([]);

    const handleDeclineModalOpen = (id, value) => {
        setOrderDeclineModal(true);
        setModalID(id);
        setSelectedValue(value);
    };

    const handleDeclineModalClose = () => {
        setOrderDeclineModal(false);
    };

    const handleOrderReturnedDaysOpen = (value) => {
        setOrderReturnedDays(true);
        setSelectedValue(value);
    };

    const handleOrderReturnedDaysClose = () => {
        setOrderReturnedDays(false);
    };

    const GetCancellationList = async () => {
        try {
            const response = await getCancelationList();
            setCancelList(response.data);
            console.log("cancelationList", response.data);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        GetCancellationList();
    }, []);

    useEffect(() => {
        if (!orderDeclineModal) {
            GetCancellationList();
        }
    }, [orderDeclineModal]);

    useEffect(() => {
        if (!orderReturnedDays) {
            GetCancellationList();
        }
    }, [orderReturnedDays]);

    return (
        <>
            <Main>
                <OrderCancellation
                    modalID={modalID}
                    open={orderDeclineModal}
                    onClose={handleDeclineModalClose}
                    selectedValue={selectedValue}
                />
                <OrderReturnedDays
                    open={orderReturnedDays}
                    onClose={handleOrderReturnedDaysClose}
                    selectedValue={selectedValue}
                />
                <h4 className='title-heading'>Order Transaction Management</h4>
                {/* card container */}
                <div className='card-list'>
                    {/* charges card */}
                    <div className='card-bg'>
                        {/* card title */}
                        <div className='card-body pt-2'>
                            <h6>Order Cancellation Charges</h6>
                            {cancelList.slice(2, 3).map((item) => (
                                <span key={item.id} className='d-flex flex-row align-items-center gap-2'>
                                    <div>${item.value}</div>
                                    <button className='edit-btn' onClick={() => handleDeclineModalOpen(item.id, item.value)}>Edit</button>
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Order Declined charges for vendor card */}
                    <div className='card-bg'>
                        {/* card title */}
                        <div className='card-body pt-2'>
                            <h6>Order Declined charges for vendor</h6>
                            {cancelList.slice(0, 1).map((item) => (
                                <span key={item.id} className='d-flex flex-row align-items-center gap-2'>
                                    <div>${item.value}</div>
                                    <button className='edit-btn' onClick={() => handleDeclineModalOpen(item.id, item.value)}>Edit</button>
                                </span>
                            ))}
                        </div>
                    </div>
                    
                    {/* Admin Fees Per Order card */}
                    <div className='card-bg'>
                        {/* card title */}
                        <div className='card-body pt-2'>
                            <h6>Admin Fees Per Order</h6>
                            {cancelList.slice(1, 2).map((item) => (
                                <span key={item.id} className='d-flex flex-row align-items-center gap-2'>
                                    <div>{item.value}%</div>
                                    <button className='edit-btn' onClick={() => handleDeclineModalOpen(item.id, item.value)}>Edit</button>
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Days Remaining for Order Return card */}
                    <div className='card-bg'>
                        {/* card title */}
                        <div className='card-body pt-2'>
                            <h6>Days Remaining for Order Return</h6>
                            {cancelList.slice(3, 4).map((item) => (
                                <span key={item.id} className='d-flex flex-row align-items-center gap-2'>
                                    <div>{item.value} Days</div>
                                    <button className='edit-btn' onClick={() => handleOrderReturnedDaysOpen(item.value)}>Edit</button>
                                </span>
                            ))}
                        </div>
                    </div>
                </div>
            </Main>
        </>
    );
};

export default OrderTransactionManagement;
