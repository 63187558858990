import styled from "styled-components";

export const Main = styled.div`
width: 100%;
background-color: var(--all-background);
height: var(--height);
.view-icon{
    background-color: #209A42;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.order{
    font-family: inter;
    font-weight: 600;
    font-size: 20px;
    color: #252525;
    padding-block: 20px;
}
.input-area{
    width: 100%;
}
.input{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    outline: none;
}
.input2{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    outline: none;
}
.select1{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    outline: none;
}
.select2{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    outline: none;
}
.date{
    font-family: inter;
    font-weight: 600;
    font-size: 14px;
    color: #1C1C27;
}
.date-input{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    gap: 88px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    padding: 10px;
    outline: none;
}
.flex{
    display: flex;
    gap: 10px;
}
.filter{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: white;
}
.reset{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #E2E8F0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: #808D9E;
}
.style-font2{
  font-family: inter;
  font-size: 14px;
  font-weight: 400;
  color: black;
  border-bottom: 1px solid #E2E8F0;
}
.border1{
  width: 81px;
    height: 30px;
    background-color: #00D1FF1A;
    color: #00D1FF;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
}
.border3 {
    width: 100px;
    height: 30px;
    background-color: #00D1FF1A;
    color: #00D1FF;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}
.table-head{
    font-family :inter ;
    font-weight: 700;
    font-size: 14px;
    color: #0C3A2D;
    border: white;
  
}
.custom-select {
    border: none;
    border-radius: 5px;
    width: 99px;
    height: 24px;
    outline: none;
}

.custom-select option {
  background-color: #fff;
}
.options{
    font-family: inter;
   font-family: 400;
   font-size: 12px;
   outline: none;
   border: 0px;
}
.status{
  width: 65px;
    height: 28px;
    border-radius: 5px;
    /* background-color: #FF00001A; */
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #FF0000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.status-Processing{
   width: 99px;
   height: 24px;
   border-radius: 5px;
   background-color: #00D1FF1A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #00D1FF;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-Delivered{
   width: 82px;
   height: 24px;
   border-radius: 5px;
   background-color: #209A421A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #209A42;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-pending{
    width: 82px;
   height: 24px;
   border-radius: 5px;
   background-color: #FF8F501A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF8F50;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-cancel{
    width: 75px;
   height: 24px;
   border-radius: 5px;
   background-color: #FF00001A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF0000;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-shipped{
    width: 75px;
   height: 24px;
   border-radius: 5px;
   background-color: #FFFED3;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #B1AFFF;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.card-container{
  display: flex;
  flex-direction: row;
}
/* table responsive */
.table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */

    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }

/* ALL responsive */
@media (max-width: 600px) {
   .card-container{
    display: flex;
    flex-direction: column ;
    width: 100%;
   }
   .input{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    outline: none;
}
.input2{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    outline: none;
}
.select1{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94A3B8;
    outline: none;
}
.card-body{
  display: flex;
  flex-direction:column;
}
.input-div{
  width: 100% !important;
}
.flex{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  }
.button-container{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 10px;
}

`