import styled from 'styled-components';

export const Main = styled.div`
    width: 100%;   
    .eye-icon{
        position: absolute;
    margin-top: 10px;
    cursor: pointer;
    margin-left: -26px;
    }
    .yellow_border{
    border-bottom: 6px solid #FFBB02;
    padding: 10%;
    margin-inline: 10%;
    }
    .heading{
    font-family: 'Plus Jakarta Sans';
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.2px;
    color: #0F172A;
    }
    .welcome_Content{
        font-family: 'Plus Jakarta Sans';
    font-size: 14px;
    font-weight: 400;
    color: #64748B;
    }
    .input{
    width: 60%;
    height: 45px;
    border: 1px solid #E2E8F0;
    border-radius: 6px;
    color: black;
    padding: 9px;
    outline: #FFBB02;
    font-family: inter;
    }
    .margin{
    margin-top: 12%;
    margin-left: 20%;
    }
    .remember{
    display: flex;
    justify-content: space-between;
    margin: 3% 20%;
    }
    .remember_me{
        font-family: 'Plus Jakarta Sans';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #252525;
    }
    .Forgot_Password{
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        letter-spacing: 0.2px;
        color: #FFBB02;
        font-weight: 500;
       
    }
    .SignIn_Button{
        background-color: #0C3A2D;
    color: white;
    width: 60%;
    border-radius: 10px;
    font-family:'Plus Jakarta Sans' ;
    height: 45px;
    }
   @media (max-width:1400px){
    .margin{
    margin-top: 4%;
    margin-left: 20%;
    }
   }
    /* @media (max-width:1500px) {
        .eye-icon{
        position: absolute;
        right:180px;
        top: 56%;
        transform: translateY(-50%);
        cursor: pointer;
    }   
    } */
`;