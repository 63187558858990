import styled from "styled-components";


export const Reviews = styled.div`

.review-img{
  width: 100px;
  height: 100px;
  border-radius: 5px;
}
.reviews{
  font-family: inter;
  font-weight: 500;
  font-size: 16px;
  color: black;
}
.see-all{
  font-family: inter;
  font-weight: 400;
  font-size: 14px;
  color: black;
}
.name{
  font-family: inter;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
}
.rating{
  font-family: inter;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}
.star{
  width: 16px;
    height: 15px;
}
.date{
  font-family: inter;
  font-size: 14px;
  font-weight: 400;
  color: #64748B;
}
.img-height{
  border-radius: 30px;
  width: 45px;
  height: 45px;
}
.comment{
  font-family: roboto;
  font-weight: 400;
  font-size: 16px;
  color: #333333;
}

`

export const SingleUser = styled.div`
.text-headding{
  font-family: Inter;
font-size: 24px;
font-weight: 600;
line-height: 36px;
letter-spacing: 0.20000000298023224px;
text-align: left;
color:#FFFFFF;
}
.text-ratting{
  font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.20000000298023224px;
text-align: left;
color: white;
}
.text-color{
  /* width: 156px;
height: 24px; */
top: 347px;
left: 468px;
gap: 0px;
opacity: 0px;
color:#808D9E

}
.license-padding{
  padding-left: 22px;
}
.padding-type{
  padding-left: 42px;
}
.padding-city{
  padding-left: 50px;
}
.padding-date{
  padding-left: 5px;
}
.padding-state{
  padding-left: 45px;
}

.padding-zip{
  padding-left: 18px;
}
.padding-dba{
  padding-left: 52px;
}
.padding-status{
  padding-left: 35px;
}
.text-field-color{
  font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
letter-spacing: 0.20000000298023224px;
text-align: left;
color:black

}


.verifiction-btn{
  background-color: #6B9773;
  width:auto;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border-radius: 5px;
  border:none;
  color:white ;
}
.btn-container{
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top:10px;
  right: 20px;
}
.view-business-detail{
  background-color: #6B9773;
  width:auto;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;
  outline: none;
  border-radius: 5px;
  border:none;
  color:white ;
}
.vendor-image{
  top: -100px;
  
}
.vendor-image-container{
  position: absolute;
   top: 100px;
    left: 30px ;
    width: 95%;
}
.business-name{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.women{
border-radius: 100px;
    width: 149px;
    height: 149px;
    outline: 4px solid white;
}
.black{
  margin-top: 60px;
  color: black;
  padding: 0px 25px;
  width: 100%;
}
.flex-main{
  display: flex;
}
.space-between{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    gap:20px
}
.city-padding{
  padding-left: 30px;
}
.city-container{
width: 210px;
}
.business-information1{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:40px;
}
.business-information2{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:40px;
}
.business-information3{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:110px;
}
.dba-container{
  gap:70px;
}
.email{
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #808D9E;
}
.email-name{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #1C1C27;
}

.email-flex{
  display: flex;
  flex-direction:row;
  /* gap: 57px; */
}
.email-flex{
  display: flex;
  /* gap: 57px; */
}
.phone-flex{
  display: flex;
  /* gap: 22px; */
}
.date-adj{
  margin-left: 0px;
}
.state-adj{
  margin-left: 85px;
}
.rating{
  font-family: inter;
  font-weight: 500;
  font-size: 16px;
  color: #1C1C27;
}
.ratting-direction{
  display: flex;
  justify-content: center;
  align-items: center;

}
.like-flex{
  display: flex;
  gap: 110px;
    padding: 4% 0%;
}
.profile-like{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: white;
}
.profile-name{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: white; 
}
.followers{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: white;
}
.followers-name{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: white; 
}
.rating-2{
  font-family: inter;
  font-weight: 500;
  font-size: 16px;
  color: #808D9E;
}
@media (max-width:14000px) {
  .dot-property-col-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    width: 120px;
  }
  .dot-property-col-2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    width: 240px;
  }
  .dot-property-col-3 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    width: 240px;
  }
}
@media (max-width:1240px) {
.card-profile{
  overflow-x: auto;
  overflow-y: auto;
}
.black{
  margin-top: 60px;
  color: black;
  padding: 0px 25px;
 
}
.business-name{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: white;
}


  .space-between{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    gap:20px
}
.city-padding{
  padding-left: 30px;
}
.city-container{
width: 210px;
}
.black{
  display: flex;
  flex-wrap: wrap;
}
.business-information1{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap:wrap;
  gap:20px;
}
.type-business{
  gap: 20px;
}
.business-information2{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap:wrap;

  gap:20px;
}
.business-information3{
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;

  width: 100%;
  gap:20px;
}
.dba-container{
  gap:70px;
}
.email{
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #808D9E;
}
.email-name{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #1C1C27;
}

.email-flex{
  display: flex;
  flex-direction:row;
  /* gap: 57px; */
}
.email-flex{
  display: flex;
  /* gap: 57px; */
}
.phone-flex{
  display: flex;
  /* gap: 22px; */
}
.date-adj{
  margin-left: 0px;
}
.state-adj{
  margin-left: 0px;
}

/* dot properties */
/* Licence */
.dot-property-col-1 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    width: 120px;
  }
  .dot-property-col-2 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    width: 240px;
  }
  .dot-property-col-3 {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    width: 240px;
  }
}


@media (max-width:960px){
  .space-between{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    width: 100%;
    gap:20px
}
.city-padding{
  padding-left: 30px;
}
.city-container{
width: 210px;
}
.business-information1{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:40px;
}
.business-information2{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:40px;
}
.business-information3{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap:110px;
}
.dba-container{
  gap:70px;
}
.email{
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #808D9E;
}
.email-name{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #1C1C27;
}

.email-flex{
  display: flex;
  flex-direction:row;
  /* gap: 57px; */
}
.email-flex{
  display: flex;
  /* gap: 57px; */
}
.phone-flex{
  display: flex;
  /* gap: 22px; */
}
.date-adj{
  margin-left: 0px;
}
.state-adj{
  margin-left: 0px;
}
.type-business{
  gap: 45px;
}
.ssin-flex{
  gap: 50px;
}
.city-flex{
  gap:30px;
  width: 100%;;
}
}


@media (max-width:850px) {
  .women{
border-radius: 100px;
    width: 120px;
    height: 120px;
}
.vendor-image{
  margin-top: 25px;
}
.black{
  margin-top: 60px;
  color: black;
  padding: 0px 15px;
}
.vendor-image-container{
    overflow-x: auto;
}
.padding{
  /* padding: 0px 25px; */
  font-family: inter;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.2px;
  color: white;
}
/*  */
.business-information1{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap:wrap;
  gap:40px;
}
.business-information2{
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap:wrap;

  gap:40px;
}
.business-information3{
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  width: 100%;
  gap:80px;
}


.email{
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #808D9E;
}
.email-name{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #1C1C27;
}

.email-flex{
  display: flex;
  flex-direction:row;
  gap: 50px;
  /* gap: 57px; */
}

.phone-flex{
  display: flex;
  gap: 30px;
}
.date-adj{
  margin-left: 0px;
}
.state-adj{
  margin-left: 0px;
  gap: 10px ;
}

.ssin-flex{
  gap: 50px;
}

.city-flex{
  width: 100%;
}

.startDate-flex{
  gap: 20px;
}
.zip-flex{
  gap: 30px !important;
}
.dba-container{
  gap: 20px;
}

}


@media (max-width:600px) {
  .card{
    height: 400px;
  }
  .space-between{
    margin-top: 14px;
    display: flex;
    flex-direction: row;
  }
  .business-information1{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap:5px;
}
.business-information2{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap:5px;
}
.business-information3{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap:5px;
  /* margin-bottom: 10px; */
}
.state-flex{
  display: flex;
  gap:55px
}
.ssin-flex{
  display: flex;
  gap: 55px;
}
.city-container{
  width: 20%;
}
  .women{
border-radius: 100px;
    width: 90px;
    height: 90px;
}
.vendor-image{
  margin-top: 35px;
}
.padding{
  /* padding: 0px 25px; */
  font-family: inter;
  font-weight: 600;
  font-size: 20px;
  letter-spacing: 0.2px;
  color: white;
}
.black{
  margin-top: 68px;
  color: black;
  padding: 0px 10px;
}
.email{
    font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #808D9E;
}
.email-name{
  font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #1C1C27;
}
.space-between{
  display: flex;
  flex-direction: column;
  gap: 1px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.licence-flex{
  display: flex;
  gap: 34px;
}
.email-flex{
  display: flex;
  gap: 53px;
}
.phone-flex{
  margin-top: 10px;
  display: flex;
  gap: 34px;
}
.dba-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  gap: 60px;
}
.rating{
  font-family: inter;
  font-weight: 500;
  font-size: 14px;
  color: #1C1C27;
}
.ratting-container{
  margin-left: 30px;
}
.city-flex{
  display: flex;
  gap:30px
}
.startDate-flex{
  display: flex;
  align-items: center;
  gap:22px;
}
.state-flex{
  margin-top: 10px;
}
.like-flex{
  display: flex;
  flex-direction: column;
  gap:10px;
}
.profile-like{
  font-family: inter;
    font-weight: 500;
    font-size: 14px;
    /* color: #808D9E; */
    color: white;
    
}
.profile-name{
  font-family: inter;
    font-weight: 500;
    font-size: 14px;
    /* color: #1C1C27;  */
    color: white;
    margin-left: 25px;
}
.followers{
  font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #808D9E;
}
.followers-name{
  font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #1C1C27; 
    margin-left: 25px;
}
.vendor-image-container{
  /* position: absolute;
   top: 100px;
    left: 10px ; */
    overflow-x: auto;
}
.verified-container{
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 48px;
}
.verify{
  font-family: inter;
    font-weight: 500;
    font-size: 14px;
    color: #808D9E;
}
.verifiction-btn{
  margin-top: 3px;
}
}

@media (max-width:430px)  {
.card{
  height: 480px;
}
.profile-like{
  display: flex;
  flex-direction: column;
  gap: 0px !important;

}
.followers{
  display: flex;
  flex-direction: column;
}
.rating-2{
  font-family: inter;
  font-weight: 500;
  font-size: 14px;
  color: #808D9E;
  left: 0px;
}
.profile-name{
  margin-right: 10px;
}
.followers-name{
  margin-left: 0px;
}
.ratting-direction{
  margin-left: -55px;
}
.vendor-image-container{
    overflow-x: auto;
}
.business-information1{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap:10px;
}
.business-information2{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap:10px;
}
.business-information3{
  display: flex;
  flex-direction: column;
  width: 100%;
  gap:10px;
}
.ssin-flex{
  gap: 60px;
}
.followers-name{
  display: flex;
  flex-direction:column;
}
.follow-color{
  color:#808D9E;

}
.profile-name{
  color:#808D9E;
}
.space-between{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 12px;
}
}

`


export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
  width: 100%;
  overflow-x: scroll;
  overflow: hidden;
  flex-wrap: wrap;


  .view-profile{
  background-color: #6B9773;
  width: 160px;
  height: 48px;
  border: none;
  border-radius: 5px;
  outline: none;
  color: white;
}

  
.name-container{
  display: flex;
  flex-direction: column;

}
  .lotus-pro{
    width: 118px;
    height:118px;
    border-radius: 100px;

  }
  .profile-pic{
    border-radius: 100px;
  }
  .profile-picture{
width: 120px;
border-radius: 100px;
border: 1px solid #D9D9D9;
border-style: dashed;
height: 120px;
  }
 
  .search{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    border: 0;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}

/* @media (min-width: 600px) and (max-width:1100px) {
  overflow-x: auto;
    width: 100%;

} */
    @media (max-width:600px) {
      display: flex;
  align-items: center;
  justify-content: center;
flex-direction: column;
  background-color: white;
  width: 100%;
  gap: 10px;
    .profile-picture{
      margin-bottom: 15px;
    }

    .name-container{

      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    h6{
      width:30%;
      margin-bottom: 0px;
      text-align: left;
    }
    span{
      width:30%
    }
    .email-container{
      width: 100%;
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      /* gap: 20px; */
    }
    .phone-container{
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      /* gap: 20px; */
    }
    .search{
      margin-top: 10px;
      width: 100%;
    }
    .name-label{
      width: 31%;
    }
    .email-label{
      width: 45%;
    }
    .email-vendor{
      width: 100%;
    }
    .phone-label{
      width:31%
    }
    .view-profile{
      margin-top: 10px;
    }
  }

  @media (max-width:430px) {
    .name-container{
      gap:82px
    }
    .phone-number{
      width: 100%;
    }
    .phone-container{
      gap:10px
    }
    .phone-label{
      width:100%
    }
    .email-container{
      gap:85px
    }
.text-name{
  width: 100%;
}

  }
`;