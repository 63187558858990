// import styled from "styled-components";
// import { NavLink } from 'react-router-dom';

// export const Main = styled.div`
//   width: ${({ isOpen }) => (isOpen ? '17%' : '60px')}; 
//   transition: width 0.3s ease-in-out;
//   .background-color {
//     background-color: #0C3A2D;
//     height: 200vh;
//     position: fixed;
//     width: 250px;
//   }
//   .logo {
//     width: 60%;
//     margin-top: 6%;
//   }
//   .border-white {
//     border-bottom: 1px solid #F1F5F91A;
//     margin: 9% 6%;
//   }
//   .burger-icon {
//     color: black;
//     cursor: pointer;
//     display: none;

  
//   }
//   @media (max-width: 769px) {
//     .background-color {
//       width: ${({ isOpen }) => (isOpen ? '250px' : '60px')};
//       position:fixed;
//       z-index: 999;
//       top: 0px;

//     }
//     .burger-icon {
//       display: block; 
//     }
//     .sidebar-content {
//       display: ${({ isOpen }) => (isOpen ? 'block' : 'none')}; 
      
//     }
//   }
//   .sidebar-content {
//     overflow: hidden;
//   }
//   .sidebar-content.open {
//     width: 100%;
//   }
//   .dashboard {
//     color: white;
//     font-size: 14px;
//     margin-left: 3%;
//     font-family: Plus Jakarta Sans;
//   }
//   .dash-logo {
//     width: 22px;
//     height: 22px;
//   }
//   .padding {

//   }
//   .color {
//     color: white;
//   }
//   .cross{
//     color: white;
//     padding: 15px 0px 0px 0px;
//     display: none;
//   }
//   @media (max-width: 768px) {
//     .cross{
//         display: block; 
      
//     }
//     }
// `;

// export const NavLinkStyled = styled(NavLink)`
//   padding: 13px 13px;
//   cursor: pointer;
//   .color{
//     path{
//       fill:"#8080d9e"
//     }
//   }
//   &:hover {
//     color: #ffbb02;
//     background-color: #ffffff1a;
//     border-radius: 5px;
//     .color{
//     path{
//       fill:"white"
//     }
//   }
//   }
//   &.active {
//     background-color: #ffffff1a;
//     color: #ffbb02;
//     border-radius: 5px;
//     .color{
//     path{
//       fill:"white"
//     }
//   }
//   }
// `;

import styled from "styled-components";
import { NavLink } from 'react-router-dom';

export const Main = styled.div`
  width: ${({ isOpen }) => (isOpen ? '17%' : '60px')}; 
  transition: width 0.3s ease-in-out;
  
  .background-color {
    background-color: #0C3A2D;
    height: 200vh;
    position: fixed;
    width: 250px;
  }
  
  .logo {
    width: 40px;
    height:44px;
    margin-top: 6%;
  }
  .logo-container{
    display: flex;
    flex-direction: row;
    color: white;
    align-items: center;
  }
  .logo-heading{
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 30px;
    font-family: inter;
    font-weight: 400;
    margin-top: 14px;
  }
  .pro {
    font-size: 30px;
    font-family: inter;
    font-weight: 900;
    color: white;
}
  .border-white {
    border-bottom: 1px solid #F1F5F91A;
    margin: 9% 6%;
  }
  
  .burger-icon {
    color: black;
    cursor: pointer;
    display: none;
  }
  
  @media (max-width: 769px) {
    .background-color {
      width: ${({ isOpen }) => (isOpen ? '250px' : '60px')};
      position: fixed;
      z-index: 999;
      top: 0px;
    }
    .burger-icon {
      display: block; 
    }
    .sidebar-content {
      display: ${({ isOpen }) => (isOpen ? 'block' : 'none')}; 
    }
  }
  
  .sidebar-content {
    overflow: hidden;
  }
  
  .sidebar-content.open {
    width: 100%;
  }
  
  .dashboard {
    color: white;
    font-size: 14px;
    margin-left: 3%;
    font-family: Plus Jakarta Sans;
  }
  
  .dash-logo {
    width: 22px;
    height: 22px;
  }
  
  .color {
    color: white;
  }
  
  .cross {
    color: white;
    padding: 15px 0px 0px 0px;
    display: none;
  }
  
  @media (max-width: 768px) {
    .cross {
      display: block; 
    }
  }
`;

export const NavLinkStyled = styled(NavLink)`
  padding: 13px 13px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: white;
  
  .color {
    path {
      fill: white;
    }
  }
  
  &:hover, &.active {
    color: #FFBB02;
    background-color: #ffffff1a;
    border-radius: 5px;
    
    .color {
      path {
        fill: #FFBB02;
      }
    }
  }
`;
