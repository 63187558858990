import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  position: relative;
  .burger-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .burger-icon {
    color: #808d9e;
    /* cursor: pointer; */
    display: none;
    /* border: 1px solid; */
    width: 50px;
    border: 1px solid #e2e8f0;
    padding: 0px 11px;
    height: 48px;
    border-radius: 6px;
  }

.custom-img{
  width:100px;
  height:100px;
}
  .width{
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .flex{
    display: flex;
    justify-content: end;
    padding: 15px 28px;
    align-items: center;
    gap: 16px;
    flex-wrap: wrap-reverse;
    
  }

  .select{
    font-size: 16px;
    font-family: inter;
    font-weight: 600;
    padding-right: 11px;
  }
  .boder{
    border: 1px solid;
    width: 21%;
    padding: 1%;
  }
  .padding{
    padding: 9px;
    border: 1px solid #E2E8F0;
  }
  .image{
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border-radius: 60px;
  }
  .icon-width{
    width: 49px;
    height: 48px;
    /* border: 1px solid #E2E8F0; */
    /* padding: 8px; */
    border-radius: 5px;
    /* cursor: pointer; */
  }
  @media (max-width: 480px) {
    .width{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .burger-icon{
    display: block;
  }
    .burger-div {
      align-items: normal;
    }
  }
  
  @media (max-width: 769px) {
    .background-color {
      width: ${({ isOpen }) => (isOpen ? "250px" : "60px")};
      position: fixed;
      z-index: 999;
      top: 0px;
    }

    .burger-icon {
      display: block;
    }
.width{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
}
    .sidebar-content {
      display: ${({ isOpen }) => (isOpen ? "block" : "none")};
      transition: all ease-in-out 0.5s;
    }
  }
  `