import React, { useState} from "react";
import { Main, TabsContainer } from "./style";
import Collection from "../collection";
import Catalogs from "../catalogs";
import VenderDispute from "../vendor-dispute";
import ProductListing from "../product-listing";
import OrderListing from "../order-listing";
import VendorProfile from "../vendor-profile";

const VenderDetails = () => {

  const Tab = ({ label, onClick, isActive }) => {
    return (
      <div className={`tab ${isActive ? "active" : ""}`} onClick={onClick}>
        {label}
      </div>
    );
  };

  const Contnt = ({ isActive, children }) => {
    return (
      <div className={`content ${isActive ? "active" : ""}`}>{children}</div>
    );
  };

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <Main>
      <div className="container-fluid">
        <div className="flex">
          <div className="heading-flex">
            <h1 className="heading m-0">Vendor Details</h1>
          </div>
        </div>
        <div className="card border-0">
          <div className="card-body overflow-x-auto">
            <div className="tabs profile">
              <Tab
                label="Profile"
                onClick={() => handleTabClick(0)}
                isActive={activeTab === 0}
              />
              <Tab
                label="Order"
                onClick={() => handleTabClick(1)}
                isActive={activeTab === 1}
              />
              <Tab
                label="Product Listing" 
                onClick={() => handleTabClick(2)}
                isActive={activeTab === 2}
              />
              <Tab
                label="Collection"
                onClick={() => handleTabClick(3)}
                isActive={activeTab === 3}
              />
              <Tab
                label="Catalog"
                onClick={() => handleTabClick(4)}
                isActive={activeTab === 4}
              />
              <Tab
                label="Dispute"
                onClick={() => handleTabClick(5)}
                isActive={activeTab === 5}
              />
            </div>
          </div>
        </div>
        <div>
          {/* Profile Content start */}

          {activeTab === 0 ? <Contnt isActive={activeTab === 0}>
            <VendorProfile />
          </Contnt> : activeTab === 1 ? <Contnt isActive={activeTab === 1}>
            {/* #==================================CURRENT ORDER======================================= */}
            {/* <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              > */}
                {/* #==================================ORDER HISTORY======================================= */}

                <OrderListing />
{/*       
              </div>
            </div> */}

            {/* #==================================ORDER RETURN======================================= */}
          </Contnt> : activeTab === 2 ? <Contnt isActive={activeTab === 2}>
            <TabsContainer>

              <ProductListing />

            </TabsContainer>
          </Contnt> : activeTab === 3 ? <Contnt isActive={activeTab === 3}>
            <Collection />
          </Contnt> : activeTab === 4 ? <Contnt isActive={activeTab === 4}>
            <Catalogs />
          </Contnt> : <Contnt isActive={activeTab === 5}>
            <VenderDispute />
          </Contnt>}

        </div>
      </div>
    </Main>
  );
};

export default VenderDetails;
