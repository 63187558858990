import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };

  const useHook=()=>{
    // const colorPost = async (body) => {
    //     try {
    //         const response = await AuthAPI.colorPost(body);
    //         console.log("color response", response)
    //        toastr.success("Color added successfully")
    //     }
    //     catch(error){
    //         alert(error)
    //         toastr.error(error)
    //     }
    // }
    // color get
    // const colorGet = async (body) => {
    //     try {
    //         const response = await AuthAPI.colorGet(body);
    //         console.log("color get response", response);
    //         toastr.success("successfully got color list");
    //         return response.data || [];
    //     } catch (error) {
    //         alert('There was an error getting the color list: ' + error.message);
    //         console.error('Error details:', error);
    //         return [];
    //     }
       
    // };
// material post
const MaterialPost = async (body) => {
    try {
        const response = await AuthAPI.materialPost(body);
        console.log("material response", response.data)
        toastr.success('Material added successfully')
        // alert(response.data.name)
        // alert("material successfull")
        
    }
    catch(error){
        alert(error)
        toastr.error('Error posting Material')
    }
}
const materialGet = async (body) => {
    try {
        const response = await AuthAPI.materialGet(body);
        // console.log('material response only',response)
        // console.log("material get response", response.data)
        // toastr.success('Material get successfully')
        // alert(response.data.name)
        // alert("material successfull")
        return response.data || [];
    }
    catch(error){
        // alert(error)
        toastr.error('Error posting Material')
        return [];
    }
}
const materialDelete = async (params) => {
    try {
        const response = await AuthAPI.materialDelete(params);
        return response;
        // console.log('material response only',response)
        // console.log("material get response", response.data)
        // toastr.success('Material get successfully')
        // alert(response.data.name)
        // alert("material successfull")
    }
    catch(error){
        // alert(error)
        toastr.error('Error deleting material')
        return [];
    }
}

    return {
// Post
            MaterialPost,
            
            // Get
            // colorGet,
            materialGet,

            // Delete
            materialDelete

        }
  }
export default useHook;