
import React, { useEffect, useRef, useState } from 'react'
import useHook from './usehook';
import { VendorProfile } from 'assets/img/index'
import { Download } from 'assets/img/index';
import Error from 'assets/img/profile/Error.png'
import toastr from 'toastr';

const BusinessTabs = ({ vendorData, editId }) => {
    const { imagePost, UpdateBusinessInfo } = useHook();
    const [selectedImage, setSelectedImage] = useState("");
    const [bannerImage, setBannerImage] = useState("");
    const [dba, setdba] = useState('');
    const [businesstype, setbusinessType] = useState('');
    const [businessdate, setbusinesdate] = useState('');
    const [ssnNumber, setSSNnumber] = useState('');
    const [businessLicense, setBusinessLicense] = useState('');
    const [BusinessName, setBusinessName] = useState("")
    const [errors, setErrors] = useState({});
    const [businessLicenseImagepath, setbusinessLicenseImagepath] = useState('');



    const maxDba = 30;
    const maxEIN = 11;
    const maxbusinesslicence = 20;
    const maxBusinessName = 150;
    
    const ssnPattern = /^\d{3}-\d{2}-\d{4}$/; // SSN pattern
    const einPattern = /^\d{2}-\d{7}$/; // EIN pattern
    
    const handleChange = (e) => {
        const value = e.target.value;
        setSSNnumber(value);
    
        // Reset error message
        setErrors((prevErrors) => ({ ...prevErrors, ssnNumber: '' }));
    
        // Validate the input against the patterns
        if (value && !ssnPattern.test(value) && !einPattern.test(value)) {
            setErrors((prevErrors) => ({ ...prevErrors, ssnNumber: 'Please enter a valid EIN or SSN.' }));
        }
    };
    
    const handleBlur = () => {
        // Final validation on blur
        if (!ssnPattern.test(ssnNumber) && !einPattern.test(ssnNumber)) {
            setErrors((prevErrors) => ({ ...prevErrors, ssnNumber: 'Please enter a valid EIN or SSN.' }));
        }
    };
    


    const validateFields = () => {
        const newErrors = {};

        if (!selectedImage) {
            newErrors.selectedImage = "Business logo is required.";
        }

        if (!bannerImage) {
            newErrors.bannerImage = "Business banner image is required.";
        }

        // if (dba && dba.length > maxDba) {
        //     newErrors.dba = `DBA must be less than ${maxDba} characters.`;
        // }

        if (!businesstype) {
            newErrors.businesstype = "Business type is required.";
        }

        if (!businessdate) {
            newErrors.businessdate = "Business start date is required.";
        }

        if (!ssnNumber) {
            newErrors.ssnNumber = "EIN/SSN is required.";
        } else if (ssnNumber.length > maxEIN) {
            newErrors.ssnNumber = `EIN/SSN must be less than ${maxEIN} characters.`;
        }

        if (!businessLicense) {
            newErrors.businessLicense = "Business license is required.";
        } else if (businessLicense.length > maxbusinesslicence) {
            newErrors.businessLicense = `Business license must be less than ${maxbusinesslicence} characters.`;
        }

        if (!BusinessName) {
            newErrors.businessName = "Business Name is required.";
        } else if (BusinessName.length > maxBusinessName) {
            newErrors.businessName = `Business Name must be less than ${maxBusinessName} characters.`;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    //   // Create refs for file inputs
    const imageInputRef = useRef(null);
    const bannerImageInputRef = useRef(null);
    const handleImageChange = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click();
            setErrors((prevErrors) => ({ ...prevErrors, selectedImage: '' }));
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage("");
    };

    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];

        try {
            const imagePath = await imagePost(file); // Ensure this is awaited
            console.log("FINAL: ", imagePath);

            if (imagePath) {
                setSelectedImage(imagePath);
              
                console.log("Uploaded Image URL:", imagePath);
            }
        } catch (error) {
            console.error("Error in handleFileInputChange: ", error);
            toastr.error("An error occurred during image upload");
        }
    };
    const handlebannerRemove = () => {
        setBannerImage("");
        const imageInputElement = document.getElementById('imageInput1');
        if (imageInputElement) {
            imageInputElement.value = '';
        } else {
            console.error("Element with ID 'imageInput1' not found.");
        }
    };

    const handleBannerChange = () => {
        if (bannerImageInputRef.current) {
            bannerImageInputRef.current.click();
            setErrors((prevErrors) => ({ ...prevErrors, bannerImage: '' }));
        }
    };
    const handleBannerFileInputChange = async (e) => {
        const file = e.target.files[0];
        try {
            const imagePath = await imagePost(file); // Ensure this is awaited
            console.log("FINAL: ", imagePath);

            if (imagePath) {
                setBannerImage(imagePath);
                console.log("Uploaded Image URL:", imagePath);
            }
        } catch (error) {
            console.error("Error in handleFileInputChange: ", error);
            toastr.error("An error occurred during image upload");
        }
    };
    const [loading, setLoading] = useState(false)
    const handleLicensePickerClick = () => {
        document.getElementById("businessLicenseInput").click();
    };
    const handleLicenseChange = async (event) => {
        const file = event.target.files[0];
        const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
        const maxSize = 5 * 1024 * 1024; // 5 MB
        try {
            setLoading(true);
            if (!allowedTypes.includes(file.type)) {
                console.log(`File ${file.name} is not allowed (type: ${file.type})`);
                return;
            }
            if (file.size > maxSize) {
                console.log(
                    `File ${file.name} exceeds the size limit (max: ${maxSize} bytes)`
                );
                return;
            }
            const uploadedFilePath = await imagePost(file);
            if (uploadedFilePath) {
                console.log(
                    "Business license uploaded successfully:",
                    uploadedFilePath
                );
                setbusinessLicenseImagepath(uploadedFilePath);
            }
        } catch (error) {
            console.error("Error in handleLicenseChange: ", error);
            toastr.error("An error occurred during business license upload");
        } finally {
            setLoading(false);
        }
    };
    const handleSubmit = async () => {
        if (validateFields()) {
            const params =
            {
                "businessLogo": selectedImage,
                "businessBannerImage": bannerImage,
                "dba": dba,
                "businessName": BusinessName,
                "businessType": businesstype,
                "businessLicenseNo": businessLicense,
                "businessSsin": ssnNumber,
                "businessStartDate": businessdate,
                "businessLicenseImage": businessLicenseImagepath
            }
            try {
                const response = await UpdateBusinessInfo(editId, params);
                if (response?.error) {
                    toastr.error(response.error); 
                } else {
                    toastr.success("Vendor Business info saved successfully");
                }
            } catch (error) {
                console.error("error updating business info")
            }

        } else {
            console.log("Form validation failed");
        }
    };

    useEffect(() => {
        if (editId) {
            setBannerImage(vendorData.businessInfo?.businessBannerImage);
            setSelectedImage(vendorData.businessInfo?.businessLogo);
            setBusinessLicense(vendorData.businessInfo?.businessLicenseNo);
            setBusinessName(vendorData.businessInfo?.businessName);
            setSSNnumber(vendorData.businessInfo?.businessSsin);
            setbusinesdate(vendorData.businessInfo?.businessStartDate);
            setbusinessType(vendorData.businessInfo?.businessType);
            setdba(vendorData.businessInfo?.dba);
            setbusinessLicenseImagepath(vendorData.businessInfo?.businessLicenseImage)
        }
    }, [editId])

    return (
        <>
            <div className="card border-0 overflow" >
                <div className="padding1  overflow-y-auto">
                    <div className="gap-4 form-container">
                        <div className="column">
                            <div className="">
                                <label htmlFor="">Business Logo</label>
                                <div className="card img-border">
                                    <div className="card-body" onClick={handleImageChange}>
                                        {selectedImage ? (
                                            <div>
                                                <div onClick={handleRemoveImage}>
                                                    <img
                                                        src={Error}
                                                        alt=""
                                                        className="profile-picture-margin-left"
                                                    />
                                                </div>
                                                <img
                                                    src={
                                                        process.env.REACT_APP_BASE_URL +
                                                        "/" +
                                                        selectedImage
                                                    }
                                                    alt="Uploaded"
                                                    className="width"
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <input
                                                    ref={imageInputRef}
                                                    type="file"
                                                    accept="image/*"
                                                    className="input-field driving-license-input-field"
                                                    hidden
                                                    onChange={handleFileInputChange}
                                                    id="imageInput"
                                                />
                                                <span className="profile-image-container">
                                                    <VendorProfile />
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                                {errors.selectedImage && (
                                    <p className="error">{errors.selectedImage}</p>
                                )}
                            </div>
                        </div>
                        <div className="w-100 h-100">
                            <label htmlFor="">Business Banner Image</label>
                            <div
                                className="background-img"
                                onClick={handleBannerChange}
                            >
                                {bannerImage ? (
                                    <>
                                        <div onClick={handlebannerRemove}>
                                            <img src={Error} alt="" className="banner-close" />
                                        </div>
                                        <img
                                            src={
                                                process.env.REACT_APP_BASE_URL + "/" + bannerImage
                                            }
                                            alt="banner-image"
                                            className="banner-image"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <div className="text-center pt-3">
                                            <input
                                                ref={bannerImageInputRef}
                                                type="file"
                                                accept="image/*"
                                                className="input-field driving-license-input-field"
                                                hidden
                                                onChange={handleBannerFileInputChange}
                                                id="imageInput1"
                                            />
                                            <Download className='img-width' />
                                            {/* <img src={Img} alt="" className=" img-width" /> */}
                                            <p className="m-0 banner-font">
                                                Upload Banner Image
                                            </p>
                                            <p className="banner-font">
                                                (Only *.jpeg, *.webp and *.png images will be
                                                accepted)
                                            </p>
                                        </div>
                                        {errors.bannerImage && (
                                            <p className="error">{errors.bannerImage}</p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* #======================= Name Start============================== */}
                    <div className='d-flex flex-column mt-2'>
                        <label htmlFor="Business Name">Business Name</label>
                        <input type="text" className='business-name-input' value={BusinessName} onChange={(e) => {setBusinessName(e.target.value)
                           ; setErrors((prevErrors) => ({ ...prevErrors, businessName: '' }));
                            }} />
                        {errors.businessName && (
                            <div className="error">
                                {errors.businessName}
                            </div>
                        )}
                    </div>
                    <div className='info-container-1'>
                        <div className="d-flex flex-column w-100">
                            <div className="dba-field mt-2">
                                <div>
                                    <label className="name-label">DBA (if applicable)</label>
                                </div>
                                <div className="d-flex flex-column">
                                    <div className="d-flex flex-row align-items-center">
                                        <input
                                            type="text"
                                            name=""
                                            id=""
                                            className="input-width-business"
                                            value={dba}
                                            onChange={(e) => {
                                                const newName = e.target.value;
                                                if (newName.length <= maxDba) {
                                                    setdba(newName);
                                                    
                                                }
                                            }}
                                        />
                                        <span
                                            style={{
                                                fontSize: "12px",
                                                left: "270px",
                                                position: "absolute",
                                            }}
                                        >
                                            {dba?.length}/{maxDba}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="business-type mt-2 w-100">
                            <label className="name-label">Type of Business</label>
                            <div className="d-flex flex-column ">
                                <div className="d-flex flex-row align-items-center">
                                    <select className="select-gender" value={businesstype} onChange={(e) => { setbusinessType(e.target.value);
                                         setErrors((prevErrors) => ({ ...prevErrors, businesstype: '' })); }}>
                                        <option value="" disabled selected>
                                            Select a Type
                                        </option>
                                        <option value="Sole Poprietorship">
                                            Sole Poprietorship
                                        </option>
                                        <option value="Partnership">
                                            Partnership
                                        </option>
                                        <option value="LLC">
                                            LLC
                                        </option>
                                        <option value="Corporation">
                                            Corporation
                                        </option>
                                    </select>
                                    <span
                                        style={{
                                            fontSize: "12px",
                                            left: "480px",
                                            position: "absolute",
                                        }}
                                    >
                                    </span>
                                </div>
                                {errors.businesstype && (
                                    <p className="error">{errors.businesstype}</p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="area-direction">
                        {/* email */}
                        <div className="email-container">
                            <label className="name-label">Date Business Started</label>
                            <div className="d-flex flex-column">
                                <input
                                    type="date"
                                    name=""
                                    id=""
                                    className="email-width"
                                    value={businessdate}
                                    onChange={(e) => { setbusinesdate(e.target.value); 
                                        setErrors((prevErrors) => ({ ...prevErrors, businessdate: '' })); }}
                                />
                                {errors.businessdate && <p className="error">{errors.businessdate}</p>}
                            </div>
                        </div>
                        {/* gender */}
                        <div className="gender-container"
                        >
                            <label htmlFor="">Business Licence No.</label>
                            <input style={{ position: 'relative', paddingRight: "50px" }}
                                type="text"
                                name=""
                                id=""
                                className="input-width-business"
                                value={businessLicense}
                                onChange={(e) => {
                                    const newBrandName = e.target.value;
                                    if (newBrandName.length <= maxbusinesslicence) {
                                        setBusinessLicense(newBrandName);
                                       
                                    }
                                    setErrors((prevErrors) => ({ ...prevErrors, businessLicense: '' }))
                                }}
                            />
                            <span
                                style={{
                                    fontSize: "12px",
                                    left: "570px",
                                    top: "460px",
                                    position: "absolute",
                                }}
                            >
                                {businessLicense?.length}/{maxbusinesslicence}
                            </span>
                            {errors.businessLicense && <p className="error">{errors.businessLicense}</p>}

                        </div>
                    </div>

                    {/* #======================= Email End============================== */}

                    {/* #======================= Contact Number Start============================== */}
                    <div className="area-direction">
                    <div className="phone-container">
    <label className="name-label">EIN/SSN</label>
    <div className="d-flex flex-column">
        <div className="d-flex flex-row align-items-center">
            {console.log(ssnNumber)}
            <input 
                style={{ paddingRight: "50px" }}
                type="text"
                name="ssnNumber"
                className="input-width"
                value={ssnNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                onWheel={(e) => e.target.blur()}
            />
        </div>
        {errors.ssnNumber && <p className="error" style={{fontSize:"13px"}}>{errors.ssnNumber}</p>}
    </div>
</div>

                        <div className="Vendor-Information-child">
                            <label className="main-label">
                                Upload Business Licence
                            </label>
                            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>

                                {businessLicenseImagepath && (
                                    <img src={`${process.env.REACT_APP_MEDIA_URL}${businessLicenseImagepath}`} style={{ width: "50px", height: "50px", borderRadius: "5px" }} alt="" />
                                )}

                                <button
                                    type="button"
                                    className="upload-btn"
                                    onClick={handleLicensePickerClick}
                                >
                                    Upload file
                                </button>
                                <input
                                    type="file"
                                    id="businessLicenseInput"
                                    style={{ display: "none" }}
                                    onChange={handleLicenseChange}
                                />

                            </div>

                            {/* <p>{businessLicenseImagepath}</p> */}
                        </div>

                    </div>

                    {/* #======================= Address Number End============================== */}

                    <div className="text-end mt-2">
                        <button
                            type="submit"
                            className="CreateVendor"
                            onClick={handleSubmit}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BusinessTabs;
