import React, { useState, useRef } from 'react';
import { Modal, Box, Typography } from '@mui/material';
import { Main, TabContent, Tab, TabsWrapper, Send } from './style';
import { IoMdClose } from "react-icons/io";
import { VendorProfile } from "assets/img"
import { FaChevronDown } from "react-icons/fa";
import useHooks from './usehook';
import toastr from "toastr";
import { useParams } from 'react-router-dom';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut",
};
const CustomTabs = ({handleClose,handleDisputes}) => {
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState(0);
    const { commentPost, imagePost } = useHooks();
    const [completedTabs, setCompletedTabs] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [uploadedImagePaths, setUploadedImagePaths] = useState([]);
    const [comment, setComment] = useState("");
    const [warning, setWarning] = useState("");
    const [status, setStatus] = useState("");
    const fileInputRef = useRef(null);

    const handleSend =async () => {
        if (!completedTabs.includes(activeTab)) {
            setCompletedTabs([...completedTabs, activeTab]);
        }
        if (!comment && !status && !warning && uploadedImagePaths.length === 0) {
            toastr.error(
                "Please fill in at least one required field or upload at least one image."
            );
            return;
        }

        const formData = {
            comment,
            warning,
            status: status, // This converts the status to 'initiated', 'in-progress', etc.
            images: uploadedImagePaths, // Array of image paths
        };
        console.log("Prepared API data:", formData);
       await commentPost(id, formData)
            .then((response) => {
                console.log("Form successfully submitted:", response);
                handleClose();
                setComment('')
                setWarning('');
                setStatus('');
                setUploadedImagePaths('')
                handleDisputes()
            })
            .catch((error) => {
                console.error("Error submitting the form:", error);
            });
    };

    const tabs = ["Comment", "Update Status", "Warning"];
    const handleImageChange = async (e) => {
        const files = Array.from(e.target.files);
        if (selectedImages.length + files.length > 5) {
            toastr.error("You can only upload up to 5 images.");
            return;
        }
        const imagePromises = files.map(async (file) => {
            setSelectedImages((prevImages) => [...prevImages, file]);
            const uploadedImagePath = await imagePost(file);
            if (uploadedImagePath) {
                setUploadedImagePaths((prevPaths) => [...prevPaths, uploadedImagePath]);
            }
        });
        await Promise.all(imagePromises);
    };


    const triggerFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger a click on the hidden file input
        } else {
            console.error("File input reference is null");
        }
    };
    const handleComment = async () => {
        const formdata =
        {
            "comment": "string",
            "warning": "string",
            "status": "initiated",
            "images": [
                "string"
            ]
        }
        try {
            const response = await commentPost(id, formdata)
            console.log("response is", response)
        } catch (error) {

        }
    }
    const removeImage = (indexToRemove) => {
    setSelectedImages((prevImages) =>
        prevImages.filter((_, index) => index !== indexToRemove)
    );
    setUploadedImagePaths((prevPaths) =>
        prevPaths.filter((_, index) => index !== indexToRemove)
    );
    };

    return (
        <>
            <TabsWrapper>
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        active={activeTab === index}
                        onClick={() => setActiveTab(index)}
                    >
                        {tab}
                    </Tab>
                ))}
            </TabsWrapper>
            <TabContent>
                {activeTab === 0 && <div>
                    <div className='comment-container'>
                        <span className='comment'>Comment</span>
                        <textarea className='textarea'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        ></textarea>
                        <div className='image-container gap-2'>
                            <div className='selected-images mt-2 gap-2'>
                                {selectedImages.map((image, index) => (
                                    <div key={index} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                                        <img
                                            key={index}
                                            src={URL.createObjectURL(image)}
                                            alt={`Selected ${index}`}
                                            className='thumbnail'
                                        />
                                        <IoMdClose style={{
                                            position: 'absolute',
                                            top: '-10px',
                                            right: '-5px',
                                            backgroundColor: 'red',
                                            color: 'white',
                                            border: 'none',
                                            borderRadius: '50%',
                                            width: '20px',
                                            height: '20px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            cursor: 'pointer',
                                        }} onClick={() => removeImage(index)} />
                                    </div>

                                ))}
                            </div>
                            <div className='mt-2' onClick={triggerFileInput}>
                                <VendorProfile className='vendor-pic' />
                                <input
                                    ref={fileInputRef}
                                    id='file-upload'
                                    type='file'
                                    accept='image/*'
                                    onChange={handleImageChange}
                                    style={{ display: 'none' }}
                                    multiple
                                />
                            </div>
                        </div>
                    </div>
                </div>}
                {activeTab === 1 && <div>
                    <div className='select-conatiner'>
                        <select className='select-input'
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <option value="">Select Status</option>
                            <option value="initiated">Initiated</option>
                            <option value="resolved">Resolved</option>
                            <option value="admin_review">Admin Review</option>
                            <option value="returned">Returned</option>
                        </select>
                        <FaChevronDown className="custom-arrow" />
                    </div>
                </div>}
                {activeTab === 2 && <div>
                    <span className='comment'>Warning</span>
                    <textarea className='textarea'
                        value={warning}
                        onChange={(e) => setWarning(e.target.value)}
                    ></textarea>

                </div>}
                <div className='btn-container'>
                    <Send onClick={handleSend}>Send</Send>
                </div>
            </TabContent>

        </>
    );
};

const OrderDisputeModal = ({ open, handleClose, disputelist,handleDisputes }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
        }).format(date);
    };
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 600,
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        border: "none",
                        borderRadius: '20px 20px 10px 10px',
                    }}
                >
                    {console.log(disputelist)}
                    <Main>
                        <Typography className='header'>
                            <Typography variant="h6" component="h2">
                                Order Dispute
                            </Typography>
                            <IoMdClose className='close-icon' onClick={handleClose} />
                        </Typography>
                        <div className='container-body'>
                            <div className='row1'>
                                <div className='direction1'>
                                    <label className='label-color'>Dispute ID</label>
                                    <span className='customerlabel'>{disputelist.id}</span>
                                </div>
                                <div className='direction1'>
                                    <label className='label-color'>Dispute Date</label>
                                    <span className='customerlabel'>
                                        {disputelist?.order?.createdAt
                                            ? formatDate(disputelist.order.createdAt)
                                            : "N/A"}
                                    </span>
                                </div>
                                <div className='direction1'>
                                    <label className='label-color'>Dispute Amount</label>
                                    <span className='customerlabel'>
                                        ${disputelist.orderProduct?.totalPrice}
                                    </span>
                                </div>
                            </div>
                            <div className='row2'>
                                <div className='direction1'>
                                    <label className='label-color'>Customer</label>
                                    <span className='customerlabel'>
                                        {disputelist.order?.user?.name}
                                    </span>
                                </div>
                                <div className='direction1'>
                                    <label className='label-color'>Vendor</label>
                                    <span className='customerlabel'>
                                        {disputelist.order?.vendor?.name}
                                    </span>
                                </div>
                                <div className='direction1'>
                                    <label className='label-color'>Product Name</label>
                                    <span className='customerlabel dot-property'>
                                        {disputelist.orderProduct?.product.name}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <div className='reason-container'>
                                    <span className='reason'>Reason</span>
                                    <span className='reason-field'>{disputelist.reason}</span>
                                </div>
                            </div>
                            <div>
                                <CustomTabs   handleClose={handleClose} disputelist={disputelist} handleDisputes={handleDisputes}/>
                            </div>
                        </div>
                    </Main>

                </Box>
            </Modal>
        </>
    )
}

export default OrderDisputeModal;