import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHook = () => {
  const GetVendors = async (params) => {
    console.log("params hook",params)

    try {
      const response = await AuthAPI.GetVendors(params);
      return response;
    } catch (error) {
      alert(error.message);
      return null;
    }
  };

  const VendorsPut = async (id, status) => {
    try {
      const response = await AuthAPI.VendorsPut(id, { status });
      toastr.success("Switch updated successfully");
      return response.data || [];
    } catch (error) {
      alert("There was an error posting the switch: " + error.message);
      console.error("Error details:", error);
      return [];
    }
  };

  const VenderDelete=async(id)=>{
    try{
        const response = await AuthAPI.venderDelete(id); 
        return response
    }
    catch(error){
        console.error(error);
              toastr.error("Error. Try Agan Later")
              return null;
    }
}


  return { GetVendors, VendorsPut,VenderDelete };
};

export default useHook;
