import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

const useHook = () => {
  const productlisting = async (body) => {
    try {
      const response = await AuthAPI.productlist(body);
      return response;
    } catch (error) {
      console.error("error is", error);
    }
  };

  const getcategory = async (params) => {
    try {
      const response = await AuthAPI.getcategory(params);
      //   console.log(response,'Category')
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };

  return {
    productlisting,
    getcategory,
  };
};

export default useHook;
