import styled from 'styled-components';


export const Main = styled.div`
    width: 100%;
    height: 100vh;
    overflow: hidden;
    .auth-container {
        flex: 1;
        overflow: hidden;  /* Prevents overflow on the main container */
    }
    .auth-routes{
        overflow-y: hidden;

    }
 
    .img-con {
        width: 100%;  /* Use 100% to ensure image is responsive */
        height: auto;  /* Keeps aspect ratio */
    }

    @media (max-width: 1600px) {
        .auth-routes {
            padding: 1rem;  /* Adds padding on smaller screens */
        }
        
        .img-con {
            width: 100%;  /* Responsive width for smaller screens */
        }
    }
    @media (max-width: 1200px) {
        .auth-routes {
            padding: 1rem;  /* Adds padding on smaller screens */

        }
        
        .img-con {
            width: 100%;  /* Responsive width for smaller screens */
            height: 100vh;
            /* display: none; */
        }
    }

    @media (max-width: 768px) {
        .row {
            flex-direction: column;  /* Stacks columns on small screens */
        }
        .col-6 {
            width: 100%;  /* Full width on smaller screens */
        }
        .img-con {
            width: 100%;  /* Responsive width for smaller screens */
            display: none;
        }
    }

`;