import React from 'react'
import Sofa from 'assets/img/loader/sofa.png'
import UpperLoader from 'assets/img/loader/Ellipse 3958.png'
import LowerLoader from 'assets/img/loader/Ellipse 3959.png'
import Reactangular from 'assets/img/loader/Rectangle 23859.png'
import LotusPro from 'assets/img/sidebar/lotusPro.svg'
import { Main } from './style'
const Spinner = () => {
    return (
        <>
            <Main>
                <div className="spinner-container">
                    <div className="spinner-background">
                        {/* <img src={Reactangular} alt="Reactangular" className='rectangle' /> */}
                        <div className="spinner">
                            <div className="spinner-upper-half">
                                <img src={UpperLoader} alt='upperloader' className='upper-loader' />
                            </div>
                            <div className="spinner-lower-half">
                                <img src={LowerLoader} alt='lowerLoader' className='lower-loader' />
                            </div>
                        </div>
                        <img src={LotusPro} alt="Sofa" className="spinner-image" />
                    </div>
                </div>
            </Main>
        </>
    )
}

export default Spinner
