import { AuthAPI } from "libs/http-service/api/auth.api";
import { useNavigate } from 'react-router-dom'
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
  };

const useHooks = () => {
    const navigate  = useNavigate();
    const login = async (body) => {
        try {
            const response = await AuthAPI.login(body);
            if (response?.data?.token) {
                localStorage.setItem("Token", response.data.token)
                localStorage.setItem("Name", response.data.user.name)
                localStorage.setItem("Profile_Image", response.data.user.profile_pic)
                localStorage.setItem("id", response.data.user.id)
                
                navigate ('/');
                toastr.success("Login successful");
                
            }
            return response;
            
        } catch (error) {
            console.error("Login error:", error);
        }

    }
    const ForgetPassword = async (body) => {
        try {
          const response = await AuthAPI.forgetPassword(body);
          return response
          console.log("response");
        } catch (error) {
          toastr.error("Login error:", error);
        }
      };

      const VerifyOtp = async (body) => {
        try {
          const response = await AuthAPI.verifyOtp(body);
          return response
        } catch (error) {
          toastr.error("Login error:", error);
        }
      };
      const ChangePassword = async (body) => {
        try {
          const response = await AuthAPI.changePassword(body);
          return response
        } catch (error) {
          toastr.error("Login error:", error);
        }
      };

    return {
        login,ForgetPassword,VerifyOtp,ChangePassword
    }
}

export default useHooks;