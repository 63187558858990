import React, { useEffect, useState } from 'react'
import { Main } from './style'
import IOSSwitch from 'components/ios button'
import BannerModal from '../banner-modal'
import DeleteModal from 'components/modals/delete'
import { AdminLotusPRO, Delete } from 'assets/img'
import useHook from './usehooks'
import Spinner from 'components/loader'
import toastr from 'toastr';
import Viewer from "react-viewer";
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
};

const ManageBanners = () => {
    const { getBanners, bannersPut, bannerDelete } = useHook()
    const [banner, setbannerlist] = useState([]);
    const [switches, setSwitches] = useState([]);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false)
    const [deleteID, setDeleteID] = useState(null);
    const [loading, setloading] = useState(false)
    const [limit, setlimit] = useState();
    const handleBanner = async () => {
        setloading(true)
        try {
            const response = await getBanners();
            setbannerlist(response);
            const bannerCount = response.length;
            setlimit(bannerCount);
            setSwitches(response.map(item => item.status));
        }
        catch (error) {
            console.error("Error", error.message)
        } finally {
            setloading(false)
        }
    }
    useEffect(() => {
        handleBanner()
    }, [])


    const handleSwitchToggle = async (index, itemID) => {
        // Create a copy of the switches array
        const newSwitches = [...switches];
        // Toggle the switch
        newSwitches[index] = !newSwitches[index];

        setSwitches(newSwitches);
        const status = newSwitches[index];
        const name = "String"

        try {
            await bannersPut(itemID, status, name);
        } catch (error) {
            console.error('Error in handleSwitchToggle: ', error);
            setSwitches((prevSwitches) => [...prevSwitches]);
            return;
        }
    };
    // delete modal
    const handleOpenDeleteModal = (id) => {
        setDeleteID(id);
        setDeleteModalOpen(true);
    };

    const handleCloseDeleteModal = () => {
        setDeleteID(null);
        setDeleteModalOpen(false);
    };
    const handleConfirmDelete = async () => {
        if (deleteID !== null) {
            try {
                await bannerDelete(deleteID);
                const newdata = banner.filter((catalog) => catalog.id !== deleteID);
                setbannerlist(newdata);
                handleCloseDeleteModal();
            } catch (error) {
                console.error("Error deleting catalog:", error);
            }
        }
    };
    useEffect(() => {
        if (!deleteModalOpen) {
            handleBanner()
        }
    }, [deleteModalOpen])
    const handleBannerCreation = () => {
        toastr.error("You can only make 5 banners");
    };

        // image preview
        const [visible, setVisible] = useState(false);
        const [currentImage, setCurrentImage] = useState(null); // Store the currently clicked image
        const handleImageClick = (imageSrc) => {
            setCurrentImage(imageSrc); // Set the current image to show in viewer
            setVisible(true); // Open the viewer
        };
    return (
        <Main>
            {loading ? (
                <Spinner />
            ) : (
                <>
                    <div className='container-fluid'>
                        <div className='heading-flex'>
                            <h1 className='heading m-0'>Banners</h1>
                            <button className='banner-button'
                            >
                                {limit === 5 ? (
                                    <span onClick={handleBannerCreation}>Add new Banner</span>
                                ) : (
                                    <BannerModal getBanner={handleBanner} />
                                )}
                            </button>
                        </div>


                        {banner.length > 0 ? (banner.map((item, index) => (
                            <div className='card mt-3 border-0' key={index} >
                                <div className='card-body'>
                                    <div className='d-flex flex-wrap justify-content-between align-items-center banner-container'>
                                        <div className='d-flex align-items-center'>
                                            {item.image ? (
                                                <img onClick={()=>handleImageClick(`${process.env.REACT_APP_MEDIA_URL}/${item.image}`)}
                                                    src={`${process.env.REACT_APP_MEDIA_URL}${item.image}`}
                                                    alt="logo"
                                                    className='banner-img' style={{cursor:"pointer"}}
                                                />
                                            ) : (
                                                <AdminLotusPRO style={{ width: "100px", height: "100px" }} />
                                            )}
                                            {visible && currentImage && (
                                                <Viewer
                                                    visible={visible}
                                                    onClose={() => setVisible(false)}
                                                    images={[
                                                        { src: currentImage, alt: "Image" },
                                                    ]} // Only show the clicked image
                                                />
                                            )}
                                            <div className='ms-5'>
                                                <p className='m-0 banner-name'>{item.name}</p>
                                            </div>
                                        </div>
                                        <div className='d-flex action-container'>
                                            <div className='me-5'>
                                                <label className='pb-2 enable'>Enable/ Disable</label>
                                                <div className='mt-2'>
                                                    <IOSSwitch checked={switches[index]} onChange={() => handleSwitchToggle(index, item.id)} />
                                                </div>
                                            </div>
                                            <div className='me-4 d-flex flex-column gap-2'>
                                                <label className='pb-2 enable'>Actions</label>
                                                <div>
                                                    <span className='delete-icon' onClick={() => handleOpenDeleteModal(item.id)}>
                                                        {/* <img src={Delete} alt='' className='delete-width' /> */}
                                                        <Delete />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))) : (
                            <p className='no-record'>No Banner Created</p>
                        )}
                    </div>

                </>
            )}


            <DeleteModal
                open={deleteModalOpen}
                handleClose={handleCloseDeleteModal}
                handleDelete={handleConfirmDelete}
            />
        </Main>
    )
}

export default ManageBanners
