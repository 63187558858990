import styled from "styled-components";
// 
export const Main = styled.div`

.heading{
    background-color:#156A3D ;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
}
.text-container{
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
}
.filter{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: white;
}
.reset{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    background-color: #E2E8F0;
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    border: 0px;
    color: #808D9E;
    margin-left: 6px;
}


`