import React, { useEffect, useState } from "react";
import styled from "styled-components";
// Styled component for custom date range picker
const StyledDateRangePickerWrapper = styled.div`
  display: flex;
  /* gap: 10px; */
  justify-content: center;
  align-items: center;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid lightgray !important;
  input {
    /* padding: 16.5px 11px; */
    font-family: inter, sans-serif;
    font-size: 13px;
    font-weight: 600;
    padding-left: 10px;
    color: #252D31;
    border: none;
    border-width: 1px;
    box-sizing: content-box;
    /* background: none; */
    line-height: 22px;
    height: 48px;
    margin: 0;
    width: 100px; /* Adjust width for better visibility */
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    outline: none;
    box-shadow: none;
    cursor: pointer;
  }
  .date-range-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100% !important;
  

  }
  label {
    color: white;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    padding: 0;
    position: relative;
    display: block;
    transform-origin: top left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 32px);
    pointer-events: auto;
    user-select: none;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(14px, 12px) scale(0.75);
  }
  input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    color: #252d31;
    opacity: 1;
    display: block;
  }
`;
const CustomDateRangePicker = ({
  onDateChange,
  initialStartDate,
  initialEndDate,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  useEffect(() => {
    // Trigger onDateChange when the start or end date changes
    onDateChange({ startDate, endDate });
  }, [startDate, endDate]);
  return (
    <StyledDateRangePickerWrapper>
      <input
        type="date"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <span className="to">-</span>
      <input
        type="date"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
    </StyledDateRangePickerWrapper>
  );
};
export default CustomDateRangePicker;