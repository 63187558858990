import React, { useEffect, useState, useRef } from "react";
import { Main } from "./style";
// import Img from '../../../Assets/img/Ellips.png'
import ChartDasboard from "../chart";
import { useNavigate } from "react-router-dom";
import Loader from 'components/loader'
import { TotalUsers, DeactivateUser, DeactivateVendor, OrderCancel, OrderDelivered, OrderPending, OrderReturns, OrderProcessing, TotalCategories, TotalOrders, TotalProducts, TotalVendor } from 'assets/img/index'
import useHook from "./usehook";
import CustomDateRangePicker from "components/CustomDateRangePicker";
import moment from "moment";
import { Chart } from "chart.js";
import { Calender } from 'assets/img/index'
const Home = () => {
  const { getDashboardTopCards, GraphOrdersGet, GraphDataGet } = useHook();
  const [cards, setCards] = useState({})
  const handleDashbaordTopCards = async () => {
    try {
      const response = await getDashboardTopCards();
      console.log("dashbaord top titles", response)
      setCards(response.data)
    } catch (error) {
      console.error("error i getting dashboard top cards");
    }
  }
  useEffect(() => {
    handleDashbaordTopCards();
  }, [])

  const datas = [
    { index: 1, image: TotalUsers, number: cards.totalUsers, text: "Total Users", bgColor: "rgba(55, 148, 23, 0.1)" },
    { index: 2, image: DeactivateUser, number: cards.deactivatedUsers, text: "Deactivate  Users", bgColor: "rgba(55, 71, 79, 0.1)" },
    // { index: 3, image: Approvel, number: "10", text: "Approval Pending" },
  ];

  const vendor = [
    { index: 1, image: TotalVendor, text: "Total Vendor", number: cards.totalVendors, bgColor: "rgba(244, 183, 51, 0.1)" },
    { index: 2, image: DeactivateVendor, text: "Deactive Vendor", number: cards.deactivatedVendors, bgColor: "rgba(55, 71, 79, 0.1)" },
    { index: 3, image: TotalCategories, text: "Total categories", number: cards.totalCategories, bgColor: "rgba(189, 137, 255, 0.1)" },
    { index: 4, image: TotalProducts, text: "Total Products", number: cards.products?.total, bgColor: "rgba(0, 78, 151, 0.1)" },
  ];

  const Order = [
    { index: 1, svg: TotalOrders, text: "Total Order", number: cards.order?.total, bgColor: "rgba(251, 146, 60, 0.1)" },
    { index: 2, svg: OrderPending, text: "Order Pending", number: cards.order?.pending, bgColor: "rgba(146, 61, 255, 0.1)" },
    { index: 3, svg: OrderProcessing, text: "Orders Processing", number: cards.order?.processing, bgColor: "rgba(64, 209, 255, 0.1)" },
    { index: 4, svg: OrderDelivered, text: "Orders Delivered", number: cards.order?.delivered, bgColor: "rgba(2, 180, 86, 0.1)" },
    { index: 5, svg: OrderCancel, text: "Order Cancel", number: cards.order?.canceled, bgColor: "rgba(255, 16, 16, 0.1)" },
    { index: 6, svg: OrderReturns, text: "Order Returns", number: cards.order?.returned, bgColor: "rgba(139, 86, 37, 0.1)" },
  ];

  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("Token");
    if (!token) {
      return navigate("/auth");
    }
  }, [navigate]);

  // graph
  const [noData, setNoData] = useState(false);
  const chartContainer = useRef(null);
  const [graphDataList, setGraphDataList] = useState([]);
  const [ordersDataList, setOrdersDataList] = useState([]);
  const [currentGraph, setCurrentGraph] = useState("sales"); // 'sales' or 'orders'
  //////////////////////////////////////////////////////
  const getTodayDate = () => {
    const today = new Date();
    return today.toISOString().split("T")[0];
  };
  const getOneMonthAgoDate = () => {
    const today = new Date();
    const oneMonthAgo = new Date(today.setMonth(today.getMonth() - 1));
    return oneMonthAgo.toISOString().split("T")[0];
  };
  const [dateRange, setDateRange] = useState({
    startDate: getOneMonthAgoDate(),
    endDate: getTodayDate(),
  });
  const [utcOffset, setUtcOffset] = useState(0);
  // Function to get a moment object with a dynamic offset
  const getMomentWithDynamicOffset = (offsetMinutes) => {
    const now = moment();
    return now.clone().utcOffset(offsetMinutes);
  };
  useEffect(() => {
    // Set the desired offset in minutes
    const offsetMinutes = 300;
    const momentWithOffset = getMomentWithDynamicOffset(offsetMinutes);
    // Update state with the UTC offset
    setUtcOffset(momentWithOffset.utcOffset());
  }, []); // Empty dependency array means this runs once when the component mounts
  const fetchGraphData = async () => {
    try {
      const { startDate, endDate } = dateRange;
      const formattedDateRange = `?startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;
      setGraphDataList([]); // Reset the state before fetching new data
      const response = await GraphDataGet(
        `${formattedDateRange}&offset=${utcOffset}`
      );
      console.log("Graph Data API Response:", response); // Check data
      if (response && Object.keys(response).length > 0) {
        const data = Object.entries(response).map(([date, price]) => ({
          date,
          price,
        }));
        setGraphDataList(data);
        setNoData(false);
      } else {
        setNoData(true);
      }
    } catch (error) {
      console.error("Error fetching graph data:", error);
      setNoData(true);
    }
  };
  const fetchOrdersData = async () => {
    try {
      const { startDate, endDate } = dateRange;
      const formattedDateRange = `?startDate=${encodeURIComponent(
        startDate
      )}&endDate=${encodeURIComponent(endDate)}`;
      const response = await GraphOrdersGet(
        `${formattedDateRange}&offset=${utcOffset}`
      );
      console.log("Orders Data API Response:", response);
      if (response && Object.keys(response).length > 0) {
        const data = Object.entries(response).map(([date, price]) => ({
          date,
          price,
        }));
        setOrdersDataList(data);
        setNoData(false);
      } else {
        setNoData(true);
      }
    } catch (error) {
      console.error("Error fetching orders data:", error);
      setNoData(true);
    }
  };
  useEffect(() => {
    if (utcOffset !== undefined) {
      fetchGraphData();
      fetchOrdersData();
    }
  }, [utcOffset, dateRange]); // Run when utcOffset or dateRange changes
  useEffect(() => {
    if (chartContainer.current) {
      const ctx = chartContainer.current.getContext("2d");
      let dates = [],
        dataPoints = [],
        label = "",
        borderColor = "";
      // Check which graph to display
      if (currentGraph === "sales" && graphDataList.length > 0) {
        dates = graphDataList.map((entry) => entry.date);
        dataPoints = graphDataList.map((entry) => entry.price);
        label = "Sales";
        borderColor = "#0C3A2D";
      } else if (currentGraph === "orders" && ordersDataList.length > 0) {
        dates = ordersDataList.map((entry) => entry.date);
        dataPoints = ordersDataList.map((entry) => entry.price);
        label = "Orders";
        borderColor = "#FFBB02";
      } else {
        // No data to display
        return;
      }
      // Clear existing chart if any
      if (window.myChart) {
        window.myChart.destroy();
      }
      const myChart = new Chart(ctx, {
        type: "line",
        data: {
          labels: dates,
          datasets: [
            {
              label: label,
              data: dataPoints,
              fill: false,
              borderColor: borderColor,
              tension: 0.1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 0,
                callback: function (value) {
                  return value + "";
                },
              },
            },
            x: {
              beginAtZero: true,
              ticks: {
                autoSkip: true,
                maxTicksLimit: 10,
              },
            },
          },
        },
      });
      // Store chart instance for cleanup
      window.myChart = myChart;
      // Cleanup function
      return () => {
        if (window.myChart) {
          window.myChart.destroy();
          window.myChart = null;
        }
      };
    }
  }, [currentGraph, graphDataList, ordersDataList]);

  return (
    <Main>
      <div className="background">
        <div className="container-fluid">
          <h2 className="dashboard-name">Dashboard Overview</h2>
          {/* #----------------------------------------------------- */}
          <div className="flex-dates">
            {datas.map((items) => {
              const Datas = items.image
              return (
                <div className="card card-width w-100 ">
                  <div className="card-body">
                    <div className="w-100">
                      <div style={{ backgroundColor: items.bgColor, padding: "5px", width: "35px", borderRadius: "5px" }}>
                        <Datas />
                      </div>
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <p className="m-0 para">{items.text}</p>
                        <h2 className=" number">{items.number}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            )}
          </div>

          {/* #----------------------------------------------------- */}

          <div className="flex-vendor">
            {vendor.map((items) => {
              const VendorIcon = items.image
              return (
                <div className="card second-card">
                  <div className="card-body">
                    {/* <img src={items.image} alt="" className="second-cards" /> */}
                    <div style={{ backgroundColor: items.bgColor, padding: "5px", width: "30px", height: "30px", borderRadius: "5px", display: "flex", alignItems: "center" }}>
                      <VendorIcon style={{ width: "30px", height: "30px" }} />

                    </div>
                    <p className="m-0 padding vendor-style">{items.text}</p>
                    <p className="m-0 vendor-number">{items.number}</p>
                  </div>
                </div>
              )
            }

            )}
          </div>

          {/* #----------------------------------------------------- */}


          <div className="order-flex">
            {Order.map((items) => {
              const OrderIcon = items.svg
              return (
                <div className="card third-card border-1 order-list">
                  <div className="card-body">
                    <div className="third-flex">
                      <div style={{ backgroundColor: items.bgColor, padding: "5px", width: "50px", height: "50px", borderRadius: "30px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <OrderIcon style={{ width: "25px", height: "25px" }} />
                      </div>
                      <div className="margin">
                        <p className="m-0 order-para">{items.text}</p>
                        <p className="m-0 order-number">{items.number}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }

            )}
          </div>
          <div className="card mt-3" style={{ border:"1px solid #EDF2F7" }}>
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center">
                <p className="SalesStatistics m-0">Sales Statistics</p>
                <div className="d-flex flex-row align-items-center">
                  <span className="calender">
                    <Calender />
                  </span>
                  <div>
                    <CustomDateRangePicker
                      onDateChange={(dates) => setDateRange(dates)}
                      initialStartDate={dateRange.startDate}
                      initialEndDate={dateRange.endDate}
                    />
                  </div>
                </div>

              </div>
              <div className="mt-3">
                <button type="button" className="sales" onClick={() => {
                  setCurrentGraph("sales");
                  fetchGraphData()
                }}>
                  Sales
                </button>{" "}
                &nbsp;
                <button type="button" className="Orders" onClick={() => {
                  setCurrentGraph("orders");
                  fetchGraphData()
                }}>
                  Orders
                </button>
              </div>
              <div className="chart-container">
                {(graphDataList.length > 0 ||
                  Object.keys(ordersDataList).length > 0) && (
                    <canvas
                      ref={chartContainer}
                      style={{ height: "442.46px" }}
                    />
                  )}
              </div>
              {/* <ChartDasboard /> */}
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Home;
