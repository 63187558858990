import React, { useState, useEffect } from "react";
import { Main, Input } from "./style";
import useHook from "../disputes/usehook";
import PaginationRounded from "components/pagination";
import { useNavigate } from "react-router-dom";
import { View } from "assets/img";

const InitiatedDisputes = () => {
  const { getDisputes } = useHook();
  const navigate = useNavigate();
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [disputelist, setDisputelist] = useState([]);
  const [search, setSearch] = useState("");
  const fetchData = async (resetPage = false) => {
    const formData = {
      search: search,
      status: "initiated",
      page: resetPage ? 1 : currentPage,
      perPage: 500,
    };
    try {
      const response = await getDisputes(formData);
      console.log("Dispute initiated list is", response);
      setTotalPage(Math.ceil(response.data.counts / 10));
      setDisputelist(response.data.results);
    } catch (error) {
      console.log("dispute error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);
  // date format
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
};
  // const formatDate = (inputDate) => {
  //   // Create a date object from the input string
  //   const date = new Date(inputDate);

  //   // Array to convert month index to month name
  //   const months = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];

  //   // Get day, month, and year from the date object
  //   const day = date.getDate();
  //   const month = months[date.getMonth()];
  //   const year = date.getFullYear();

  //   // Return formatted date
  //   return `${day}-${month}-${year}`;
  // };
  // time format
  const formatTimeFromISO = (dateTimeString) => {
    const date = new Date(dateTimeString);

    // Get hours, minutes, and seconds
    let hours = date.getUTCHours(); // Use getUTCHours() to work with UTC time
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();

    // Determine AM or PM
    const period = hours >= 12 ? "PM" : "AM";

    // Convert 24-hour format to 12-hour format
    hours = hours % 12 || 12; // the hour '0' should be '12'

    // Format minutes and seconds to always show two digits
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    // Return the formatted time
    return `${hours}:${formattedMinutes} ${period}`;
  };
  const handleSearch = () => {
    setcurrentPage(1); // Reset the current page to 1
    fetchData(true); // Pass true to reset the page in fetchUser
  };
  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };
  const handleDisputeDetail = (id) => {
    navigate(`/disputes/dispute-detail/${id}`);
  }
  return (
    <Main>
      <Input>
        <div className="card border-0 mt-3">
          <div className="card-body">
            <div className="d-flex flex-row flex-nowrap gap-2">
              <div className="w-100">
                <input
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  type="text"
                  className="input-reason me-2 "
                  placeholder="Search by Reason"
                />
              </div>

              {/* <select className="dispute-list me-2">
                  <option value="1">Category</option>
                  <option value="2">status 1</option>
                  <option value="3">status 2</option>
                </select> */}

              <div className="w-25">
                <button
                  type="submit"
                  className="filter-dispute"
                  onClick={handleSearch}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Input>
      <div className="card border-0 mt-3">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table dummy-table w-100">
              <thead>
                <tr className="heading">
                  <th style={{ width: "10%" }}>Dispute ID</th>
                  <th style={{ width: "20%" }}>Customer</th>
                  <th style={{ width: "10%" }}>Invoice No</th>
                  <th style={{ width: "30%" }}>Reason</th>
                  <th style={{ width: "13%" }}>Date</th>
                  <th style={{ width: "10%" }}>Actions</th>
                  {/* <th>ACTIONS</th> */}
                </tr>
              </thead>
              <tbody>
                {disputelist.length === 0 ? (
                  <tr>
                    <td colSpan="8" className="no-record">
                      No Records found
                    </td>
                  </tr>
                ) : (
                  disputelist.map((data, index) => (
                    <tr key={index} className="sub-heading ">
                      <td className="top">{data.id}</td>
                      <td className="top">{data.order?.user?.name}</td>
                      <td className="top">{data.order.id}</td>
                      <td className="top">{data.reason}</td>
                      <td className="top">{formatDate(data.createdAt)}</td>
                      <td className="top">
                        <span className="view-icon"
                          onClick={() => handleDisputeDetail(data.id)}
                        >
                          <View />
                        </span>
                      </td>
                      {/* <td className='top'>
                                        <select name="" id="" className='pending'>
                                            <option value="1">Assign to Admin</option>
                                            <option value="2">Solved</option>
                                            <option value="3" selected>Pending</option>
                                            <option value="4">Declined</option>
                                        </select>
                                    </td> */}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>

          <PaginationRounded
            count={totalPage}
            page={currentPage}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </Main>
  );
};

export default InitiatedDisputes;
