import React, { useEffect, useState } from "react";
import { Main } from "./style";
import IOSSwitch from "components/ios button";
import { useNavigate } from "react-router-dom";
import useHook from "./usehooks";
import PaginationRounded from "components/pagination";
import DeleteModal from "components/modals/delete";
import Spinner from "components/loader";
import { AdminLotusPRO, Delete, View } from 'assets/img'
const UserManagement = () => {
  const naviagte = useNavigate();
  const { getUsers, deleteUser, userPutSwitches } = useHook();
  const Profile = (id) => {
    naviagte(`/user-management/user-profile/${id}`);
  };

  const [userlist, setUserlist] = useState([]);
  const [userlength, setuserlength] = useState('');
  const [search, setSearch] = useState('');
  const [switches, setSwitches] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteID, setDeleteID] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchUser = async (resetPage = false) => {
    const params = {
      search: search,
      role: "customer",
      page: resetPage ? 1 : currentPage,
      perPage: 10,
    }
    try {
      setLoading(true);
      const response = await getUsers(params);
      console.log("userlsit", response)
      setUserlist(response.data.results);
      setuserlength(response.data.counts)
      setTotalPage(Math.ceil(response.data.counts / 10));
      setSwitches(response.data.results.map(item => item.status));
      console.log("switches", switches)

    } catch (error) {
      console.log("error fetch userlist", error)
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    fetchUser()
  }, [currentPage])


  const handlePageChange = (e, value) => {
    setcurrentPage(value);

  };

  const handleSwitchToggle = async (index, itemID) => {
    // Create a copy of the switches array
    const newSwitches = [...switches];
    // Toggle the switch
    newSwitches[index] = !newSwitches[index];

    setSwitches(newSwitches);
    const status = newSwitches[index];

    try {
      await userPutSwitches(itemID, status);
    } catch (error) {
      console.error('Error in handleSwitchToggle: ', error);
      setSwitches((prevSwitches) => [...prevSwitches]);
      return;
    }
  };
  //  delete modal
  const handleOpenDeleteModal = (id) => {
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };
  const handleConfirmDelete = async () => {
    if (deleteID !== null) {
      try {
        await deleteUser(deleteID);
        const newdata = userlist.filter((user) => user.id !== deleteID);
        setUserlist(newdata);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Error deleting catalog:", error);
      }
    }
  };
  useEffect(() => {
    if (deleteModalOpen) {
      fetchUser();
    }
  }, [deleteModalOpen, currentPage])
  const handleSearch = () => {
    setcurrentPage(1); // Reset the current page to 1
    fetchUser(true); // Pass true to reset the page in fetchUser
  };
  const handleReset=()=>{
    setSearch('');
    fetchUser(true);
  }
  useEffect(() => {
    if (search === '') {
      fetchUser(true); 
    }
  }, [search]);
  
  return (
    <Main>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="background-color">
            <div className="container-fluid">
              <div className="heading-flex">
                <h1 className="heading m-0">User Management</h1>
              </div>

              <div className="width">
                <input
                  type="text" aria-autocomplete="off" autoComplete="off"
                  name="search"
                  value={search || ""}
                  onChange={(e) => setSearch(e.target.value)}
                  id=""
                  className="input1"
                  placeholder="Search by Name/ Email/ Phone"
                />
                <button className="search" onClick={handleSearch}>
                  Filter
                </button>
                <button type="submit" className="reset" onClick={handleReset}>
                    Reset
                  </button>
              </div>

              <div className="card mt-4 border-0 ">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="w-100 table-width">
                      <thead>
                        <tr className="flex">
                          <th style={{ paddingLeft: "55px" }}>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Gender</th>
                          <th className="text-center">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="waleed-margin">
                        {userlength <= 0 ? (
                          <tr>
                            <td colSpan={6}>
                              <div className="no-record">No Record Found</div>
                            </td>
                          </tr>
                        ) : (
                          userlist.map((item, index) => (
                            <tr
                              index={index}
                              key={item.id}
                              className="mt-3 flex2"
                              style={{ paddingTop: "10%" }}
                            >
                              <td className="text-style" style={{ paddingBlock: "15px" }}>
                                <div>
                                  <span>
                                    {item.profile_pic ? (
                                      <img
                                        src={
                                          item.profile_pic.startsWith("https://lh3.googleusercontent.com/")
                                            ? item.profile_pic
                                            : `${process.env.REACT_APP_MEDIA_URL}/${item.profile_pic}`
                                        }
                                        alt="sofa img"
                                        className="img-width"
                                      />
                                    ) : (
                                      <AdminLotusPRO className="img-width" />
                                    )}
                                  </span>
                                  <span className="ps-2">
                                    {item.name ? item.name : "-"}
                                  </span>

                                </div>
                                </td>
                              <td style={{ paddingBlock: "15px" }}>{item.email ? item.email : "-"}</td>
                              <td style={{ paddingBlock: "15px" }}>{item.phone ? item.phone : "-"}</td>
                              <td style={{ paddingBlock: "15px" }}>{item.gender ? item.gender : "-"}</td>
                              <td style={{ paddingBlock: "15px" }}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                                  <span>
                                    <IOSSwitch
                                      checked={switches[index]}
                                      onChange={() => handleSwitchToggle(index, item.id)}
                                    />
                                  </span>
                                  &nbsp;
                                  <span onClick={() => Profile(item.id)} className="view-icon">
                                    <View />
                                  </span>

                                  &nbsp;
                                  <span className="delete-icon" onClick={() => handleOpenDeleteModal(item.id)}>
                                    <Delete />
                                  </span>
                                </div>
                              </td>
                            </tr>
                          ))
                        )}

                      </tbody>
                    </table>
                  </div>

                  <PaginationRounded
                    count={totalPage}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <DeleteModal
            open={deleteModalOpen}
            handleClose={handleCloseDeleteModal}
            handleDelete={handleConfirmDelete}
          />
        </>
      )}

    </Main>
  );
};

export default UserManagement;





// import React, { useEffect, useState } from "react";
// import { Main } from "./style";
// // import circle from "../../../assets/img/user-management/circle.png";
// import Edit from "../../../assets/img/user-management/edit.png";
// import Delete from "../../../assets/img/user-management/delete.png";
// import IOSSwitch from "components/ios button";
// import { useNavigate } from "react-router-dom";
// import useHook from "./usehooks";
// import Lotuspro from 'assets/img/common/lotuspro.png'
// import PaginationRounded from "components/pagination";
// import DeleteModal from "components/modals/delete";
// import Spinner from "components/loader";
// const UserManagement = () => {
//   const naviagte = useNavigate();
//   const { getUsers, deleteUser, userPutSwitches } = useHook();
//   const Profile = (id) => {
//     naviagte(`/user-management/user-profile/${id}`);
//   };

//   const [userlist, setUserlist] = useState([]);
//   const [userlength, setuserlength] = useState('');
//   const [search, setSearch] = useState('');
//   const [switches, setSwitches] = useState([]);
//   const [currentPage, setcurrentPage] = useState(1);
//   const [totalPage, setTotalPage] = useState(1);
//   const [deleteModalOpen, setDeleteModalOpen] = useState(false)
//   const [deleteID, setDeleteID] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const fetchUser = async () => {
//     const params = {
//       search: search,
//       role: "customer",
//       page: currentPage,
//       perPage: 10,
//     }
//     try {
//       setLoading(true);
//       const response = await getUsers(params);
//       console.log("userlsit", response)
//       setUserlist(response.data.results);
//       setuserlength(response.data.counts)
//       setTotalPage(Math.ceil(response.data.counts / 10));
//       setSwitches(response.data.results.map(item => item.status));
//       console.log("switches", switches)

//     } catch (error) {
//       console.log("error fetch userlist", error)
//     }finally {
//       setLoading(false);
//     }
//   }
//   useEffect(() => {
//     fetchUser()
//   }, [currentPage])


//   const handlePageChange = (e, value) => {
//     setcurrentPage(value);
//   };

//   const handleSwitchToggle = async (index, itemID) => {
//     // Create a copy of the switches array
//     const newSwitches = [...switches];
//     // Toggle the switch
//     newSwitches[index] = !newSwitches[index];

//     setSwitches(newSwitches);
//     const status = newSwitches[index];

//     try {
//       await userPutSwitches(itemID, status);
//     } catch (error) {
//       console.error('Error in handleSwitchToggle: ', error);
//       setSwitches((prevSwitches) => [...prevSwitches]);
//       return;
//     }
//   };
//   //  delete modal
//   const handleOpenDeleteModal = (id) => {
//     setDeleteID(id);
//     setDeleteModalOpen(true);
//   };

//   const handleCloseDeleteModal = () => {
//     setDeleteID(null);
//     setDeleteModalOpen(false);
//   };
//   const handleConfirmDelete = async () => {
//     if (deleteID !== null) {
//       try {
//         await deleteUser(deleteID);
//         const newdata = userlist.filter((user) => user.id !== deleteID);
//         setUserlist(newdata);
//         handleCloseDeleteModal();
//       } catch (error) {
//         console.error("Error deleting catalog:", error);
//       }
//     }
//   };
//   useEffect(() => {
//     if (!deleteModalOpen) {
//       fetchUser();
//     }
//   }, [deleteModalOpen, currentPage])
//   return (
//     <Main>
//       <div className="background-color">
//         <div className="container-fluid">
//           <div className="heading-flex">
//             <h1 className="heading m-0">User Management</h1>
//           </div>

//           <div className="width">
//             <input
//               type="text"
//               name="search"
//               value={search || ""}
//               onChange={(e) => setSearch(e.target.value)}
//               id=""
//               className="input1"
//               placeholder="Search by Name/ Email/ Phone"
//             />
//             <button className="search" onClick={fetchUser}>
//               Search
//             </button>
//           </div>

//           <div className="card mt-4 border-0 ">
//             <div className="card-body">
//               {loading ? ( // Show spinner if loading
//                 <div>
//                   <Spinner />
//                 </div>
//               ) : userlength > 0 ? (
//                 <>
//                   <table className="w-100">
//                     <thead>
//                       <tr className="flex">
//                         <th>id</th>
//                         <th>Picture</th>
//                         <th>Name</th>
//                         <th>Email</th>
//                         <th>Phone</th>
//                         <th>Enable/Disable</th>
//                         <th>Actions</th>
//                       </tr>
//                     </thead>
//                     <tbody className="waleed-margin">
//                       {userlist.map((item, index) => {
//                         const startingIndex = (currentPage - 1) * 10 + 1; // Calculate the starting index for the current page
//                         return (
//                           <tr
//                             index={index}
//                             key={item.id}
//                             className="mt-3 flex2"
//                             style={{ paddingTop: "10%" }}
//                           >
//                             <td style={{ paddingBlock: "20px" }}>{startingIndex + index}</td> {/* Display the correct index */}
//                             <td style={{ paddingBlock: "20px" }}>
//                               <img
//                                 style={{ borderRadius: "30px" }}
//                                 src={
//                                   item.profile_pic
//                                     ? `${process.env.REACT_APP_MEDIA_URL}/${item.profile_pic}`
//                                     : Lotuspro
//                                 }
//                                 alt="profile"
//                                 width="50"
//                                 height="50"
//                               />
//                             </td>
//                             <td className="text-style" style={{ paddingBlock: "20px" }}>{item.name}</td>
//                             <td style={{ paddingBlock: "20px" }}>{item.email}</td>
//                             <td style={{ paddingBlock: "20px" }}>{item.phone}</td>
//                             <td style={{ paddingBlock: "20px" }}>
//                               <IOSSwitch
//                                 checked={switches[index]}
//                                 onChange={() => handleSwitchToggle(index, item.id)}
//                               />
//                             </td>
//                             <td style={{ paddingBlock: "20px" }}>
//                               <span onClick={() => Profile(item.id)} className="view-icon">
//                                 <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                   <g clip-path="url(#clip0_1971_40786)">
//                                     <path d="M15.1028 7.57304C14.9698 7.41807 11.7897 3.7832 7.72439 3.7832C3.65897 3.7832 0.479027 7.41807 0.345651 7.57319C0.0839761 7.87705 0.0839761 8.31794 0.345805 8.6221C0.479027 8.77706 3.65897 12.4118 7.72439 12.4118C11.7897 12.4118 14.9698 8.77676 15.103 8.62179C15.3647 8.31794 15.3647 7.87704 15.1028 7.57304ZM8.96615 5.96671C9.23952 5.81934 9.62073 5.98843 9.81748 6.34425C10.0142 6.70021 9.95209 7.10829 9.67872 7.25566C9.40551 7.40318 9.02415 7.23394 8.82724 6.87812C8.63064 6.52215 8.69263 6.11408 8.96615 5.96671ZM7.72439 11.0949C4.88458 11.0949 2.49474 8.94099 1.66756 8.09749C2.22614 7.52776 3.4979 6.36111 5.13918 5.66346C4.81905 6.14446 4.63137 6.7192 4.63137 7.33785C4.63137 9.02486 6.01604 10.3924 7.72424 10.3924C9.43243 10.3924 10.8173 9.02486 10.8173 7.33785C10.8173 6.7192 10.6297 6.14446 10.3096 5.66346C11.9509 6.36126 13.2225 7.52776 13.7811 8.09749C12.9539 8.94145 10.564 11.0949 7.72439 11.0949Z" fill="white" />
//                                   </g>
//                                   <defs>
//                                     <clipPath id="clip0_1971_40786">
//                                       <rect width="15.1498" height="14.9618" fill="white" transform="translate(0.149414 0.617188)" />
//                                     </clipPath>
//                                   </defs>
//                                 </svg>
//                               </span>


//                               &nbsp;
//                               <span className="delete-icon" onClick={() => handleOpenDeleteModal(item.id)}>
//                                 <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
//                                   <g clip-path="url(#clip0_2007_55096)">
//                                     <path d="M2.10938 5.01367L2.98034 15.5277C3.04275 16.286 3.68861 16.8804 4.44972 16.8804H12.4747C13.2358 16.8804 13.8817 16.286 13.9441 15.5277L14.815 5.01367H2.10938ZM6.00315 14.9136C5.74575 14.9136 5.5292 14.7133 5.51288 14.4526L5.02116 6.51954C5.00434 6.24824 5.21034 6.01487 5.48118 5.99806C5.76208 5.97836 5.98538 6.18678 6.00266 6.45807L6.49437 14.3911C6.51178 14.672 6.28949 14.9136 6.00315 14.9136ZM8.95391 14.4219C8.95391 14.6936 8.73398 14.9136 8.46219 14.9136C8.1904 14.9136 7.97048 14.6936 7.97048 14.4219V6.48882C7.97048 6.21703 8.1904 5.99711 8.46219 5.99711C8.73398 5.99711 8.95391 6.21703 8.95391 6.48882V14.4219ZM11.9032 6.51957L11.4115 14.4526C11.3954 14.7107 11.1802 14.9258 10.89 14.9126C10.6192 14.8958 10.4132 14.6624 10.43 14.3911L10.9217 6.45811C10.9385 6.18681 11.1762 5.98943 11.4432 5.99809C11.714 6.01491 11.92 6.24827 11.9032 6.51957Z" fill="white" />
//                                     <path d="M14.855 2.06257H11.9047V1.57085C11.9047 0.757423 11.243 0.0957031 10.4295 0.0957031H6.4958C5.68237 0.0957031 5.02065 0.757423 5.02065 1.57085V2.06257H2.07035C1.5272 2.06257 1.08691 2.50285 1.08691 3.046C1.08691 3.58909 1.5272 4.02944 2.07035 4.02944C6.59303 4.02944 10.3325 4.02944 14.855 4.02944C15.3981 4.02944 15.8384 3.58909 15.8384 3.046C15.8384 2.50285 15.3981 2.06257 14.855 2.06257ZM10.9213 2.06257H6.00408V1.57085C6.00408 1.29956 6.2245 1.07914 6.4958 1.07914H10.4295C10.7008 1.07914 10.9213 1.29956 10.9213 1.57085V2.06257Z" fill="white" />
//                                   </g>
//                                   <defs>
//                                     <clipPath id="clip0_2007_55096">
//                                       <rect width="16.7839" height="16.7839" fill="white" transform="translate(0.0703125 0.0957031)" />
//                                     </clipPath>
//                                   </defs>
//                                 </svg>
//                               </span>
//                             </td>
//                           </tr>
//                         );
//                       })}

//                     </tbody>
//                   </table>
//                   <PaginationRounded
//                     count={totalPage}
//                     page={currentPage}
//                     onChange={handlePageChange}
//                   />
//                 </>
//               ) : (
//                 <div className="no-record">
//                   No Record Found
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//       <DeleteModal
//         open={deleteModalOpen}
//         handleClose={handleCloseDeleteModal}
//         handleDelete={handleConfirmDelete}
//       />
//     </Main>
//   );
// };

// export default UserManagement;
