import React, { useEffect, useRef, useState } from 'react';
import { Main } from "./style";
import {
    Modal,
    Box,
    Tabs,
    Tab,
    Typography,
} from '@mui/material';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Error from "../../../assets/img/profile/Error.png";
import { MdClose } from "react-icons/md";
import { VendorProfile } from 'assets/img/index'
import toastr from "toastr";
import useHook from './usehook';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};

const TabPanel = ({ children, value, index, ...other }) => {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const CreateNewVendor = ({ open, handleNewVednorClose,GetVendorsData }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };


    const { createVendor, imagePost } = useHook();
    //   // Validation state
    const [errors, setErrors] = useState({});
    const [isValid, setIsValid] = useState(true);

    const [selectedImage, setSelectedImage] = useState("");
    const [firstName, setfirstName] = useState('')
    const [lastName, setlastName] = useState('')
    // const [name, setName] = useState("");
    // const [Brandname, setBrandName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setContactNumber] = useState("");
    const [gender, setGender] = useState('');
    const [dob, setDOB] = useState('');
    const [charges, setcharges] = useState(0.1);


    const maxfirstNamelength = 25;
    const maxlastNamelength = 25;

    const maxPhonelength = 15;
    const minPhoneLength = 11;
    // Create refs for file inputs
    const imageInputRef = useRef(null);
    const handleImageChange = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click();
        }
    };

    const handleFileInputChange = async (event) => {
        const file = event.target.files[0];

        try {
            const imagePath = await imagePost(file); // Ensure this is awaited
            console.log("FINAL: ", imagePath);

            if (imagePath) {
                setSelectedImage(imagePath);
                setErrors((prevErrors) => ({ ...prevErrors, selectedImage: '' }));
                console.log("Uploaded Image URL:", imagePath);
            }
        } catch (error) {
            console.error("Error in handleFileInputChange: ", error);
            toastr.error("An error occurred during image upload");
        }
    };
    const formatDate = (inputDate) => {
        // Create a date object from the input string
        const date = new Date(inputDate);

        // Get the year, month, and day
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0');

        // Return the formatted date in YYYY-MM-DD format
        return `${year}-${month}-${day}`;
    };

    const handleRemoveImage = () => {
        setSelectedImage("");
    };
    const handlePhoneChange = (newContactNumber) => {
        if (newContactNumber.length <= maxPhonelength) {
            setContactNumber(newContactNumber);
            setIsValid(newContactNumber.length >= minPhoneLength && newContactNumber.length <= maxPhonelength);
            setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
        }
    };
    const isValidPhoneNumber = (phone) => {
        // Check if phone number is between 11 and 15 digits
        return /^\d{11,15}$/.test(phone);
    };
    // const validateDateOfBirth = (inputDate) => {
    //     const validationErrors = {};
    //     if (!inputDate) {
    //         validationErrors.dob = "Date of birth is required";
    //     } else {
    //         const dobDate = new Date(inputDate);
    //         const currentDate = new Date();
    //         if (dobDate > currentDate) {
    //             validationErrors.dob = "Date of birth cannot be in the future";
    //         }
    //     }
    //     return validationErrors;
    // };

    const validateDateOfBirth = (inputDate) => {
        const validationErrors = {};
        
        if (!inputDate) {
            validationErrors.dob = "Date of birth is required";
        } else {
            const dobDate = new Date(inputDate);
            const currentDate = new Date();
    
            // Calculate 14 years ago from today
            const fourteenYearsAgo = new Date();
            fourteenYearsAgo.setFullYear(currentDate.getFullYear() - 14);
    
            if (dobDate > currentDate) {
                validationErrors.dob = "Date of birth cannot be in the future";
            } else if (dobDate > fourteenYearsAgo) {
                validationErrors.dob = "You must be at least 14 years old";
            }
        }
    
        return validationErrors;
    };
    
    const handleSubmit = (e) => {
        e.preventDefault();
        
        // Concatenate firstName and lastName
        const name = firstName + " " + lastName;
        // Validate form fields
        const validationErrors = {};
        if (!firstName) validationErrors.firstName = "First name is required";
        if (!lastName) validationErrors.lastName = "Last name is required";
        if (!dob) {
            validationErrors.dob = "Date of birth is required";
        } else {
            const dobErrors = validateDateOfBirth(dob);
            if (Object.keys(dobErrors).length > 0) {
                validationErrors.dob = dobErrors.dob;
            }
        }
        if (!gender) validationErrors.gender = "Gender is required";
        if (!email) validationErrors.email = "Email is required";
        else if (!isValidEmail(email))
            validationErrors.email = "Email is not valid";
        if (!phone) validationErrors.phone = "Phone is required";
        else if (!isValidPhoneNumber(phone))
            validationErrors.phone = "Phone number must be between 11 and 15 digits";
        // if (!address) validationErrors.address = "Address is required";
        if (!selectedImage) validationErrors.selectedImage = "Picture required";
        // if (!bannerImage) validationErrors.bannerImage = "Banner Image is required";

        setErrors(validationErrors);

        // If there are errors, do not submit the form
        if (Object.keys(validationErrors).length > 0) return;
        const formattedGender = gender.charAt(0).toUpperCase() + gender.slice(1).toLowerCase();
        let formData = {
            name: name,
            email: email,
            countryCode: "789",
            phone: phone,
            dob: dob,
            isoCode: "string",
            profile_pic: selectedImage,
            platformCharges: charges,
            gender: formattedGender,
        };

        createVendor(formData);
        // Reset the form fields
        handleNewVednorClose();
        setfirstName("");
        setlastName("");
        setEmail("");
        setDOB('');
        setGender("");
        setContactNumber("");
        setSelectedImage(null);
        GetVendorsData();
    };
    const handleCloseModal = () => {
        setErrors("");
        setDOB('');
        setGender('')
        setfirstName("");
        setlastName("");
        setEmail("");
        setGender("");
        setContactNumber("");
        setSelectedImage(null);
        handleNewVednorClose();
    };
    useEffect(() => {
        if (!open) {
            // Reset the form fields whenever the modal is closed
            setErrors({});
            setDOB('');
            setGender('');
            setfirstName('');
            setlastName('');
            setEmail('');
            setContactNumber('');
            setSelectedImage(null);
        }
    }, [open]);



    const isValidEmail = (email) => {
        // Simple regex for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };


    return (
        <Modal
            open={open}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box className="modal-container"
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    border: "none",
                    transform: "translate(-50%, -50%)",
                    width: 700,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    border: "none",
                    outline: "none",
                    borderRadius: "20px",
                    "@media (max-width: 585px)": {
                        width: 350,
                        height: "auto",
                        padding: 2,
                    },
                }}
            >
                <Main>
                    <div className="heading-container">
                        <Typography variant="h6" component="h2"></Typography>
                        <Typography variant="h6" component="h2">
                            Create Vendor
                        </Typography>
                        <MdClose className="close-icon" onClick={handleCloseModal} />
                    </div>
                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="info tabs" TabIndicatorProps={{
                        style: { backgroundColor: "#808D9E", height: "5px" }, // Tab indicator color
                    }}>
                        <Tab label="Vendor Information"
                            sx={{
                                color: tabIndex === 0 ? "#000" : "#808D9E", textTransform: "capitalize !important",// Change color based on selection
                                "&.Mui-selected": { color: "#000", fontWeight: 600, fontSize: "16px",textTransform:"capitalize" }, // Ensure selected color is applied
                            }} />
                    </Tabs>
                    <TabPanel value={tabIndex} index={0}>
                        <div className="card border-0 overflow" >
                            <div className="padding mt-4">
                                <div className="gap-4 form-container" >
                                    <div className="">
                                        <div className="column">
                                            <div className="card img-border">
                                                <div className="card-body" onClick={handleImageChange}>
                                                    {selectedImage ? (
                                                        <div>
                                                            <div onClick={handleRemoveImage}>
                                                                <img
                                                                    src={Error}
                                                                    alt=""
                                                                    className="profile-picture-margin-left"
                                                                />
                                                            </div>
                                                            <img
                                                                src={
                                                                    process.env.REACT_APP_BASE_URL +
                                                                    "/" +
                                                                    selectedImage
                                                                }
                                                                alt="Uploaded"
                                                                className="width"
                                                            />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            <input
                                                                ref={imageInputRef}
                                                                type="file"
                                                                accept="image/*"
                                                                className="input-field driving-license-input-field"
                                                                hidden
                                                                onChange={handleFileInputChange}
                                                                id="imageInput"
                                                            />
                                                            <span className="profile-image-container">
                                                                <VendorProfile />
                                                            </span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {errors.selectedImage && (
                                                <p className="error">{errors.selectedImage}</p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="w-100 h-100">
                                        <div className="w-100">
                                            <div className="first-name">
                                                <label className="name-label">
                                                    First Name
                                                </label>
                                                <input className="input-width1" list="" placeholder="First Name" autoComplete='off' value={firstName} onChange={(e) => {
                                                    const newName = e.target.value;
                                                    if (newName.length <= maxfirstNamelength) {
                                                        setfirstName(newName);
                                                    } setErrors((prevErrors) => ({ ...prevErrors, firstName: '' }));
                                                }} />
                                                <span
                                                    style={{
                                                        top: "42px",
                                                        fontSize: "12px",
                                                        left: "580px",
                                                        position: "absolute",
                                                    }}
                                                >
                                                    {firstName.length}/{maxfirstNamelength}
                                                </span>
                                                {errors.firstName && <p className="error">{errors.firstName}</p>}
                                            </div>
                                            <div className="last-name">
                                                <label className="name-label">
                                                    Last Name
                                                </label>
                                                <input className="input-width1" placeholder="Last Name" autoComplete='off' value={lastName} onChange={(e) => {
                                                    const newName = e.target.value;
                                                    if (newName.length <= maxlastNamelength) {
                                                        setlastName(newName);
                                                    } setErrors((prevErrors) => ({ ...prevErrors, lastName: '' }));
                                                }} />
                                                <span
                                                    style={{
                                                        top: "123px",
                                                        fontSize: "12px",
                                                        left: "580px",
                                                        position: "absolute",
                                                    }}
                                                >
                                                    {lastName.length}/{maxlastNamelength}

                                                </span>
                                                {errors.lastName && <p className="error">{errors.lastName}</p>}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="area-direction">
                                    {/* email */}
                                    <div className="email-container">
                                        <label className="name-label">Email</label>
                                        <div className="d-flex flex-column w-100">
                                            <input
                                                type="email" placeholder='Email'
                                                name=""
                                                id=""
                                                className="email-width"
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, email: '' })); }}
                                            />
                                            {errors.email && <p className="error">{errors.email}</p>}
                                        </div>
                                    </div>
                                    {/* gender */}
                                    <div className="gender-container" onChange={(e) => { setGender(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, gender: '' })); }}>
                                        <label>Gender</label>
                                        <select className="select-gender" value={gender}>
                                            <option value=''>Select Gender</option>
                                            <option value='Male'>Male</option>
                                            <option value='Female'>Female</option>
                                            <option value='Other'>other</option>
                                        </select>
                                        {errors.gender && <p className="error">{errors.gender}</p>}

                                    </div>
                                </div>

                                {/* #======================= Email End============================== */}

                                {/* #======================= Contact Number Start============================== */}
                                <div className="area-direction mt-2">
                                    <div className="phone-container">
                                        <label className="name-label">Phone</label>
                                        <div className="d-flex flex-column">
                                            <div className="d-flex flex-row align-items-center">
                                                <ReactPhoneInput
                                                    country={'us'}
                                                    value={phone}
                                                    onChange={handlePhoneChange}
                                                    inputClass="input-width"
                                                    tabIndex="-1"
                                                />
                                                <span
                                                    style={{
                                                        fontSize: "12px",
                                                        left: "280px",
                                                        position: "absolute",
                                                    }}
                                                >
                                                    {phone.length}/{maxPhonelength}
                                                </span>
                                            </div>
                                            {!isValid && (
                                                <span style={{ color: 'red', fontSize: '12px' }}>
                                                    Phone number must be between {minPhoneLength} and {maxPhonelength} digits.
                                                </span>
                                            )}
                                            {errors.phone && <p className="error">{errors.phone}</p>}
                                        </div>
                                    </div>
                                    <div className="date-of-birth-container">
                                        <label>Date of Birth</label>
                                        <input type="date" className="date-of-birth" value={dob} onChange={(e) => { setDOB(e.target.value); setErrors((prevErrors) => ({ ...prevErrors, dob: '' })); }} />
                                        {errors.dob && <p className="error">{errors.dob}</p>}
                                    </div>

                                </div>


                                <div className="text-end mt-2">
                                    <button
                                        type="submit"
                                        className="CreateVendor"
                                        onClick={handleSubmit}
                                    >
                                        Create Vendor
                                    </button>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Main>

            </Box>
        </Modal>
    );
};

export default CreateNewVendor;
