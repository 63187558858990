import React, { useEffect, useState } from "react";
import { Main } from "./style";
import IOSSwitch from "components/ios button";
import { useNavigate } from "react-router-dom";
import useHook from "./useHook";
import DeleteModal from "components/modals/delete";
import CreateVendor from "components/modals/create-vendor";
import Spinner from "components/loader";
import PaginationRounded from "components/pagination";
import { VscVerifiedFilled } from "react-icons/vsc";
import { AdminLotusPRO, View, Delete, Edit } from 'assets/img'
import Viewer from "react-viewer";

import toastr from "toastr";
import CreateNewVendor from "components/modals/create-new-vendor";
toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-top-right",
  preventDuplicates: true,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "3000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut"
};
const VendorManagement = () => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const naviagte = useNavigate();


  const VenderDetails = (id) => {
    naviagte(`/vendor-management/vendor-details/${id}`);
  };

  //   API
  const [switches, setSwitches] = useState([]);
  const [search, setSearch] = useState('');
  const [vendorsData, setVendorsData] = useState([]);
  const { GetVendors, VendorsPut, VenderDelete } = useHook();
  const [isVerified, setisVerified] = useState("");
  const [status, setStatus] = useState("");


  //   console.log(params)

  // const GetVendorsData = async (resetPage = false) => {
  //   const page = resetPage ? 1 : currentPage;
  //   const perPage = 10;
  //   const role = "vendor";


  //   const params = {
  //     role, page, perPage,
  //     ...(search && { search }),
  //     ...(isVerified !== "" && { isVerified }),  // only add if isVerified is truthy
  //     ...(status !== "" && { status })
  //   };
  //   console.log("params sge", params)
  //   try {
  //     setLoading(true);
  //     const response = await GetVendors(params);
  //     // console.log("here is the data",response);
  //     if (response && response.data && response.data.results) {
  //       const results = response.data.results;
  //       setVendorsData(results);
  //       setSwitches(results.map((item) => item.status));
  //       setTotalPage(Math.ceil(response.data.counts / 10));
  //       console.log(
  //         "set switches states",
  //         results.map((item) => item.status)
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Error fetching vendors:", error);
  //   } finally {
  //     setLoading(false); // Set loading to false after fetching data
  //   }
  // };

  const GetVendorsData = async (resetPage = false) => {
    const page = resetPage ? 1 : currentPage;
    const perPage = 10;
    const role = "vendor";

    const params = {
      role, page, perPage,
      ...(search && { search }),
      ...(isVerified !== "" && { isVerified }),  // only add if isVerified is truthy
      ...(status !== "" && { status })
    };

    try {
      setLoading(true);
      const response = await GetVendors(params);
      if (response && response.data && response.data.results) {
        const results = response.data.results;
        setVendorsData(results);
        setSwitches(results.map((item) => item.status));
        setTotalPage(Math.ceil(response.data.counts / 10));
      }
    } catch (error) {
      console.error("Error fetching vendors:", error);
    } finally {
      setLoading(false);
    }
  };

  // console.log(vendorsData);
  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };
  useEffect(() => {
    GetVendorsData();
  }, [currentPage]);

  const handleSwitchToggle = async (index, itemID) => {
    // Create a copy of the switches array
    const newSwitches = [...switches];
    // Toggle the switch
    newSwitches[index] = !newSwitches[index];

    setSwitches(newSwitches);
    const status = newSwitches[index];

    try {
      await VendorsPut(itemID, status);
    } catch (error) {
      console.error("Error in handleSwitchToggle: ", error);
      toastr.error("An error occurred while updating switch state");
      setSwitches((prevSwitches) => [...prevSwitches]);
      return;
    }
  };
  // delete modal
  const handleOpenDeleteModal = (id) => {
    console.log("delete id is", id);
    setDeleteID(id);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteID(null);
    setDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    if (deleteID !== null) {
      try {
        await VenderDelete(deleteID);
        const newdata = vendorsData.filter((data) => data.id !== deleteID);
        setVendorsData(newdata);
        handleCloseDeleteModal();
      } catch (error) {
        console.error("Error deleting catalog:", error);
      }
    }
  };
  useEffect(() => {
    if (!deleteModalOpen) {
      GetVendorsData()
    }
  }, [deleteModalOpen, currentPage])

  // vendor modal open 
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (!isOpen) {
      GetVendorsData();
    }
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const [vendorData, setVendorData] = useState({});
  const [editId, seteditId] = useState();
  const VenderEdit = (id, item) => {
    setVendorData(item);
    seteditId(id);
    setIsOpen(true);
  }
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
};
  // const formatDate = (inputDate) => {
  //   // Create a date object from the input string
  //   const date = new Date(inputDate);

  //   // Array to convert month index to month name
  //   const months = [
  //     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
  //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  //   ];

  //   // Get day, month, and year from the date object
  //   const day = date.getDate();
  //   const month = months[date.getMonth()];
  //   const year = date.getFullYear();

  //   // Return formatted date
  //   return `${day}-${month}-${year}`;
  // }
  // const handleSearch = () => {
  //   setcurrentPage(1); // Reset the current page to 1
  //   GetVendorsData(true); // Pass true to reset the page in fetchUser
  // };

  const [filterClicked, setFilterClicked] = useState(false);

  const handleFilter = () => {
    // When filter is clicked, update the state
    setFilterClicked(true);
    setcurrentPage(1); // reset page to 1 when filtering
  };

  const handleReset = () => {
    setSearch("");
    setisVerified("");
    setStatus("");
    setFilterClicked(true);  // Reset the filters and trigger data fetch
    setcurrentPage(1);
  };
  useEffect(() => {
    // Only fetch data if the filter button or reset button has been clicked
    if (filterClicked) {
      GetVendorsData(true);
      setFilterClicked(false); // Reset filterClicked after fetching data
    }
  }, [filterClicked]);


  // new vendor
  const [newVendor, setNewVendor] = useState(false);
  const handleNewVednorOpen = () => {
    setNewVendor(true)
  }
  const handleNewVednorClose = () => {
    setNewVendor(false)
  }
  useEffect(() => {
    if (!newVendor) {
      GetVendorsData()
    }
  }, [newVendor])

  // image preview
  const [visible, setVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null); // Store the currently clicked image
  const handleImageClick = (imageSrc) => {
    setCurrentImage(imageSrc); // Set the current image to show in viewer
    setVisible(true); // Open the viewer
  };

  return (
    <>
      <Main>
        {/* {loading ? (
          <div className="loader-container">
            <Spinner />
          </div>
        ) : ( */}
        <>
          <div className="background-color">
            <div className="vendor-management-screen">
              <div className="heading-flex">
                <h1 className="heading m-0">Vendor Management</h1>
                <button
                  type="submit"
                  className="new-vender"
                  onClick={() => handleNewVednorOpen()}
                >
                  Create New Vendor
                </button>
              </div>
              <div className="width">

                <input autoComplete="off" aria-autocomplete="off"
                  type="search"
                  name=""
                  id="" value={search}
                  className="input1"
                  onChange={(e) => setSearch(e.target.value)}
                  placeholder="Search by Name/ Email"
                />


                <select placeholder='status' className="status" value={isVerified} onChange={(e) => setisVerified(e.target.value)} >
                  <option value=""  >Select Verification Status</option>
                  <option value="true">Verified</option>
                  <option value="false">Not verified</option>
                </select>





                <select placeholder="Select Publish" className="status2" value={status} onChange={(e) => setStatus(e.target.value)} >
                  <option value="" >Select Status</option>
                  <option value="true">Active</option>
                  <option value="false">Inactive</option>
                </select>

                <div className=" btn-container">
                  <button type="button" className="search" onClick={handleFilter}>
                    Filter
                  </button>
                  <button type="button" className="reset" onClick={handleReset}>
                    Reset
                  </button>
                </div>


              </div>

              <div className="card mt-4 border-0 ">
                <div className="card-body">

                  <div className="table-responsive">
                    <table className="w-100 table">
                      <thead>
                        <tr className="flex">
                          <th style={{ width: "15%",paddingLeft:"82px" }}>Name</th>
                          <th style={{ width: "20%" }}>Business Name</th>
                          <th style={{ width: "18%" }}>Email</th>
                          <th style={{ width: "15%" }}>Creation Date</th>
                          <th style={{ width: "15%" }}>Phone</th>
                          <th style={{ width: "10%", textAlign: "center" }}>Actions</th>
                        </tr>
                      </thead>
                      <tbody className="waleed-margin">
                        {vendorsData.length === 0 ? (
                          <tr>
                            <td colSpan="6" className="no-record">
                              No Records Found
                            </td>
                          </tr>
                        ) : (vendorsData.map((item, index) => (
                          <>
                            <tr
                              key={index}
                              className="mt-3 flex2"
                              style={{ paddingTop: "10%" }}
                            >
                              {/* <td style={{ paddingBlock: "15px" }}>
                            
                                </td> */}

                              <td style={{ paddingBlock: "15px"}}>
                              <div style={{display: "flex", alignItems: "center", gap: "15px" }}>
                              {item.profile_pic ? (
                                  <img onClick={()=>handleImageClick(`${process.env.REACT_APP_MEDIA_URL}/${item.profile_pic}`)}
                                    src={`${process.env.REACT_APP_MEDIA_URL}${item.profile_pic}`}
                                    alt="logo"
                                    className="vendorPic" style={{cursor:"pointer"}}
                                  />
                                ) : (
                                  <AdminLotusPRO className="vendorPic" />
                                )}
                                     {visible && currentImage && (
                                                <Viewer
                                                    visible={visible}
                                                    onClose={() => setVisible(false)}
                                                    images={[
                                                        { src: currentImage, alt: "Image" },

                                                    ]} // Only show the clicked image
                                                    drag={false}
                                                />
                                            )}
                                <span className="ms-2 dot-property"> {item.name ? item.name : '-'}</span>
                                <span>
                                  {item.isVerified ? (
                                    <span className="verified">
                                      <VscVerifiedFilled style={{ fontSize: "20px", color: "#156A3D" }} />
                                    </span>
                                  ) : ""}
                                </span>
                              </div>
                              </td>
                              <td style={{ paddingBlock: "25px", textTransform: "lowercase" }}>
                                <span className="">
                                  {item.businessInfo?.businessName ? item.businessInfo?.businessName : "-"}
                                </span>
                              </td>
                              <td style={{ paddingBlock: "25px", textTransform: "lowercase" }}>
                                <span className="">
                                  {item.email ? item.email : "-"}
                                </span>
                              </td>
                              <td style={{ paddingBlock: "25px" }}>
                                <span>{formatDate(item.createdAt)}</span>
                              </td>
                              <td style={{ paddingBlock: "27px" }}>
                                <span>
                                  {item.phone ? item.phone : '-'}
                                </span>

                              </td>
                              {/* <td style={{ paddingBlock: "15px" }} >
                                  <span className="dot-property">
                                    {item.address ? item.address : "-"}
                                  </span></td> */}
                              <td style={{ paddingBlock: "15px" }}>
                                <div className="d-flex flex-row gap-2 align-items-center">
                                  <IOSSwitch
                                    checked={switches[index]}
                                    onChange={() => handleSwitchToggle(index, item.id)}
                                  />
                                  <span onClick={() => VenderDetails(item.id)} className="view-icon">
                                    <View />
                                  </span>
                                  <span onClick={() => VenderEdit(item.id, item)} className="edit-icon">
                                    <Edit />
                                  </span>
                                  <span className="delete-icon" onClick={() => handleOpenDeleteModal(item.id)}>
                                    <Delete />
                                  </span>
                                </div>

                              </td>
                            </tr>

                          </>
                        )
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <PaginationRounded
                    count={totalPage}
                    page={currentPage}
                    onChange={handlePageChange}
                  />


                </div>
              </div>
            </div>
          </div >
          <DeleteModal
            open={deleteModalOpen}
            handleClose={handleCloseDeleteModal}
            handleDelete={handleConfirmDelete}
          />
          <CreateVendor
            open={isOpen}
            handleClose={handleClose}
            GetVendorsData={GetVendorsData}
            editId={editId}
            vendorData={vendorData}
          />
          {/* for creating a new Vendor */}
          <CreateNewVendor
            open={newVendor} handleNewVednorClose={handleNewVednorClose} GetVendorsData={GetVendorsData}
          />
        </>
        {/* )} */}
      </Main >


    </>
  );
};

export default VendorManagement;
