import styled from "styled-components";

export const Main = styled.div`
 width: 100%;
background-color: var(--all-background);

.error {
    color: red;
    font-size: 12px;
}
.defaultimage{
    height: 72px;
    width: 67px;
}
.delete-icon{
    background-color: #FF0000;
    padding: 15px;
    border-radius: 6px;
    cursor: pointer;
}
.edit-icon{
    background-color: #FF8F50;
    height: 48px;
    width:48px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 6px; */
    border-radius: 5px;
    cursor: pointer;
}
.category{
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;  
}
.new-vender{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    gap: 10px;
    background-color: #FFBB02;
    color: white;
    font-family: poppins;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    border: 0px;
}
.add-button{
    width: 156px;
    height: 51px;
    border-radius: 5px;
    padding: 10px, 15px, 10px, 15px;
    gap: 10px;
    background-color: #FFBB02;
    color: white;
    border: 0;
    font-family: poppins;
    font-weight: 700;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
}
.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: 8px;
}
.sub-pic{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}
.singleRow{
    width: 100%;
    height: auto;
    border-radius: 5px;
    background-color: white;
    padding: 9px 16px;
}
.img-width{
    width: 50px;
    height: 50px;
    border-radius: 100px;
}
.style-furniture{
    font-family: poppins;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #252525;
    padding-left: 10%;
    padding-top: 10%;
    white-space: nowrap;
}
.flex-2{
    display: flex;
    align-items: center;
    flex-direction: row;
    /* gap: 10px; */
    justify-content: space-between;
    flex-wrap: wrap;
}
.enable-style{
    font-family: poppins;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 2%;
    margin: auto;
}
.Category{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    gap: 10px;
    background-color: #0C3A2D;
    font-family: poppins;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    color: white;
    border: 0px;
}
.arrow{
    width: 20px;
    height: 20px;
}
.flex-second{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 31px;
    border-bottom: 1px solid #E9E9E9;

    @media (max-width:520px) {
        justify-content: center;
        flex-wrap: wrap;
        
    }
}
.gap{
    display: flex;
    align-items: center;
    gap: 50px;
}

.style-sub-category{
    font-family: poppins;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #252525;
    /* padding-left: 10%; */
    padding-top: 10%;
    white-space: nowrap;
    padding: 0px;
}
.icons{
    height: 34px;
    width: 40px;
}
.style-category{
    font-size: 19px;
    margin-left: 35%;
    font-family: poppins;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    color: white;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
  
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius);
    outline: 0;
    border-radius: 20px;
    border: 0;
}
.background2{
    background-color: #FFBB02;
    border-radius: 20px 20px 0px 0px;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
    padding: 5% 15%;
}
.front{
    display: flex;
    gap: 6%;
    align-items: center;
}
.front2{
    display: flex;
    gap: 10%;
    align-items: center;
}
.img{
    width: 67px;
    height: 67px;
}
.input1{
    width: 245px;
    background-color: #F9F9F9;
    height: 52px;
    border: 0;
    outline: none;
    font-size: 14px;
    font-family: poppins;
    padding: 20px;
    font-weight: 500;
    color: #999999;

    @media (max-width: 500px) {
        width: 100%;
    }
}

.font{
    font-size: 14px;
    font-family: poppins;
    font-weight: 500;
    color: #0C3A2D;
}
.add-button2 {
    width: 100%;
    margin-top: 20px;
    height: 51px;
    border-radius: 5px;
    padding: 10px, 15px, 10px, 15px;
    gap: 10px;
    background-color: #0C3A2D;
    color: white;
    border: 0;
    font-family: poppins;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
    text-align: center;
}
.input2{
    width: 100%;
    background-color: #F9F9F9;
    height: 52px;
    border: 0;
    outline: none;
    font-size: 14px;
    font-family: poppins;
    padding: 20px;
    font-weight: 500;
    color: #999999;
    border-radius: 5px;
}
.label{
    font-family: poppins;
    font-weight: 500;
    font-size: 14px;
    color: #0C3A2D;
}

`