import React, { useState, useEffect } from 'react';
import { Card, Main, Product } from './style';
// import total from '../../../assets/img/user-management/total.png';
import Total from '../../../assets/img/collection/Group 1597883350.png';
import Products from '../../../assets/img/collection/Data collection.png';

import useHook from './usehook';
import { useParams, useNavigate } from 'react-router-dom';
import PaginationRounded from "components/pagination";
import { AdminLotusPRO, View, Delete } from 'assets/img'


const Collection = () => {
    const { getCollection } = useHook()

    const navigate = useNavigate();
    const [currentPage, setcurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const { id } = useParams();

    const handleClick = (id) => {
        // console.log("collection detail", id)
        navigate(`/vendor-management/collection-detail/${id}`)
    };


    // Collections 
    const [collectionList, setcollectionList] = useState({});
    const [totalproductCount, settotalProductCount] = useState();
    const getCollections = async () => {
        const formData = {
            userId: id,
            page: currentPage,
            perPage: 9,
        }
        try {
            const response = await getCollection(formData)
            setcollectionList(response.data)
            setTotalPage(Math.ceil(response.data.counts / 10));
            const totalProductCount = response.data.results.reduce((accumulator, item) => {
                return accumulator + item.productCount;
            }, 0);
            settotalProductCount(totalProductCount)
            // console.log("collection listing response", response.data)
        } catch (error) {
            console.log("response error", error)
        }
    }
    useEffect(() => {
        getCollections()
    }, [])
    const handlePageChange = (e, value) => {
        setcurrentPage(value);
    };



    return (
        <Main>
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-home3-tab" data-bs-toggle="pill" data-bs-target="#pills-home3" type="button" role="tab" aria-controls="pills-home3" aria-selected="true">
                        <div className='order-flex'>
                            <div className="card third border-0">
                                <div className="card-body">
                                    <div className='third-flex'>
                                        <img src={Total} alt="img" className='total-img' />
                                        <div className='margin'>
                                            <p className='m-0 order-para'>Total Collection</p>
                                            <p className='m-0 order-number'>{collectionList.counts}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-profile3-tab" data-bs-toggle="pill" data-bs-target="#pills-profile3" type="button" role="tab" aria-controls="pills-profile3" aria-selected="false"> <div className='order-flex'>
                        <div className="card third  border-0">
                            <div className="card-body">
                                <div className='third-flex'>
                                    <img src={Products} alt="img" className='total-img' />
                                    <div className='margin'>
                                        <p className='m-0 order-para'>Total Products in Collection</p>
                                        <p className='m-0 order-number'>{totalproductCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </button>
                </li>
            </ul>
            <div >
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home3-tab" tabIndex="0">
                        <div className='card-flex'>
                            {collectionList.results?.length > 0 ? (
                                <>
                                    {collectionList.results.map((items) => (
                                        <div className='card card-width' key={items.id} onClick={() => handleClick(items.id)}>
                                            <div className="card-body">
                                                <div>
                                                    {items.image ? (
                                                        <img
                                                            src={`${process.env.REACT_APP_MEDIA_URL}${items.image}`}
                                                            alt="logo"
                                                            className='img'
                                                        />
                                                    ) : (
                                                        <AdminLotusPRO className='img-default' />
                                                    )}
                                                    <div className='mt-3' style={{ cursor: "pointer" }}>
                                                        <p className='m-0 heading'>{items.name}</p>
                                                        <p className='m-0 sub-heading'>
                                                            {items.productCount} {items.productCount > 1 ? 'Products' : 'Product'}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    <div className='w-100 d-flex justify-content-end'>
                                        <PaginationRounded
                                            count={totalPage}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                        />
                                    </div>
                                </>
                            ) : (
                                <div className='no-record'>No Record Found</div>
                            )}


                        </div>
                    </div>
                    {/* <div className="tab-pane fade" id="pills-profile3" role="tabpanel" aria-labelledby="pills-profile3-tab" tabIndex="0">profile</div> */}
                </div>
            </div>
        </Main>
    );
};

export default Collection;

