import styled from "styled-components";

export const Main = styled.div`
  width: 100%;
  background-color: var(--all-background);
  .card-body {
    width: 100%;
    flex-wrap: nowrap;
  }
  .input-reason {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #94a3b8;
    padding: 15px;
    outline: none;
  }
  .dispute-list {
    width: 295px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e2e8f0;
    padding: 15px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    color: #808d9e;
    outline: none;
  }
  .filter-dispute {
    width: 150px;
    height: 48px;
    border-radius: 5px;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    color: white;
    border: 0px;
    background-color: #156a3d;
  }
  .nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: #808d9e;
    text-decoration: none;
    background: white;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
    width: 131px;
    height: 48px;
    border-radius: 7px 7px 0px 0px;
  }
  /* .nav-link:focus, .nav-link:hover {
    color: black;
} */
  .padding-dispute {
    padding: 0px 3px;
  }
  .nav-tabs .nav-link.active {
    color: white;
    background-color: #0c3a2d;
    border-color: var(--bs-nav-tabs-link-active-border-color);
  }
  .dispute-font {
    font-family: inter;
    font-size: 16px;
    font-weight: 400;
    white-space: nowrap;
    border-bottom: 0;
    color: #808d9e;
  }
  .dispute-content {
    background: white;
    margin: 0px 3px;
  }
  .card-border {
    border-radius: 0px 0px 7px 7px;
  }
  .table-heading {
    font-family: inter;
    font-weight: 700;
    font-size: 14px;
    color: #0c3a2d;
  }
  .table-row {
    font-family: inter;
    font-weight: 400;
    font-size: 14px;
    color: #1c1c27;
    border-bottom: 1px solid #e2e8f0;
  }
  .drop {
    width: 82px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff8f501a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ff8f50;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  .solved {
    width: 56px;
    height: 24px;
    border-radius: 5px;
    background-color: #209a421a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #209a42;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  .Declined {
    width: 67px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #ff0000;
    border: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }
  .status-Processing {
    width: 99px;
    height: 24px;
    border-radius: 5px;
    background-color: #00d1ff1a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #00d1ff;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-delivered {
    width: 90px;
    height: 24px;
    border-radius: 5px;
    background-color: #209a421a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #209a42;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-pending {
    width: 82px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff8f501a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff8f50;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .status-cancel {
    width: 75px;
    height: 24px;
    border-radius: 5px;
    background-color: #ff00001a;
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    color: #ff0000;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: 0px;
  }
  .custom-select {
    border: none;
    border-radius: 5px;
    width: 99px;
    height: 24px;
    outline: none;
  }

  .custom-select option {
    background-color: #fff;
  }
  .options {
    font-family: inter;
    font-family: 400;
    font-size: 12px;
    outline: none;
    border: 0px;
  }
  .pagination-flex {
    display: flex;
    justify-content: end;
  }
`;
