import styled from "styled-components";


export const Main = styled.div`
width: 100%;
background-color: var(--all-background);
.view-icon{
    background-color: #209A42;
    padding: 6px;
    border-radius: 6px;
    cursor: pointer;
}
.dot-property {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    margin-top: 40px;
    height: 30px;
    width: 120px;
  }
  .dot-property-product-listing{
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    margin-top: 0px;
    height: 20px;
    width: 120px;
  }
.display{
  display: flex;
    margin-left: 44%;
    gap: 0px;
}
.font-style{
  font-family: poppins;
  font-weight: 600;
  font-size: 12px;
  color: #252525;
}
.img-width1{
  width: 29px;
  height: 31px;
}
.vendor-image{
  top: -100px;
}
.flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.style-profile{
  font-family: poppins;
  font-weight: 600;
  font-size: 20px;
  color: #252525;
  margin: 0px;
  padding-block: 20px;
}
.display-flex{
    list-style: none;
    text-align: center;
    padding: 0px;
    display: flex;
    align-items: center;
    gap: 25px;
}
.tabs{
    display: flex;
    gap: 2%;
    border-bottom: 5px solid #E2E8F0;
    width: 655px; 

}
.active-border {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
}
.active-border:hover{
    border-bottom: 5px solid black;
}
/* #======================= */
.tab {
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}

.tab.active {
  position: relative; 
}

.tab.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  top: 44px;
  width: 100%;
  height: 5px; 
  background-color: black; 
}

.content {
  display: none;
}

.content.active {
  display: block;
}
.profile{
    font-family: inter;
    font-weight: 600;
    font-size: 16px;
    color: #1C1C27;
}
.women{
border-radius: 100px;
    width: 149px;
    height: 149px;
}
.padding{
  /* padding: 0px 25px; */
  font-family: inter;
  font-weight: 600;
  font-size: 24px;
  letter-spacing: 0.2px;
  color: white;
}
.email{
    font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #808D9E;
}
.email-name{
  font-family: inter;
    font-weight: 500;
    font-size: 16px;
    color: #1C1C27;
}
.email-flex{
  display: flex;
  gap: 57px;
}
.phone-flex{
  display: flex;
  gap: 22px;
}
.vendor-image-container{
  position: absolute;
   top: 100px;
    left: 30px ;
}
.black{
  margin-top: 60px;
  color: black;
  padding: 0px 25px;
}
/* .like-flex{
  display: flex;
  gap: 110px;
    padding: 4% 0%;
} */
.space-between{
  display: flex;
    justify-content: space-between;
}
.rating{
  font-family: inter;
  font-weight: 500;
  font-size: 16px;
  color: #1C1C27;
}
.rating-2{
  font-family: inter;
  font-weight: 500;
  font-size: 16px;
  color: #808D9E;
}
.bussiness-name{
  font-family: inter;
  font-weight: 400;
    font-size: 16px;
    color: #1C1C27;
}
/* #================== Table ========================== */
.order-flex{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 13px;
    margin-top: 3%;
}
.third-card{
    width: 264px;
    height: 92px;
    border: 1px solid #EDF2F7;
    /* margin-top: 1%; */

}
.third-flex{
    justify-content: start;
    display: flex;
    align-items: center;
}
.order-img{
    width: 62px;
    height: 62px;
}
.margin{
    margin-left: 8%;
}
.order-para{
    font-family: Inter;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: #1C1C27;
    white-space: nowrap;
}
.order-number{
    font-family: Inter;
    font-weight: 700;
    font-size: 26px;
    letter-spacing: 0.2px;
    color: #1C1C27;
    text-align: start;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color:white;
    background-color: #F9F9F9 !important;
}
.border1{
  width: 81px;
    height: 30px;
    background-color: #00D1FF1A;
    color: #00D1FF;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
}
.border2{
  width: 81px;
    height: 30px;
    background-color: #209A421A;
    color: #209A42;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
}
.border3 {
    width: 100px;
    height: 30px;
    background-color: #00D1FF1A;
    color: #00D1FF;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}


.border4 {
    width: 100px;
    height: 30px;
    background-color: #209A421A;
    color: #209A42;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}
.rating-number{
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-weight: 700;
  color: #1C1C27;
}
.cancel{
  background-color: #F084751A;
  color: #EB2A00;
  width: 73px;
    height: 24px;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}
.return{
  background-color: #FFE9D2;
  color: #B36618;
  width: 73px;
    height: 24px;
    padding: 1px 9px;
    padding: 1px 9px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin: 0;
    font-family: inter;
    font-size: 12px;
    font-weight: 400;
    outline: none;
    border: 0;
}
.total-img{
  width: 50px;
    height: 50px;
    background-color: #004E971A;
    padding: 10px;
    border-radius: 35px;
}
.cancel-img{
  width: 50px;
    height: 50px;
    background-color: #FF10101A;
    padding: 10px;
    border-radius: 35px;
}
.return-img{
  width: 50px;
    height: 50px;
    background-color: #8B56251A;
    padding: 6px;
    border-radius: 35px;
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;

    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: inter;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
    min-width: 48px;
    height: 48px;
    border-radius: 12px;
    padding: 0 6px;
    margin: 0 3px;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(0, 0, 0, 0.23);
}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #0C3A2D;
    color: #FFBB02;
}
.status{
  width: 65px;
    height: 28px;
    border-radius: 5px;
    /* background-color: #FF00001A; */
    font-family: inter;
    font-weight: 400;
    font-size: 12px;
    color: #FF0000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}
.styled-font{
  font-family: inter;
  font-weight: 400;
  font-size: 14px;
  color: #1C1C27;
}
.table-head{
  font-family: inter;
  font-weight: 700;
  font-size: 14px;
  color: #0C3A2D;
}
.status-Processing{
   width: 99px;
   height: 24px;
   border-radius: 5px;
   background-color: #00D1FF1A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #00D1FF;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-Delivered{
   width: 82px;
   height: 24px;
   border-radius: 5px;
   background-color: #209A421A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #209A42;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-pending{
    width: 82px;
   height: 24px;
   border-radius: 5px;
   background-color: #FF8F501A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF8F50;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-cancel{
    width: 75px;
   height: 24px;
   border-radius: 5px;
   background-color: #FF00001A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF0000;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-shipped{
    width: 75px;
   height: 24px;
   border-radius: 5px;
   background-color: #FFFED3;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #B1AFFF;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.custom-select {
    border: none;
    border-radius: 5px;
    width: 99px;
    height: 24px;
    outline: none;
}

.custom-select option {
  background-color: #fff;
}
.options{
    font-family: inter;
   font-family: 400;
   font-size: 12px;
   outline: none;
   border: 0px;
}
/* #================== Table ========================== */

/* 3============== Fields ================== */
.input{
  width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    /* color: #94A3B8; */
    outline: none;
}
.input2{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    /* color: #94A3B8; */
    outline: none;
}
/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.select1{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    /* color: #94A3B8; */
    outline: none;
}
.select2{
    width: 240px;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    /* color: #94A3B8; */
    outline: none;
}
.date{
    font-family: inter;
    font-weight: 600;
    font-size: 12px;
    /* color: #1C1C27; */
}
.date-input{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    gap: 88px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    /* color: #808D9E; */
    padding: 10px;
}
.flex{
    display: flex;
    gap: 10px;
}
.flex-buttons{
    display: flex;
    flex-direction: row;
    /* justify-content: end; */
    /* margin-right: 60px; */
    width: 100%;
    gap: 10px;
}
.filter{
  width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 0px;
    color: white;
}
.reset{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #E2E8F0;
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 0px;
    color: #808D9E;
}

/* 3============== Fields ================== */

`


/* 3============== tabs ================== */
export const TabsContainer = styled.div`

.nav-pills .nav-link.active, .edagDK .nav-pills .show>.nav-link {
    color: black;
    background-color: #F9F9F9 !important;
}

.nav-link {
    display: block;
    padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    color: black;
    text-decoration: none;
    background: 0 0;
    border: 0;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}
.input-approved{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    /* color: #94A3B8; */
    outline: none;
}
.select-approved{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    /* color: #94A3B8; */
    outline: none;
}
.select-approved{
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #E2E8F0;
    padding: 10px;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    /* color: #94A3B8; */
    outline: none;
}
.filter-approved{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 0px;
    color: white;
}
.reset-approved{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #E2E8F0;
    font-family: inter;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border: 0px;
    color: #808D9E;
}
.edit{
    width: 31px;
    height: 32px;
}
.status-delivered{
   width: 70px;
   height: 24px;
   border-radius: 5px;
   background-color: #209A421A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #209A42;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-pending{
    width: 82px;
   height: 24px;
   border-radius: 5px;
   background-color: #FF8F501A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF8F50;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
.status-cancel{
    width: 65px;
   height: 24px;
   border-radius: 5px;
   background-color: #FF00001A;
   font-family: inter;
   font-family: 400;
   font-size: 12px;
   color: #FF0000;
   display: flex;
   justify-content: center;
   align-items: center;
   outline: none;
   border: 0px;
}
/* product listing */
.container-fields{
  display: flex;
  flex-wrap: wrap;
}
.container-body-filed{
  display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
}
/* Product listing */
@media (max-width:1000px) {
  .container-body-filed{
display: flex;
flex-direction: column;
gap: 12px;
}
.filter-approved{
  width: 100%;
}
.reset-approved{
  width: 100%;
}
}

@media (max-width:550px) {
.container-body-filed{
display: flex;
flex-direction: column;
gap: 12px;
}
.filter-approved{
  width: 100%;
}
.reset-approved{
  width: 100%;
}
}

`
/* 3============== tabs ================== */