import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: false,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    // showEasing: "swing",
    // hideEasing: "linear",
    // showMethod: "fadeIn",
    // hideMethod: "fadeOut"
  };

  
  const useHook = () => {
    const catalogGetSingle = async (id) => {
        try {
            const response = await AuthAPI.getCatalogSingle(id);
            // console.log("usehook response", response)
            console.log("running")
            return response
        } catch (error) {
            toastr.error("An error occurred during vendor creation")
        }
    }
    const catalogimagesGet = async (id) => {
        try {
            const response = await AuthAPI.getCatalogImages(id);
            // console.log("usehook response", response)
            return response
        } catch (error) {
            toastr.error("An error occurred during vendor creation")
        }
    }

    return {
        catalogGetSingle,
        catalogimagesGet
    }
  }
  
  export default useHook;