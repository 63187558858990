import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PaginationRounded from "components/pagination";
import { Star } from "../../../assets/img/index";
import useHook from "./usehook";

import { AdminLotusPRO, View } from 'assets/img'
const ProductListing = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { productlisting, getcategory } = useHook();
  // product Listing
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [productlist, setproductlist] = useState([]);
  const [search, setSearch] = useState("");
  const [categoryData, setCategoryData] = useState([]);
  const [searchCategory, setSearchCategory] = useState("");
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [searchSubCategory, setSearchSubCategory] = useState("");
  const [selectedSubCategoryId, setSelectedSubCategoryId] = useState(null);
  const getproducts = async (resetPage = false) => {
    const formData = {
      userId: id,
      search: search,
      categoryId: selectedSubCategoryId,
      page: resetPage ? 1 : currentPage,
      perPage: 10,
    };
    try {
      const response = await productlisting(formData);
      setTotalPage(Math.ceil(response.data.counts / 10));
      // console.log("product listing response", response)
      setproductlist(response.data.results);
    } catch (error) {
      console.log("response error", error);
    }
  };


  const handleReset = async () => {
    setSearch("");
    setSelectedSubCategoryId(null);
    setSearchCategory("");
    setSearchSubCategory("");
  };
  useEffect(() => {
    if (
      search === "" &&
      // selectedSubCategoryId === "" &&
      searchCategory === "" &&
      searchSubCategory === ""
    ) {
      getproducts(true)
    }
  }, [search,selectedSubCategoryId,searchCategory,searchSubCategory]);

  useEffect(() => {
    getproducts();
  }, []);

  const handlePageChange = (e, value) => {
    setcurrentPage(value);
  };
  const handleProductDetail = (id) => {
    navigate(`/product-detail/${id}`);
  };
  const handleFilter = () => {
    let params = `?page=${currentPage}&perPage=10`;

    if (search) {
      params += `&search=${encodeURIComponent(search)}`;
    }
    if (selectedSubCategoryId) {
      params += `&categoryId=${encodeURIComponent(selectedSubCategoryId)}`;
    }

    getproducts(params);
  };

  //  GET CATEGORY AND SUB_CATEGORY
  const getCategoryData = async () => {
    try {
      const response = await getcategory();
      const activeCategories = response.data.filter(
        (category) => category.isActive === true
      );
      setCategoryData(activeCategories);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
   
    getCategoryData();
  }, []);

  const handleCategoryChange = async (e) => {
    const selectedCategoryId = e.target.value;
    setSearchCategory(selectedCategoryId);

    if (selectedCategoryId) {
      try {
        const response = await getcategory({
          parentId: Number(selectedCategoryId),
        });
        const activeSubCategories = response.data.filter(
          (subcategory) => subcategory.isActive === true
        );
        setSubCategoryData(activeSubCategories);
      } catch (error) {
        console.error(error);
      }
    } else {
      setSubCategoryData([]);
    }
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = e.target.value;
    setSearchSubCategory(selectedSubCategoryId);

    const selectedSubCategory = subCategoryData.find(
      (subcategory) => subcategory.id === Number(selectedSubCategoryId)
    );

    if (selectedSubCategory) {
      console.log("Selected Sub-Category:", selectedSubCategory);
      setSelectedSubCategoryId(selectedSubCategory.id); // Save the sub-category ID in state
    }
  };

  return (
    <>
      <div className="card border-0">
        <div className="card-body">
          <div className="container-fields">
            <div className="container-body-filed w-100">
              <div className="w-100">
                <input
                  type="text"
                  value={search}
                  className="input-approved me-2"
                  placeholder="Search Product"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <div className="w-100">
                <select
                  name="category"
                  id="category"
                  value={searchCategory}
                  className="input-approved "
                  onChange={handleCategoryChange}
                >
                  <option value="">Select Category</option>
                  {categoryData.map((category, index) => (
                    <option key={index} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="w-100">
                <select
                  name="subcategory"
                  id="subcategory"
                  value={searchSubCategory}
                  className="input-approved "
                  onChange={handleSubCategoryChange}
                >
                  <option value="">Select Sub-Category</option>
                  {subCategoryData.map((subcategory, index) => (
                    <option key={index} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex-buttons w-100">
                <div className="w-100" onClick={handleFilter}>
                  <button type="submit" className="filter-approved">
                    Filter
                  </button>
                </div>
                <div className="w-100">
                  <button className="reset-approved" onClick={handleReset}>
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-4 border-0 ">
        <div className="card-body table-responsive">
          <table className="w-100 table">
            <thead>
              <tr className="table-head">
                <th>ID</th>
                <th>Image</th>
                <th>Product Name</th>
                <th>Ratings</th>
                <th>Category</th>
                <th>Sub Category</th>
                <th>Price</th>
                <th>Sale Price</th>
                <th>Stock</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            {productlist.length === 0 ? (
              <tbody className="waleed-margin">
                <tr>
                  <td colSpan="10" >
                   <div className="no-record">No records found</div> 
                  </td>
                </tr>
              </tbody>
            ) : (
              productlist.map((items, index) => (
                <tbody className="waleed-margin" key={index}>
                  <tr
                    className="mt-3 flex2 styled-font"
                    style={{ paddingTop: "10%" }}
                  >
                    <td style={{ paddingTop: "3%" }}>{items.id}</td>
                    {items.images && items.images.length > 0 && (
                      <td style={{ paddingTop: "3%" }}>
                        {items.images[0].url ? (
                          <img
                            src={`${process.env.REACT_APP_MEDIA_URL}${items.images[0].url}`}
                            alt="logo"
                            width="50"
                            height="50"
                          />
                        ) : (
                          <AdminLotusPRO width="50"
                          height="50" style={{borderRadius:"5px"}} />
                        )}
                      </td>
                    )}

                    <td style={{ paddingTop: "3%" }} >
                     <span className="dot-property-product-listing">{items?.name}</span> 
                    </td>
                    <td style={{ paddingTop: "3%" }}>
                      {" "}
                      <span className="d-flex flex-row align-items-center">
                        <Star />{" "}
                        <span>{items.totalRatting}</span>
                      </span>{" "}
                    </td>
                    <td style={{ paddingTop: "3%" }}>
                      {items.category?.parent?.name}
                    </td>
                    <td style={{ paddingTop: "3%" }}>{items.category?.name}</td>
                    <td style={{ paddingTop: "3%" }}>{items.productPrice}</td>
                    <td style={{ paddingTop: "3%" }}>{items.salePrice}</td>
                    <td style={{ paddingTop: "3%" }}>{items.qnty}</td>
                    <td style={{ paddingTop: "3%" }}>
                      {" "}
                      <p
                        className={`status-${items.qnty >= 1 ? "delivered" : "cancel"
                          } m-0`}
                      >
                        {items.qnty >= 1 ? "In Stock" : "Sold Out"}
                      </p>
                    </td>
                    <td style={{ paddingTop: "3%" }}>
                      <span
                        className="view-icon"
                        onClick={() => handleProductDetail(items.id)}
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_1971_40786)">
                            <path
                              d="M15.1028 7.57304C14.9698 7.41807 11.7897 3.7832 7.72439 3.7832C3.65897 3.7832 0.479027 7.41807 0.345651 7.57319C0.0839761 7.87705 0.0839761 8.31794 0.345805 8.6221C0.479027 8.77706 3.65897 12.4118 7.72439 12.4118C11.7897 12.4118 14.9698 8.77676 15.103 8.62179C15.3647 8.31794 15.3647 7.87704 15.1028 7.57304ZM8.96615 5.96671C9.23952 5.81934 9.62073 5.98843 9.81748 6.34425C10.0142 6.70021 9.95209 7.10829 9.67872 7.25566C9.40551 7.40318 9.02415 7.23394 8.82724 6.87812C8.63064 6.52215 8.69263 6.11408 8.96615 5.96671ZM7.72439 11.0949C4.88458 11.0949 2.49474 8.94099 1.66756 8.09749C2.22614 7.52776 3.4979 6.36111 5.13918 5.66346C4.81905 6.14446 4.63137 6.7192 4.63137 7.33785C4.63137 9.02486 6.01604 10.3924 7.72424 10.3924C9.43243 10.3924 10.8173 9.02486 10.8173 7.33785C10.8173 6.7192 10.6297 6.14446 10.3096 5.66346C11.9509 6.36126 13.2225 7.52776 13.7811 8.09749C12.9539 8.94145 10.564 11.0949 7.72439 11.0949Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1971_40786">
                              <rect
                                width="15.1498"
                                height="14.9618"
                                fill="white"
                                transform="translate(0.149414 0.617188)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                    </td>
                  </tr>
                </tbody>
              ))
            )}
          </table>

          <PaginationRounded
            count={totalPage}
            page={currentPage}
            onChange={handlePageChange}
          />

        </div>
      </div>
    </>
  );
};

export default ProductListing;
