import React, { useEffect, useState } from 'react'
import {Main, Table, TableContainer } from './style'
import Spinner from 'components/loader';
import PaginationRounded from 'components/pagination';
import WithdrawRequest from 'components/modals/withdraw-request';
import useHook from './usehook';
import toastr from 'toastr';
import Viewer from "react-viewer";
import { ApprovedPayment } from 'assets/img';
import { VscVerifiedFilled } from "react-icons/vsc";

import Transfer from 'assets/img/withraw/Transfer.png'
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    showEasing: "swing",
    hideEasing: "linear",
    showMethod: "fadeIn",
    hideMethod: "fadeOut"
};

const Withdraw = () => {

    const [searchVendor, setSearchVendor] = useState("");
    const [searchCustomer, setSearchCustomer] = useState("");
    const [status, setStatus] = useState("");
    // const [invoiceNumber, setInvoiceNumber] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [currentPage, setcurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [userlength, setuserlength] = useState(20);
    const [userlist, setuserlist] = useState([]);
    const { getAllWithdrawRequest } = useHook()
    // request modal
    const [requestModal, setRequestModal] = useState(false)
    const [currentItem, setCurrentItem] = useState({})
    const handlemodalopen = (item) => {
        console.log("withdraw item is ", item)
        setCurrentItem(item)
        setRequestModal(true)
    }
    const handlemodalclose = () => {
        setRequestModal(false)
    }
    const handleStartDateChange = (date) => {
        const selectedStartDate = new Date(date);

        if (endDate && selectedStartDate > new Date(endDate)) {
            setEndDate(null);
        }

        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        const selectedEndDate = new Date(date);

        if (startDate && selectedEndDate < new Date(startDate)) {
            toastr.error("End date cannot be earlier than start date");
            return;
        }

        setEndDate(date);
    };
    const handleStatus = (e) => {
        setStatus(e.target.value);
    };
    const handleReset = (e) => {
        e.preventDefault()
        setSearchVendor("");
        setStatus("");
        setStartDate("");
        setEndDate("");
        setcurrentPage(1); // Reset to page 1
        handleWithdrawlist(true); // Pass true to reset the page to 1 in the API call
    };
    const handlePageChange = (e, value) => {
        setcurrentPage(value);
    };

    const handleWithdrawlist = async (resetPage = false) => {
        setLoading(true);
        const formData = {
            search: searchVendor,
            status: status,
            startDate: startDate,
            endDate: endDate,
            page: resetPage ? 1 : currentPage,
            perPage: 10,

        }
        try {
            const response = await getAllWithdrawRequest(formData);
            setuserlist(response.data.results)
            setTotalPage(Math.ceil(response.data.counts / 10));
            console.log("withdaw list is : ", response)
        } catch (error) {
            console.error("Error while getting the withdraw list", error)
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        handleWithdrawlist();
    }, [currentPage])
    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "short", day: "numeric" };
        const date = new Date(dateString);
        return date.toLocaleDateString("en-US", options);
    };
    useEffect(() => {
        if (!requestModal) {
            handleWithdrawlist();
        }
    }, [requestModal])
    const handleFilter = (e) => {
        e.preventDefault()
        setcurrentPage(1);
        handleWithdrawlist(true);
    }
    // image preview
    const [visible, setVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(null); // Store the currently clicked image
    const handleImageClick = (imageSrc) => {
        setCurrentImage(imageSrc); // Set the current image to show in viewer
        setVisible(true); // Open the viewer
    };

    useEffect(() => {
        if (searchVendor === "" && status === "" && startDate === "" && endDate === "") {
            handleWithdrawlist(true); // Call API only when all filters are cleared
        }
    }, [searchVendor, status, startDate, endDate]);
    return (
        <Main>
            {loading ? (
                <Spinner /> // Full-page loader
            ) : (
                <div className='container-fluid'>
                    <div>
                        <p className='withdraw m-0'>Withdraw Request</p>
                    </div>

                    <div className="card border-0">
                        <div className="card-body">
                            <div className="gap-2 create-vendor">
                                <div className="search-vendor">
                                    <input
                                        value={searchVendor}
                                        onChange={(e) => setSearchVendor(e.target.value)}
                                        type="text"
                                        className="input2 me-2"
                                        placeholder="Search by Vendor/ IBAN number"
                                    />
                                </div>
                                <div className="select-status">
                                    <select
                                        className="select1 me-2"
                                        onChange={handleStatus}
                                        value={status}
                                    >
                                        <option value=''>status</option>
                                        <option value="pending">Pending</option>
                                        <option value="approved">Approved</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex mt-3 container-vendor">
                                <div className="w-100">
                                    <label className="date">Start Date</label> <br />
                                    <input
                                        type="date"
                                        className="date-input"
                                        name=""
                                        id=""
                                        onChange={(e) => handleStartDateChange(e.target.value)}
                                        value={startDate || ""}
                                    />
                                </div>
                                <div className="w-100">
                                    <label className="date">End Date</label> <br />
                                    <input
                                        type="date"
                                        className="date-input"
                                        name=""
                                        id=""
                                        onChange={(e) => handleEndDateChange(e.target.value)}
                                        value={endDate || ""}
                                    />
                                </div>
                                <div className="mt-4 w-50 button-container">
                                    <button type="submit" className="filter" onClick={handleFilter}>
                                        Filter
                                    </button>
                                </div>
                                <div className="mt-4 w-50">
                                    <button type="submit" className="reset" onClick={handleReset}>
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* table */}
                    <div className="card mt-4 border-0 ">
                        <div className="card-body">
                            <TableContainer>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>Id</th>
                                            <th>Vendor Name</th>
                                            <th>Amount</th>
                                            <th>IBAN Number</th>
                                            <th>Status</th>
                                            <th>Image</th>
                                            <th>Requested Date</th>
                                            <th>Approved Date</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    {userlist.length > 0 ? (
                                        userlist.map((item, index) => (
                                            <tbody>

                                                <tr key={index}>
                                                    <td style={{ paddingBlock: "20px" }}>{item.id}</td>
                                                    <td style={{ paddingBlock: "20px" }}>{item.user?.name}</td>
                                                    <td style={{ paddingBlock: "20px" }}>{item.amount}</td>
                                                    <td style={{ paddingBlock: "20px" }}>{item.account?.accountNumber}</td>
                                                    <td style={{ paddingBlock: "20px" }} className=''>
                                                        <p className={`status-${item.status === "pending"
                                                            ? "pending" : 'approved'
                                                            } m-0`}>{item.status}</p>

                                                    </td>
                                                    <td style={{ paddingBlock: "20px" }}>
                                                        {item.status === "approved" && (
                                                            <img onClick={() => handleImageClick(`${process.env.REACT_APP_MEDIA_URL}/${item.image}`)}
                                                                src={`${process.env.REACT_APP_MEDIA_URL}/${item.image}`}
                                                                alt="profile"
                                                                width="40" style={{ cursor: "pointer" }}
                                                                height="40"
                                                            />
                                                        )}
                                                    </td>
                                                    {visible && currentImage && (
                                                        <Viewer
                                                            visible={visible}
                                                            onClose={() => setVisible(false)}
                                                            images={[
                                                                { src: currentImage, alt: "Image" },
                                                            ]} // Only show the clicked image
                                                            drag={false}
                                                            disableMouseZoom={true}
                                                            zoomable={false}
                                                        />
                                                    )}
                                                    <td style={{ paddingBlock: "20px" }}>{formatDate(item.createdAt)}</td>
                                                    <td style={{ paddingBlock: "20px" }}>{formatDate(item.updatedAt)}</td>
                                                    <td style={{ paddingBlock: "20px" }}>
                                                        {item.status === "pending" ? (
                                                            <>
                                                        <span className='transfer-icon' onClick={() => handlemodalopen(item)} >
                                                        <img src={Transfer} alt="transfer" className='transfer-img'/>
                                                        </span>
                                                            </>
                                                          
                                                        ) : (
                                                            <span className="verified">
                                                            <VscVerifiedFilled style={{ fontSize: "20px", color: "#156A3D" }} />
                                                          </span>
                                                        )}
                                                    </td>
                                                </tr>

                                            </tbody>
                                        ))
                                    ) : (
                                        <tr className="no-record w-100">
                                            <td colSpan={9} className='no-record w-100' >No Record Found</td>
                                        </tr>
                                    )}

                                </Table>
                            </TableContainer>

                        </div>
                    </div>
                    <PaginationRounded
                        count={totalPage}
                        page={currentPage}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <WithdrawRequest open={requestModal} onClose={handlemodalclose} currentItem={currentItem} />
        </Main>
    )
}

export default Withdraw
