import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from 'toastr';
toastr.options = {
    closeButton: true,
    debug: false,
    newestOnTop: false,
    progressBar: true,
    positionClass: "toast-top-right",
    preventDuplicates: true,
    onclick: null,
    showDuration: "300",
    hideDuration: "1000",
    timeOut: "3000",
    extendedTimeOut: "1000",
    // showEasing: "swing",
    // hideEasing: "linear",
    // showMethod: "fadeIn",
    // hideMethod: "fadeOut"
  };

  
  const useHook = () => {
    
    const getBanners = async (body) => {
        try {
            const response = await AuthAPI.bannerGet(body);
            console.log("banner get response", response);
            // toastr.success("Banners get successfully");
            return response.data || [];
        } catch (error) {
            toastr.error('There was an error getting the color list: ' + error.message);
            return [];
        }
       
    };
    const bannersPut = async (id, status,name) => {
        try {
            const response = await AuthAPI.bannerPut(id, { status,name:name });
            // toastr.success("Switch updated successfully");
            return response.data || [];
        } catch (error) {
            console.error('Error details:', error);
            return [];
        }
    };
    const bannerDelete=async(id)=>{
        try{
            const response = await AuthAPI.bannerDelete(id); 
            return response
        }
        catch(error){
            console.error(error);
                //   toastr.error("Error. Try Agan Later")
                  return null;
        }
    }

    return {
        getBanners,bannersPut,bannerDelete
    }
  }
  
  export default useHook;
  