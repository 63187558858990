// import styled from "styled-components";
// export const Main = styled.div`
// width: 100%;


// .pagination {
//   display: flex;
//   justify-content: center;
//   margin: 20px 0;
// }

// .page-item {
//   margin: 0 5px;
//   padding: 10px 15px;
//   border: 1px solid #ddd;
//   cursor: pointer;
// }

// .page-item.active {
//   background-color: #007bff;
//   color: white;
//   border-color: #007bff;
// }

// .page-item:hover {
//   background-color: #0056b3;
//   color: white;
//   border-color: #0056b3;
// }

// `

import styled from "styled-components";
export const Main = styled.div`

display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  
  .showing{
        font-family: inter;
        font-weight: 600;
        font-size: 16px;
        letter-spacing: 0.2px;
        color: #0F172A;
    }
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    background-color: #0C3A2B !important;
    color: #FFBB02;

}
.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    vertical-align: middle;
    -webkit-text-decoration: none;
    text-decoration: none;
    color: inherit;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    border-radius: 4px;
    text-align: center;
    box-sizing: border-box;
    min-width: 32px;
    /* height: 32px; */
    padding: 0 6px;
    margin: 0 3px;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid #E2E8F0 !important;
    transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(0, 0, 0, 0.23);

  
}
`