import { AuthAPI } from "libs/http-service/api/auth.api";
import toastr from "toastr";
const useHook = () => {
  const singleUserGet=async (id) => {
    try {
      const response = await AuthAPI.sigleUserget(id);
      return response;
    } catch (error) {
      console.error(error)
      return null;
    }
  };

  const customerOrderDetailGet=async (id) => {
    try {
      const response = await AuthAPI.customerOrderDetail(id);
      return response;
    } catch (error) {
      console.error(error)
      return null;
    }
  };
const getOrders = async (body) => {
  try {
    
    const response = await AuthAPI.getOrders(body);
    return response;
  } catch (error) {
    console.error(error);
    // toastr.error(error.message);
    return null;
  }
};


  return {  singleUserGet,customerOrderDetailGet,getOrders };
};


export default useHook;
