import styled from 'styled-components';

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: white;
  width: 100%;
  flex-wrap: wrap;


  .lotus-pro{
    width: 118px;
    height:118px;
    border-radius: 100px;

  }
  .profile-pic{
    border-radius: 100px;
  }
  .profile-picture{
width: 120px;
border-radius: 100px;
border: 1px solid #D9D9D9;
border-style: dashed;
height: 120px;
  }
  .search{
    width: 150px;
    height: 48px;
    border-radius: 5px;
    background-color: #156A3D;
    border: 0;
    font-family: inter;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    color: #FFFFFF;
}
@media (max-width:965px) {
  width: 800px;
  overflow-x: auto; 
  overflow: visible;
  }

/* @media (min-width: 600px) and (max-width:1100px) {
  overflow-x: auto;
    width: 100%;

} */
    @media (max-width:600px) {
      display: flex;
  align-items: center;
  justify-content: center;
flex-direction: column;
  background-color: white;
  width: 100%;
    .profile-picture{
      margin-bottom: 15px;
    }
    .name-container{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    h6{
      width:30%;
      margin-bottom: 0px;
      text-align: left;
    }
    span{
      width:30%
    }
    .email-container{
      width: 100%;
      margin-top: 5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      text-align: left;
      gap: 20px;
    }
    .phone-container{
      margin-top: 5px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    .search{
      margin-top: 10px;
      width: 100%;
    }
  }


`;
