import styled from "styled-components";

export const Main = styled.div`
 /* width: 100%; */
background-color: var(--all-background);
border-radius: 20px;


.style-category{
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 30px;
text-align: center;
margin-left: 30%;

}
.close-icon{
    font-size: 20px;
    cursor: pointer;
}
.error {
    color: red;
    font-size: 12px;
}
.img{
    width: 67px;
    height: 67px;
}
.background2{
    /* background-color: #FFBB02; */
    background-color: #0C3A2D;
    border-radius: 20px 20px 0px 0px;
    color:white;
}
.front2{
    display: flex;
    gap: 10%;
    align-items: center;
}
.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
    padding: 5% 5%;
}
.input1{
    width: 245px;
    background-color:rgba(0,0,0,0.05);
    height: 48px;
    border: 0;
    border-radius: 5px;
    outline: none;
    font-size: 14px;
    font-family: poppins;
    padding: 20px;
    font-weight: 500;
    color: #999999;

    @media (max-width: 500px) {
        width: 100%;
    }
}
.add-button2 {
    width: 100%;
    margin-top: 20px;
    height: 48px;
    border-radius: 5px;
    padding: 10px, 15px, 10px, 15px;
    gap: 10px;
    background-color: #0C3A2D;
    color: white;
    border: 0;
    font-family: poppins;
    font-weight: 500;
    font-size: 19px;
    line-height: 21px;
    text-align: center;
}

`